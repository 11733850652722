import { MPID_COOKIE_NAME } from '@wallapop/analytics/src/mparticle-lib.constants';
import { HttpClientInterceptor, HttpClientRequestOnFulfilledInterceptor } from '../http-client.types';
import { getMPID } from '@wallapop/analytics';

export const MPID_HEADER_NAME = 'MPID'.toLowerCase();

interface MParticleInterceptorOptions {
  baseUrl: string;
  getCurrentCookies?: () => Partial<Record<string, string>>;
}

type CreateParticleInterceptor = (options: MParticleInterceptorOptions) => HttpClientInterceptor;

export const createMParticleInterceptor: CreateParticleInterceptor = (options) => {
  const { baseUrl, getCurrentCookies: getCookies } = options;

  const onFulfilledInterceptor: HttpClientRequestOnFulfilledInterceptor = (request) => {
    request.headers ??= {};
    const mpidFromCookies = getCookies?.() ? getCookies?.()[MPID_COOKIE_NAME] : null;

    const isWallapopRequest = request.url?.startsWith(baseUrl);
    const MPID = mpidFromCookies || getMPID();

    if (isWallapopRequest && MPID) {
      request.headers[MPID_HEADER_NAME] = MPID;
    }

    return request;
  };

  return {
    request: {
      onFulfilled: onFulfilledInterceptor,
    },
  };
};
