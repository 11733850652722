export const ACCEPT_HEADERS = {
  SUGGESTERS_V3: 'application/vnd.api.v3.suggesters.object-type.v3+json',
  CATEGORIES_V2: 'application/vnd.categories-v2+json',
  UPLOAD_V2: 'application/vnd.upload-v2+json',
};

export const CACHE_CONTROL_HEADERS = {
  NO_CACHE: 'no-cache',
};

export const HEADER_NAMES = {
  ACCEPT: 'Accept',
  APPVERSION: 'X-AppVersion',
  ACCEPT_LANGUAGE: 'Accept-Language',
  CACHE_CONTROL: 'Cache-Control',
  NEXT_PAGE: 'X-NextPage',
  SEARCH_ID: 'x-wallapop-search-id',
  REQUEST_COUNTRY_CODE: 'X-Wallapop-Request-Country-Code',
};
