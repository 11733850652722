import { ASSETS_INTERFACES } from '@wallapop/web-components';

export const mapIconNameToDesignSystemAsset = (icon: string): ASSETS_INTERFACES | undefined => {
  if (!icon) return undefined;
  switch (icon) {
    case 'bookshelf':
      return ASSETS_INTERFACES.OPEN_BOOK;
    case 'ball':
      return ASSETS_INTERFACES.BASKETBALL;
    case 'furniture':
      return ASSETS_INTERFACES.SOFA;
    case 'helmet':
      return ASSETS_INTERFACES.HELMET;
    case 'laundry':
      return ASSETS_INTERFACES.WASHING_MACHINE;
    case 'baby_car':
      return ASSETS_INTERFACES.PRAM;
    case 'collecting':
      return ASSETS_INTERFACES.PAINTING;
    case 'building':
      return ASSETS_INTERFACES.BRICKS;
    case 'farming':
      return ASSETS_INTERFACES.SICKLE;
    case 'jobs':
      return ASSETS_INTERFACES.BRIEFCASE;
    case 'toolbox':
      return ASSETS_INTERFACES.WRENCH;
    case 'car':
      return ASSETS_INTERFACES.CAR;
    case 'motorbike':
      return ASSETS_INTERFACES.MOTORBIKE;
    case 't-shirt':
      return ASSETS_INTERFACES.TSHIRT;
    case 'house':
      return ASSETS_INTERFACES.HOUSE;
    case 'robot':
      /*
       * The previous robot icon is deprecated.
       * We have to display 'Chip', but the backend is still sending 'Robot' for mobile compatibility.
       * This should be removed once Backend returns 'Chip' instead of 'Robot'. In the webapp, `robot` icon should be removed too.
       */
      return ASSETS_INTERFACES.CHIP;
    case 'chip':
      return ASSETS_INTERFACES.CHIP;
    case 'bike':
      return ASSETS_INTERFACES.BIKE;
    case 'pc':
      return ASSETS_INTERFACES.COMPUTER;
    case 'tv':
      return ASSETS_INTERFACES.TV;
    case 'phone':
      return ASSETS_INTERFACES.CELLPHONE;
    case 'gamepad':
      return ASSETS_INTERFACES.GAME_CONTROLLER;
    case 'ghost':
      return ASSETS_INTERFACES.ELLIPSIS_HORIZONTAL;
    case 'qr_code':
      return ASSETS_INTERFACES.QR_CODE;
    case 'co2':
      return ASSETS_INTERFACES.CLOUD_CO2;
    case 'h2o':
      return ASSETS_INTERFACES.TAP;
    case 'plastic':
      return ASSETS_INTERFACES.BOTTLE;
    case 'coupe_cabrio':
      return ASSETS_INTERFACES.COUPE;
    case 'family_car':
      return ASSETS_INTERFACES.STATION_WAGON;
    case 'mini_van':
      return ASSETS_INTERFACES.MINIVAN;
    case '4X4':
      return ASSETS_INTERFACES.FOURXFOUR_2;
    default:
      return icon as ASSETS_INTERFACES;
  }
};
