import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { Bullet } from './models/bullets.interface';

@Injectable()
export class BulletsService {
  private bulletsSubject$: ReplaySubject<Bullet> = new ReplaySubject();

  public get bullets$(): Observable<Bullet> {
    return this.bulletsSubject$.asObservable();
  }

  public set bullets(value: Bullet) {
    this.bulletsSubject$.next(value);
  }
}
