import { NgModule, Optional, SkipSelf } from '@angular/core';
import { UnreadChatMessagesService } from '@core/unread-chat-messages/unread-chat-messages.service';
import { CookieModule } from 'ngx-cookie';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EventService } from './event/event.service';
import { GeolocationService } from './geolocation/geolocation.service';
import { AccessTokenService } from './access-token/services/access-token.service';
import { I18nService } from './i18n/i18n.service';
import { PaymentService } from './payments/payment.service';
import { DevelopmentGuard } from './user/development.guard';
import { LoggedGuard } from './user/logged.guard';
import { SocialShareService } from './social-share/social-share.service';
import { AnalyticsService } from './analytics/analytics.service';
import { DeviceService } from './device/device.service';
import { LoadExternalLibsService } from './load-external-libs/load-external-libs.service';
import { FeatureFlagService } from './user/featureflag.service';
import { SessionService } from './session/session.service';
import { UserService } from './user/user.service';
import { ItemService } from './item/item.service';
import { AdsModule } from './ads/ads.module';
import { HAMMER_PROVIDER } from './hammerjs/hammerjs-provider';
import { HammerModule } from '@angular/platform-browser';
import { ReleaseVersionService } from './release-version/release-version.service';
import { SlugsUtilService } from './services/slugs-util/slugs-util.service';
import { MomentCalendarSpecService } from './i18n/moment/moment-calendar-spec.service';
import { ReportService } from './trust-and-safety/report/report.service';
import { SvgService } from './svg-icon/svg.service';
import { SearchNavigatorService } from '@core/search/search-navigator.service';
import { SearchQueryStringService } from '@core/search/search-query-string.service';
import { QueryStringLocationService } from '@core/search/query-string-location.service';
import { MonitoringModule } from './monitoring/monitoring.module';
import { LottieService } from './lottie/lottie.service';
import { ExperimentationService } from './experimentation/services/experimentation/experimentation.service';
import { StandaloneService } from './standalone/services/standalone.service';
import { WindowMessageService } from './window-message/services/window-message.service';
import { NotificationApiModule } from '@api/notification/notification-api.module';
import { OneTapLoginService } from '@public/features/login/core/services/one-tap-login.service';
import { OneTrustService } from './ads/vendors/oneTrust/one-trust.service';
import { TermsNCommsModule } from './terms-n-comms/terms-n-comms.module';
import { LeakedCredentialsModule } from './leaked-credentials/leaked-credentials.module';
import { BulletsService } from './bullets/bullets.service';
import { RecentSearchesApiModule } from '../api/search/recent-searches/recent-searches-api.module';
import { FavoriteSearchesApiModule } from '@api/favorite-searches/favorite-searches-api.module';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { CategoryModule } from './category/category.module';
import { OnboardingService } from './onboarding/onboarding.service';
import { ScrollLockService } from '@public/shared/services/scroll-lock.service';
import { ConsentApiModule } from '@api/consent/consent-api.module';
import { SmoothScrollBehaviourService } from '@public/shared/services/scroll-behaviour/smooth-scroll-behaviour.service';
import { ExperimentatorModule } from './experimentator/experimentator.module';
import { NavigationCategoriesModule } from './navigation-categories/navigation-categories.module';

@NgModule({
  imports: [
    CookieModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AdsModule,
    MonitoringModule,
    HammerModule,
    NotificationApiModule,
    TermsNCommsModule,
    LeakedCredentialsModule,
    FavoriteSearchesApiModule,
    RecentSearchesApiModule,
    UserSettingsModule,
    CategoryModule,
    ConsentApiModule,
    NavigationCategoriesModule,
    ExperimentatorModule,
  ],
  providers: [
    LoggedGuard,
    DevelopmentGuard,
    EventService,
    I18nService,
    AccessTokenService,
    UnreadChatMessagesService,
    PaymentService,
    SocialShareService,
    GeolocationService,
    AnalyticsService,
    DeviceService,
    OneTrustService,
    LoadExternalLibsService,
    SvgService,
    LottieService,
    FeatureFlagService,
    SessionService,
    UserService,
    ItemService,
    HAMMER_PROVIDER,
    ReleaseVersionService,
    SlugsUtilService,
    MomentCalendarSpecService,
    ReportService,
    SearchNavigatorService,
    SearchQueryStringService,
    QueryStringLocationService,
    ExperimentationService,
    StandaloneService,
    WindowMessageService,
    OneTapLoginService,
    BulletsService,
    OnboardingService,
    ScrollLockService,
    SmoothScrollBehaviourService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
