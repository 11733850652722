import { map } from 'rxjs/operators';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, from } from 'rxjs';

import { Location, PlaceGeometry, SearchPlaceIndexForSuggestionsResponse, SearchPlaceIndexForTextResponse } from '@aws-sdk/client-location';
import { ItemPlace } from './geolocation-response.interface';
import { Coordinate } from './address-response.interface';
import { APP_LOCALE } from '@configs/subdomains.config';
import { DEFAULT_LOCATIONS } from '@public/features/search/core/services/constants/default-locations';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private region: string;
  private indexName: string;
  private publicApiKey: string;
  private locationClient: Location;

  constructor(@Inject(LOCALE_ID) private locale: APP_LOCALE) {
    this.setupAWS();
  }

  public search(query: string): Observable<ItemPlace[]> {
    return this.searchBySuggestion(query).pipe(map((res) => this.mapSuggestionsResponseToItemPlace(res)));
  }

  public geocode(placeId: string): Observable<Coordinate | null> {
    return this.searchByText(placeId).pipe(map((response) => this.findCoordinateByPlaceId(placeId, response)));
  }

  public reverseGeocode(latitude: number, longitude: number): Observable<string> {
    return this.searchByPosition({ latitude, longitude });
  }

  private setupAWS(): void {
    this.loadConfig();
    this.initializeLocationClient();
  }

  private loadConfig(): void {
    const { region, indexName, apiKey } = environment.awsLocationService.geoConfig;
    this.region = region;
    this.indexName = indexName;
    this.publicApiKey = apiKey;
  }

  private initializeLocationClient(): void {
    this.locationClient = new Location({
      region: this.region,
      signer: this.signRequestsWithApiKey(),
    });
  }

  private signRequestsWithApiKey() {
    return {
      sign: async (requestToSign) => {
        requestToSign.query = {
          key: this.publicApiKey,
          ...(requestToSign.query ?? {}),
        };
        return requestToSign;
      },
    };
  }

  private searchByText(text: string) {
    const biasPosition = this.getDefaultCoordsByLocale();

    return from(
      this.locationClient.searchPlaceIndexForText({
        IndexName: this.indexName,
        Text: text,
        BiasPosition: [+biasPosition.longitude, +biasPosition.latitude],
        Language: this.locale,
        MaxResults: 5,
      }),
    );
  }

  private searchBySuggestion(text: string) {
    const biasPosition = this.getDefaultCoordsByLocale();

    return from(
      this.locationClient.searchPlaceIndexForSuggestions({
        IndexName: this.indexName,
        Text: text,
        BiasPosition: [+biasPosition.longitude, +biasPosition.latitude],
        Language: this.locale,
        MaxResults: 5,
      }),
    );
  }

  private searchByPosition(coordinates: Coordinate): Observable<string> {
    return from(
      this.locationClient.searchPlaceIndexForPosition({
        IndexName: this.indexName,
        Position: [coordinates.longitude, coordinates.latitude],
      }),
    ).pipe(map((res) => res.Results[0].Place.Label));
  }

  private mapSuggestionsResponseToItemPlace(placesResponse: SearchPlaceIndexForSuggestionsResponse): ItemPlace[] {
    return placesResponse.Results.map((place) => {
      return {
        placeId: place.PlaceId,
        description: place.Text,
      };
    });
  }

  private findCoordinateByPlaceId(placeId: string, placesResponse: SearchPlaceIndexForTextResponse): Coordinate | null {
    const exactPlaceMatch = placesResponse.Results.find((place) => place.PlaceId === placeId)?.Place;
    if (exactPlaceMatch) {
      return this.getCoordinateFromPoint(exactPlaceMatch.Geometry.Point, exactPlaceMatch.Label);
    }

    const firstPlace = placesResponse.Results[0].Place;
    return this.getCoordinateFromPoint(firstPlace.Geometry.Point, firstPlace.Label);
  }

  private getCoordinateFromPoint(point: PlaceGeometry['Point'], name?: string): Coordinate {
    const longitude = point[0];
    const latitude = point[1];
    return {
      longitude,
      latitude,
      name,
    };
  }

  private getDefaultCoordsByLocale() {
    return DEFAULT_LOCATIONS[this.locale];
  }
}
