import { Injectable } from '@angular/core';
import { OneTrustService } from '@core/ads/vendors/oneTrust/one-trust.service';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { AppStatusService } from '@core/app-status/app-status.service';
import { ExperimentationService } from '@core/experimentation/services/experimentation/experimentation.service';
import { PermissionsInitializerService } from '@core/permissions-initializer/permissions-initializer.service';
import { FeatureFlagService } from '@core/user/featureflag.service';
import { OneTapLoginService } from '@public/features/login/core/services/one-tap-login.service';

@Injectable({ providedIn: 'root' })
export class InitializeUnauthenticatedUserService {
  constructor(
    private permissionsService: PermissionsInitializerService,
    private analyticsService: AnalyticsService,
    private featureFlagsService: FeatureFlagService,
    private experimentationService: ExperimentationService,
    private oneTapLoginService: OneTapLoginService,
    private oneTrustService: OneTrustService,
    private appStatusService: AppStatusService,
  ) {}

  public async initialize(): Promise<void> {
    this.appStatusService.initializeErrorListener();

    await this.oneTrustService.init();
    this.permissionsService.setDefaultPermissions();
    await this.featureFlagsService.getWebappInitializationFlags();

    await this.analyticsService.initializeAnalyticsWithUnauthenticatedUser();
    await this.experimentationService.initializeExperimentation();
    this.oneTapLoginService.init();

    this.appStatusService.finalizeErrorListener();
  }
}
