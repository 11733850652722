import { AnalyticsUser } from '#analytics/index';
import { AnalyticsFeatureFlagService } from '../../event-routing/feature-flag-service';
import { RudderstackAnalyticsLib } from './rudderstack.analytics.types';
import { getCommonRudderstackApiOptions } from './utils/get-common-rudderstack-api-options';

export const setUserForRudderstack = async (lib: RudderstackAnalyticsLib, user: AnalyticsUser): Promise<RudderstackAnalyticsLib> => {
  const isRudderstackEnabled = await AnalyticsFeatureFlagService.isRudderstackEnabled();
  const isDisabled = !isRudderstackEnabled;
  if (isDisabled) {
    return Promise.resolve(lib);
  }

  return new Promise((resolve) => {
    const nonIdentifiedUserId = '';
    const analyticsUserId = user.id !== null ? user.id : nonIdentifiedUserId;

    const apiOptions = getCommonRudderstackApiOptions();
    lib.identify(analyticsUserId, null, apiOptions, () => resolve(lib));
  });
};
