import { Bullet, BulletType } from '@core/bullets/models/bullets.interface';
import { FavoriteSearchesTotalHits } from '../dtos/favorite-searches-total-hits';

export function mapFavoriteSearchesHitsToBullet(favoriteSearchesHits: FavoriteSearchesTotalHits): Bullet {
  const { hits } = favoriteSearchesHits;
  return {
    show: hits > 0,
    type: BulletType.FAVORITE_SEARCHES_HITS,
  };
}
