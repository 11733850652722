<div class="GdprInfoModal px-md-5 py-5">
  <div class="GdprInfoModal__close position-absolute" (click)="closeModal()">
    <tsl-svg-icon src="/assets/icons/cross.svg"></tsl-svg-icon>
  </div>
  <div class="GdprInfoModal__body mt-2">
    <h4 class="GdprInfoModal__title my-2" i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_title">
      ESSENTIAL DATA PROTECTION INFORMATION
    </h4>
    <div class="GdprInfoModal__content text-left">
      <p>
        <b i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_1_title_web_specific">Data controller:</b
        ><span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_1_description_web_specific"
          >Wallapop, S.L.</span
        >
      </p>
      <p>
        <b i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_2_title_web_specific">Purpose:</b
        ><span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_2_description_web_specific"
          >Management of requests made through the Web Site, the sending of commercial communication and, where appropriate, online
          purchases made.</span
        >
      </p>
      <p>
        <b i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_3_title_web_specific">Legitimation:</b
        ><span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_3_description_web_specific"
          >Consent of the interested party.</span
        >
      </p>
      <p>
        <b i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_4_title_web_specific">Recipients: </b
        ><span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_4_description_web_specific"
          >Data will only be shared with suppliers that have a contractual relationship with Wallapop.</span
        >
      </p>
      <p>
        <b i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_5_title_web_specific">Rights:</b
        ><span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_5_description_web_specific"
          >Access, rectification, erasure and objection, alongside other rights, as explained in “Additional Information”.</span
        >
      </p>
      <p>
        <b i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_6_title_web_specific">Additional Information:</b
        ><span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_gdpr_info_modal_part_6_description_web_specific"
          >Further additional detailed information about data protection can be found in the Privacy Policy on our webpage:</span
        >
      </p>
    </div>
  </div>
</div>
