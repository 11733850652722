import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export function encryptText(text): Observable<string> {
  const utf8 = new TextEncoder().encode(text);
  return from(crypto.subtle.digest('SHA-256', utf8)).pipe(
    map((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
      return hashHex;
    }),
  );
}
