export const FIRST_TIME_SEARCH_ONBOARDING = 'firstTimeSearchOnboarding';

type STEP_KEY = 'filters_tooltip' | 'sorting_tooltip' | 'favorite_search_tooltip' | 'end';

export const ONBOARDING_STEPS: Record<string, STEP_KEY> = {
  FILTERS_TOOLTIP: 'filters_tooltip',
  SORTING_TOOLTIP: 'sorting_tooltip',
  FAVORITE_SEARCH_TOOLTIP: 'favorite_search_tooltip',
  END: 'end',
} as const;

export type ONBOARDING_STEP_KEY = keyof typeof ONBOARDING_STEPS;

export const ONBOARDING_STEPS_QUEUE: ONBOARDING_STEP_KEY[] = [
  ONBOARDING_STEPS.FILTERS_TOOLTIP,
  ONBOARDING_STEPS.SORTING_TOOLTIP,
  ONBOARDING_STEPS.FAVORITE_SEARCH_TOOLTIP,
  ONBOARDING_STEPS.END,
];

export const THIRTY_DAYS = 30;
