import { Injectable } from '@angular/core';
import { LiveRampService } from '@core/ads/vendors/liveramp/liveramp.service';
import { OneTrustService } from '@core/ads/vendors/oneTrust/one-trust.service';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { AppStatusService } from '@core/app-status/app-status.service';
import { ExperimentationService } from '@core/experimentation/services/experimentation/experimentation.service';
import { ExternalCommsService } from '@core/external-comms/external-comms.service';
import { PermissionsInitializerService } from '@core/permissions-initializer/permissions-initializer.service';
import { SessionInfoService } from '@core/session-info/session-info.service';
import { UnreadChatMessagesService } from '@core/unread-chat-messages/unread-chat-messages.service';
import { FeatureFlagService } from '@core/user/featureflag.service';
import { UserService } from '@core/user/user.service';

@Injectable({ providedIn: 'root' })
export class InitializeAuthenticatedUserService {
  constructor(
    private userService: UserService,
    private permissionsService: PermissionsInitializerService,
    private analyticsService: AnalyticsService,
    private featureFlagsService: FeatureFlagService,
    private experimentationService: ExperimentationService,
    private unreadChatMessagesService: UnreadChatMessagesService,
    private oneTrustService: OneTrustService,
    private sessionInfoService: SessionInfoService,
    private liverampService: LiveRampService,
    private appStatusService: AppStatusService,
    private externalCommsService: ExternalCommsService,
  ) {}

  public async initialize(): Promise<void> {
    this.appStatusService.initializeErrorListener();

    this.sessionInfoService.init();
    await this.oneTrustService.init();
    this.permissionsService.setDefaultPermissions();
    this.unreadChatMessagesService.initializeUnreadChatMessages();

    const user = await this.userService.initializeUser();
    this.permissionsService.setUserPermissions(user);
    await this.featureFlagsService.getWebappInitializationFlags();

    await this.analyticsService.initializeAnalyticsWithAuthenticatedUser(user);
    await this.experimentationService.initializeExperimentation();
    try {
      await this.externalCommsService.initializeExternalComms();
    } catch (error) {
      this.appStatusService.addError('[Braze]: Error while initializing session/subscription', error);
    }
    this.liverampService.init(user.email);

    this.appStatusService.finalizeErrorListener();
  }
}
