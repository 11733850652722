export const PAYMENT_MONITORING_TRANSACTION_NAME = 'PaymentFlow';

export const PAYMENT_MONITORING_TRANSACTION_FLOW = {
  NEW: 'new',
  CURRENT: 'current',
} as const;

export const PAYMENT_MONITORING_TRANSACTION_TAG = {
  PAYMENT_METHOD: 'payment_method',
} as const;

export const PAYMENT_MONITORING_TRANSACTION_PAYMENT_METHOD = {
  PAYPAL: 'paypal',
  CREDIT_CARD: 'credit_card',
  KLARNA: 'klarna',
} as const;

export const PAYMENT_MONITORING_TRANSACTION_MEASUREMENT = {
  TOTAL_TIME: 'total_time',
} as const;
