import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { snakeCase } from 'lodash-es';
import { CategoriesResponseDto, SuggestedCategoriesParams, SuggestedCategoriesResponseDto } from '../dtos';
import { CATEGORIES_ENDPOINT, SEARCH_CATEGORIES_ENDPOINT, SUGGESTED_CATEGORIES_ENDPOINT_V2 } from './endpoints';

const ACCEPT_HEADER_MERGE_SUGGESTER = 'application/vnd.category.suggester.v2+json';

@Injectable()
export class CategoriesHttpService {
  public constructor(private httpClient: HttpClient) {}

  public getCategories(): Observable<CategoriesResponseDto> {
    return this.httpClient.get<CategoriesResponseDto>(CATEGORIES_ENDPOINT);
  }

  public getSearchCategories(): Observable<CategoriesResponseDto> {
    return this.httpClient.get<CategoriesResponseDto>(SEARCH_CATEGORIES_ENDPOINT);
  }

  public getSuggestedCategories(suggestedCategoriesParams: SuggestedCategoriesParams): Observable<SuggestedCategoriesResponseDto> {
    const params = this.getSuggestedCategoriesHttpParams(suggestedCategoriesParams);
    return this.httpClient.get<SuggestedCategoriesResponseDto>(SUGGESTED_CATEGORIES_ENDPOINT_V2, {
      params,
      headers: { Accept: ACCEPT_HEADER_MERGE_SUGGESTER },
    });
  }

  private getSuggestedCategoriesHttpParams(suggestedCategoriesParams: SuggestedCategoriesParams): HttpParams {
    let params = new HttpParams();
    Object.entries(suggestedCategoriesParams).forEach(([key, value]) => {
      if (value) {
        params = params.set(snakeCase(key), value);
      }
    });
    return params;
  }
}
