import { CurrencyCode } from '#api-layer/core/interfaces/money.interface';
import { ExperimentatorVideoDataDto, ExperimentatorVideoDto } from '../dtos/experimentator-video-response.dto';
import { ExperimentatorResponseVideo, ExperimentatorVideoData } from '../interfaces/experimentator-video-response.interface';
import { experimentatorMetaResponseMapper } from './experimentator-meta.mapper';

export const experimentatorVideoResponseMapper = (response: ExperimentatorVideoDto): ExperimentatorResponseVideo => {
  const mappedData: ExperimentatorVideoData[] = response.data.map((data: ExperimentatorVideoDataDto) => {
    return experimentatorVideosDataResponseMapper(data);
  });

  return {
    data: mappedData,
    meta: experimentatorMetaResponseMapper(response.meta),
  };
};

const experimentatorVideosDataResponseMapper = (video: ExperimentatorVideoDataDto): ExperimentatorVideoData => {
  return {
    id: video.id,
    title: video.title,
    subtitle: video.subtitle || null,
    description: video.description,
    image: video.image,
    video: video.video || null,
    price: {
      amount: video.price.amount,
      currency: video.price.currency as CurrencyCode,
    },
    favoritesCount: video.favorites_count,
    seller: {
      avatarImage: video.seller.avatar_image,
      name: video.seller.name,
      rating: video.seller.rating,
      sells: video.seller.sells,
    },
  };
};
