import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConsentHttpService } from './user-consent/http/user-consent-http.service';
import type { UserConsentPayloadDto } from '@wallapop/api-layer/apis/consent/user-consent/dtos/user-consent-payload-dto';

@Injectable()
export class ConsentApiService {
  constructor(private userConsentHttpService: UserConsentHttpService) {}

  public setUserConsent(body: UserConsentPayloadDto): Observable<void> {
    return this.userConsentHttpService.setUserConsent(body);
  }
}
