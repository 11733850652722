import { ApiObject } from '@rudderstack/analytics-js/*';

export const DEFAULT_PAGE_ATTRIBUTES = {
  path: null,
  referrer: null,
  referring_domain: null,
  search: null,
  title: null,
  url: null,
  initial_referrer: null,
  initial_referring_domain: null,
  tab_url: null,
};

/**
 * We are normalizing Rudderstack attributes for page events as the SDK adds default and non configurable attributes that we currently don't want to send
 * Check
 * By setting `DEFAULT_PAGE_ATTRIBUTES` attributes to `null`, the SDK does not send them, not even as `null`
 * Notice that we are allowing those values to be overwritten by the original value of the attributes
 */
export const normalizeRudderstackPageAttributes = (attributes: AnyObject): ApiObject => {
  return {
    ...DEFAULT_PAGE_ATTRIBUTES,
    ...attributes,
  };
};
