import { AnalyticsEventAttributes, AnalyticsPageView } from '#analytics/index';
import { initRudderstackAnalytics, isRudderstackAnalyticsDisabled } from './init-rudderstack-analytics';
import { getCommonRudderstackApiOptions } from './utils/get-common-rudderstack-api-options';
import { normalizeRudderstackPageAttributes } from './utils/normalize-rudderstack-page-attributes';

export const trackRudderstackPageView = async <T extends AnalyticsEventAttributes>(event: AnalyticsPageView<T>): Promise<void> => {
  if (isRudderstackAnalyticsDisabled()) {
    return;
  }

  const lib = await initRudderstackAnalytics();

  const { name, attributes: rawAttributes } = event;
  const attributes = normalizeRudderstackPageAttributes(rawAttributes);
  const apiOptions = getCommonRudderstackApiOptions();
  lib?.page(name, attributes, apiOptions);
};
