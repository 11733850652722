export enum TRANSLATION_KEY {
  YES,
  NO,
  MALE,
  FEMALE,
  CONSUMER_GOODS_GENERAL_CATEGORY_TITLE,
  CHAT_DESKTOP_NOTIFICATION_TITLE,
  CHAT_MY_PHONE_NUMBER,
  CHAT_ARCHIVE_CONVERSATION_SUCCESS,
  CHAT_UNARCHIVE_CONVERSATION_SUCCESS,
  CHAT_REPORT_USER_SUCCESS,
  CHAT_REPORT_LISTING_SUCCESS,
  CHAT_BLOCK_USER_SUCCESS,
  CHAT_UNBLOCK_USER_SUCCESS,
  CHAT_INPUT_ENABLED_PLACEHOLDER,
  CHAT_INPUT_DISABLED_PLACEHOLDER,
  CHAT_TOO_MANY_CONVERSATIONS,
  ACTIVE,
  INACTIVE,
  SOLD,
  PUBLISHED,
  REVIEWS,
  SEARCH_BY_TITLE,
  DATE_DESC,
  DATE_ASC,
  PRICE_DESC,
  PRICE_ASC,
  TITLE_DESC,
  TITLE_ASC,
  STATS_GRAPH_NO_DATA,
  STATS_GRAPH_PHONES_SHARED,
  STATS_GRAPH_CITY_BUMP,
  STATS_GRAPH_COUNTRY_BUMP,
  STATS_GRAPH_VIEWS,
  STATS_GRAPH_CHATS,
  STATS_GRAPH_LAST_THIRTY_DAYS,
  STATS_GRAPH_LAST_FIFTEEN_DAYS,
  STATS_GRAPH_LAST_SEVEN_DAYS,
  STATS_FAVORITES,
  STATS_VIEWS,
  STATS_CHATS,
  TOAST_ERROR_TITLE,
  PRO_SUBSCRIPTION_CANCEL_SUCCESS_TITLE,
  PRO_SUBSCRIPTION_CANCEL_SUCCESS_BODY,
  PRO_SUBSCRIPTION_CANCEL_ERROR_TITLE,
  PRO_SUBSCRIPTION_CANCEL_ERROR_BODY,
  PRO_SUBSCRIPTION_CONTINUE_SUCCESS_TITLE,
  PRO_SUBSCRIPTION_CONTINUE_SUCCESS_BODY,
  PRO_SUBSCRIPTION_CONTINUE_ERROR_TITLE,
  PRO_SUBSCRIPTION_CONTINUE_ERROR_BODY,
  PRO_SUBSCRIPTION_EDIT_SUCCESS_TITLE,
  PRO_SUBSCRIPTION_EDIT_SUCCESS_BODY,
  PRO_SUBSCRIPTION_EDIT_ERROR_TITLE,
  PRO_SUBSCRIPTION_EDIT_ERROR_BODY,
  PRO_SUBSCRIPTION_CARD_SET,
  PRO_SUBSCRIPTION_CARD_DELETED,
  PRO_SUBSCRIPTION_CARD_DELETED_ERROR,
  WALLAPOP_PRO,
  BECOME_PRO,
  DAY,
  HOUR,
  MINUTE,
  LEFT,
  CARD_NUMBER_INVALID,
  CARD_DATE_INVALID,
  CARD_CVC_INVALID,
  NO_RESULTS_FOUND,
  SEARCH_INPUT_PLACEHOLDER,
  DEFAULT_ERROR_MESSAGE,
  TOAST_DEFAULT_SUCCESS_TITLE,
  TOAST_PRODUCT_CREATED,
  BUMP_ERROR,
  NO_CARD_SELECTED_ERROR,
  PRODUCT_CREATED,
  ALREADY_FEATURED_ERROR,
  FORM_FIELD_ERROR_TITLE,
  FORM_FIELD_ERROR,
  BULK_DELETE_ERROR,
  BULK_STOCK_DELETE_ERROR,
  BULK_RESERVE_ERROR,
  INVOICE_CANNOT_GENERATE_ERROR,
  INVOICE_CANNOT_DOWNLOAD_ERROR,
  PAYMENT_FAILED_ERROR,
  PAYMENT_FAILED_ERROR_TITLE,
  PAYMENT_FAILED_UNKNOWN_ERROR,
  MISSING_IMAGE_ERROR,
  PHONE_NUMBER_ERROR,
  SERVER_ERROR,
  PACK_ERROR,
  LINK_ERROR,
  DELETE_ITEM_ERROR,
  EDIT_ITEM_ERROR,
  INVALID_PASSWORD_ERROR,
  ADD_NEW_CARD_ERROR,
  DELETE_BILLING_INFO_ERROR,
  PASSWORD_MIN_LENGTH_ERROR,
  PASSWORD_DO_NOT_MATCH_ERROR,
  SUBSCRIPTION_CARD_NOT_SET_ERROR,
  STRIPE_CARDS_RETRIEVAL_ERROR,
  UPLOAD_EXTENSION_NOT_ALLOWED_ERROR,
  UPLOAD_MAX_UPLOADS_ERROR,
  UPLOAD_MAX_SIZE_ERROR,
  ITEM_UPDATED,
  INVOICE_GENERATED,
  INVOICE_DOWNLOADED,
  USER_EDITED,
  IMAGE_UPLOADED,
  SUGGESTED_CATEGORY,
  DELETE_BILLING_INFO,
  DELETE_ITEMS_TITLE,
  DELETE_ITEMS_DESCRIPTION,
  DELETE_BUTTON,
  RESERVE_ITEMS_TITLE,
  RESERVE_ITEMS_DESCRIPTION,
  RESERVE_BUTTON,
  REMOVE_FEATURED_PROFILE_FAVORITE_TITLE,
  REMOVE_FEATURED_PROFILE_FAVORITE_DESCRIPTION,
  REMOVE_BUTTON,
  PROFILE_NO_LONGER_FEATURED_TITLE,
  PROFILE_NO_LONGER_FEATURED_DESCRIPTION,
  REMOVE_ITEM_FROM_FAVORITES_TITLE,
  REMOVE_ITEM_FROM_FAVORITES_DESCRIPTION,
  DELETE_CARD_TITLE,
  DELETE_CARD_DESCRIPTION,
  I_AM_SURE_CONFIRMATION,
  CANCEL_BUTTON,
  FINDING_MISSING_WEIGHT_ERROR,
  FINDING_UPLOAD_TITLE_NOT_ALLOWED_LINK_ERROR,
  FINDING_UPLOAD_DESCRIPTION_NOT_ALLOWED_LINK_ERROR,
  RESERVED_ITEM_CANNOT_BE_BUMPED,

  // TODO: Remaining keys with no explicit usage. Some of them might come from the backend so we need to keep it's string value
  //       We leave cleaning this keys after Italy release, as some of them come from some legacy parts (chat, calls..)
  //       and it is really difficult to follow the keys as this part needs a refactor
  TO_CLEAN_DEFAULT_ERROR_MESSAGE = 'defaultErrorMessage',
  TO_CLEAN_BULK_SOLD_ERROR = 'bulkSoldError',
  TO_CLEAN_PHONE_CALLS = 'phoneCalls',
  TO_CLEAN_PHONES = 'phones',
  TO_CLEAN_MEETINGS = 'meetings',
  TO_CLEAN_MESSAGES = 'messages',
  TO_CLEAN_SHARED_PHONE = 'shared',
  TO_CLEAN_MISSED_CALL = 'missed',
  TO_CLEAN_ANSWERED_CALL = 'answered',
  TO_CLEAN_FEATURED = 'featured',
  TO_CLEAN_ERROR_PURCHASING_ITEMS = 'errorPurchasingItems',
  TO_CLEAN_SETTINGS_EDITED = 'settingsEdited',
  TO_CLEAN_TWITTER_SHARE = 'twitterShare',
  TO_CLEAN_COUNTRY_BUMP = 'countrybump',
  TO_CLEAN_ZONE_BUMP = 'zonebump',
  TO_CLEAN_CARS_TUTORIAL_LISTING_LIMIT_5 = 'cars_subscription_tutorial_listinglimit5',
  TO_CLEAN_CARS_TUTORIAL_LISTING_LIMIT_9 = 'cars_subscription_tutorial_listinglimit9',
  TO_CLEAN_CARS_TUTORIAL_LISTING_LIMIT_15 = 'cars_subscription_tutorial_listinglimit15',
  TO_CLEAN_CARS_TUTORIAL_DESCRIPTION_2 = 'cars_subscription_tutorial_description2',
  TO_CLEAN_PRO_TUTORIAL_EXTRA_PHONE_WEB = 'pro_subscription_tutorial_extra_fields_phone_web',
  TO_CLEAN_PRO_TUTORIAL_EXTRA_DIRECTION_DESCRIPTION = 'pro_subscription_tutorial_extra_fields_description_direction',
  TO_CLEAN_CARS_TUTORIAL_DESCRIPTION_3 = 'cars_subscription_tutorial_description3',
  TO_CLEAN_PRO_TUTORIAL_NO_EXPIRE = 'pro_subscription_tutorial_items_no_expire',
  TO_CLEAN_CARS_TUTORIAL_PHONE_ON_CAR = 'cars_subscription_tutorial_phone_on_car',
  TO_CLEAN_BRAND = 'brand',
  TO_CLEAN_MODEL = 'model',
  TO_CLEAN_PHONES_BRAND_EXAMPLE = 'phones_brand_example',
  TO_CLEAN_FASHION_BRAND_EXAMPLE = 'fashion_brand_example',
  TO_CLEAN_MODEL_EXAMPLE = 'model_example',
  TO_CLEAN_REVIEWS = 'reviews',
  TO_CLEAN_ERROR_SAVING_DATA = 'errorSavingData',
  TO_CLEAN_CITY_BUMP = 'citybump',
  TO_CLEAN_CARS_ENGINE_GASOIL = 'gasoil',
  TO_CLEAN_CARS_ENGINE_GASOLINE = 'gasoline',
  TO_CLEAN_CARS_ENGINE_ELECTRIC_HYBRID = 'electric-hybrid',
  TO_CLEAN_CARS_ENGINE_OTHERS = 'others',
  TO_CLEAN_CARS_GEARBOX_MANUAL = 'manual',
  TO_CLEAN_CARS_GEARBOX_AUTOMATIC = 'automatic',
  NOTIFICATIONS_VIEW_ALL_USERS_CATALOG_SECTION_TITLE = 'notifications_view_all_users_catalog_section_title',
  NOTIFICATIONS_VIEW_ALL_USERS_CATALOG_SECTION_DESCRIPTION = 'notifications_view_all_users_catalog_section_description',
  NOTIFICATIONS_VIEW_ALL_USERS_CATALOG_SECTION_ITEMS_EXPIRED_LABEL = 'notifications_view_all_users_catalog_section_items_expired_label',
  NOTIFICATIONS_VIEW_ALL_USERS_SAVED_SEARCHES_SECTION_TITLE = 'notifications_view_all_users_saved_searches_section_title',
  NOTIFICATIONS_VIEW_ALL_USERS_SAVED_SEARCHES_SECTION_DESCRIPTION = 'notifications_view_all_users_saved_searches_section_description',
  NOTIFICATIONS_VIEW_ALL_USERS_SAVED_SEARCHES_SECTION_ALERTS_LABEL = 'notifications_view_all_users_saved_searches_section_alerts_label',
  NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_TITLE = 'notifications_view_all_users_favorites_section_title',
  NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_DESCRIPTION = 'notifications_view_all_users_favorites_section_description',
  NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_PRICE_DROPS_LABEL = 'notifications_view_all_users_favorites_section_price_drops_label',
  NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_FAVORITES_SOLD_LABEL = 'notifications_view_all_users_favorites_section_favorites_sold_label',
  NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_FAVORITES_RESERVED_LABEL = 'notifications_view_all_users_favorites_section_favorites_reserved_label',
  NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_NEW_ITEMS_LABEL = 'notifications_view_all_users_favorites_section_new_items_label',
  NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_TITLE = 'notifications_view_all_users_additional_options_section_title',
  NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_DESCRIPTION = 'notifications_view_all_users_additional_options_section_description',
  NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_TIPS_SUGGESTIONS_LABEL = 'notifications_view_all_users_additional_options_section_tips_suggestions_label',
  NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_PROMOS_NEWS_LABEL = 'notifications_view_all_users_additional_options_section_promos_news_label',
  ACCEPT_SCREEN_REJECT_REQUEST_MODAL_TITLE = 'accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_title',
  ACCEPT_SCREEN_REJECT_REQUEST_MODAL_DESCRIPTION = 'accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_description',
  ACCEPT_SCREEN_REJECT_REQUEST_MODAL_CONTINUE_BUTTON = 'accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_continue_button',
  ACCEPT_SCREEN_REJECT_REQUEST_MODAL_BACK_BUTTON = 'accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_back_button',
  ACCEPT_SCREEN_SCHEDULES_GET_ERROR = 'accept_view_seller_all_all_snackbar_shcedules_loading_error',
  ACCEPT_SCREEN_SCHEDULES_SAVE_ERROR = 'accept_view_seller_all_all_snackbar_schedules_save_error',
  DELIVERY_MAP_GENERIC_ERROR = 'map_view_all_users_all_all_snackbar_generic_error',
  DELIVERY_MAP_LOCATION_POP_UP_DESCRIPTION = 'map_view_all_users_all_all_no_user_location_pop_up_description',
  DELIVERY_MAP_LOCATION_POP_UP_ADD_BUTTON = 'map_view_all_users_all_all_no_user_location_pop_up_add_button',
  DELIVERY_MAP_LOCATION_POP_UP_CANCEL_BUTTON = 'map_view_all_users_all_all_no_user_location_pop_up_cancel_button',
  FAVORITES_VIEW_TAB_SEARCHES_ALL_CATEGORIES_LABEL_WEB_SPECIFIC = 'favorites_view_tab_searches_all_categories_label_web_specific',
  UPLOAD_PRODUCT_MISSING_ITEM_SIZE_TITLE_ERROR = 'upload_item_general_info_view_snackbar_missing_item_size_title_web_specific',
  UPLOAD_PRODUCT_MISSING_ITEM_SIZE_DESCRIPTION_ERROR = 'upload_item_general_info_view_snackbar_missing_item_size_description_web_specific',
  UPLOAD_PRODUCT_MIN_MEASURE_ERROR = 'bulky_items_seller_product_measuraments_minimum_size_required_error',
  UPLOAD_PRODUCT_MISSING_ALL_MEASURES = 'upload_item_general_info_view_snackbar_missing_all_measurements_description_web_specific',
  BUMP_CANT_BE_BUMPED_BECAUSE_IS_IN_MODERATION_TITLE = 'bump_selector_view_item_not_published_error_modal_pro_user_title',
  BUMP_CANT_BE_BUMPED_BECAUSE_IS_IN_MODERATION_TEXT = 'bump_selector_view_item_not_published_error_modal_pro_user_descripton_1_part',
  BUMP_CANT_BE_BUMPED_GENERIC_ERROR_TITLE = 'bump_selector_view_generic_error_modal_pro_user_title',
  BUMP_CANT_BE_BUMPED_GENERIC_ERROR_TEXT = 'bump_selector_view_generic_error_modal_pro_user_descripton_1_part',
}
