import { environment } from '@wallapop/environments';
import { HTTP_CLIENT_HEADERS } from '../http-client.constants';
import { HttpClientInterceptor, HttpClientRequestOnFulfilledInterceptor } from '../http-client.types';
import { getMatchingHeadersByRules, type HttpRequestHeaderRules } from './rule-matcher/rule-matcher';

interface AppVersionInteceptorProps {
  appVersion: string;
  baseUrl?: string;
}

type CreateAppVersionInterceptor = (options: AppVersionInteceptorProps) => HttpClientInterceptor;

const FF_DOMAIN = [new URL(environment.featureFlagEndpoint).hostname];
const ALLOWED_DOMAINS = [new URL(environment.baseUrl).hostname, ...FF_DOMAIN];
const RULES: HttpRequestHeaderRules[] = [
  {
    headerName: HTTP_CLIENT_HEADERS.APP_VERSION,
    domains: ALLOWED_DOMAINS,
  },
  {
    headerName: HTTP_CLIENT_HEADERS.SEMANTIC_VERSION,
    domains: FF_DOMAIN,
  },
];

export const createAppVersionInterceptor: CreateAppVersionInterceptor = ({ appVersion }) => {
  const onFulfilledInterceptor: HttpClientRequestOnFulfilledInterceptor = (request) => {
    request.headers ??= {};

    const matchingHeaders = getMatchingHeadersByRules(RULES, request, () => ({
      [HTTP_CLIENT_HEADERS.APP_VERSION]: appVersion.replace(/\./g, ''),
      [HTTP_CLIENT_HEADERS.SEMANTIC_VERSION]: appVersion,
    }));
    Object.assign(request.headers, matchingHeaders);

    return request;
  };

  return {
    request: {
      onFulfilled: onFulfilledInterceptor,
    },
  };
};
