import { initBraze } from '../core/init-braze';
import { ExternalCommsApi } from '../typing/types/external-comms-api.type';
import { getGlobalAttribute } from './get-global-attribute';

const ensureBrazeIsReady = (lib: ExternalCommsApi) => {
  let isReady: boolean = true;

  try {
    // We are checking one basic method in order to see if Braze SDK initialized correctly
    lib.getDeviceId();
  } catch (e) {
    isReady = initBraze(lib);
  }

  return isReady;
};

// Notice we are delegating mParticle to load and initialize braze with the mParticle braze kit
export const getBrazeFromWindow = () => {
  return new Promise<ExternalCommsApi | null>((resolve) => {
    getGlobalAttribute<any>('mParticle').then((mparticle) =>
      mparticle.ready(() => {
        getGlobalAttribute<ExternalCommsApi>('braze').then((lib) => {
          ensureBrazeIsReady(lib) ? resolve(lib) : resolve(null);
        });
      }),
    );
  });
};
