import { RefreshTokenRepository } from '@wallapop/api-layer/core/authorization/refresh-token/refresh-token-repository.interface';
import { createObservable } from '@wallapop/api-layer/core/observable/observable';

export const refreshTokenClientRepository = (): RefreshTokenRepository => {
  let isRefreshingValue = false;

  const isRefreshing = () => {
    return isRefreshingValue;
  };

  const startIsRefreshing = () => {
    isRefreshingValue = true;
  };

  const clearIsRefreshing = () => {
    isRefreshingValue = false;
  };

  const refreshToken$ = createObservable();

  return {
    isRefreshing,
    startIsRefreshing,
    clearIsRefreshing,
    refreshToken$,
  };
};
