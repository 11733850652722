import { Locale } from '../types';
import { FALLBACK_LOCALE, LOCALES_LIST } from '../constants';

export const isLocale = (locale?: string): locale is Locale => {
  return !!locale && LOCALES_LIST.includes(locale as Locale);
};

export const getLocale = (locale?: string): Locale => {
  return isLocale(locale) ? locale : FALLBACK_LOCALE;
};
