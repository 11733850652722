<div class="CheckMailboxModal d-flex flex-column w-100 align-items-center pt-3 px-4 pt-sm-5">
  <tsl-svg-icon class="mb-4" src="/assets/images/check-mailbox.svg"></tsl-svg-icon>
  <h4 class="CheckMailboxModal__title text-center">{{ copies.title }}</h4>
  <div class="mt-3 text-center">
    <p class="CheckMailboxModal__description mt-0">{{ copies.description }}</p>
  </div>
  <div class="py-3 pb-sm-5 pt-sm-3 w-100 d-flex flex-column position-sticky">
    <tsl-button class="w-100" className="btn-primary w-100" (click)="activeModal.close()">{{ copies.button }}</tsl-button>
  </div>
</div>
