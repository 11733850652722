export interface IDSyncHelper {
  idSyncReady: (f: () => void) => void;
  flushQueue: () => void;
  resetQueue: () => void;
}

export const instantiateIdSyncListener = (): IDSyncHelper => {
  let readyQueue: Array<() => void> = [];

  /**
   * This essentially works as an mParticle.ready but for the idSync callback.
   * This way we can ensure that the MPID is available and user attributes can be set, since the ready callback does not provide that.
   * @param f callback to execute
   */
  const idSyncReady = (f: () => void) => {
    if (window.mParticle?.Identity?.getCurrentUser()?.getMPID() && typeof f === 'function') {
      f();
    } else {
      readyQueue.push(f);
    }
  };

  const resetQueue = () => {
    readyQueue = [];
  };

  const flushQueue = () => {
    if (readyQueue && readyQueue.length) {
      readyQueue.forEach((readyQueueItem) => {
        readyQueueItem();
      });
      resetQueue();
    }
  };

  return {
    idSyncReady,
    flushQueue,
    resetQueue,
  };
};
