import axios from 'axios';

import { CreateHttpClientOptions, HttpClient } from './http-client.interface';
import { Locale } from '@wallapop/i18n/src/types';

interface HttpClientConfig extends CreateHttpClientOptions {
  locale?: Locale;
}

export const createHttpClient = (config: HttpClientConfig = {}): HttpClient => {
  const { locale, ...otherConfig } = config;
  const httpClient = axios.create(otherConfig) as HttpClient;
  httpClient.locale = locale;
  return httpClient;
};
