<div class="LeakedCredentialsModal d-flex flex-column w-100 align-items-center pt-3 px-4 pt-sm-5">
  <div class="LeakedCredentialsModal__close position-absolute m-3" (click)="close()">
    <tsl-svg-icon src="/assets/icons/cross.svg"></tsl-svg-icon>
  </div>
  <tsl-svg-icon [height]="260" [width]="300" class="mb-4" [src]="'/assets/images/leaked-credentials.svg'"></tsl-svg-icon>
  <span i18n="@@leaked_credentials_view_user_already_registered_title" class="LeakedCredentialsModal__title text-center"
    >Change your password</span
  >

  <span i18n="@@leaked_credentials_view_user_already_registered_description" class="mt-3 text-center"
    >Your current password may not be secure enough. To keep your account secure, we suggest you change your password.</span
  >
  <div class="py-3 py-sm-5 LeakedCredentialsModal__buttons w-100 d-flex flex-column position-sticky">
    <tsl-button
      i18n="@@leaked_credentials_view_user_already_registered_cancel_button"
      class="LeakedCredentialsModal__buttons--no-thanks w-100"
      [className]="'btn-link btn-link--bold w-100'"
      (click)="clickNoThanks()"
      >Not now, thanks</tsl-button
    >
    <tsl-button
      i18n="@@leaked_credentials_view_user_already_registered_change_password_button"
      class="LeakedCredentialsModal__buttons--change-password w-100 mt-3"
      [className]="'btn-primary btn-primary--bold w-100'"
      (click)="clickChangePassword()"
      >Change password</tsl-button
    >
  </div>
</div>
