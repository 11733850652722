import type { ExperimentClient } from '@amplitude/experiment-js-client';
import { INTERNAL_SDK_VARIANTS } from './amplitude-integration.constants';
import type { ExperimentData, ThrottledArgs, ValidateIdentityChange } from './amplitude-types';

const ID_SYNC_TIMEOUT = 5000;
export const THROTTLE_TIME = 2000;
const cache: Record<string, ExperimentData> = {};

/**
 * Waits for mParticle to identify the user. If it doesn't within 5 seconds, resolve the promise and continue the flow
 * as the user set in the cookies (MPID cookie) or an anonymous user.
 */
export const waitForMParticleIdResolution = (identityReadyCallback: (callback: () => void) => void) => {
  return new Promise<void>((resolve) => {
    const timer = setTimeout(() => {
      console.error('[Experimentation SDK]: MP IDSync API did not respond in time.');
      resolve();
    }, ID_SYNC_TIMEOUT);

    identityReadyCallback(() => {
      clearTimeout(timer);
      resolve();
    });
  });
};

/**
 * Cache variant calls during 2 seconds
 */
export const getThrottledVariant = ({ flagKey, experimentClient }: ThrottledArgs): ExperimentData => {
  const _flagKey = flagKey?.toLowerCase();

  if (cache[_flagKey]) {
    return cache[_flagKey];
  }

  if (!cache[_flagKey] && experimentClient) {
    cache[_flagKey] = experimentClient.variant(_flagKey);
    setTimeout(() => {
      delete cache[_flagKey];
    }, THROTTLE_TIME);
    return cache[_flagKey];
  }

  return {
    key: 'off',
    value: undefined,
  };
};

/**
 * Helper util to evaluate if a user will be shown the off/control option
 */
export const isFlagOffOrControl = (key: string | undefined | null) => {
  return key === INTERNAL_SDK_VARIANTS.OFF || key === INTERNAL_SDK_VARIANTS.CONTROL || !key;
};

/**
 * If analytics user and experimentation user does not match, update experimentClient with the new analytics user
 */
export const validateIdentityChange = async ({
  experimentClient,
  analyticsIdentity,
}: ValidateIdentityChange): Promise<ExperimentClient | null> => {
  if (!experimentClient) return null;

  const currentTrackingId = experimentClient.getUser()?.user_id;
  const newTrackingId = analyticsIdentity?.userGetter();

  if (currentTrackingId !== newTrackingId) {
    const deviceId = analyticsIdentity?.deviceGetter();

    return await experimentClient.fetch({
      user_id: newTrackingId ?? undefined,
      device_id: deviceId,
    });
  }
  return null;
};
