<walla-dialog [isOpen]="true" [isFullscreen]="false" [isAnimated]="false" (dialogClosed)="activeModal.dismiss()">
  <div slot="sectionTitle">{{ properties.title }}</div>
  <div slot="sectionBody">
    <p>{{ properties.description }}</p>
  </div>
  <div slot="footer">
    <div [ngClass]="properties.hideCancelButton ? 'd-block' : 'd-flex flex-direction-row justify-content-end'">
      <walla-button
        *ngIf="!properties.hideCancelButton"
        [text]="properties.cancelMessage"
        [buttonSubtype]="properties.cancelButtonSubtype || DEFAULT_CANCEL_BUTTON_SUBTYPE"
        (click)="activeModal.dismiss()"
        buttonType="secondary"
        class="mr-2"
      ></walla-button>
      <walla-button
        [fullWidth]="properties.hideCancelButton"
        [text]="properties.confirmMessage"
        [buttonSubtype]="properties.confirmButtonSubtype"
        (click)="activeModal.close()"
      ></walla-button>
    </div>
  </div>
</walla-dialog>
