export const WALLAPOP_PLACEMENT_ID: string = '14040';
export const LIVERAMP_ENVELOPE_COOKIE_NAME = '_lr_env';
export const LIVERAMP_IDENTIFIER_HASH_COOKIE_NAME = '_lr_hash';
export const LIVERAMP_ENVELOPE_EXPIRATION_TIME = '_lr_env_exp';

export const IDENTIFIER_TYPES = {
  EMAIL: 4,
  PHONE_NUMBER: 11,
  CUSTOM_ID: 15,
  ENVELOPE: 19,
} as const;
export type IdentifierTypes = EnumValues<typeof IDENTIFIER_TYPES>;
export const ENVELOPE_DEFAULT_IDENTIFIER_TYPE: IdentifierTypes = IDENTIFIER_TYPES.EMAIL;

export const CONSENT_TYPES = {
  UE: 4,
} as const;

export type ConsentTypes = EnumValues<typeof CONSENT_TYPES>;
