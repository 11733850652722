import { Locale } from '../types';

export const LOCALES = {
  SPANISH: 'es',
  ENGLISH: 'en',
  ITALIAN: 'it',
  PORTUGUESE: 'pt',
  FRENCH: 'fr',
} as const;

export const LOCALES_LIST: Locale[] = [LOCALES.ENGLISH, LOCALES.SPANISH, LOCALES.ITALIAN, LOCALES.PORTUGUESE, LOCALES.FRENCH];

export const FALLBACK_LOCALE: Locale = LOCALES.ENGLISH;
