export enum F2F_BUYER_PATHS {
  F2F_DELIVERY = 'delivery',
  F2F_INFO = 'information',
  F2F_METHOD = 'method',
  F2F_AMOUNT = 'amount',
  F2F_PAYMENT = 'payment',
  F2F_SUMMARY = 'summary',
  F2F_QR = 'qr',
  F2F_SUCCESS = 'success',
  F2F_ERROR = 'error',
  CREDIT_CARD = 'card',
  F2F_TTS = 'tts',
}
