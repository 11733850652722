export enum IABVendors {
  GOOGLE = 755,
  CRITEO = 91,
}
export const OT_CONSENT_COOKIE_NAME = 'eupubconsent-v2';
export const ONE_TRUST_SOURCE = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
export const SET_USER_CONSENT_ENDPOINT_COOKIE = 'userConsentSent';
export const TIKTOK_VENDOR = 'V11';

export const SESSION_TIMEOUT = 15 * 60000;
export const SET_USER_CONSENT_MAX_RETRIES = 3;
