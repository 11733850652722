import { Snackbar } from '@layout/snackbar/core/interfaces/snackbar.interface';
import { USER_KIND_RADIO_BUTTON } from '@private/features/profile/pages/profile-info/profile-info.constants';
import { USER_TYPE } from '#api-layer/apis/user-type/interfaces/user-type.interface';
import { UserKindModalStep, UserTypeModalConfig } from '@shared/required-user-kind-modal/required-user-kind-modal.interface';

export const USER_KIND_MODAL_STEPS = {
  IDENTIFICATION_REGISTRATION: 'identification_registration',
  IDENTIFICATION_NEW_SESSION: 'identification_new_session',
  IDENTIFICATION_UPLOAD: 'identification_upload',
  IDENTIFICATION_DSA_CHANGE_TYPE: 'identification_dsa_change_type',
  IDENTIFICATION_DSA_NO_TYPE: 'identification_dsa_no_type',
  IDENTIFICATION_DSA_FORM_INFO: 'identification_dsa_form_info',
  CONFIRMATION_INDIVIDUAL: 'confirmation_individual',
  DSA_FORM_INFO: 'dsa_form_info',
  CATALOG_BLOCKED: 'catalog_blocked',
  CATALOG_BLOCKED_UPLOAD: 'catalog_blocked_upload',
} as const;

export const SNACKBAR_ERROR_MESSAGE: Snackbar = {
  content: $localize`:@@app_snackbar_generic_error_message:Something has gone wrong. Try again.`,
  type: 'negative',
};

export const IS_FIRST_LOGIN_STORE_KEY: string = 'justRegistered';

export const PADLOCK_SCREENS: Partial<UserKindModalStep>[] = [
  USER_KIND_MODAL_STEPS.CATALOG_BLOCKED,
  USER_KIND_MODAL_STEPS.CATALOG_BLOCKED_UPLOAD,
];

export const FORM_SCREENS: Partial<UserKindModalStep>[] = [
  USER_KIND_MODAL_STEPS.IDENTIFICATION_UPLOAD,
  USER_KIND_MODAL_STEPS.IDENTIFICATION_NEW_SESSION,
  USER_KIND_MODAL_STEPS.IDENTIFICATION_REGISTRATION,
  USER_KIND_MODAL_STEPS.IDENTIFICATION_DSA_NO_TYPE,
  USER_KIND_MODAL_STEPS.IDENTIFICATION_DSA_CHANGE_TYPE,
];

export const FAQS_SCREENS: Partial<UserKindModalStep>[] = [
  USER_KIND_MODAL_STEPS.DSA_FORM_INFO,
  USER_KIND_MODAL_STEPS.IDENTIFICATION_DSA_FORM_INFO,
];

export const RADIO_BUTTON_USER_KIND_CONFIG: USER_KIND_RADIO_BUTTON[] = [
  {
    title: $localize`:@@account_identification_view_all_users_regular_account_title:Personal`,
    value: USER_TYPE.INDIVIDUAL,
    description: $localize`:@@account_identification_view_all_users_regular_account_description:Account for personal use, not used for commercial or professional purposes.`,
    faqsText: $localize`:@@account_identification_view_all_users_regular_account_terms_and_conditions_link_button:Terms and conditions for Individuals`,
    faqsUrl: $localize`:@@web_footer_links_terms_href:https://about.wallapop.com/en/legal-terms-and-conditions`,
  },
  {
    title: $localize`:@@account_identification_view_all_users_pro_account_title:Professional`,
    value: USER_TYPE.BUSINESS,
    description: $localize`:@@account_identification_view_all_users_pro_account_description:Account for commercial or professional use: freelancers, companies...`,
    faqsText: $localize`:@@account_identification_view_all_users_pro_account_terms_and_conditions_link_button:Terms and conditions for Professionals`,
    faqsUrl: `${$localize`:@@web_footer_links_terms_href:https://about.wallapop.com/en/legal-terms-and-conditions`}#business`,
  },
];

export const modalConfigSteps: Record<UserKindModalStep, UserTypeModalConfig> = {
  [USER_KIND_MODAL_STEPS.IDENTIFICATION_NEW_SESSION]: {
    title: $localize`:@@seller_dsa_new_session_title:What use you’ll make of Wallapop?`,
    description: $localize`:@@seller_dsa_registration_description:As an online platform, we need to identify if users are particulars or professionals.`,
    showCloseButton: true,
  },
  [USER_KIND_MODAL_STEPS.IDENTIFICATION_DSA_CHANGE_TYPE]: {
    title: $localize`:@@seller_dsa_new_session_title:What use you’ll make of Wallapop?`,
    description: $localize`:@@seller_dsa_registration_description:As an online platform, we need to identify if users are particulars or professionals.`,
    showCloseButton: true,
  },
  [USER_KIND_MODAL_STEPS.IDENTIFICATION_DSA_NO_TYPE]: {
    title: $localize`:@@seller_dsa_new_session_title:What use you’ll make of Wallapop?`,
    description: $localize`:@@seller_dsa_registration_description:As an online platform, we need to identify if users are particulars or professionals.`,
    showCloseButton: true,
  },
  [USER_KIND_MODAL_STEPS.IDENTIFICATION_REGISTRATION]: {
    title: $localize`:@@seller_dsa_registration_title:What use you’ll make of Wallapop?`,
    description: $localize`:@@seller_dsa_registration_description:As an online platform, we need to identify if users are particulars or professionals.`,
    showCloseButton: true,
  },
  [USER_KIND_MODAL_STEPS.IDENTIFICATION_UPLOAD]: {
    title: $localize`:@@seller_dsa_new_session_title:What use you’ll make of Wallapop?`,
    description: $localize`:@@seller_dsa_registration_description:As an online platform, we need to identify if users are particulars or professionals.`,
    showCloseButton: false,
  },
  [USER_KIND_MODAL_STEPS.DSA_FORM_INFO]: {
    title: $localize`:@@seller_dsa_form_info_title:You need to provide additional information`,
    image: 'assets/images/pro/modals/user-type/more-info-required.png',
    description: $localize`:@@seller_dsa_form_info_description:According to the applicable regulations, as a professional, you must provide certain information to start selling.`,
    showBackButton: true,
    buttons: [
      {
        type: 'secondary',
        text: $localize`:@@dsa_confirmation_all_button_keep_exploring:I’ll do it later`,
      },
      {
        type: 'primary',
        text: $localize`:@@dsa_confirmation_all_button_fill_up_info:Fill in my legal information`,
      },
    ],
  },
  [USER_KIND_MODAL_STEPS.IDENTIFICATION_DSA_FORM_INFO]: {
    title: $localize`:@@seller_dsa_form_info_title:You need to provide additional information`,
    image: 'assets/images/pro/modals/user-type/more-info-required.png',
    description: $localize`:@@seller_dsa_form_info_description:According to the applicable regulations, as a professional, you must provide certain information to start selling.`,
    showBackButton: false,
    showCloseButton: true,
    buttons: [
      {
        type: 'secondary',
        text: $localize`:@@dsa_confirmation_all_button_keep_exploring:I’ll do it later`,
      },
      {
        type: 'primary',
        text: $localize`:@@dsa_confirmation_all_button_fill_up_info:Fill in my legal information`,
      },
    ],
  },
  [USER_KIND_MODAL_STEPS.CONFIRMATION_INDIVIDUAL]: {
    title: $localize`:@@view_user_identification_confirmation_container_overlay_component_textbox_element_title:Identification saved`,
    description: $localize`:@@view_user_identification_confirmation_container_overlay_component_textbox_element_description:You can modify it at any time in your profile settings.`,
    image: 'assets/images/pro/modals/user-type/identification-saved.png',
    buttons: [
      {
        type: 'primary',
        text: $localize`:@@view_user_identification_confirmation_container_overlay_component_primary_button:Understood`,
      },
    ],
  },
  [USER_KIND_MODAL_STEPS.CATALOG_BLOCKED]: {
    title: $localize`:@@dsa_catalog_blocked_info_before_deadline_title:Don’t forget! You’ll need to complete your information`,
    description: $localize`:@@dsa_catalog_blocked_info_before_deadline_description:If you want to keep selling on Wallapop, you need to add your information by February 17th. Don’t miss out!`,
    image: 'assets/images/pro/modals/user-type/catalog-blocked.png',
    showBackButton: true,
    buttons: [
      {
        type: 'secondary',
        text: $localize`:@@dsa_catalog_blocked_info_do_later_button:I’ll do it later`,
      },
      {
        type: 'primary',
        text: $localize`:@@dsa_catalog_blocked_info_complete_button:Complete information`,
      },
    ],
  },
  [USER_KIND_MODAL_STEPS.CATALOG_BLOCKED_UPLOAD]: {
    title: $localize`:@@dsa_catalog_blocked_info_before_deadline_title:Don’t forget! You’ll need to complete your information`,
    description: $localize`:@@dsa_catalog_blocked_info_before_deadline_description:If you want to keep selling on Wallapop, you need to add your information by February 17th. Don’t miss out!`,
    image: 'assets/images/pro/modals/user-type/catalog-blocked.png',
    showCloseButton: true,
    buttons: [
      {
        type: 'secondary',
        text: $localize`:@@dsa_catalog_blocked_info_do_later_button:I’ll do it later`,
      },
      {
        type: 'primary',
        text: $localize`:@@dsa_catalog_blocked_info_complete_button:Complete information`,
      },
    ],
  },
};
