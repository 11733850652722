export const EXPECTED_VARIANTS = {
  OFF: 'off',
  VARIANT_A: 'variant_a',
  VARIANT_B: 'variant_b',
  VARIANT_C: 'variant_c',
  VARIANT_D: 'variant_d',
  VARIANT_E: 'variant_e',
  VARIANT_F: 'variant_f',
} as const;

/**
 * For library use only, do not import
 */
export const INTERNAL_SDK_VARIANTS = {
  ...EXPECTED_VARIANTS,
  CONTROL: 'control',
} as const;
