import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailModalComponent } from './email-modal.component';
import { ButtonModule } from '@shared/button/button.module';
import { SvgIconModule } from '@shared/svg-icon/svg-icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EmailModalComponent],
  imports: [CommonModule, ButtonModule, SvgIconModule, FormsModule, ReactiveFormsModule, NgbModalModule],
  exports: [EmailModalComponent],
})
export class EmailModalModule {}
