import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AUTH_REFRESH_ENDPOINT } from './endpoints';
import { RefreshTokenDto } from '../dtos/refresh-token-dto.interface';

@Injectable()
export class RefreshTokenHttpService {
  constructor(private http: HttpClient) {}

  public refresh(): Observable<RefreshTokenDto> {
    return this.http.post<RefreshTokenDto>(AUTH_REFRESH_ENDPOINT, null, { withCredentials: true });
  }
}
