import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserVerificationsModule } from '@api/user-verifications/user-verifications.module';
import { UserSettingsService } from './user-settings.service';
import { EmailModalModule } from '@shared/profile/email-modal/email-modal.module';
import { MultiFactorManagerModule } from '@core/multi-factor/multi-factor-manager.module';

@NgModule({
  imports: [CommonModule, EmailModalModule, UserVerificationsModule, MultiFactorManagerModule],
  providers: [UserSettingsService],
})
export class UserSettingsModule {}
