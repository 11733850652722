import { Inject, Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';

import { DeviceType } from '@core/device/deviceType.enum';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { getUUID } from '@core/uuid/uuid.utils';
import { Browser, OperatingSystem, UserAgentParser } from './interfaces';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private static DEVICE_ID_COOKIE_NAME = 'device_id';
  private userAgentParser: UserAgentParser;

  constructor(
    private cookieService: CookieService,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {
    this.initalizeParser();
    this.window['deviceType'] = this.getDeviceType();
  }

  public getDeviceType(): DeviceType {
    if (this.isMobile()) {
      return DeviceType.MOBILE;
    }

    if (this.isTablet()) {
      return DeviceType.TABLET;
    }

    return DeviceType.DESKTOP;
  }

  public isMobile(): boolean {
    return this.userAgentParser.getDevice().type === 'mobile';
  }

  public isTablet(): boolean {
    return this.userAgentParser.getDevice().type === 'tablet';
  }

  public isDesktop(): boolean {
    return this.userAgentParser.getDevice().type === undefined;
  }

  public isTouchDevice(): boolean {
    return 'ontouchstart' in this.window || navigator.maxTouchPoints > 0;
  }

  public getDeviceId(): string {
    let deviceId = this.cookieService.get(DeviceService.DEVICE_ID_COOKIE_NAME);

    if (!deviceId) {
      const currentYear = new Date().getFullYear();
      const oneYearFromNow = new Date().setFullYear(currentYear + 1);
      const expirationDate = new Date(oneYearFromNow);
      deviceId = getUUID();

      this.cookieService.put(DeviceService.DEVICE_ID_COOKIE_NAME, deviceId, {
        expires: expirationDate,
        // TODO: Generic cookie options could be abstracted and extended on each case
        domain: environment.name === 'local' ? 'localhost' : '.wallapop.com',
        path: '/',
      });
    }

    return deviceId;
  }

  public getDeviceLanguages(): readonly string[] {
    return navigator.languages;
  }

  public getOS(): OperatingSystem {
    return this.userAgentParser.getOS();
  }

  public getBrowser(): Browser {
    return this.userAgentParser.getBrowser();
  }

  private initalizeParser(): void {
    this.userAgentParser = new UAParser();
  }
}
