import { Event } from '@sentry/angular';
import { MONITORING_EVENT_TYPES } from '../constants/event-types';

export const mapEventBeforeSend = (event: Event): Event | null => {
  const NON_MANUAL_ERRORS_FORWARDING_RATE = 0.1;

  if (event.tags?.eventType === MONITORING_EVENT_TYPES.MANUAL) {
    return event;
  }

  if (Math.random() < NON_MANUAL_ERRORS_FORWARDING_RATE) {
    return event;
  }

  return null;
};
