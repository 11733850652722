import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_USER_CONSENT_ENDPOINT } from './endpoints';
import type { UserConsentPayloadDto } from '@wallapop/api-layer/apis/consent/user-consent/dtos/user-consent-payload-dto';

@Injectable()
export class UserConsentHttpService {
  constructor(private http: HttpClient) {}

  public setUserConsent(body: UserConsentPayloadDto): Observable<void> {
    return this.http.post<void>(API_USER_CONSENT_ENDPOINT, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
