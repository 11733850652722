import { ExternalCommsApi } from '../typing/types/external-comms-api.type';
import { environment } from '@wallapop/environments';

export const initBraze = (lib: ExternalCommsApi): boolean => {
  const { brazeKey, brazeBaseUrl } = environment;

  return lib.initialize(brazeKey, {
    baseUrl: brazeBaseUrl,
  });
};
