import { ENVELOPE_DEFAULT_IDENTIFIER_TYPE, IdentifierTypes, RELEVANT_YIELD_CONFIG_ID } from './constants';
import {
  AdSlot,
  AdSlotContainer,
  PageAdType,
  WidthHeightPair,
  AdLibrary,
  AdsLibraryNameType,
  SearchAds,
} from './interfaces/ads.interfaces';
import { AdKeywords } from './interfaces/keywords.interfaces';
import { init } from './liveramp/liveramp';
import { getCookie } from './utils/cookie.utils';

export const filterAdSlotsByDevice = (adSlots: AdSlot[], deviceType: string): AdSlot[] => {
  return adSlots.filter((adSlot: AdSlot) => adSlot.device.includes(deviceType));
};

export const mapAdSlotsToAdSlotContainer = (adSlots: AdSlot[]): AdSlotContainer[] => {
  return adSlots.map((adSlot: AdSlot) => ({
    id: adSlot.id,
    adUnit: adSlot.name,
    maxHeight: _findMaxHeight(adSlot.sizes as WidthHeightPair[]),
  }));
};

export const setUpAds = (
  page: PageAdType,
  adSlots: AdSlot[],
  deviceType: string,
  isGoogleAllowed: boolean,
  isCriteoAllowed: boolean,
  adKeywords: AdKeywords,
  searchAds?: SearchAds,
): void => {
  (<any>window)['deviceType'] = deviceType;
  (<any>window)['googletag'] = (<any>window)['googletag'] || { cmd: [] };

  const allowSegmentation: boolean = isGoogleAllowed;
  const { content } = adKeywords;

  googletag.cmd.push(() => {
    if (isCriteoAllowed) _initCriteo();
    _initApstag();
    _setAdKeywords(adKeywords);
    _defineAdsSlots(adSlots, deviceType);
    _displayAds(allowSegmentation, adSlots, deviceType);
    if (searchAds && content) displaySearchAds(searchAds, content);
    _initRelevantYield();
  });
};

export const refreshAds = (adKeyword: AdKeywords): void => {
  (<any>window)['googletag'] = (<any>window)['googletag'] || { cmd: [] };
  googletag.cmd.push(() => {
    _setAdKeywords(adKeyword);
    googletag.pubads().refresh();
  });
};

export const displaySearchAds = (searchAds: SearchAds, query: string): void => {
  const { searchAd } = searchAds;
  const { config, options } = searchAd;

  (<any>window)['googletag'] = (<any>window)['googletag'] || { cmd: [] };

  googletag.cmd.push(() => {
    (<any>window)['_googCsa']('ads', { ...options, ...(query && { query }) }, [config]);
  });
};

export const setUpLiveramp = (
  consentHash: string,
  identifier: string,
  identifierType: IdentifierTypes = ENVELOPE_DEFAULT_IDENTIFIER_TYPE,
) => {
  init(consentHash, identifier, identifierType);
};

export const setUpGeoedge = (apiKey: string) => {
  (<any>window)['grumi'] = { key: apiKey };
};

export const setUpAdsLibraries = (libraries: AdLibrary[], librariesToRemove: AdsLibraryNameType[]): AdLibrary[] => {
  if (librariesToRemove.length === 0) return libraries;

  return libraries.filter((library) => !librariesToRemove.includes(library.name));
};

const _displayAds = (allowSegmentation: boolean, adSlots: AdSlot[], deviceType: string): void => {
  const publisherId = _getPublisherId();
  const definedSlots = googletag.pubads().getSlots();
  const adSlotsFiltered = filterAdSlotsByDevice(adSlots, deviceType).filter((slot) => !slot.slotType);

  if ((<any>window)['fetchHeaderBids']) (<any>window)['fetchHeaderBids'](allowSegmentation, adSlotsFiltered, definedSlots);

  googletag.pubads().enableSingleRequest();
  googletag.pubads().collapseEmptyDivs(true);
  googletag.pubads().disableInitialLoad();
  googletag.pubads().setPublisherProvidedId(publisherId);
  googletag.enableServices();
};

const _initApstag = (): void => {
  (<any>window)['apstag']?.init({
    pubID: '3703',
    adServer: 'googletag',
    gdpr: {
      cmpTimeout: 1000,
    },
  });
};

const _initCriteo = (): void => {
  (<any>window)['Criteo'] = (<any>window)['Criteo'] || { events: [] };

  (<any>window)['Criteo'].events.push(() => {
    (<any>window)['Criteo'].RequestBidsOnGoogleTagSlots(
      6866,
      () => {
        (<any>window)['Criteo'].SetDFPKeyValueTargeting();
      },
      1000,
    );
  });
};

const _initRelevantYield = (): void => {
  (<any>window)['relevantDigital'] = (<any>window)['relevantDigital'] || { cmd: [] };

  (<any>window)['relevantDigital'].cmd.push(() => {
    (<any>window)['relevantDigital'].loadPrebid({
      configId: RELEVANT_YIELD_CONFIG_ID,
      manageAdserver: false,
      collapseEmptyDivs: true,
      collapseBeforeAdFetch: false,
      allowedDivIds: null,
      noSlotReload: false,
      noGpt: true,
    });
  });
};

const _setAdKeywords = (adKeywords: AdKeywords) => {
  if (Object.keys(adKeywords).length > 0) {
    Object.entries(adKeywords).forEach(([key, value]) => {
      googletag.pubads().setTargeting(key, value);
    });
  }
};

const _defineAdsSlots = (adSlots: AdSlot[], deviceType: string): void => {
  adSlots.forEach((adSlot: AdSlot) => {
    if (adSlot.device.includes(deviceType) && !adSlot.isDefined) {
      _defineAdSlot(adSlot);
      adSlot.isDefined = true;
    }
  });
};

const _defineAdSlot = (adSlot: AdSlot): void => {
  const slot = googletag.defineSlot(adSlot.name, adSlot.sizes, adSlot.id);

  if (adSlot.slotType) {
    _displayAdSlot(adSlot);
  } else {
    slot.defineSizeMapping(_getAdSlotSizeMapping(adSlot));
    slot.setTargeting('ad_h', new Date().getUTCHours().toString());
  }

  slot.addService(googletag.pubads());
};

const _displayAdSlot = (adSlot: AdSlot) => {
  googletag.display(adSlot.id);
};

const _getAdSlotSizeMapping = (adSlot: AdSlot) => {
  if (!adSlot.sizeMapping) {
    return [];
  }

  return googletag
    .sizeMapping()
    .addSize(adSlot.sizeMapping.desktop.screenSize, adSlot.sizeMapping.desktop.mapping) //Desktop
    .addSize(adSlot.sizeMapping.tablet.screenSize, adSlot.sizeMapping.tablet.mapping) //Tablet
    .addSize(adSlot.sizeMapping.mobile.screenSize, adSlot.sizeMapping.mobile.mapping) //Mobile
    .build();
};

const _getPublisherId = (): string => {
  const ppid = getCookie('ppid');
  const publisherId = getCookie('publisherId');

  if (!!publisherId) {
    return publisherId;
  }
  if (!!ppid) {
    return ppid;
  }
  return '-1' + Array(31).join('0');
};

const _findMaxHeight = (sizes: WidthHeightPair[]): number => {
  const heights = sizes.map((pairOfSizes: WidthHeightPair) => pairOfSizes[1]);

  return heights.reduce((a, b) => Math.max(a, b), -Infinity);
};
