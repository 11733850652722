import type { Genders } from '@braze/web-sdk';
import { changeUser, setUserDetails, setUserEmail, setUsername } from '../core';
import { type ExternalCommsUser } from '../typing/types/external-comms-api.type';

export type ExternalCommsGender = 'male' | 'female';
export const EXTERNAL_COMMS_GENDER = {
  male: 'm',
  female: 'f',
  unknown: 'u',
} as const;

const externalCommsGenderMapper = (key?: ExternalCommsGender): Genders => {
  if (!key) return EXTERNAL_COMMS_GENDER.unknown;
  return EXTERNAL_COMMS_GENDER?.[key] ?? EXTERNAL_COMMS_GENDER.unknown;
};

export const setExternalCommsUser = async (user: ExternalCommsUser) => {
  const { id, firstName, lastName, birthDate, gender, email } = user;
  const externalCommsGender = externalCommsGenderMapper(gender as ExternalCommsGender);

  await changeUser(id);

  if (firstName && lastName) {
    await setUsername(firstName, lastName);
  }

  if (birthDate) {
    await setUserDetails(birthDate, externalCommsGender);
  }

  if (email) {
    await setUserEmail(email);
  }
};
