import { mapImageHttpToHttps } from '../../../core/mappers/image-mapper';
import { ExperimentatorMetaDto } from '../dtos/experimentator-response.dto';
import { ExperimentatorMeta } from '../interfaces/experimentator-response.interface';

export const experimentatorMetaResponseMapper = (meta: ExperimentatorMetaDto): ExperimentatorMeta => {
  const newMeta: ExperimentatorMeta = {
    next: meta.next || null,
    layout: meta.layout,
    type: meta.type,
    title: meta.title,
    subtitle: meta.subtitle || null,
    feedback: meta.feedback || false,
    images: meta.images?.map((image) => mapImageHttpToHttps(image)) || [],
    badges: meta.badges || null,
    impressionId: meta.impression_id || '',
    animation:
      (meta.animation && {
        transition: meta.animation.transition,
        sequence: meta.animation.sequence,
        interval: meta.animation.interval,
        duration: {
          in: meta.animation.duration.in,
          out: meta.animation.duration.out,
        },
      }) ||
      null,
    seeMore:
      (meta.see_more && {
        text: meta.see_more.text,
        type: meta.see_more.type,
        action: {
          actionType: meta.see_more.action.action_type,
          payload: {
            linkUrl: meta.see_more.action.payload.link_url,
          },
        },
      }) ||
      null,
    tab1Title: meta.tab1_title || null,
    tab2Title: meta.tab2_title || null,
  };

  return newMeta;
};
