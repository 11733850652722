import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LEAKED_CREDNETIALS_MODAL_ACTIONS } from '../constants/leaked-credentilas-modal-actions.constants';

@Component({
  selector: 'tsl-leaked-credentials-modal',
  templateUrl: './leaked-credentials-modal.component.html',
  styleUrls: ['./leaked-credentials-modal.component.scss'],
})
export class LeakedCredentialsModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  public clickNoThanks(): void {
    this.activeModal.close(LEAKED_CREDNETIALS_MODAL_ACTIONS.NO_THANKS);
  }

  public clickChangePassword(): void {
    this.activeModal.close(LEAKED_CREDNETIALS_MODAL_ACTIONS.CHANGE_PASSWORD);
  }

  public close(): void {
    this.activeModal.close();
  }
}
