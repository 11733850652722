import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsNCommsModalComponent } from './components/terms-n-comms-modal/terms-n-comms-modal.component';
import { ButtonModule } from '@shared/button/button.module';
import { CheckboxFormModule } from '@shared/form/components/checkbox/checkbox-form.module';
import { FormsModule } from '@angular/forms';
import { GdprInfoModalComponent } from './components/gdpr-info-modal/gdpr-info-modal.component';
import { SvgIconModule } from '@shared/svg-icon/svg-icon.module';
import { TermsNCommsApiModule } from '@api/terms-n-comms/terms-n-comms-api.module';
import { TermsNCommsService } from './services/terms-n-comms.service';

@NgModule({
  declarations: [TermsNCommsModalComponent, GdprInfoModalComponent],
  imports: [CommonModule, FormsModule, ButtonModule, CheckboxFormModule, SvgIconModule, TermsNCommsApiModule],
  providers: [TermsNCommsService],
})
export class TermsNCommsModule {}
