import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NullQueryParamsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let params = new HttpParams();
    request.params.keys().forEach((paramKey) => {
      const paramValue = request.params.getAll(paramKey);
      paramValue.forEach((param) => {
        if (param !== null && param !== undefined && param !== 'null') {
          params = params.append(paramKey, param);
        }
      });
    });
    request = request.clone({ params });
    return next.handle(request);
  }
}
