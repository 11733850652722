import { Inject, Injectable } from '@angular/core';

import { WINDOW_TOKEN } from '@core/window/window.token';
import { RelevantDigitalLibrary } from './relevant-yield.interface';
import { RELEVANT_YIELD_CONFIG } from './relevant-yield.constants';

@Injectable({
  providedIn: 'root',
})
export class RelevantYieldService {
  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  get relevantDigital(): RelevantDigitalLibrary {
    return this.window['relevantDigital'];
  }

  public init(): void {
    this.relevantDigital.cmd.push(() => {
      this.relevantDigital.loadPrebid(RELEVANT_YIELD_CONFIG);
    });
  }

  public isLibraryRefDefined(): boolean {
    return !!this.relevantDigital;
  }
}
