import { Inject, Injectable } from '@angular/core';
import { AppStatusService } from '@core/app-status/app-status.service';
import { NotificationsPermissionStatus } from '@core/desktop-notifications/desktop-notifications.service';
import { LOCAL_STORAGE_TOKEN } from '@core/local-storage/local-storage.token';
import { BehaviorSubject, Observable } from 'rxjs';

type PushOptinStatus = 'True' | 'False' | 'Undefined';
@Injectable({ providedIn: 'root' })
export class SessionInfoService {
  private static USER_JUST_LOGGED_IN_KEY = 'wallapop_user_just_logged_in';
  private readonly STORAGE_SYSTEM: Storage;
  private _userJustLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(LOCAL_STORAGE_TOKEN) private localStorage: Storage,
    private appStatusService: AppStatusService,
  ) {
    this.STORAGE_SYSTEM = this.localStorage;
  }

  public get userJustLoggedIn$(): Observable<boolean> {
    return this._userJustLoggedIn$.asObservable();
  }

  public get userJustLoggedIn(): boolean {
    return this._userJustLoggedIn$.getValue();
  }

  public getPushOptInStatus(): PushOptinStatus {
    const status = window.Notification?.permission;

    return this.mapNotificationsToPushOptIn(status);
  }

  public init(): void {
    this.checkUserJustLoggedIn();
  }

  private checkUserJustLoggedIn(): void {
    const userJustLoggedIn = this.retrieveUserLoggedInValue();

    if (userJustLoggedIn) {
      this._userJustLoggedIn$.next(true);
    }

    this.resetLocalStorageValue();
  }

  private resetLocalStorageValue(): void {
    this.STORAGE_SYSTEM.setItem(SessionInfoService.USER_JUST_LOGGED_IN_KEY, 'false');
  }

  private retrieveUserLoggedInValue(): boolean {
    const userJustLoggedIn = this.STORAGE_SYSTEM.getItem(SessionInfoService.USER_JUST_LOGGED_IN_KEY);

    try {
      if (!userJustLoggedIn) {
        return true;
      }
      return JSON.parse(userJustLoggedIn);
    } catch (e) {
      this.appStatusService.addError('[Session Info Service]: Error retrieving user logged in key.', e);
      return false;
    }
  }

  private mapNotificationsToPushOptIn(status: NotificationsPermissionStatus): PushOptinStatus {
    switch (status) {
      case 'granted':
        return 'True';
      case 'denied':
        return 'False';
      default:
        return 'Undefined';
    }
  }
}
