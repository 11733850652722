export type HttpResponseCodeType = (typeof HTTP_RESPONSE_CODE)[keyof typeof HTTP_RESPONSE_CODE];

export type HttpResponseStatusType = (typeof HTTP_RESPONSE_STATUS)[keyof typeof HTTP_RESPONSE_STATUS];

export type HttpRedirectCodeType = 301 | 302;

export const HTTP_RESPONSE_CODE = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  GONE: 410,
  UNAUTHORIZED_ERROR: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
} as const;

export const HTTP_RESPONSE_STATUS = {
  INFO: 'info',
  SUCCESS: 'success',
  REDIRECTION: 'redirection',
  CLIENT_ERROR: 'client error',
  SERVER_ERROR: 'server error',
} as const;
