import { environment } from '@wallapop/environments';
import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../core/http/http-client.interface';
import { ExperimentatorResponseDto } from '../dtos/experimentator-response.dto';
import { EXPERIMENTATOR_ENDPOINT } from '../endpoints';

type ExperimentatorResponseService = (
  httpClient: HttpClient,
  experimentator: string,
  itemId?: string,
  categoryId?: string,
  keyword?: string,
) => Promise<AxiosResponse<ExperimentatorResponseDto>>;

export const getExperimentator: ExperimentatorResponseService = (
  httpClient: HttpClient,
  experimentator: string,
  itemId?: string,
  categoryId?: string,
  keyword?: string,
) => {
  const config = {
    params: {
      initiative: experimentator,
      item_id: itemId,
      category_id: categoryId,
      keyword,
    },
  };

  return httpClient.get<ExperimentatorResponseDto>(`${environment.baseUrl}${EXPERIMENTATOR_ENDPOINT}`, config);
};
