export const AD_FOR_SEARCH_PUB_ID_WALLAPOP = 'partner-pub-5927681371993674';

export const AD_FOR_SEARCH_1 = '9051181869';
export const AD_FOR_SEARCH_SLIDER = '1688707416';
export const AD_FOR_SEARCH_SLIDER_SIDEBAR = '9620702332';
export const AD_FOR_SEACH_WIDE_CARD = '8461173566';

export const AD_FOR_SEARCH_SLIDER_CONTAINER = 'afscontainer';
export const AD_FOR_SEARCH_SLIDER_CONTAINER_SIDEBAR = 'afscontainer1';
export const AD_FOR_SEARCH_SLIDER_DESKTOP = '5242187361';
export const AD_FOR_SEARCH_SLIDER_DESKTOP_SIDEBAR = '4827221620';
export const AD_FOR_SEARCH_SLIDER_MOBILE = '9963085783';

export const AD_FOR_SEARCH_SLOT_ID = 'afshcontainer';
export const AD_FOR_SEARCH_SLOT_ID_SIDEBAR = 'afscontainer1';
