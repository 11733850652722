import { CATEGORY_NAVIGATION_SOURCES } from '@private/shared/category-navigation/category-navigation-tracking/category-navigation-tracking.interface';
import { ExperimentatorCategoryData } from '@wallapop/api-layer/apis/experimentator/interfaces/experimentator-category-response.interface';
import { ASSETS_INTERFACES } from '@wallapop/web-components';

export interface ViewAllOptionProps {
  url: string;
  categoryId: number | null;
  rootCategoryId: number | null;
  isTopCategory: boolean;
}

export function getViewAllCategoryOption({
  url,
  categoryId,
  rootCategoryId,
  isTopCategory,
}: ViewAllOptionProps): ExperimentatorCategoryData {
  const isFromAllCategoriesSection = !categoryId;

  return {
    categoryId: 0,
    title: $localize`:@@home_view_all_users_category_selector_list_view_all_categories_label:View all`,
    icon: isFromAllCategoriesSection ? ASSETS_INTERFACES.CATEGORIES : null,
    url: isFromAllCategoriesSection ? getAllCategoriesURL(url) : getCategoryURL({ url, categoryId, rootCategoryId, isTopCategory }),
    subcategories: [],
  };
}

function getAllCategoriesURL(url: string) {
  return `${url}app/search?filters_source=${CATEGORY_NAVIGATION_SOURCES.categoryNavigation}`;
}

function getCategoryURL({ url, categoryId, rootCategoryId, isTopCategory }: ViewAllOptionProps): string {
  const isRootCategory = rootCategoryId === categoryId;
  const filtersSource = `${
    isTopCategory ? CATEGORY_NAVIGATION_SOURCES.topCategoryNavigation : CATEGORY_NAVIGATION_SOURCES.categoryNavigation
  }`;

  if (isRootCategory) {
    return `${url}app/search?category_ids=${categoryId}&filters_source=${filtersSource}`;
  }
  return `${url}app/search?category_ids=${rootCategoryId}&object_type_ids=${categoryId}&filters_source=${filtersSource}`;
}
