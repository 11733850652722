import { MPConfiguration } from '@mparticle/web-sdk';
import { environment } from '@wallapop/environments/environment';

const DATA_PLAN_NAME = 'dataplan';
const DATA_PLAN_VERSION = 6;

export const COMMON_MPARTICLE_CONFIG: MPConfiguration = {
  isDevelopmentMode: !environment.production,
  dataPlan: {
    planId: DATA_PLAN_NAME,
    planVersion: DATA_PLAN_VERSION,
  },
};

export const KIT_REGISTER_ERROR_MSG = `Could not register mParticle kit: `;

export const RATE_LIMIT_ERROR_CODE = 429;
export const MAX_ATTEMPTS = 3;
export const ID_SYNC_OPERATION = {
  LOGIN: 'login',
  LOGOUT: 'logout',
} as const;

export const MPID_COOKIE_NAME = 'MPID';
export const COOKIE_EXPIRATION_DAYS = 7;
