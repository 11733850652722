import { PAYMENT_MONITORING_TRANSACTION_NAME } from '@private/shared/continue-delivery-payment/modules/continue-delivery-payment-monitoring/constants/continue-delivery-payment-monitoring.constants';
import { SamplingContext } from '@sentry/types';

export const mapTracesSampler = (samplingContext: SamplingContext) => {
  const transactionName = samplingContext.transactionContext.name || '';

  if (transactionName.includes(PAYMENT_MONITORING_TRANSACTION_NAME)) {
    return 1;
  }

  return 0;
};
