import { CanActivate, CanLoad, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { AccessTokenService } from '../access-token/services/access-token.service';
import { SITE_URL } from '@configs/site-url.config';
import { PRIVATE_PATHS } from '@private/private-routing-constants';
import { SETTINGS_PATHS } from '@private/features/settings/settings-routing.constants';
import { LEGAL_INFORMATION_ROUTES } from '@private/features/settings/pages/legal-information/legal-information-routing.constants';
import { MonitoringService } from '@core/monitoring/services/monitoring.service';
import { DeviceService } from '@core/device/device.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate, CanLoad {
  constructor(
    private accessTokenService: AccessTokenService,
    private router: Router,
    private monitoringService: MonitoringService,
    private deviceService: DeviceService,
    @Inject(SITE_URL) private siteUrl: string,
  ) {}

  public canActivate() {
    const { loginSource = '' } = this.router.getCurrentNavigation().extractedUrl.queryParams;
    return this._loggedGuardLogic(loginSource);
  }

  public canLoad() {
    const { loginSource = '' } = this.router.getCurrentNavigation().extractedUrl.queryParams;
    return this._loggedGuardLogic(loginSource);
  }

  private _loggedGuardLogic(source?: string): boolean {
    if (!this.accessTokenService.accessToken) {
      const redirectUrl = `redirectUrl=${encodeURIComponent(window.location.href)}`;
      const loginSource = source ? `&loginSource=${source}` : '';
      const redirect = `${this.siteUrl}login?${redirectUrl}${loginSource}`;

      this.sendUnauthorizedAccessLogs();
      window.location.href = redirect;

      return false;
    }
    return true;
  }

  private sendUnauthorizedAccessLogs(): void {
    //TODO: Remove this logs after analyzing the results. Task WPA-62571
    const pathname = window.location.pathname;
    const isPersonalImpactRoute = pathname.includes(`/${PRIVATE_PATHS.SUSTAINABILITY}`);
    const isDAC7Route = pathname.includes(
      `/${PRIVATE_PATHS.SETTINGS}/${SETTINGS_PATHS.LEGAL_INFORMATION}/${LEGAL_INFORMATION_ROUTES.FORM}`,
    );

    if (isPersonalImpactRoute || isDAC7Route) {
      this.monitoringService.captureMessage('[WEB-AUTH]: The private route was not accesible due to a missing accessToken', {
        extra: {
          pathname: window.location.pathname,
          href: window.location.href,
          deviceId: this.deviceService.getDeviceId(),
        },
      });
    }
  }
}
