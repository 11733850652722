import { Locale } from '@wallapop/i18n/src/types';
import { InjectionToken } from '@angular/core';

export type Market = 'ES' | 'IT' | 'PT';

const MARKET_MAP: Record<Locale, Market> = {
  en: 'ES',
  es: 'ES',
  fr: 'ES',
  it: 'IT',
  pt: 'PT',
};

export const MARKET_PROVIDER: InjectionToken<Market> = new InjectionToken<Market>('MARKET_PROVIDER');

export function MarketSiteByLocale(locale: Locale): Market {
  return MARKET_MAP[locale];
}
