import { AnalyticsFeatureFlagService } from './feature-flag-service';

type AnalyticsProvider = 'mparticle' | 'rudderstack';

export const getProvidersForAnalytics = async (): Promise<AnalyticsProvider[]> => {
  const providers: AnalyticsProvider[] = [];

  const isMparticleEnabled = await AnalyticsFeatureFlagService.isMparticleEnabled();
  const isRudderstackEnabled = await AnalyticsFeatureFlagService.isRudderstackEnabled();

  if (isMparticleEnabled) {
    providers.push('mparticle');
  }

  if (isRudderstackEnabled) {
    providers.push('rudderstack');
  }

  return providers;
};
