export interface UserType {
  type: UserTypeValue;
}

export interface LoggedUserType extends UserType {
  identificationNeeded: boolean;
  dsaVerificationNeeded: boolean;
  dsaCatalogBlocked: boolean;
}

export const USER_TYPE = {
  BUSINESS: 'business',
  INDIVIDUAL: 'individual',
  UNKNOWN: 'unknown',
} as const;

export type UserTypeValue = EnumValues<typeof USER_TYPE>;

export type RequiredUserData = 'identificationNeeded' | 'dsaNeeded';
