import { RudderstackAnalyticsLib, RudderStackAnalyticsLibConfigOptopns } from './rudderstack.analytics.types';
import { environment } from '@wallapop/environments';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { getDecryptedValueBrowser } from '@rudderstack/analytics-js-cookies';
import { AnalyticsUser, getMPID } from '#analytics/index';
import { setUserForRudderstack } from './set-user-for-rudderstack';

let analyticsLib: RudderstackAnalyticsLib;
let analyticsLibPromise: Promise<RudderstackAnalyticsLib> | null = null;

type RudderstackSessionCookiesDecryptedRaw = {
  autoTrack?: boolean;
  id: number;
  manualTrack?: boolean;
  sessionStart: boolean;
};

export const isRudderstackAnalyticsDisabled = (): boolean => {
  return !environment.analytics.enabled;
};

export const initRudderstackAnalytics = (
  user?: AnalyticsUser,
  config?: RudderStackAnalyticsLibConfigOptopns,
): Promise<RudderstackAnalyticsLib | null> => {
  const { analytics } = environment;
  const { url, writeKey } = analytics;

  if (isRudderstackAnalyticsDisabled()) {
    return Promise.resolve(null);
  }

  if (analyticsLibPromise) {
    return analyticsLibPromise;
  }

  if (analyticsLib && analyticsLib.isReady) {
    ensureAnonymousIdIsMparticleId();
    return syncUserToRudderstackLib(user);
  }

  analyticsLibPromise = new Promise<RudderstackAnalyticsLib>((resolveInit) => {
    analyticsLib = new RudderAnalytics() as RudderstackAnalyticsLib;
    analyticsLib.load(writeKey, url, {
      configUrl: url,
      integrations: {
        ...config?.integrations,
      },
      queueOptions: {
        batch: {
          enabled: true,
          flushInterval: 5_000,
        },
      },
    });

    analyticsLib.ready(async () => {
      await ensureValidSessionId();
      ensureAnonymousIdIsMparticleId();
      syncUserToRudderstackLib(user).then(() => {
        analyticsLib.isReady = true;
        analyticsLibPromise = null;
        resolveInit(analyticsLib);
      });
    });
  });

  return analyticsLibPromise;
};

const ensureAnonymousIdIsMparticleId = () => {
  const mParticleId = getMPID() || undefined;
  const anonymousIdNeedsSync = analyticsLib.getAnonymousId() !== mParticleId;
  if (anonymousIdNeedsSync) {
    analyticsLib.setAnonymousId(mParticleId);
  }
};

const ensureValidSessionId = async () => {
  try {
    const sessionCookieKey = 'rl_session';
    const lastSessionCookieRaw = getCookieValue(sessionCookieKey);
    const isFreshSession = !lastSessionCookieRaw;
    if (isFreshSession) {
      return;
    }

    const forceSessionReset = () => {
      deleteCookie(sessionCookieKey);
      analyticsLib.reset();
    };

    const lastSession = getDecryptedValueBrowser(lastSessionCookieRaw) as RudderstackSessionCookiesDecryptedRaw;
    const { id } = lastSession;
    const isInvalidSessionId = !isValidSessionId(id);
    if (isInvalidSessionId) {
      forceSessionReset();
    }

    return new Promise<void>((resolve) => {
      let currentTries = 0;
      const maxTries = 50;
      const timeBetweenTriesMs = 100;
      const interval = setInterval(() => {
        const id = analyticsLib.getSessionId();
        const isValid = isValidSessionId(id);
        if (isValid) {
          return done();
        }

        currentTries += 1;
        if (currentTries >= maxTries) {
          console.error(`[Rudderstack] Wrong session id detected: ${id}`);
          return done();
        }

        forceSessionReset();
      }, timeBetweenTriesMs);

      const done = () => {
        clearInterval(interval);
        return resolve();
      };
    });
  } catch {}
};

const getCookieValue = (key: string) => {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [cookieKey, cookieValue] = cookie.split('=');
    if (cookieKey === key) {
      return decodeURIComponent(cookieValue);
    }
  }

  return null;
};

const deleteCookie = (key: string) => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

const isValidSessionId = (sessionId: unknown) => {
  const isNotNumber = typeof sessionId !== 'number' || isNaN(sessionId);
  if (isNotNumber) {
    return false;
  }

  const hasTimestampLength = sessionId.toString().length === 13;
  return hasTimestampLength;
};

const syncUserToRudderstackLib = (user?: AnalyticsUser) => {
  if (!user) {
    return Promise.resolve(analyticsLib);
  }

  const analyticsUserId = analyticsLib.getUserId();
  const hasUserChanged = analyticsUserId !== user.id;
  const isUserIdNull = user.id === null;
  const userNeedsSync = hasUserChanged || isUserIdNull;
  if (userNeedsSync) {
    return setUserForRudderstack(analyticsLib, user);
  }

  return Promise.resolve(analyticsLib);
};
