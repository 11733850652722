import { Inject, Injectable } from '@angular/core';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { MPARTICLE_KITS } from '#analytics/analytics.constants';
import type { RudderStackAnalyticsLibConfigOptopns } from '#analytics/index';

export const WEBVIEW_QUERY_PARAM = 'webview';

/**
 * Centralizing service for webview-specific configurations
 */
@Injectable({
  providedIn: 'root',
})
export class WebviewManagerService {
  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  public getIsWebview = () => {
    const urlParams = new URLSearchParams(this.window.location?.search);
    return !!urlParams.get(WEBVIEW_QUERY_PARAM);
  };

  public getIsBrazeDisabled = (): boolean => true;

  public adaptKitsForWebviews = (kitsArray: MPARTICLE_KITS[]): MPARTICLE_KITS[] => {
    if (this.getIsBrazeDisabled() && this.getIsWebview()) {
      return kitsArray.filter((kit) => !kit.includes(MPARTICLE_KITS.BRAZE));
    }
    return kitsArray;
  };

  public getIntegrationsConfig = (baseIntegrationsObject: RudderStackAnalyticsLibConfigOptopns['integrations']) => {
    if (this.getIsBrazeDisabled() && this.getIsWebview()) {
      return { ...baseIntegrationsObject, Braze: false };
    }
    return { ...baseIntegrationsObject };
  };
}
