import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tsl-gdpr-info-modal',
  templateUrl: './gdpr-info-modal.component.html',
  styleUrls: ['./gdpr-info-modal.component.scss'],
})
export class GdprInfoModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  public closeModal(): void {
    this.activeModal.close();
  }
}
