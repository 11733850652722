export enum MULTI_FACTOR_OPERATION {
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  KYC_BANNER_BANK_DETAILS = 'KYC_BANNER_BANK_DETAILS',
  EDIT_BANK_DETAILS = 'EDIT_BANK_DETAILS',
  ADD_BANK_DETAILS = 'ADD_BANK_DETAILS',
  DEFAULT = 'DEFAULT',
}

export type MultiFactorOperation = MULTI_FACTOR_OPERATION;
