import { HttpClientInterceptor, HttpClientRequestOnFulfilledInterceptor } from '../http-client.types';
import { HTTP_CLIENT_HEADERS } from '../http-client.constants';
import { getAccessTokenFromCookies } from '../../authorization/access-token';
import { AUTH_REFRESH_ENDPOINT } from '../../../apis/auth/refresh/endpoints';
import { environment } from '@wallapop/environments';

interface AuthorizationInterceptorOptions {
  baseUrl: string;
  getCurrentCookies?: () => Partial<Record<string, string>>;
}

type CreateAuthorizationInterceptor = (options: AuthorizationInterceptorOptions) => HttpClientInterceptor;

export const createAuthorizationInterceptor: CreateAuthorizationInterceptor = (options) => {
  const { baseUrl, getCurrentCookies: getCookies } = options;
  const requestOnFulfilledInterceptor: HttpClientRequestOnFulfilledInterceptor = (request) => {
    const cookies = getCookies?.();
    request.headers ??= {};

    const isWallapopRequest = request.url?.startsWith(baseUrl);
    const isLanguageHeaderPresent = !!request.headers[HTTP_CLIENT_HEADERS.AUTHORIZATION];
    const accessToken = getAccessTokenFromCookies(cookies);
    const isFFProxyRequest = request.url?.startsWith(environment.featureFlagEndpoint);
    const needAddAuthorizationHeader = (isWallapopRequest || isFFProxyRequest) && !!accessToken && !isLanguageHeaderPresent;
    const isRefreshEndpoint = request.url?.includes(AUTH_REFRESH_ENDPOINT);

    if (needAddAuthorizationHeader && !isRefreshEndpoint) {
      request.headers[HTTP_CLIENT_HEADERS.AUTHORIZATION] = `Bearer ${accessToken}`;
    }

    return request;
  };

  return {
    request: {
      onFulfilled: requestOnFulfilledInterceptor,
    },
  };
};
