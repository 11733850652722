import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TermsNCommsBodyRequest } from '../dtos/request/terms-n-comms-request.interface';
import { ME_TERMS_COMMS_URL } from './endpoints';

@Injectable()
export class TermsNCommsHttpService {
  public constructor(private httpClient: HttpClient) {}

  public updateTermsAndComms(body: TermsNCommsBodyRequest): Observable<void> {
    return this.httpClient.put<void>(ME_TERMS_COMMS_URL, body);
  }
}
