import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, Subject, fromEvent, race, timer } from 'rxjs';
import {
  FIRST_TIME_SEARCH_ONBOARDING,
  ONBOARDING_STEPS,
  ONBOARDING_STEPS_QUEUE,
  ONBOARDING_STEP_KEY,
  THIRTY_DAYS,
} from './onboarding.constants';
import { ScrollLockService } from '@public/shared/services/scroll-lock.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import {
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_TYPES,
  AnalyticsEvent,
  DismissTooltip,
  ImpressionFavoriteSearchesTooltip,
  ImpressionTooltip,
  SCREEN_IDS,
} from '@wallapop/analytics/constants';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { ONBOARDING_TOOLTIP_DISMISS_SOURCES, ONBOARDING_TOOLTIP_SOURCES, ONBOARDING_TOOLTIP_TYPE } from './onbarding.interface';

@Injectable()
export class OnboardingService {
  private readonly showOnboarding$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly activeStep$: BehaviorSubject<ONBOARDING_STEP_KEY> = new BehaviorSubject<ONBOARDING_STEP_KEY>(null);
  private readonly ONBOARDING_START_TIMER = 2000;
  private destroy$ = new Subject<void>();

  public get show$(): Observable<boolean> {
    return this.showOnboarding$.asObservable();
  }

  public get currentStep$(): Observable<ONBOARDING_STEP_KEY> {
    return this.activeStep$.asObservable();
  }

  constructor(
    private cookieService: CookieService,
    private scrollLockService: ScrollLockService,
    private analyticsService: AnalyticsService,
  ) {}

  public initSearchOnboarding(): void {
    const isFirstTime = !this.cookieService.get(FIRST_TIME_SEARCH_ONBOARDING);

    if (isFirstTime) {
      const timer$ = timer(this.ONBOARDING_START_TIMER);

      const scroll$ = fromEvent(window, 'scroll').pipe(
        map(() => window.scrollY),
        filter((scrollPosition) => scrollPosition >= 300),
        take(1),
      );

      const race$ = race(timer$, scroll$);

      race$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.setNextStep(ONBOARDING_STEPS_QUEUE[0]);
        this.showOnboarding$.next(true);
        this.scrollLockService.lock();
        this.handleOnboardingCompletion();
      });
    }
  }

  public stopOnboarding(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setNextStep(stepKey: ONBOARDING_STEP_KEY): void {
    if (stepKey) this.activeStep$.next(stepKey);
  }

  public trackTooltipImpression(source: ONBOARDING_TOOLTIP_SOURCES): void {
    const event: AnalyticsEvent<ImpressionTooltip> = {
      name: ANALYTICS_EVENT_NAMES.ImpressionTooltip,
      attributes: {
        source: source,
        screenId: SCREEN_IDS.Search,
      },
      eventType: ANALYTICS_EVENT_TYPES.Other,
    };
    this.analyticsService.trackEvent(event);
  }

  public trackFavoriteSearchTooltipImpression(searchId: string, source: 'first_search' = 'first_search'): void {
    const event: AnalyticsEvent<ImpressionFavoriteSearchesTooltip> = {
      name: ANALYTICS_EVENT_NAMES.ImpressionFavoriteSearchesTooltip,
      attributes: {
        searchId: searchId,
        source: source,
        screenId: SCREEN_IDS.Search,
      },
      eventType: ANALYTICS_EVENT_TYPES.Other,
    };
    this.analyticsService.trackEvent(event);
  }

  public trackTooltipDismiss(tooltip: ONBOARDING_TOOLTIP_TYPE, source: ONBOARDING_TOOLTIP_DISMISS_SOURCES): void {
    const event: AnalyticsEvent<DismissTooltip> = {
      name: ANALYTICS_EVENT_NAMES.DismissTooltip,
      attributes: {
        tooltip,
        source,
        screenId: SCREEN_IDS.Search,
      },
      eventType: ANALYTICS_EVENT_TYPES.UserPreference,
    };
    this.analyticsService.trackEvent(event);
  }

  private handleOnboardingCompletion(): void {
    this.currentStep$.subscribe((step) => {
      if (step === ONBOARDING_STEPS.END) {
        this.showOnboarding$.next(false);
        this.scrollLockService.unlock();
        this.setTooltipCookie(FIRST_TIME_SEARCH_ONBOARDING);
      }
    });
  }

  private setTooltipCookie(cookieName: string): void {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + THIRTY_DAYS);
    this.cookieService.put(cookieName, 'true', { expires: expirationDate });
  }
}
