// Utility that ensures attribute is defined in window by checking it using an interval
export const getGlobalAttribute: <T = unknown>(attributeName: string) => Promise<T> = async <T = unknown>(attributeName: string) => {
  return new Promise<T>((resolve) => {
    const interval = setInterval(() => {
      const attribute = (window as any)[attributeName];
      if (attribute) {
        resolve(attribute);
        clearInterval(interval);
      }
    }, 50);
  });
};
