import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalProperties } from './confirmation-modal.interface';
import { ButtonSubtype } from '@wallapop/web-components';

@Component({
  selector: 'tsl-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent implements OnInit {
  public properties: ConfirmationModalProperties;
  public readonly DEFAULT_CANCEL_BUTTON_SUBTYPE: ButtonSubtype = 'neutral';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (!this.properties.cancelMessage) {
      this.properties.cancelMessage = $localize`:@@web_cancel_button:Cancel`;
    }
  }
}
