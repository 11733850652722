import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RefreshToken } from '@api/core/model/auth/refresh-token/refresh-token.interface';
import { RefreshTokenHttpService } from './refresh-token/http/refresh-refresh-http.service';
import { mapRefreshTokenDtoToRefreshToken } from './refresh-token/mappers/refresh-token.mapper';

@Injectable()
export class AuthApiService {
  constructor(private authRefreshHttpService: RefreshTokenHttpService) {}

  public refresh(): Observable<RefreshToken> {
    return this.authRefreshHttpService.refresh().pipe(map(mapRefreshTokenDtoToRefreshToken));
  }
}
