import { LOCALES } from '#api-layer/../i18n/src/constants';
import { Locale } from '#api-layer/../i18n/src/types';
import { getLocale } from '#api-layer/../i18n/src/utils/locale.utils';
import { HttpClient } from '#api-layer/core/http/http-client.interface';
import { getCategoryTree } from './http/navigation-categories-http.service';
import { NavigationCategory, NavigationCategories } from './interfaces/navigation-categories.interface';
import { mapNavigationCategories } from './mappers/navigation-categories.mapper';

type FallbackModuleType = () => Promise<{ data: NavigationCategory[] }>;

export const FALLBACK_CATEGORIES: Record<Locale, FallbackModuleType> = {
  [LOCALES.SPANISH]: () => import('./fallback/categories-es'),
  [LOCALES.ITALIAN]: () => import('./fallback/categories-it'),
  [LOCALES.PORTUGUESE]: () => import('./fallback/categories-pt'),
  [LOCALES.ENGLISH]: () => import('./fallback/categories-en'),
  [LOCALES.FRENCH]: () => import('./fallback/categories-fr'),
};

const CUSTOM_CATEGORY_EMPTY_ERROR_NAME = 'EmptyCategoryTreeError';
const CUSTOM_CATEGORY_EMPTY_ERROR_MESSAGE = 'Category tree is empty: expected at least one category';

export const fetchNavigationCategories = async (
  httpClient: HttpClient,
  errorHandler: (error: unknown) => void,
): Promise<NavigationCategories> => {
  const fallback = await FALLBACK_CATEGORIES[getLocale(httpClient.locale)]();
  const fallbackResponse = { data: fallback.data, meta: { title: '' } };

  try {
    const { data: NavigationCategories } = await getCategoryTree(httpClient);
    const isCategoryTreeEmpty = NavigationCategories.data.length === 0;
    if (isCategoryTreeEmpty) {
      const error = new RangeError(CUSTOM_CATEGORY_EMPTY_ERROR_MESSAGE);
      error.name = CUSTOM_CATEGORY_EMPTY_ERROR_NAME;
      throw error;
    }
    return mapNavigationCategories(NavigationCategories);
  } catch (error) {
    errorHandler(error);

    return fallbackResponse;
  }
};
