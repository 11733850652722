import { Injectable } from '@angular/core';
import { VERIFICATION_METHOD } from '@api/core/model/verifications';
import {
  AnalyticsEvent,
  AnalyticsPageView,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_TYPES,
  ClickEditPassword,
  ClickSendEmailChangePassword,
  ClickVerificationOption,
  SCREEN_IDS,
  ViewChangeNotSecurePassword,
  ANALYTICS_SCREEN_EVENT_NAMES,
} from '@wallapop/analytics/constants';
import { AnalyticsService } from '@core/analytics/analytics.service';

@Injectable()
export class LeakedCredentialsTrackingEventsService {
  constructor(private analyticsService: AnalyticsService) {}

  public trackLeakedCredentialsView(): void {
    const event: AnalyticsPageView<ViewChangeNotSecurePassword> = {
      name: ANALYTICS_SCREEN_EVENT_NAMES.ViewChangeNotSecurePassword,
      attributes: {
        screenId: SCREEN_IDS.NotSecurePassword,
      },
    };

    this.analyticsService.trackPageView(event);
  }

  public trackClickEditPassword(emailVerified: boolean): void {
    const event: AnalyticsEvent<ClickEditPassword> = {
      name: ANALYTICS_EVENT_NAMES.ClickEditPassword,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        screenId: SCREEN_IDS.NotSecurePassword,
        emailVerified,
      },
    };

    this.analyticsService.trackEvent(event);
  }

  public trackClickVerificationOption(): void {
    const event: AnalyticsEvent<ClickVerificationOption> = {
      name: ANALYTICS_EVENT_NAMES.ClickVerificationOption,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        screenId: SCREEN_IDS.NotSecurePassword,
        verificationMethod: VERIFICATION_METHOD.EMAIL,
      },
    };

    this.analyticsService.trackEvent(event);
  }

  public trackClickSendEmailChangePassword(): void {
    const event: AnalyticsEvent<ClickSendEmailChangePassword> = {
      name: ANALYTICS_EVENT_NAMES.ClickSendEmailChangePassword,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        screenId: SCREEN_IDS.NotSecurePassword,
      },
    };

    this.analyticsService.trackEvent(event);
  }
}
