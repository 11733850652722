import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { FavoriteSearchesDto } from '../dtos/favorite-searches-dto';
import { BASE_FAVORITE_SEARCHES_ENDPOINT, FAVORITE_SEARCHES_EXIST_ENDPOINT, FAVORITE_SEARCHES_TOTAL_HITS_ENDPOINT } from './endpoints';
import { NewOldFavoriteSearchResponse } from '../dtos/favorite-search-new-old-dto.interface';
import { HEADER_NAMES } from '@core/constants/header-constants';
import { FavoriteSearchesTotalHits } from '../dtos/favorite-searches-total-hits';

@Injectable()
export class FavoriteSearchesHttpService {
  private defaultHeaderOptions: { headers: { [key: string]: string } } = {
    headers: {
      [HEADER_NAMES.APPVERSION]: '0',
    },
  };

  private headerOptionsWithHttpResponse = {
    ...this.defaultHeaderOptions,
    observe: 'response' as 'body',
  };

  constructor(private httpClient: HttpClient) {}

  public get(): Observable<FavoriteSearchesDto[]> {
    return this.httpClient.get<FavoriteSearchesDto[]>(`${BASE_FAVORITE_SEARCHES_ENDPOINT}`, this.defaultHeaderOptions);
  }

  public create(filters: string): Observable<HttpResponse<HttpHeaderResponse>> {
    return this.httpClient.post<HttpResponse<HttpHeaderResponse>>(
      `${BASE_FAVORITE_SEARCHES_ENDPOINT}?${filters}`,
      undefined,
      this.headerOptionsWithHttpResponse,
    );
  }

  public enable(favoriteSearchId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${BASE_FAVORITE_SEARCHES_ENDPOINT}${favoriteSearchId}/alert/enable/`,
      undefined,
      this.defaultHeaderOptions,
    );
  }

  public delete(favoriteSearchId: string): Observable<void> {
    return this.httpClient.delete<void>(`${BASE_FAVORITE_SEARCHES_ENDPOINT}${favoriteSearchId}`, this.defaultHeaderOptions);
  }

  public exist(filters: string): Observable<HttpResponse<HttpHeaderResponse>> {
    return this.httpClient.head<HttpResponse<HttpHeaderResponse>>(
      `${FAVORITE_SEARCHES_EXIST_ENDPOINT}?${filters}`,
      this.headerOptionsWithHttpResponse,
    );
  }

  public getFavoriteSearch(favoriteSearchId: string): Observable<HttpResponse<NewOldFavoriteSearchResponse>> {
    return this.httpClient.get<HttpResponse<NewOldFavoriteSearchResponse>>(
      `${BASE_FAVORITE_SEARCHES_ENDPOINT}${favoriteSearchId}/search`,
      this.headerOptionsWithHttpResponse,
    );
  }

  public getFavoriteSearchById(favoriteSearchId: string): Observable<HttpResponse<FavoriteSearchesDto>> {
    return this.httpClient.get<HttpResponse<FavoriteSearchesDto>>(
      `${BASE_FAVORITE_SEARCHES_ENDPOINT}${favoriteSearchId}`,
      this.headerOptionsWithHttpResponse,
    );
  }

  public getNextFavoriteSearch(hashed_filters: string): Observable<HttpResponse<NewOldFavoriteSearchResponse>> {
    return this.httpClient.get<HttpResponse<NewOldFavoriteSearchResponse>>(
      `${BASE_FAVORITE_SEARCHES_ENDPOINT}search?hashed_filters=${hashed_filters}`,
      this.headerOptionsWithHttpResponse,
    );
  }

  public getTotalHits(): Observable<FavoriteSearchesTotalHits> {
    return this.httpClient.get<FavoriteSearchesTotalHits>(`${FAVORITE_SEARCHES_TOTAL_HITS_ENDPOINT}`, this.defaultHeaderOptions);
  }
}
