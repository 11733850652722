import { ImageDto } from '../dtos/image-dto';
import { ItemImage } from '../interfaces/image.interface';

function forceHttps(url: string): string {
  const httpsUrlPattern = /^https:\/\//i;

  if (typeof url === 'string' && !httpsUrlPattern.test(url)) {
    return url.replace(/^http:\/\//i, 'https://');
  }

  return url;
}

export function mapImageHttpToHttps(image: ImageDto): ItemImage {
  const { id, average_color, urls } = image;
  const { big: imageBigUrl, medium: imageMediumUrl, small: imageSmallUrl } = urls;

  const httpsImageBigUrl = forceHttps(imageBigUrl);
  const httpsImageMediumUrl = forceHttps(imageMediumUrl);
  const httpsImageSmallUrl = forceHttps(imageSmallUrl);

  return {
    ...(id && { id }),
    averageColor: average_color || null,
    urls: {
      small: httpsImageSmallUrl,
      medium: httpsImageMediumUrl,
      big: httpsImageBigUrl,
    },
  };
}
