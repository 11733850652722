import { NgModule } from '@angular/core';
import { AuthApiModule } from '@api/auth/auth-api.module';
import { LogoutModule } from '@core/logout/logout.module';
import { AuthService } from './auth.service';

@NgModule({
  imports: [AuthApiModule, LogoutModule],
  providers: [AuthService],
})
export class AuthModule {}
