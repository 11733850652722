export const CATEGORY_IDS = {
  CONSUMER_GOODS: -1,
  CAR: 100,
  REAL_ESTATE: 200,
  MOTORBIKE: 14000,
  MOTOR_ACCESSORIES: 12800,
  TECHNOLOGY_ELECTRONICS: 24200,
  TV_AUDIO_CAMERAS: 12545,
  CELL_PHONES_ACCESSORIES: 16000,
  COMPUTERS_ELECTRONICS: 15000,
  SPORTS_LEISURE: 12579,
  BIKES: 17000,
  GAMES_CONSOLES: 12900,
  HOME_GARDEN: 12467,
  FASHION_ACCESSORIES: 12465,
  APPLIANCES: 13100,
  MOVIES_BOOKS: 12463,
  BABIES_CHILD: 12461,
  COLLECTIBLES_ART: 18000,
  CONSTRUCTION: 19000,
  AGRICULTURE_INDUSTRIAL: 20000,
  JOBS: 21000,
  SERVICES: 13200,
  OTHERS: 12485,
  MOVIES_SERIES: 10067,
  CDS_VINYL_CASSETTES: 10060,
};

export const LEGACY_CATEGORY_IDS = [13000];
