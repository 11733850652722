import { Component } from '@angular/core';
import { TermsNComms } from '@api/core/model/terms-n-comms/terms-n-comms.interface';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GdprInfoModalComponent } from '../gdpr-info-modal/gdpr-info-modal.component';

@Component({
  selector: 'tsl-terms-n-comms-modal',
  templateUrl: './terms-n-comms-modal.component.html',
  styleUrls: ['./terms-n-comms-modal.component.scss'],
})
export class TermsNCommsModalComponent {
  public readonly TERMS_AND_CONDITIONS_URL: string = $localize`:@@register_marketing_promotions_consent_all_users_terms_use_checkbox_part_2_link_web_specific:https://wallapop.com/redirect/toc`;
  public readonly PRIVACY_POLICY_URL: string = $localize`:@@register_marketing_promotions_consent_all_users_terms_use_checkbox_part_4_link_web_specific:https://wallapop.com/redirect/priv`;

  public termsNComms: TermsNComms = {
    comms: false,
    terms: false,
  };

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) {}

  public onRejectTerms(): void {
    this.activeModal.close();
  }

  public onConfirmTerms(): void {
    this.activeModal.close(this.termsNComms);
  }

  public onClickMoreInfo(): void {
    this.modalService.open(GdprInfoModalComponent, { animation: false });
  }
}
