import { MobileScreenMappings, WidthHeightPair } from '../interfaces/ads.interfaces';

export const desktopScreenSize: WidthHeightPair = [980, 250];
export const desktopMapping: WidthHeightPair[] = [
  [728, 90],
  [970, 90],
  [980, 90],
  [970, 250],
  [980, 200],
  [980, 250],
];
export const desktopVerticalMapping: WidthHeightPair[] = [
  [300, 250],
  [336, 280],
  [300, 600],
  [120, 600],
  [160, 600],
];
export const tabletScreenSize: WidthHeightPair = [728, 250];
export const tabletMapping: WidthHeightPair[] = [
  [728, 90],
  [728, 250],
];
export const mobileScreenSize: WidthHeightPair = [0, 0];
export const mobileMapping: MobileScreenMappings = {
  small: [
    [300, 50],
    [300, 100],
    [320, 50],
    [320, 100],
  ],
  medium: [
    [300, 50],
    [300, 100],
    [320, 50],
    [320, 100],
    [300, 250],
    [336, 280],
  ],
  big: [
    [300, 50],
    [300, 100],
    [320, 50],
    [320, 100],
    [300, 250],
    [336, 280],
    [300, 600],
  ],
};
