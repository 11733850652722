export const ABANDON_SURVEY_SUCCESS_PATHS = {
  SUCCESS: 'success',
};

export const ABANDON_SURVEY_STATUS = {
  INITIALIZED: 'initialized',
  REASON_PROVIDED: 'reason_provided',
  COMPLETED: 'completed',
};

type ABANDON_SURVEY_STATUS_VALUES = keyof typeof ABANDON_SURVEY_STATUS;

export type ABANDON_SURVEY_STATUS_TYPE = (typeof ABANDON_SURVEY_STATUS)[ABANDON_SURVEY_STATUS_VALUES];
