import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeakedCredentialsModalComponent } from './leaked-credentials-modal/leaked-credentials-modal.component';
import { LeakedCredentialsService } from './services/leaked-credentials.service';
import { SvgIconModule } from '@shared/svg-icon/svg-icon.module';
import { ButtonModule } from '@shared/button/button.module';
import { UserVerificationsModule } from '@api/user-verifications/user-verifications.module';
import { LeakedCredentialsTrackingEventsService } from './services/leaked-credentials-tracking-events.service';
import { UserSettingsService } from '@core/user-settings/user-settings.service';

@NgModule({
  declarations: [LeakedCredentialsModalComponent],
  imports: [CommonModule, SvgIconModule, ButtonModule, UserVerificationsModule],
  providers: [LeakedCredentialsService, LeakedCredentialsTrackingEventsService, UserSettingsService],
})
export class LeakedCredentialsModule {}
