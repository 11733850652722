import { NavigationCategoriesDto, NavigationCategoryDto } from '../dtos/navigation-categories-response.dto';
import { NavigationCategories, NavigationCategory } from '../interfaces/navigation-categories.interface';

export const mapNavigationCategories = ({ data, meta }: NavigationCategoriesDto): NavigationCategories => {
  return {
    data: data.map((category: NavigationCategoryDto) => mapCategory(category)),
    meta: {
      title: meta.title,
    },
  };
};

const mapCategory = ({ action, id, name, icon, subcategories }: NavigationCategoryDto): NavigationCategory => {
  return {
    url: action.payload.link_url,
    categoryId: Number(id),
    title: name,
    icon: icon || null,
    subcategories: subcategories?.map((subcategory: NavigationCategoryDto) => mapCategory(subcategory)) || [],
  };
};
