import { Injectable } from '@angular/core';
import { CategoriesHttpService } from './http/categories-http.service';
import {
  HERO_CATEGORIES,
  mapCategoriesToObjectTypes,
  mapCategoriesToOptions,
  mapCategoriesToUploadCategories,
  mapCategoryDtosToCategories,
  mapSuggestedCategoriesToUploadCategory,
} from './mappers/category-mapper';
import { CategoryResponseDto } from '@core/category/category-response.dto.interface';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Category } from '@core/category/category.interface';
import { ObjectType } from '@private/features/upload/core/models/brand-model.interface';
import { SelectFormOption } from '@shared/form/components/select-form-v2/interfaces/select-form-option.interface';
import { ItemType } from '@api/core/model';
import { SuggestedCategoriesParams, SuggestedCategoryDto } from './dtos';
import { environment } from '@environments/environment';
import { ALL_CATEGORIES_OPTION } from './constants/all-categories-option.constants';

@Injectable()
export class CategoriesApiService {
  private uploadCategories: CategoryResponseDto[];
  private searchCategories: Category[];
  private categories: Category[];
  private flatCategories: Category[];
  private categoriesWithLeafSelectionMandatory: number[] = [];

  constructor(private categoriesHttpService: CategoriesHttpService) {}

  public getCategories(): Observable<Category[]> {
    if (this.categories) {
      return of(this.categories);
    } else {
      return this.categoriesHttpService.getCategories().pipe(
        map((response) => {
          return mapCategoryDtosToCategories(response.categories);
        }),
        tap((categories) => {
          this.categories = categories;

          this.categoriesWithLeafSelectionMandatory = categories
            .filter((category) => category.categoryLeafSelectionMandatory)
            .map((category) => category.id);

          const flattenDeep = (arr1: Category[]) => {
            return arr1.reduce(
              (acc, val) => (val.subcategories.length ? acc.concat(flattenDeep(val.subcategories), val) : acc.concat(val)),
              [],
            );
          };

          this.flatCategories = flattenDeep(categories);
        }),
      );
    }
  }

  public getUploadCategories(): Observable<CategoryResponseDto[]> {
    if (this.uploadCategories) {
      return of(this.uploadCategories);
    } else {
      return this.categoriesHttpService.getCategories().pipe(
        map((categories) => {
          return mapCategoriesToUploadCategories(categories.categories);
        }),
        tap((categories) => (this.uploadCategories = categories)),
      );
    }
  }

  public getCategoryById(id: number): Observable<Category> {
    return this.getCategories().pipe(map(() => this.flatCategories.find((category) => category.id === id)));
  }

  public getCategoriesByParentId(id: number): Observable<Category[]> {
    return this.getCategoryById(id).pipe(
      map((category) => {
        return category?.subcategories || [];
      }),
    );
  }

  public getUploadCategoriesByParentId(id: number): Observable<Category[]> {
    return this.getCategoryById(id).pipe(map((category) => category?.subcategories || []));
  }

  public getAllCategoriesAndMapToObjectType(): Observable<ObjectType[]> {
    return this.getSearchCategories().pipe(
      map((categories) => {
        categories = [ALL_CATEGORIES_OPTION, ...categories];
        return mapCategoriesToObjectTypes(categories);
      }),
    );
  }

  public getCategoriesByParentIdAsObjectType(id: number): Observable<ObjectType[]> {
    return this.getCategoriesByParentId(id).pipe(
      map((categories) => {
        return mapCategoriesToObjectTypes(categories);
      }),
    );
  }

  public getCategoryTaxonomyByCategoryId(id: number): Observable<number[]> {
    return this.getCategoryById(id).pipe(
      map((category: Category) => {
        const taxonomies: number[] = [];

        const addCategory = (id: number) => {
          const category: Category = this.flatCategories.find((category: Category) => category.id === id);
          taxonomies.unshift(category.id);
          if (category.parentId) {
            addCategory(category.parentId);
          }
        };

        taxonomies.push(category.id);

        if (category.parentId) {
          addCategory(category.parentId);
        }

        return taxonomies;
      }),
    );
  }

  public getUploadSuggestedCategories(params: SuggestedCategoriesParams): Observable<SelectFormOption[]> {
    return forkJoin(this.getSuggestedCategorySourcesObject(params)).pipe(
      map((responses) => {
        if (responses.suggestedCategories.categories) {
          return this.filterConsumerGoodsAsSelectFormOptions(responses.uploadCategories, responses.suggestedCategories.categories);
        }

        return this.filterConsumerGoodsAsSelectFormOptions(responses.uploadCategories, []);
      }),
    );
  }

  public getUploadConsumerGoodsCategoriesWithSuggestions(params: SuggestedCategoriesParams): Observable<SelectFormOption[]> {
    if (!params?.text) {
      return this.getCategories().pipe(
        map((categories: Category[]) => {
          return this.addRootSelectionOptions(this.mapCategoryToSelectFormOption(categories));
        }),
      );
    }
    return forkJoin(this.getSuggestedCategorySourcesObject(params)).pipe(
      map((responses) => {
        if (responses.suggestedCategories?.categories) {
          const suggestionsWithoutHeroCategories = responses.suggestedCategories.categories.filter((category: SuggestedCategoryDto) => {
            if (category.parent?.id) {
              return !HERO_CATEGORIES.includes(this.getRootCategoryId(category.id));
            }
            return true;
          });

          return this.addRootSelectionOptions(
            this.filterConsumerGoodsAsSelectFormOptions(responses.uploadCategories, suggestionsWithoutHeroCategories),
          );
        }

        return this.addRootSelectionOptions(this.mapCategoryToSelectFormOption(responses.uploadCategories));
      }),
    );
  }

  private getSearchCategories(): Observable<Category[]> {
    if (this.searchCategories) {
      return of(this.searchCategories);
    } else {
      return this.categoriesHttpService.getSearchCategories().pipe(
        map((response) => {
          return mapCategoryDtosToCategories(response.categories);
        }),
        tap((categories) => {
          this.searchCategories = categories;
        }),
      );
    }
  }

  private getParentCategoryId(id: number): number {
    {
      const category: Category = this.flatCategories.find((category: Category) => category.id === id);
      const categoryParentId = category?.parentId;

      if (categoryParentId) {
        this.getParentCategoryId(category.parentId);
      }

      return categoryParentId;
    }
  }

  private getRootCategoryId(id: number): number {
    return this.getParentCategoryId(id);
  }

  private mapCategoryToSelectFormOption(categories: Category[]): SelectFormOption[] {
    const filterExcludingHeroCategories = this.getConsumerGoodCategoriesFromCategory(categories);
    const mappedOptions = mapCategoriesToOptions(filterExcludingHeroCategories);

    return mappedOptions;
  }

  private getConsumerGoodCategoriesFromCategory(categories: Category[]): Category[] {
    const consumerGoodsCategoriesWithoutHeroCategories = categories.filter(
      (category) => category.verticalId === ItemType.CONSUMER_GOODS && !HERO_CATEGORIES.includes(category.id),
    );

    return consumerGoodsCategoriesWithoutHeroCategories;
  }

  private filterConsumerGoodsAsSelectFormOptions(categories: Category[], suggestedCategories: SuggestedCategoryDto[]): SelectFormOption[] {
    const suggestedToCategory = mapSuggestedCategoriesToUploadCategory(categories, suggestedCategories);

    const mergedCategoriesWithSuggestions = suggestedToCategory.concat(
      categories.filter((category) => category.verticalId === ItemType.CONSUMER_GOODS),
    );
    const mappedOptions = this.mapCategoryToSelectFormOption(mergedCategoriesWithSuggestions);

    return mappedOptions;
  }

  private addRootSelectionOptions(options: SelectFormOption[]): SelectFormOption[] {
    return options.map((option: SelectFormOption) => {
      const buildRootSelectionOption =
        option.parentValue === undefined && !this.categoriesWithLeafSelectionMandatory.includes(+option.value);

      const optionHasChildren = !!option.children?.length;

      if (optionHasChildren && buildRootSelectionOption) {
        const optionToReturn = { ...option, children: [this.buildRootSelectionOption(option), ...option.children] };

        optionToReturn.children = [...this.addRootSelectionOptions(optionToReturn.children)];

        return optionToReturn;
      }

      return option;
    });
  }

  private buildRootSelectionOption(option: SelectFormOption): SelectFormOption {
    return {
      value: option.value,
      label: $localize`:@@subcategory_selector_level_2_seller_list_just_select_level_1_label:Just select ${option.label}:INTERPOLATION:`,
    };
  }

  private getSuggestedCategorySourcesObject(params: SuggestedCategoriesParams) {
    const isProduction = environment.production;
    const areAllParamsPresent = !!params?.text && (!!params?.uploadId || !!params.editId);
    return {
      uploadCategories: this.getCategories(),
      suggestedCategories:
        isProduction && areAllParamsPresent
          ? this.categoriesHttpService.getSuggestedCategories(params).pipe(catchError((error) => of(error)))
          : of({}),
    };
  }
}
