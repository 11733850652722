import { environment } from '@wallapop/environments/environment';
import mParticle, {
  AllUserAttributes,
  User as MPUser,
  MPID,
  EventType,
  SDKEventAttrs,
  IdentityResult,
  MPConfiguration,
} from '@mparticle/web-sdk';
import { UserIdentities } from '@mparticle/web-sdk';
import { EventTypeEnum } from '@mparticle/event-models';
import {
  COMMON_MPARTICLE_CONFIG,
  KIT_REGISTER_ERROR_MSG,
  MAX_ATTEMPTS,
  RATE_LIMIT_ERROR_CODE,
  ID_SYNC_OPERATION,
  MPID_COOKIE_NAME,
  COOKIE_EXPIRATION_DAYS,
} from './mparticle-lib.constants';
import { AnalyticsUser } from './interfaces/analytics-user.interface';
import {
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_TYPES,
  AnalyticsEvent,
  AnalyticsPageView,
  MparticleLogoutSuccess,
  MparticleLogoutError,
  MparticleLoginSuccess,
  MparticleLoginError,
} from '../analytics.constants';
import { MParticleKitSDK, MPARTICLE_KITS, CustomMPConfiguration } from './interfaces/mParticle-kits.interface';
import { instantiateIdSyncListener } from './helpers/id-sync-helper';
import { AnalyticsFeatureFlagService } from './core/event-routing/feature-flag-service';
import { trackAnalyticsEvent } from './core';

type IDSyncTypes = EnumValues<typeof ID_SYNC_OPERATION>;

const { idSyncReady, flushQueue } = instantiateIdSyncListener();

const loadMParticleKits = (mParticleKits: MPARTICLE_KITS[], config: CustomMPConfiguration): Promise<void[]> => {
  const loadingKitsPromises: Promise<void>[] = [];
  mParticleKits.forEach((kit: MPARTICLE_KITS) => loadingKitsPromises.push(registerMparticleKit(kit, config)));

  return Promise.all(loadingKitsPromises);
};

export const initMParticleSDK = (config: CustomMPConfiguration, mParticleKits?: MPARTICLE_KITS[]): Promise<void> => {
  return new Promise(async (resolve) => {
    if (mParticleKits) {
      await loadMParticleKits(mParticleKits, config);
    }
    mParticle.init(environment.mParticleKey, config as MPConfiguration);
    mParticle.ready(() => {
      resolve();
    });
  });
};

export const getMParticleConfig = async (
  userIdentities: UserIdentities,
  userAttributes?: AllUserAttributes,
): Promise<CustomMPConfiguration> => {
  let _isLogoutDone = false;

  const isMparticleSessionDisabled = !(await AnalyticsFeatureFlagService.isMparticleEnabled());

  return {
    ...COMMON_MPARTICLE_CONFIG,
    identifyRequest: {
      userIdentities,
    },
    identityCallback: (result) => {
      let mParticleUser: MPUser | undefined = result.getUser();
      const isUserLoggedInMParticle = !!mParticleUser?.getUserIdentities()?.userIdentities?.customerid;
      const isUserLoggedInWallapop = !!userIdentities?.customerid;
      const isMparticleLogoutNeeded = !isUserLoggedInWallapop && isUserLoggedInMParticle;

      if (isMparticleLogoutNeeded) {
        // TODO: consider handling logout in alternative way once old webseo project has been deleted: WPA-40120. Included in getLogoutMethod
        mParticleLogout((idResult?: IdentityResult) => {
          const _mParticleUser = idResult?.getUser();
          const _MPID = _mParticleUser?.getMPID();
          if (_MPID) setMPIDAsCookie(_MPID);

          _isLogoutDone = true;
          syncQueue(() => {
            setUserAttributes(_mParticleUser, userAttributes);
          });
        });
      } else {
        const _MPID = mParticleUser?.getMPID();
        if (_MPID) setMPIDAsCookie(_MPID);

        syncQueue(() => {
          setUserAttributes(mParticleUser, userAttributes);
        });
      }
    },
    onCreateBatch: (batch) => {
      // The following events are being removed from the uploading queue due to new contract with mParticle.
      // More information can be found here: WPA-32825
      const EVENTS_TO_FILTER = [EventTypeEnum.applicationStateTransition, EventTypeEnum.sessionEnd];

      if (isMparticleSessionDisabled) {
        EVENTS_TO_FILTER.push(EventTypeEnum.sessionStart);
      }

      // Sometimes after a logout is done, the first batch of events will be sent with the previous logged user.
      // Here we remove the user identities of the batch if they are present after the logout. Once a batch no longer has user identities
      // this is no longer needed, hence why we reset it back to false.
      if (_isLogoutDone) {
        if (batch.user_identities) {
          const MPID = getMPID();

          delete batch.user_identities;
          if (MPID) batch.mpid = MPID;
        } else {
          _isLogoutDone = false;
        }
      }
      batch.events = batch.events?.filter((event) => {
        return !EVENTS_TO_FILTER.includes(event.event_type);
      });
      // So long as we apply filtering, do NOT remove this
      if (batch?.events?.length === 0) {
        return null;
      }
      return batch;
    },
  };
};

const syncQueue = (callback: () => void) => {
  flushQueue();
  callback();
};

const setUserAttributes = (mParticleUser: MPUser | undefined, userAttributes?: AllUserAttributes) => {
  const currentUserAttributes = mParticleUser?.getAllUserAttributes() || {};

  if (userAttributes) {
    Object.entries(userAttributes).forEach(([key, value]) => {
      if (value && currentUserAttributes[key] !== value) {
        mParticleUser?.setUserAttribute(key, String(value));
      }
    });
  }
};

const sendMPIdentityEvent = (idResult: IdentityResult, type: IDSyncTypes, retryCount: number): void => {
  const sharedAttrs = {
    httpCode: idResult.httpCode,
    origin: 'webapp',
    retryCount,
  };

  if (idResult.httpCode >= 200 && idResult.httpCode < 400) {
    const event: AnalyticsEvent<MparticleLogoutSuccess | MparticleLoginSuccess> = {
      name: type === ID_SYNC_OPERATION.LOGOUT ? ANALYTICS_EVENT_NAMES.MparticleLogoutSuccess : ANALYTICS_EVENT_NAMES.MparticleLoginSuccess,
      eventType: ANALYTICS_EVENT_TYPES.Other,
      attributes: sharedAttrs,
    };
    trackAnalyticsEvent(event);
  } else if (idResult.httpCode >= 400) {
    const event: AnalyticsEvent<MparticleLogoutError | MparticleLoginError> = {
      name: type === ID_SYNC_OPERATION.LOGOUT ? ANALYTICS_EVENT_NAMES.MparticleLogoutError : ANALYTICS_EVENT_NAMES.MparticleLoginError,
      eventType: ANALYTICS_EVENT_TYPES.Other,
      attributes: sharedAttrs,
    };
    trackAnalyticsEvent(event);
  }
};

const handleCallBack = (callback: (result: IdentityResult) => void, result: IdentityResult) => {
  // This will give extra time for the mparticle tracking events to be sent before reloading the page.
  setTimeout(() => {
    callback && callback(result);
  }, 100);
};

export const mParticleLogin = (userIdentities: UserIdentities, callback?: (result: IdentityResult) => void) => {
  mParticle.Identity.login({ userIdentities }, (result: IdentityResult) => {
    const hasBeenRateLimited = result.httpCode === RATE_LIMIT_ERROR_CODE;
    if (result) sendMPIdentityEvent(result, ID_SYNC_OPERATION.LOGIN, 0);

    if (!hasBeenRateLimited) {
      setMPIDAsCookie(result.getUser().getMPID());
      callback && handleCallBack(callback, result);
    } else {
      callback &&
        handleRetry(ID_SYNC_OPERATION.LOGIN, userIdentities).then(() => {
          handleCallBack(callback, result);
        });
    }
  });
};

const handleRetry = async (retryType: IDSyncTypes, userIdentities?: UserIdentities) => {
  let currentAttempt = 1;
  let shouldExit = false;

  if (retryType === ID_SYNC_OPERATION.LOGIN && userIdentities) {
    while (!shouldExit) {
      shouldExit = await getLoginRetryEval(currentAttempt, userIdentities);
      ++currentAttempt;
    }
  } else {
    while (!shouldExit) {
      shouldExit = await getLogoutRetryEval(currentAttempt);
      ++currentAttempt;
    }
  }
};

const setMPIDAsCookie = (MPID: string) => {
  const currentDate = new Date();
  const expirationDate = new Date(currentDate);
  expirationDate.setDate(currentDate.getDate() + COOKIE_EXPIRATION_DAYS);
  const formattedExpirationDate = expirationDate.toUTCString();

  document.cookie = `${MPID_COOKIE_NAME}=${MPID}; expires=${formattedExpirationDate}; domain=.wallapop.com; path=/; SameSite=Lax`;
};

const getLoginRetryEval = (currentAttempt: number, userIdentities: UserIdentities): Promise<boolean> => {
  return new Promise((resolve) => {
    mParticle.Identity.login({ userIdentities }, (result: IdentityResult) => {
      if (result) {
        sendMPIdentityEvent(result, ID_SYNC_OPERATION.LOGIN, currentAttempt);
        setMPIDAsCookie(result.getUser().getMPID());
      }

      resolve(result.httpCode !== RATE_LIMIT_ERROR_CODE || currentAttempt === MAX_ATTEMPTS);
    });
  });
};

const getLogoutRetryEval = (currentAttempt: number): Promise<boolean> => {
  return new Promise((resolve) => {
    mParticle.Identity.logout({}, (result: IdentityResult) => {
      if (result) {
        sendMPIdentityEvent(result, ID_SYNC_OPERATION.LOGOUT, currentAttempt);
        setMPIDAsCookie(result.getUser().getMPID());
      }

      resolve(result.httpCode !== RATE_LIMIT_ERROR_CODE || currentAttempt === MAX_ATTEMPTS);
    });
  });
};

export const mParticleLogout = async (callback?: (idResult?: IdentityResult) => void): Promise<void> => {
  const isMparticleLogoutDisabled = await AnalyticsFeatureFlagService.isMparticleLogoutDisabled();

  if (mParticle && !isMparticleLogoutDisabled) {
    mParticle.Identity.logout({}, (result: IdentityResult) => {
      const hasBeenRateLimited = result.httpCode === RATE_LIMIT_ERROR_CODE;
      if (result) sendMPIdentityEvent(result, ID_SYNC_OPERATION.LOGOUT, 0);

      if (!hasBeenRateLimited) {
        setMPIDAsCookie(result.getUser().getMPID());
        callback && handleCallBack(callback, result);
      } else {
        callback &&
          handleRetry(ID_SYNC_OPERATION.LOGIN).then(() => {
            handleCallBack(callback, result);
          });
      }
    });
  } else if (callback) callback();
};

export const setUserAttribute = (key: string, value: string): void => {
  const mParticleUser = getMPUser();

  if (mParticleUser) setUserAttributes(mParticleUser, { [key]: value });
};

export const getMPID = (): MPID | null | undefined => {
  return (mParticle && getMPUser()?.getMPID()) || getMPIDFromClientCookies();
};

export const getMPDeviceId = (): string | null | undefined => {
  return mParticle && mParticle.getDeviceId();
};

const getMPIDFromClientCookies = (): string | null => {
  const isServer = () => typeof window === 'undefined';
  if (isServer()) return null;

  if (!(document as any) && !document.cookie) return null;
  const rawCookie = document.cookie.split(';').find((cookie) => {
    return cookie.trim().startsWith(`${MPID_COOKIE_NAME}=`);
  });
  return rawCookie?.split('=')[1] || null;
};

export const getMParticleReadyCallback = (): {
  (callback: () => void): void;
} => {
  return idSyncReady;
};

export const getUserIdentities = (user: AnalyticsUser): UserIdentities => {
  return {
    email: user.email,
    customerid: user.id as string | undefined,
  };
};

export const trackMParticleEvent = <T extends object>(event: AnalyticsEvent<T>): void => {
  const { name, eventType, attributes } = event;
  mParticle.logEvent(name, eventType as unknown as EventType, attributes as unknown as SDKEventAttrs);
};

export const trackMParticlePageView = <T extends object>(page: AnalyticsPageView<T>): void => {
  const { name, attributes } = page;
  mParticle.logPageView(name, attributes as unknown as SDKEventAttrs);
};

const getMPUser = (): MPUser | undefined => {
  return mParticle.Identity?.getCurrentUser();
};

const registerMparticleKit = (kit: MPARTICLE_KITS, config: CustomMPConfiguration): Promise<void> => {
  const kitsDynamicImports = {
    Braze: () => resolveDynamicImport(import('@mparticle/web-braze-kit'), config, kit),
    Google_Analytics4: () => resolveDynamicImport(import('@mparticle/web-google-analytics-4-client-kit'), config, kit),
  };

  return kitsDynamicImports[kit]();
};

const resolveDynamicImport = async (
  promise: Promise<MParticleKitSDK>,
  config: CustomMPConfiguration,
  kit: MPARTICLE_KITS,
): Promise<void> => {
  try {
    const _kit = await promise;
    return _kit.default.register(config);
  } catch (e) {
    console.error(`${KIT_REGISTER_ERROR_MSG} - ${kit}: ${e}`);
  }
};

export type { AllUserAttributes };
