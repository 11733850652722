import { FEATURE_FLAGS_ENUM } from './featureflag-constants';

/**
 * All FFs for the webapp must be set here
 */
export const GENERIC_FEATURE_FLAGS = {
  ...FEATURE_FLAGS_ENUM,
  TNS_CHAT_BLOCK_LINKS: 'tns_chat_block_links',
  TNS_CONFIRM_PERSONAL_INFO_SUBMIT: 'tns_confirm_personal_info_submit',
  PACO_CHECKOUT_ICONS: 'paco_checkout_icons',
  PACO_TOPUPS_PAYPAL: 'paco_topups_paypal',
  PACO_ABANDONED_CHECKOUT_SURVEY: 'paco_abandoned_checkout_survey',
  PAYMENTS_2FA_BANK_ACCOUNT: 'payments_2fa_bank_account',
  PAYMENTS_F2F: 'payments_f2f',
  PAYMENTS_F2F_NOTIFICATIONS: 'payments_f2f_notifications',
  PLATFORM_USER_SUSTAINABILITY_IMPACT: 'platform_user_sustainability_impact',
  TRX1_WEB_UPLOAD_BULKY: 'trx1_web_upload_bulky',
  PACO_NEW_PAYMENT_FLOW_WEB: 'paco_new_payment_flow_web',
  AICS_CHATBOT_WEB: 'aics_chatbot_web',
  PACO_MARK_ITEM_SOLD_CANCELLATION_FLOW: 'paco_mark_item_sold_cancellation_flow',
  WEBX_BRAZE_LOGIN_WEBAPP: 'webx_braze_login_webapp',
  REAL_TIME_MQTT_LOAD_TEST: 'paco_real_time_mqtt_load_test',
  ARTISANS_IDENTIFICATION_MANDATORY_FF: 'artisans_identification_mandatory_feature_flag',
  ARTISANS_IDENTIFICATION_PADLOCK_SCREEN_FF: 'artisans_identification_padlock_screen_feature_flag',
  PACO_F2F_FLOW_SIMPLIFICATION: 'paco_f2f_flow_simplification',
  LEX_VACATION_MODE: 'lex_vacation_mode',
} as const;

export type FeatureFlagKeys = EnumValues<typeof GENERIC_FEATURE_FLAGS>;
