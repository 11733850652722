import { HttpResponseCodeType } from '@api/core/model/multi-factor/http-response-code';
import { MultiFactorRequestError } from '@api/core/model/multi-factor/multi-factor-error.interface';
import { MULTI_FACTOR_OPERATION } from '@api/core/model/multi-factor/multi-factor-operation.enum';
import { RequestChangeEmailError } from '@api/user-verifications/dtos/errors/request-change-email-error';

export function mapRequestChangeEmailErrorToMultiFactorRequestError(
  requestChangeEmailError: RequestChangeEmailError,
): MultiFactorRequestError {
  const { error } = requestChangeEmailError;

  return {
    code: requestChangeEmailError.status as HttpResponseCodeType,
    blocking: error?.blocking ?? false,
    mfaToken: error?.mfaId || '',
    operation: MULTI_FACTOR_OPERATION[error?.operation] || MULTI_FACTOR_OPERATION.DEFAULT,
  };
}
