import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponseCodeType, HTTP_RESPONSE_CODE } from '@api/core/model/multi-factor/http-response-code';
import { MultiFactorRequestError } from '@api/core/model/multi-factor/multi-factor-error.interface';
import { UserVerificationsService } from '@api/user-verifications/user-verifications.service';
import { LeakedCredentialsTrackingEventsService } from '@core/leaked-credentials/services/leaked-credentials-tracking-events.service';
import { FIRST_GENERIC_STEP, MultiFactorManagerService } from '@core/multi-factor/multi-factor-manager.service';
import { UserService } from '@core/user/user.service';
import { SnackbarService } from '@layout/snackbar/core/services/snackbar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PROFILE_PATHS } from '@private/features/profile/profile-routing-constants';
import {
  CONFIRMATION_MODAL_PROPERTIES,
  CONFIRMATION_MODAL_VERIFICATIONS_TYPE,
} from '@private/features/settings/pages/verifications-n-security/constants/confirmation-modal-properties';
import { VERIFICATIONS_N_SECURITY_PATHS } from '@private/features/settings/pages/verifications-n-security/verifications-n-security.routing.constants';
import { PRIVATE_PATHS } from '@private/private-routing-constants';
import { ConfirmationModalComponent } from '@shared/confirmation-modal/confirmation-modal.component';
import { EmailModalComponent } from '@shared/profile/email-modal/email-modal.component';
import { take } from 'rxjs/operators';
import { DEFAULT_ERROR_SNACKBAR } from '@layout/snackbar/core/constants/default-snackbar';

@Injectable()
export class UserSettingsService {
  private isLeakedCredentials: boolean;

  constructor(
    private modalService: NgbModal,
    private userVerificationsService: UserVerificationsService,
    private snackbarService: SnackbarService,
    private router: Router,
    private trackingService: LeakedCredentialsTrackingEventsService,
    private userService: UserService,
    private multiFactorManagerService: MultiFactorManagerService,
  ) {}

  public handleChangePassword(isVerifiedEmail: boolean, email: string, isLeakedCredentials: boolean = false): void {
    const confirmationModalRef: NgbModalRef = this.modalService.open(ConfirmationModalComponent, { animation: false });

    this.isLeakedCredentials = isLeakedCredentials;

    isVerifiedEmail
      ? this.verifiedEmailConfrimationModal(confirmationModalRef, email)
      : this.notVerifiedEmailConfrimationModal(confirmationModalRef);

    this.trackingService.trackClickEditPassword(isVerifiedEmail);
  }

  public requestForChangeEmail(): void {
    this.multiFactorManagerService
      .start(this.userVerificationsService.requestChangeEmail(), FIRST_GENERIC_STEP)
      .pipe(take(1))
      .subscribe(
        () => {
          this.openEditEmailModal();
        },
        (error: MultiFactorRequestError) => this.handleChangeEmailError(error.code),
      );
  }

  private handleChangeEmailError(code: HttpResponseCodeType): void {
    if (code === HTTP_RESPONSE_CODE.BAD_REQUEST) {
      this.snackbarService.show({
        type: 'negative',
        title: $localize`:@@verifications_and_security_view_toast_email_change_via_app_error_message_title_web_specific:Better use the app!`,
        content: $localize`:@@verifications_and_security_view_toast_email_change_via_app_error_message_description_web_specific:To change the email, use the Wallapop app.`,
      });
    }
  }

  private openEditEmailModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(EmailModalComponent, {
      windowClass: 'modal-standard',
      animation: false,
    });

    modalRef.componentInstance.currentEmail = this.userService.user.email;
  }

  private verifiedEmailConfrimationModal(confirmationModalRef: NgbModalRef, email: string): void {
    confirmationModalRef.componentInstance.properties = CONFIRMATION_MODAL_PROPERTIES[CONFIRMATION_MODAL_VERIFICATIONS_TYPE.VERIFIED_EMAIL];
    confirmationModalRef.result.then(
      () => {
        if (this.isLeakedCredentials) this.trackingService.trackClickSendEmailChangePassword();
        this.changePassword(email);
      },
      () => {},
    );
  }

  private changePassword(email: string): void {
    this.userVerificationsService
      .changePassword(email)
      .pipe(take(1))
      .subscribe(
        () => {
          this.snackbarService.show({
            content: $localize`:@@verification_and_security_all_users_snackbar_reset_password_verified_mail_sent_message:Email sent!`,
            type: 'positive',
          });
        },
        () => {
          this.snackbarService.show(DEFAULT_ERROR_SNACKBAR);
        },
      );
  }

  private notVerifiedEmailConfrimationModal(confirmationModalRef: NgbModalRef): void {
    confirmationModalRef.componentInstance.properties =
      CONFIRMATION_MODAL_PROPERTIES[CONFIRMATION_MODAL_VERIFICATIONS_TYPE.NOT_VERIFIED_EMAIL];
    confirmationModalRef.result.then(
      () => {
        const veryEmailPath = `${PRIVATE_PATHS.SETTINGS}/${PROFILE_PATHS.VERIFICATIONS}/${VERIFICATIONS_N_SECURITY_PATHS.VERIFY_EMAIL}`;
        if (this.isLeakedCredentials) this.trackingService.trackClickVerificationOption();
        this.router.navigate([veryEmailPath]);
      },
      () => {},
    );
  }
}
