import { Route } from '@angular/router';

import { PUBLIC_PATHS } from '@public/public-routing-constants';
import { PRIVATE_PATHS } from '@private/private-routing-constants';
import { STATUS } from '@private/features/catalog/shared/selected-items/selected-product.interface';

export const universalLinkRouteRedirects: Route[] = [
  {
    path: `${PRIVATE_PATHS.PROFILE}/${PRIVATE_PATHS.CATALOG}`,
    redirectTo: `${PRIVATE_PATHS.CATALOG}/${STATUS.PUBLISHED}`,
  },
];

export const notFoundRoute: Route = {
  path: '**',
  redirectTo: `/${PUBLIC_PATHS.SEARCH}`,
};
