import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { APP_VERSION } from '@wallapop/app-version';
import { HEADER_NAMES } from '@core/constants/header-constants';

export const API_V1: string = 'shnm-portlet/api/v1';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isWallapopRequest: boolean = request.url.startsWith(environment.baseUrl);
    const isV1: boolean = request.url.startsWith(`${environment.baseUrl}${API_V1}`);

    if (isWallapopRequest) {
      let setHeaders: { [key: string]: string } = {};

      if (!isV1) {
        setHeaders = {
          [HEADER_NAMES.APPVERSION]: APP_VERSION.replace(/\./g, ''),
        };
      }

      request = request.clone({ setHeaders });
    }

    return next.handle(request);
  }
}
