import { Injectable } from '@angular/core';
import { DeviceService } from '@core/device/device.service';
import {
  getVariablesFromFlag,
  getVariationFromFlag,
  initializeExperimentation,
  isFlagEnabled,
  ExpectedVariants,
} from '@wallapop/experimentation';
import { AppStatusService } from '@core/app-status/app-status.service';
import type { WebappExperimentKeys } from './experimentation.types';
import { getMPID, getMParticleReadyCallback } from '@wallapop/analytics';

@Injectable({
  providedIn: 'root',
})
export class ExperimentationService {
  constructor(
    private deviceService: DeviceService,
    private appStatusService: AppStatusService,
  ) {}

  public async initializeExperimentation(): Promise<void> {
    try {
      await initializeExperimentation({
        userGetter: getMPID,
        deviceGetter: this.deviceService.getDeviceId.bind(this.deviceService),
        identityReadyCallback: getMParticleReadyCallback(),
      });
    } catch (error: unknown) {
      this.appStatusService.addError(`[Experimentation Service]: Error while initializing Experimentation Provider`, error);
    }
  }

  public isFlagEnabled(flagKey: WebappExperimentKeys): boolean {
    return isFlagEnabled(flagKey);
  }

  public getVariationFromFlag(flagKey: WebappExperimentKeys): ExpectedVariants {
    return getVariationFromFlag(flagKey);
  }

  public getVariablesFromFlag(flagKey: WebappExperimentKeys): AnyObject {
    return getVariablesFromFlag(flagKey);
  }
}
