import { Locale } from '@wallapop/i18n/src/types/locale.type';
import { HttpClientInterceptor, HttpClientRequestOnFulfilledInterceptor } from '../http-client.types';
import { parse } from 'accept-language-parser';
import { HTTP_CLIENT_HEADERS } from '../http-client.constants';

interface LanguageInterceptorOptions {
  baseUrl: string;
  /**
   * Current application locale
   */
  locale: Locale;
  /**
   * Either array of languages from `navigator.languages` (for client side), or `Accept-Language` string (for server side)
   */
  languages?: readonly string[] | string;
}

type CreateLanguageInterceptor = (options: LanguageInterceptorOptions) => HttpClientInterceptor;

export const createLanguageInterceptor: CreateLanguageInterceptor = (options) => {
  const { locale, baseUrl, languages } = options;

  const onFulfilledInterceptor: HttpClientRequestOnFulfilledInterceptor = (request) => {
    request.headers ??= {};

    const isWallapopRequest = request.url?.startsWith(baseUrl);
    const isLanguageHeaderPresent = !!request.headers[HTTP_CLIENT_HEADERS.ACCEPT_LANGUAGE];
    const needsLanguageHeader = isWallapopRequest && !isLanguageHeaderPresent;

    if (needsLanguageHeader) {
      request.headers[HTTP_CLIENT_HEADERS.ACCEPT_LANGUAGE] = getLanguageHeaderValue(locale, languages as string[] | string);
    }

    return request;
  };

  return {
    request: {
      onFulfilled: onFulfilledInterceptor,
    },
  };
};

const getLanguageHeaderValue = (currentLocale: Locale, languages: string[] | string) => {
  const browserLanguages = getLanguagesWithoutCurrentLocale(currentLocale, languages);
  const browserLanguagesWithQualityValue = browserLanguages.map(formatLanguageWithQuality);

  const allLanguagesWithQualityValues = [`${currentLocale}`, ...browserLanguagesWithQualityValue];
  return `${allLanguagesWithQualityValues.join(',')}`;
};

const getLanguagesWithoutCurrentLocale = (currentLocale: Locale, languages: string[] | string): string[] => {
  const languagesAsArray = Array.isArray(languages)
    ? languages
    : parse(languages).map((item) => `${item.code}${item.region ? `-${item.region}` : ''}`);
  return languagesAsArray.filter((language) => language !== currentLocale);
};

const MIN_QUALITY_VALUE = 0.1;

const formatLanguageWithQuality = (language: string, index: number): string => {
  const qualityForCurrentIndex = index * 0.1;

  let quality = 1 - qualityForCurrentIndex - 0.1;
  if (quality < MIN_QUALITY_VALUE) {
    quality = MIN_QUALITY_VALUE;
  }

  return `${language};q=${quality.toFixed(1)}`;
};
