import { DISPUTES_PATHS } from '@private/features/disputes/constants/disputes-paths-constants';
import { STREAMLINE_PATHS } from '@private/features/delivery/pages/streamline/streamline.routing.constants';
import { FAVORITES_PATHS } from './features/favorites/favorites-routing-constants';
import { STEPPED_CHECKOUT_PATHS } from './features/stepped-checkout/constants/stepped-checkout-routing-constants';
import { F2F_BUYER_PATHS } from './shared/buyer-f2f/constants/f2f-buyer-routing.constants';
import { SETTINGS_PATHS } from './features/settings/settings-routing.constants';
import { CATALOG_PATHS } from './features/catalog/catalog-routing-constants';
import { ABANDON_SURVEY_SUCCESS_PATHS } from './features/stepped-checkout/steps/abandon-survey/constants/abandon-survey-routing-constants';

export enum PRIVATE_PATHS {
  DELIVERY = 'delivery',
  WALLET = 'wallet',
  PROFILE = 'profile',
  CATALOG = 'catalog',
  VERIFICATION = 'verification',
  CHAT = 'chat',
  BUMPS = 'bumps',
  ACCEPT_SCREEN = 'accept',
  NOTIFICATION_CENTER = 'notifications',
  CHECKOUT = 'checkout',
  YOU = 'you',
  SETTINGS = 'settings',
  REVIEWS = 'reviews',
  FAVORITES = 'favorites',
  STATS = 'stats',
  PURCHASES = 'purchases',
  SELLING = 'selling',
  DISPUTE = 'dispute',
  SEARCHES = 'searches',
  MAP = 'map',
  SUSTAINABILITY = 'your-sustainability-impact',
  VACATION_MODE = 'vacation-mode',
  OFFER = 'offer',
  // This is a surprise for Dimas, if you read this be sure to not share it yet 🤫
  DIMASIADO = 'dimasiado',
  CHATBOT = 'chatbot',
}

export enum PRIVATE_PATH_PARAMS {
  ID = 'id',
}

export const PATH_TO_CHECKOUT: string = `${PRIVATE_PATHS.CHAT}/${PRIVATE_PATHS.CHECKOUT}`;
export const PATH_TO_CHECKOUT_SHIPPING_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.SHIPPING}`;
export const PATH_TO_CHECKOUT_PAYMENT_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.PAYMENT}`;
export const PATH_TO_CHECKOUT_SUMMARY_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.SUMMARY}`;
export const PATH_TO_CHECKOUT_DELIVERY_ADDRESS_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.DELIVERY_ADDRESS}`;
export const PATH_TO_CHECKOUT_CREDIT_CARD_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.CREDIT_CARD}`;
export const PATH_TO_CHECKOUT_PROMOCOCODE_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.PROMOCODE_EDITOR}`;
export const PATH_TO_CHECKOUT_MAP_STEP = (itemHash: string): string => `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.MAP}`;
export const PATH_TO_CHECKOUT_ABANDON_SURVEY_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.ABANDON_SURVEY}`;
export const PATH_TO_CHECKOUT_ABANDON_SURVEY_SUCCESS_STEP = (itemHash: string): string =>
  `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.ABANDON_SURVEY}/${ABANDON_SURVEY_SUCCESS_PATHS.SUCCESS}`;

export const PATH_TO_CHECKOUT_F2F_BUYER = (itemHash: string): string => `/${PATH_TO_CHECKOUT}/${itemHash}/${STEPPED_CHECKOUT_PATHS.F2F}`;
export const PATH_TO_F2F_BUYER = (itemHash: string): string => `/${PRIVATE_PATHS.CHAT}/${STEPPED_CHECKOUT_PATHS.F2F}/${itemHash}`;
export const PATH_TO_F2F_BASE = (itemHash: string, isCheckout: boolean): string =>
  `${isCheckout ? PATH_TO_CHECKOUT_F2F_BUYER(itemHash) : PATH_TO_F2F_BUYER(itemHash)}`;

export const PATH_TO_F2F_DELIVERY_STEP = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_DELIVERY}`;
export const PATH_TO_F2F_METHOD_STEP = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_METHOD}`;
export const PATH_TO_F2F_AMOUNT_STEP = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_AMOUNT}`;
export const PATH_TO_F2F_PAYMENT = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_PAYMENT}`;
export const PATH_TO_F2F_SUMMARY = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_SUMMARY}`;
export const PATH_TO_F2F_QR = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_QR}`;
export const PATH_TO_F2F_SUCCESS = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_SUCCESS}`;
export const PATH_TO_F2F_ERROR = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_ERROR}`;
export const PATH_TO_F2F_CREDIT_CARD_STEP = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_BASE(itemHash, isCheckout)}/${F2F_BUYER_PATHS.CREDIT_CARD}`;
export const PATH_TO_F2F_QR_TTS = (itemHash: string, isCheckout: boolean): string =>
  `${PATH_TO_F2F_QR(itemHash, isCheckout)}/${F2F_BUYER_PATHS.F2F_TTS}`;

export const PATH_TO_DISPUTE_SELF_SERVICE = (transactionId: string): string =>
  `${PRIVATE_PATHS.CHAT}/${PRIVATE_PATHS.DISPUTE}/${transactionId}/${DISPUTES_PATHS.SELF_SERFICE}`;
export const PATH_TO_VIEW_DISPUTE_SELF_SERVICE = (transactionId: string, issueId: string): string =>
  `${PRIVATE_PATHS.CHAT}/${PRIVATE_PATHS.DISPUTE}/${transactionId}/${DISPUTES_PATHS.RESOLUTION_CENTER}/${DISPUTES_PATHS.VIEW}/${issueId}`;
export const PATH_TO_CREATE_CLAIM_SELF_SERVICE = (transactionId: string, issueId: string): string =>
  `${PRIVATE_PATHS.CHAT}/${PRIVATE_PATHS.DISPUTE}/${transactionId}/${DISPUTES_PATHS.RESOLUTION_CENTER}/${DISPUTES_PATHS.CREATE_CLAIM}/${issueId}`;
export const PATH_COMPLETED_PURCHASES: string = `${PRIVATE_PATHS.PURCHASES}/${STREAMLINE_PATHS.COMPLETED}`;
export const PATH_COMPLETED_SELLS: string = `${PRIVATE_PATHS.SELLING}/${STREAMLINE_PATHS.COMPLETED}`;
export const PATH_ONGOING_PURCHASES: string = `${PRIVATE_PATHS.PURCHASES}/${STREAMLINE_PATHS.ONGOING}`;
export const PATH_ONGOING_SELLS: string = `${PRIVATE_PATHS.SELLING}/${STREAMLINE_PATHS.ONGOING}`;

export const PATH_NOTIFICATION_CENTER: string = PRIVATE_PATHS.NOTIFICATION_CENTER;

export const PATH_TO_FAVORITES_SEARCHES: string = `${PRIVATE_PATHS.FAVORITES}/${FAVORITES_PATHS.SEARCHES}`;
export const PATH_TO_FAVORITES_PRODUCTS: string = `${PRIVATE_PATHS.FAVORITES}/${FAVORITES_PATHS.PRODUCTS}`;
export const PATH_TO_FAVORITES_PROFILES: string = `${PRIVATE_PATHS.FAVORITES}/${FAVORITES_PATHS.PROFILES}`;

export const PATH_TO_VERIFICATIONS_N_SECURITY = `${PRIVATE_PATHS.SETTINGS}/${SETTINGS_PATHS.VERIFICATIONS}`;

export const PATH_TO_UPLOAD = `${PRIVATE_PATHS.CATALOG}/${CATALOG_PATHS.UPLOAD}`;
export const PATH_TO_EDIT = `${PRIVATE_PATHS.CATALOG}/${CATALOG_PATHS.EDIT}`;

export const PATH_TO_BUMPS = PRIVATE_PATHS.BUMPS;
export const PATH_TO_LEGAL_INFORMATION = `${PRIVATE_PATHS.SETTINGS}/${SETTINGS_PATHS.LEGAL_INFORMATION}`;
