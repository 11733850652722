import { APP_VERSION } from '@wallapop/app-version';
import { environment } from '@environments/environment';
import { BrowserOptions, BrowserTracing, routingInstrumentation } from '@sentry/angular';
import { Integration, TracePropagationTargets } from '@sentry/types';
import { mapEventBeforeSend as beforeSend } from '../mappers/before-send.mapper';
import { IGNORED_ERRORS as ignoreErrors } from './ignored-errors';
import { mapTracesSampler as tracesSampler } from '../mappers/traces-sampler.mapper';

const dsn: string = 'https://1d0e1781157041509326a1072f4ad490@o608018.ingest.sentry.io/5839391';
const { allowUrls, denyUrls } = environment.sentry;
const integrations: Integration[] = [
  new BrowserTracing({
    tracingOrigins: allowUrls,
    routingInstrumentation,
  }),
];

//Disabling all -> performance <- tracing related attributes
//Enabling 100% of transactions in tracesSampleRate but filtering them out using the tracesSampler logic.
//Errors will still be sent with "Sentry.init" + "beforeSend" filtering logic
//The sample rate sends a representative sample of all errors. Range between 0(O% of errors sent) and 1(100% of errors sent).
const tracesSampleRate: number = 1;
const sendClientReports: boolean = false;
const sampleRate: number = 1;

// We are currently not interested in sending extra tracing information in XHR requests due to possible incompatibilities
// Please, refer to this: https://wallapop.atlassian.net/wiki/spaces/FE/pages/3177283585/19-07-2023+-+POSTMORTEM+-+Web+users+were+not+able+to+create+disputes
const tracePropagationTargets: TracePropagationTargets = [];

export const SENTRY_INIT_CONFIGURATION: BrowserOptions = {
  environment: environment.name,
  release: APP_VERSION,
  dsn,
  integrations,
  sampleRate,
  tracesSampleRate,
  sendClientReports,
  allowUrls,
  beforeSend,
  tracePropagationTargets,
  ignoreErrors,
  denyUrls,
  tracesSampler,
};
