import { IProductUploadForm, ICarUploadForm, IRealEstateUploadForm, UploadForms } from '@shared/uploader/upload.interface';
import { CAR_SPECIFICATION_TYPE } from '@public/core/constants/item-specifications/cars-constants';
import { UploadItem, UploadItemPrice } from '@private/features/upload/core/models/upload-item.interface';
import { ITEM_TYPES } from '@core/item/item';

export function mapUploadFormToUploadItem(uploadForm: UploadForms): UploadItem {
  if (isProductUploadForm(uploadForm)) {
    return mapProductUploadFormToUploadItem(uploadForm);
  }
  if (isCarUploadForm(uploadForm)) {
    return mapCarUploadFormToUploadItem(uploadForm);
  }
  if (isRealEstateUploadForm(uploadForm)) {
    return mapRealEstateUploadFormToUploadItem(uploadForm);
  }
}

function mapProductUploadFormToUploadItem(uploadForm: IProductUploadForm): UploadItem {
  const {
    title,
    description,
    images,
    sale_price,
    currency_code,
    extra_info = {},
    hashtags,
    sale_conditions,
    delivery_info,
    location,
    is_bulky,
    is_discount,
    measures,
    category_leaf_id,
    attributes: bduiAttributes,
    stock,
  } = uploadForm;
  const { brand, model, size, gender, condition, isbn } = extra_info;
  const { heightCm, widthCm, lengthCm } = measures || {};

  const attributes = {
    ...(brand && { brand }),
    ...(model && { model }),
    ...(size?.id && { size: parseFloat(size.id) }),
    ...(condition && { condition }),
    ...(isbn && { isbn }),
    ...(gender && { gender }),
    ...((is_bulky || is_bulky === false) && { is_bulky }),
    ...(heightCm && { height_cm: parseInt(heightCm) }),
    ...(widthCm && { width_cm: parseInt(widthCm) }),
    ...(lengthCm && { length_cm: parseInt(lengthCm) }),
    ...{ ...bduiAttributes, description: bduiAttributes?.description ?? description },
  };

  const uploadItem = {
    attributes,
    category_leaf_id,
    title,
    price: getPriceFromUploadForm(sale_price, currency_code, is_discount),
    hashtags,
    location: {
      latitude: parseFloat(location.latitude),
      longitude: parseFloat(location.longitude),
      approximated: false,
    },
    delivery: {
      allowed_by_user: sale_conditions.supports_shipping,
      max_weight_kg: delivery_info?.max_weight_kg ? delivery_info.max_weight_kg : null,
      cost_configuration_id: sale_conditions.cost_configuration_id,
    },
    ...(images && { image: images }),
    ...(stock && { stock: { units: stock } }),
  };

  return uploadItem;
}

function mapCarUploadFormToUploadItem(uploadForm: ICarUploadForm): UploadItem {
  const {
    category_id,
    brand,
    model,
    sale_price,
    financed_price,
    currency_code,
    year,
    title,
    version,
    num_seats,
    num_doors,
    horsepower,
    body_type,
    km,
    engine,
    gearbox,
    description,
    images,
    location,
    attributes: bduiAttributes,
  } = uploadForm;

  const attributes = {
    gear_box: gearbox,
    ...(engine && { engine: getEngineType(engine) }),
    ...(num_seats && { seats: parseInt(num_seats) }),
    ...(num_doors && { doors: parseInt(num_doors) }),
    ...(horsepower && { horse_power: parseInt(horsepower) }),
    ...(body_type && { body_type: body_type === CAR_SPECIFICATION_TYPE.FXF ? CAR_SPECIFICATION_TYPE.FOUR_X_FOUR : body_type }),
    brand,
    model,
    year: parseInt(year),
    version,
    ...(km && { km: parseInt(km) }),
    ...{ ...bduiAttributes, description: bduiAttributes?.description ?? description },
  };

  return {
    attributes,
    category_leaf_id: category_id,
    title,
    ...(images && { image: images }),
    price: getPriceFromCarForm(sale_price, currency_code, financed_price),
    location: {
      latitude: parseFloat(location.latitude),
      longitude: parseFloat(location.longitude),
      approximated: false,
    },
  };
}

function mapRealEstateUploadFormToUploadItem(uploadForm: IRealEstateUploadForm): UploadItem {
  const { category_id, sale_price, currency_code, title, images, location, attributes = {} } = uploadForm;

  const features = attributes?.real_estate_features ?? '';
  const selectedFeatures = features.split(',').reduce((acc, key) => {
    if (key) acc[key] = true;
    return acc;
  }, {});
  const {
    description = '',
    condition = '',
    operation = '',
    type = '',
    surface = null,
    rooms = null,
    bathrooms = null,
    ...rest
  } = attributes;

  return {
    category_leaf_id: category_id,
    title,
    ...(images && { image: images }),
    price: getPriceFromUploadForm(sale_price, currency_code),
    location: {
      latitude: parseFloat(location.latitude),
      longitude: parseFloat(location.longitude),
      approximated: location.approximated_location,
    },
    attributes: {
      ...(description && { description }),
      ...(condition && { condition }),
      ...(operation && { operation }),
      ...(type && { type }),
      ...(surface && { surface: parseInt(surface, 10) }),
      ...(rooms && { rooms: parseInt(rooms) }),
      ...(bathrooms && { bathrooms: parseInt(bathrooms) }),
      ...(!!Object.keys(selectedFeatures).length && { ...selectedFeatures }),
      ...rest,
    },
  };
}

function getPriceFromUploadForm(price: string, currency: string, isDiscount?: boolean): UploadItemPrice {
  return {
    cash_amount: parseFloat(sanitizePrice(`${price}`)),
    currency,
    apply_discount: !!isDiscount,
  };
}

function getPriceFromCarForm(price: string, currency: string, financedPrice: string): UploadItemPrice {
  const isFinancedPrice: boolean = financedPrice !== '';

  return {
    cash_amount: parseFloat(sanitizePrice(`${price}`)),
    currency,
    ...(isFinancedPrice && { financed_amount: parseFloat(financedPrice) }),
  };
}

function getEngineType(engineType: string): string {
  return engineType === CAR_SPECIFICATION_TYPE.ELECTRIC ? CAR_SPECIFICATION_TYPE.ELECTRIC_HYBRID : engineType;
}

const isProductUploadForm = (uploadForm: UploadForms): uploadForm is IProductUploadForm => {
  return uploadForm.vertical_id === ITEM_TYPES.CONSUMER_GOODS;
};

const isCarUploadForm = (uploadForm: UploadForms): uploadForm is ICarUploadForm => {
  return uploadForm.vertical_id === ITEM_TYPES.CARS;
};

const isRealEstateUploadForm = (uploadForm: UploadForms): uploadForm is IRealEstateUploadForm => {
  return uploadForm.vertical_id === ITEM_TYPES.REAL_ESTATE;
};

export const sanitizePrice = (price: string): string => {
  return `${price}`.replace(',', '.');
};

export const formatPrice = (price: string): number => {
  return parseFloat(sanitizePrice(`${price}`));
};
