import { getAssetFromIconName } from '@wallapop/api-layer/apis/design-system/icons/design-system-icons-api.service';
import { ExperimentatorCategoryData } from '@wallapop/api-layer/apis/experimentator/interfaces/experimentator-category-response.interface';
import { CategoryNavigationItem } from '@wallapop/web-components-react';

export const categoryExperimentatorToCategoryNavigationListMapper = (categories: ExperimentatorCategoryData[]): CategoryNavigationItem[] =>
  categories.map((category) => categoryExperimentatorToCategoryNavigationItemMapper(category));

const categoryExperimentatorToCategoryNavigationItemMapper = ({
  categoryId,
  icon,
  subcategories,
  title,
  url,
}: ExperimentatorCategoryData): CategoryNavigationItem => ({
  title,
  categoryId,
  url,
  icon: icon ? getAssetFromIconName(icon) : '',
  subcategories: subcategories?.map((subcategory) => categoryExperimentatorToCategoryNavigationItemMapper(subcategory)),
});
