export const DISPUTES_PATHS = {
  SELF_SERFICE: 'self-service',
  RESOLUTION_CENTER: 'resolution-center',
  CATEGORIES: 'categories',
  SUBCATEGORIES: 'subcategories',
  ACTION: 'action',
  CREATE: 'create',
  CREATE_CLAIM: 'create-claim',
  VIEW: 'view',
  BARCODE: 'barcode',
  INFO: 'info',
};
