/* eslint-disable no-restricted-imports */
import { WebappEnvironment } from './interfaces/webapp-environment.interface';
import * as baseBetaEnvironment from '@wallapop/environments/environment.beta';

export const environment: WebappEnvironment = {
  ...baseBetaEnvironment.environment,
  xmppDomain: 'beta.wallapop.com',
  wsUrl: 'wss://mongooseimprotool-beta.wallapop.com:443/ws-xmpp',
  stripeKey: 'pk_test_YMrctQusG3vLNQkCSRYKdqwF',
  remoteConsoleUrl: 'https://client-metrics.beta.wallapop.com/metrics/chat',
  zendeskHost: 'https://wallapop1581674063.zendesk.com',
  MQTTBrokerUrl: 'wss://realtime.beta.wallapop.com:443/ws/mqtt',
  awsLocationService: {
    ...baseBetaEnvironment.environment.awsLocationService,
    geoConfig: {
      apiKey:
        'v1.public.eyJqdGkiOiIwMDllNDQyNy0wMWM3LTQwYzQtYTgzMy1mOTNhZGEyYmMyNzcifXcBXPw590AOsjkx7IkHZClCl5DFV1GVGFYK965LUnpKEPZI0tNsFNZmTDrW1iSNQGaPXOTITVJfwYc_55eitohO4Jk3oVCXeoKIbYEjKyk_KgpPkOigOGxiANJn2IWvVVANfK5WfzOJXVSP-9sThzBjcHyZ6i4FXgYOZ9SjwlO3O5PhUtXfNCFAeeD5KdYeDJCSN9uIaVycTSVl1tRZNjE0a7BXFmW406Ekd5SJSiUxfudeeXUEbX7kD9RV5dtJAPinDNtZSkT1gZ2xcHaZ1uXs4AnzWRk2zFKQoKo4F1vtCBM7Na71VsHH6JXmY50EOKk997qR7A9wY0JfGWXKZgk.ZGQzZDY2OGQtMWQxMy00ZTEwLWIyZGUtOGVjYzUzMjU3OGE4',
      region: 'eu-west-1',
      indexName: 'places',
    },
  },
  isMsw: false,
};
