import { BaseHttpClient } from '@wallapop/api-layer/core/http/http-client.interface';
import { environment } from '@wallapop/environments';
import { FeatureFlag, FeatureFlagCacheArgs, FeatureFlagName } from './feature-flag.types';

export const FEATURE_FLAG_ENDPOINT = 'api/v3/featureflag';
export const FEATURE_FLAG_ACCEPT_HEADER = 'application/vnd.featureflag-v2+json';

export class FeatureFlagRequestFailedError extends Error {}

export class FeatureFlagService<T extends BaseHttpClient> {
  private storeFeatureFlags: Partial<Record<FeatureFlagName, FeatureFlag>> = {};
  private httpClient: T;

  constructor(httpClient: T) {
    this.httpClient = httpClient;
  }

  public async getFlags(flags: FeatureFlagName, cache?: boolean): Promise<FeatureFlag>;
  public async getFlags(flags: FeatureFlagName[], cache?: boolean): Promise<FeatureFlag[]>;
  public async getFlags(flags: FeatureFlagName | FeatureFlagName[], cache = true): Promise<FeatureFlag | FeatureFlag[]> {
    try {
      const resultAsArray = Array.isArray(flags);
      const flagsAsArray: FeatureFlagName[] = Array.isArray(flags) ? flags : [flags];

      let flagsToRequest: string[] = [];
      const cachedFeatureFlags: FeatureFlag[] = [];

      if (cache) {
        const allFlagsInCache = this.checkCache(flagsAsArray, flagsToRequest, cachedFeatureFlags);
        if (allFlagsInCache) {
          return this.returnResult(cachedFeatureFlags, flagsAsArray, resultAsArray);
        }
      } else {
        flagsToRequest = flagsAsArray;
      }

      if (flagsToRequest.length === 0) {
        return this.returnResult(cachedFeatureFlags, flagsAsArray, resultAsArray);
      }

      // Pragma header added for axios support for cache bursting
      const { data } = await this.httpClient.get(this.getFlagsUrl(flagsToRequest), {
        headers: { Accept: FEATURE_FLAG_ACCEPT_HEADER, 'Cache-Control': 'no-store', Pragma: 'no-cache' },
      });

      for (const flagFromService of data) {
        cachedFeatureFlags.push(flagFromService);
        this.setFlagInCache(flagFromService.name, flagFromService);
      }

      return this.returnResult(cachedFeatureFlags, flagsAsArray, resultAsArray);
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Unknown error';
      throw new FeatureFlagRequestFailedError(message);
    }
  }

  public isExperimentalFeaturesEnabled(): boolean {
    try {
      return !!localStorage.getItem('experimentalFeatures');
    } catch (e) {
      this.checkServerSide();
      return false;
    }
  }

  public getFlagFromCache({
    flagName,
    shouldSkipLocalStorage = false,
    shouldSkipMemoryCache = false,
  }: FeatureFlagCacheArgs): FeatureFlag | undefined | null {
    if (!shouldSkipMemoryCache) {
      const storedFlag = this.storeFeatureFlags[flagName];
      if (storedFlag) return storedFlag;
    }
    if (this.checkServerSide() || !localStorage || shouldSkipLocalStorage) return null;
    const localStorageFlag = localStorage.getItem(flagName);

    if (!localStorageFlag) return null;
    const parsedFeatureFlag = JSON.parse(localStorageFlag);
    return parsedFeatureFlag;
  }

  protected checkCache(flagsAsArray: string[], flagsToRequest: string[], cachedFeatureFlags: FeatureFlag[]): boolean {
    for (const flagName of flagsAsArray) {
      const storeFlag = this.getFlagFromCache({ flagName, shouldSkipLocalStorage: true });
      if (!storeFlag) {
        flagsToRequest.push(flagName);
      } else {
        cachedFeatureFlags.push(storeFlag);
      }
    }

    return cachedFeatureFlags.length === flagsAsArray.length;
  }

  protected returnResult(resultFlags: FeatureFlag[], flagsAsArray: string[], asArray: boolean) {
    if (asArray) {
      return resultFlags;
    }

    const flagName = flagsAsArray[0];

    return resultFlags[0] ? resultFlags[0] : { name: flagName, active: false };
  }

  protected getFlagsUrl(flagsToRequest: string[]): string {
    const queryParams = new URLSearchParams();
    queryParams.append('featureFlags', flagsToRequest.join(','));

    const featureFlagDomain = environment.featureFlagEndpoint;

    return `${featureFlagDomain}${FEATURE_FLAG_ENDPOINT}?${queryParams.toString()}`;
  }

  protected setFlagInCache(flagName: FeatureFlagName, flagValue: FeatureFlag): void {
    this.storeFeatureFlags[flagName] = flagValue;

    if (this.checkServerSide() || !localStorage) return;
    const stringifiedFlag = JSON.stringify(flagValue);
    localStorage.setItem(flagName, stringifiedFlag);
  }

  protected clearFlagsFromMemCache(): void {
    this.storeFeatureFlags = {};
  }

  protected checkServerSide(): boolean {
    if (typeof window === 'undefined') {
      console.warn('You try to access client-only feature on the server-side');
      return true;
    }
    return false;
  }
}
