import { ToDomainMapper } from '@api/core/utils/types/to-domain-mapper.type';
import { RefreshTokenDto } from '../dtos/refresh-token-dto.interface';
import { RefreshToken } from '@api/core/model/auth/refresh-token/refresh-token.interface';

export const mapRefreshTokenDtoToRefreshToken: ToDomainMapper<RefreshTokenDto, RefreshToken> = (input: RefreshTokenDto) => {
  const { token } = input;

  const refreshToken: RefreshToken = {
    token,
  };

  return refreshToken;
};
