export enum ANALYTICS_SCREEN_EVENT_NAMES {
  OpenMyZone = 'Open My Zone',
  View3DSPopup = 'View 3DS Popup',
  View3DSRetryPayment = 'View 3DS Retry Payment',
  ViewAbandonCheckoutPopup = 'View Abandon Checkout Popup',
  ViewAcceptOffer = 'View Accept Offer',
  ViewAccountDeletion = 'View Account Deletion',
  ViewAffiliationLanding = 'View Affiliation Landing',
  ViewBankCardForm = 'View Bank Card Form',
  ViewBannedUserChatPopUp = 'View Banned User Chat Pop Up',
  ViewBlockedWalletBanner = 'View Blocked Wallet Banner',
  ViewBulkyItemBanner = 'View Bulky Item Banner',
  ViewBumpCheckout = 'View Bump Checkout',
  ViewBumpPurchaseType = 'View Bump Purchase Type',
  ViewBumpTypeInfo = 'View Bump Type Info',
  ViewBundleBanner = 'View Bundle Banner',
  ViewBundleCreationScreen = 'View Bundle Creation Screen',
  ViewBundleItems = 'View Bundle Items',
  ViewCarrierOfficeMap = 'View Carrier Office Map',
  ViewCatalogManager = 'View Catalog Manager',
  ViewChangeNotSecurePassword = 'View Change Not Secure Password',
  ViewChatScreen = 'View Chat Screen',
  ViewChatbot = 'View Chatbot',
  ViewClaimForm = 'View Claim Form',
  ViewCollectionResults = 'View Collection Results',
  ViewCompletedPurchases = 'View Completed Purchases',
  ViewCompletedSales = 'View Completed Sales',
  ViewDAC7FormScreen = 'View DAC7 Form Screen',
  ViewDAC7ReportScreen = 'View DAC7 Report Screen',
  ViewEditCard = 'View Edit Card',
  ViewEditItem = 'View Edit Item',
  ViewEditProfile = 'View Edit Profile',
  ViewEditSubscriptionPlan = 'View Edit Subscription Plan',
  ViewEditUserType = 'View Edit User Type',
  ViewF2FPaymentSummary = 'View F2F Payment Summary',
  ViewF2FRequest = 'View F2F Request',
  ViewFavoriteItems = 'View Favorite Items',
  ViewFavoriteProfiles = 'View Favorite Profiles',
  ViewFavoriteSearches = 'View Favorite Searches',
  ViewHashtagsAllUpload = 'View Hashtags All Upload',
  ViewHashtagsSuggestedUpload = 'View Hashtags Suggested Upload',
  ViewHomeContentError = 'View Home Content Error',
  ViewInProgressPurchases = 'View In Progress Purchases',
  ViewInProgressSales = 'View In Progress Sales',
  ViewInstantCashoutPopUp = 'View Instant Cashout PopUp',
  ViewItemDetailRecommendationSlider = 'View Item Detail Recommendation Slider',
  ViewItemSoldOutside = 'View Item Sold Outside',
  ViewItemStatistics = 'View Item Statistics',
  ViewKYCBankAccountInfoScreen = 'View KYC Bank Account Info Screen',
  ViewKYCBannerScreen = 'View KYC Banner Screen',
  ViewKYCDocumentationTypeScreen = 'View KYC Documentation Type Screen',
  ViewKYCIdentityVerificationScreen = 'View KYC Identity Verification Screen',
  ViewKYCReviewDocumentationImageScreen = 'View KYC Review Documentation Image Screen',
  ViewKYCTutorialScreen = 'View KYC Tutorial Screen',
  ViewKYCUploadIdentityVerificationScreen = 'View KYC Upload Identity Verification Screen',
  ViewKYCVerifyingIdentityScreen = 'View KYC Verifying Identity Screen',
  ViewLocationPermissions = 'View Location Permissions',
  ViewLoginRegisterScreen = 'View Login Register Screen',
  ViewLoginVerificationError = 'View Login Verification Error',
  ViewLoginVerificationSent = 'View Login Verification Sent',
  ViewLoginVerificationSuccessful = 'View Login Verification Successful',
  ViewMessages = 'View Messages',
  ViewMFABlockingScreen = 'View MFA Blocking Screen',
  ViewMFAOTPScreen = 'View MFA OTP Screen',
  ViewNotificationCenter = 'View Notification Center',
  ViewNotificationSettings = 'View Notification Settings',
  ViewOfferEditorScreen = 'View Offer Editor Screen',
  ViewOfferTrackingStatusScreen = 'View Offer Tracking Status Screen',
  ViewOnboardingIntentionOptions = 'View Onboarding Intention Options',
  ViewOnboardingPhoneVerification = 'View Onboarding Phone Verification',
  ViewOnBoardingUserCountryInfo = 'View On Boarding User Country Info',
  ViewOngoingSale = 'View Ongoing Sale',
  ViewOpenWeb = 'View Open Web',
  ViewOtherProfile = 'View Other Profile',
  ViewOtherReviews = 'View Other Reviews',
  ViewOwnItemDetail = 'View Own Item Detail',
  ViewOwnProSection = 'View Own Pro Section',
  ViewOwnProfile = 'View Own Profile',
  ViewOwnReviews = 'View Own Reviews',
  ViewOwnSaleItems = 'View Own Sale Items',
  ViewOwnSoldItems = 'View Own Sold Items',
  ViewP2PCashoutPopup = 'View P2P Cashout Popup',
  ViewP2PDeliveryOnboarding = 'View P2P Delivery Onboarding',
  ViewP2PItems = 'View P2P Items',
  ViewP2PManualCode = 'View P2P Manual Code',
  ViewP2PPaymentError = 'View P2P Payment Error',
  ViewP2PPaymentMethod = 'View P2P Payment Method',
  ViewP2PPaymentOffer = 'View P2P Payment Offer',
  ViewP2PPaymentSuccessBuyer = 'View P2P Payment Success Buyer',
  ViewP2PPaymentSuccessSeller = 'View P2P Payment Success Seller',
  ViewP2PQRCode = 'View P2P QR Code',
  ViewP2PScanQRCode = 'View P2P Scan QR Code',
  ViewPaymentInteractionRequired3secsScreen = 'View Payment Interaction Required 3secs Screen ',
  ViewPaymentInteractionRequiredScreen = 'View Payment Interaction Required Screen',
  ViewPersonalImpactStats = 'View Personal Impact Stats',
  ViewPopup = 'View Popup',
  ViewProBenefitsPopup = 'View Pro Benefits Popup',
  ViewProBumpPurchaseOption = 'View Pro Bump Purchase Option',
  ViewProDiscountPopup = 'View Pro Discount Popup',
  ViewProExpiredItemsPopup = 'View Pro Expired Items Popup',
  ViewProPreviewProfile = 'View Pro Preview Profile',
  ViewProSubscriptionPopup = 'View Pro Subscription Popup',
  ViewProUnsubscribePopup = 'View Pro Unsubscribe Popup',
  ViewProfileMenu = 'View Profile Menu',
  ViewPurchaseDeliveryMethod = 'View Purchase Delivery Method',
  ViewPushPrimingScreen = 'View Push Priming Screen',
  ViewReactivationShippingPopUp = 'View Reactivation Shipping PopUp',
  ViewRefundMethod = 'View Refund Method',
  ViewRegisterForm = 'View Register Form',
  ViewRegisterVerificationScreen = 'View Register Verification Screen',
  ViewRequiredUpdateAppVersionPopup = 'View Required Update App Version Popup',
  ViewResetPasswordError = 'View Reset Password Error',
  ViewResetPassword = 'View Reset Password',
  ViewResolutionCenter = 'View Resolution Center',
  ViewReviewBuyer = 'View Review Buyer',
  ViewSavedSearches = 'View Saved Searches',
  ViewSeeAllBumpSlider = 'View See All Bump Slider',
  ViewSelectBuyer = 'View Select Buyer',
  ViewSEOLanding = 'View SEO Landing',
  ViewShippingAddress = 'View Shipping Address',
  ViewShippingDropOffMethods = 'View Shipping Drop Off Methods',
  ViewShippingInfoItem = 'View Shipping Info Item',
  ViewShippingMenu = 'View Shipping Menu',
  ViewShippingRequest = 'View Shipping Request',
  ViewShippingTransactions = 'View Shipping Transactions',
  ViewSubscriptionBenefits = 'View Subscription Benefits',
  ViewSubscriptionCategories = 'View Subscription Categories',
  ViewSubscriptionPaymentSuccess = 'View Subscription Payment Success',
  ViewSubscriptionTier = 'View Subscription Tier',
  ViewSubscription = 'View Subscription',
  ViewSuccessSubscriptionPayment = 'View Success Subscription Payment',
  ViewSuccessUnsubscription = 'View Success Unsubscription',
  ViewSuggestedUpdateAppVersionPopup = 'View Suggested Update App Version Popup',
  ViewTermsCommunicationsConsent = 'View Terms Communications Consent',
  ViewTermsAndCommunicationsUpdate = 'View Terms And Communications Update',
  ViewTermsParticular = 'View Terms Particular',
  ViewTermsProfessional = 'View Terms Professional',
  ViewTopUpWalletSuccess = 'View Top Up Wallet Success',
  ViewTopUpWalletSummary = 'View Top Up Wallet Summary',
  ViewTracerError = 'View Tracer Error',
  ViewTracer = 'View Tracer',
  ViewTransactionF2FPaymentMoment = 'View Transaction F2F Payment Moment',
  ViewTransactionLogisticsScreen = 'View Transaction Logistics Screen',
  ViewTransactionNonShippableLogisticsScreen = 'View Transaction Non Shippable Logistics Screen',
  ViewTransactionPaymentScreen = 'View Transaction Payment Screen',
  ViewTransactionSummaryScreen = 'View Transaction Summary Screen',
  ViewTransactionalTimeline = 'View Transactional Timeline',
  ViewTryProlandingpage = 'View Try Pro landing page',
  ViewUnloggedChatScreen = 'View Unlogged Chat Screen',
  ViewUploadSection = 'View Upload Section',
  ViewVacationMode = 'View Vacation Mode',
  ViewVerificationsandSecurityScreen = 'View Verifications and Security Screen',
  ViewWallapopFAQs = 'View Wallapop FAQs',
  ViewWalletMovementsHistory = 'View Wallet Movements History',
  ViewWallet = 'View Wallet',
  ViewWebViewPaymentInteractionRequired = 'View Web View Payment Interaction Required',
}
