import { ANALYTICS_EVENT_NAMES } from './src/resources/analytics-event-names';
import { ANALYTICS_SCREEN_EVENT_NAMES } from './src/resources/analytics-screen-event-names';

// Export helpers to prevent multiple imports when using analytics code
export * from './src/resources/analytics-event-names';
export * from './src/resources/analytics-screen-event-names';
export * from './src/resources/analytics-screen-ids';
export * from './src/resources/events-interfaces';
export * from './src/interfaces/mpid.interface';
export * from './src/interfaces/mpconfiguration.interface';
export * from './src/interfaces/user-identities.interface';

// FIXME: This is a temporal workaround to cope with the bug in `json-schema-to-typescript` WPA-44537
import { SearchCatalogManagement as DefaultSearchCatalogManagement } from './src/resources/events-interfaces';
export interface SearchCatalogManagement extends Omit<DefaultSearchCatalogManagement, 'categorySpecificFilters'> {
  categorySpecificFilters?: string;
}

export { MPARTICLE_KITS } from './src/interfaces/mParticle-kits.interface';

export enum ANALYTICS_EVENT_TYPES {
  PageView = 0,
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}

export interface AnalyticsPageView<T extends object> {
  name: ANALYTICS_SCREEN_EVENT_NAMES;
  attributes: T;
}

export interface AnalyticsEvent<T extends object> {
  name: ANALYTICS_EVENT_NAMES;
  eventType: ANALYTICS_EVENT_TYPES;
  attributes: T;
}
