import { environment } from '@wallapop/environments';

export const getAccessTokenCookieName = (): string => {
  const cookieName = 'accessToken';
  return environment.production ? cookieName : cookieName + environment.cookieSuffix;
};

export const getAccessTokenFromCookies = (cookies?: Cookies): string | undefined => {
  return cookies ? cookies[getAccessTokenCookieName()] : undefined;
};
