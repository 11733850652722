export const HTTP_CLIENT_HEADERS = {
  ACCEPT_LANGUAGE: 'accept-language',
  AUTHORIZATION: 'authorization',
  REFERER: 'referer',
  COOKIE: 'cookie',
  CONTENT_TYPE: 'content-type',
  CUSTOM_DEVICE_ACCESS_TOKEN: 'DeviceAccessToken'.toLowerCase(),
  CUSTOM_APP_BUILD: 'AppBuild'.toLowerCase(),
  APP_VERSION: 'X-AppVersion'.toLowerCase(),
  SEMANTIC_VERSION: 'X-SemanticVersion'.toLowerCase(),
  REFRESH_TOKEN_HEADER_NAME: 'X-RefreshSession',
  CUSTOM_REFRESH_TOKEN_HEADER_NAME: 'x-refreshsession',
  CUSTOM_WALLAPOP_UNAUTHORIZED: 'x-wallapop-unauthorized',
  CUSTOM_FORWARDED_FOR: 'x-forwarded-for',
  CORRELATION_ID: 'x-correlation-id',
} as const;
