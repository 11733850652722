import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AccessTokenService } from '@core/access-token/services/access-token.service';
import { LOGIN_ENDPOINT } from '@public/features/login/core/services/login.service';
import { HTTP_RESPONSE_CODE } from '@api/core/model/multi-factor/http-response-code';
import { LOGOUT_ENDPOINT } from '@core/user/user.service';
import { AUTH_REFRESH_ENDPOINT } from '@api/auth/refresh-token/http/endpoints';
import { AuthService } from '@core/auth/auth.service';
import { AuthorizationErrorCodes } from './authorization.constants';
import { DeviceService } from '@core/device/device.service';
import { MonitoringService } from '@core/monitoring/services/monitoring.service';

export const AUTHORIZATION_HEADER_NAME = 'Authorization';
export const REFRESH_TOKEN_HEADER_NAME = 'X-RefreshSession';
export const DEVICE_TOKEN_HEADER_NAME = 'X-DeviceToken';
export const CUSTOM_WALLAPOP_UNAUTHORIZED = 'x-wallapop-unauthorized';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private accessTokenService: AccessTokenService,
    private deviceService: DeviceService,
    private monitoringService: MonitoringService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const validAccessToken = this.accessTokenService.accessToken === this.accessTokenService.accessTokenFromCookies;

    if (!validAccessToken) {
      window.location.reload();
    }

    const isLogoutOrRefreshEndpoint = this.isLogoutOrRefreshEndpoint(request);
    const isRefreshingToken = this.isRefreshingToken();
    if (!isLogoutOrRefreshEndpoint && isRefreshingToken) {
      return this.waitForRefreshingTokenAndRetryRequest(request, next);
    }

    request = this.addAuthorizationHeaders(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.httpErrorHandler(error, request, next);
      }),
    );
  }

  private httpErrorHandler(error: HttpErrorResponse, request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isUnauthorizedError = this.isUnauthorizedError(request, error);
    const isLogoutOrRefreshEndpoint = this.isLogoutOrRefreshEndpoint(request);
    const isRefreshingToken = this.isRefreshingToken();

    const httpErrorCode = error.headers.get(CUSTOM_WALLAPOP_UNAUTHORIZED);

    if (!isUnauthorizedError || isLogoutOrRefreshEndpoint) {
      return throwError(error);
    }

    if (!httpErrorCode || httpErrorCode === AuthorizationErrorCodes.REFRESH_TOKEN_EXPIRED) {
      return this.logout(error);
    }

    if (isRefreshingToken) {
      return this.waitForRefreshingTokenAndRetryRequest(request, next);
    }

    return this.refreshTokenHandler(request, next);
  }

  private isUnauthorizedError(request: HttpRequest<unknown>, error: HttpErrorResponse): boolean {
    const isLoginEndpoint: boolean = request.url.includes(LOGIN_ENDPOINT);
    const isLocalhostDomain: boolean = request.url.includes('localhost');
    const canHandleUnauthorizedError: boolean = !isLoginEndpoint && !isLocalhostDomain;
    if (!canHandleUnauthorizedError) {
      return;
    }
    if (!(error instanceof HttpErrorResponse)) {
      return;
    }
    if (error.status !== HTTP_RESPONSE_CODE.UNAUTHORIZED_ERROR) {
      return;
    }
    return true;
  }

  private refreshTokenHandler(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.authService.isRefreshing.next(true);
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        const requireNewAuthorizationHeader = true;
        const updatedRequest = this.addAuthorizationHeaders(request, requireNewAuthorizationHeader);
        return next.handle(updatedRequest);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.url.includes(AUTH_REFRESH_ENDPOINT)) {
          return this.refreshTokenErrorHandler(error, request, next);
        }
        return throwError(error);
      }),
      finalize(() => {
        this.authService.isRefreshing.next(false);
      }),
    );
  }

  private refreshTokenErrorHandler(error: HttpErrorResponse, request: HttpRequest<unknown>, next: HttpHandler): Observable<never> {
    this.monitoringService.captureMessage('[WEB-AUTH]: Error when performing the refresh token operation', { extra: { error } });
    return this.logout(error);
  }

  private waitForRefreshingTokenAndRetryRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.isRefreshing.pipe(
      filter((isRefreshing) => !isRefreshing),
      take(1),
      switchMap(() => {
        const requireNewAuthorizationHeader = true;
        request = this.addAuthorizationHeaders(request, requireNewAuthorizationHeader);
        return next.handle(request);
      }),
    );
  }

  private addAuthorizationHeaders(request: HttpRequest<unknown>, requireNewAuthorizationHeader?: boolean): HttpRequest<unknown> {
    const setHeaders = this.getAuthorizationAndRefreshHeaders(request, requireNewAuthorizationHeader);
    if (setHeaders[AUTHORIZATION_HEADER_NAME] === undefined) {
      const authorizationHeader = request.headers.delete(AUTHORIZATION_HEADER_NAME);
      return request.clone({ setHeaders, headers: authorizationHeader });
    }
    return request.clone({ setHeaders });
  }

  private getAuthorizationAndRefreshHeaders(
    request: HttpRequest<unknown>,
    requireNewAuthorizationHeader?: boolean,
  ): { [key: string]: string } {
    const isWallapopRequest = request.url.startsWith(environment.baseUrl);
    const hasAccessToken = !!this.accessTokenService.accessToken;
    const isRefreshEndpoint: boolean = request.url.includes(AUTH_REFRESH_ENDPOINT);
    const isHeaderPresent = request.headers.has(AUTHORIZATION_HEADER_NAME);
    const isFFProxyRequest = request.url?.startsWith(environment.featureFlagEndpoint);

    const canAddAuthorizationHeader = (isWallapopRequest || isFFProxyRequest) && hasAccessToken && !isHeaderPresent;
    const canAddRefreshTokenHeader = isWallapopRequest && hasAccessToken && isRefreshEndpoint;

    if (canAddRefreshTokenHeader) {
      return {
        [REFRESH_TOKEN_HEADER_NAME]: this.accessTokenService.accessToken,
        [DEVICE_TOKEN_HEADER_NAME]: this.deviceService.getDeviceId(),
      };
    }

    if (!canAddAuthorizationHeader && !requireNewAuthorizationHeader) {
      if (isHeaderPresent) {
        return { [AUTHORIZATION_HEADER_NAME]: request.headers.get(AUTHORIZATION_HEADER_NAME) };
      }
      return {};
    }

    return { [AUTHORIZATION_HEADER_NAME]: `Bearer ${this.accessTokenService.accessToken}` };
  }

  private isLogoutOrRefreshEndpoint(request: HttpRequest<unknown>): boolean {
    const isLogoutEndpoint: boolean = request.url.includes(LOGOUT_ENDPOINT);
    const isRefreshEndpoint: boolean = request.url.includes(AUTH_REFRESH_ENDPOINT);

    return isLogoutEndpoint || isRefreshEndpoint;
  }

  private isRefreshingToken(): boolean {
    return this.authService.isRefreshing.getValue();
  }

  private logout(error: HttpErrorResponse): Observable<never> {
    return this.authService.logout().pipe(
      switchMap(() => {
        return throwError(error);
      }),
    );
  }
}
