import { PATH_TO_CHECKOUT_SHIPPING_STEP } from '@private/private-routing-constants';

export enum STEPPED_CHECKOUT_PATHS {
  SHIPPING = 'shipping',
  PAYMENT = 'payment',
  SUMMARY = 'summary',
  PROMOCODE_EDITOR = 'promocode',
  DELIVERY_ADDRESS = 'address',
  CREDIT_CARD = 'card',
  MAP = 'map',
  F2F = 'f2f',
  ABANDON_SURVEY = 'abandon-survey',
}

export const STEPPED_CHECKOUT_PATH_PARAMS = {
  ID: 'id',
};

export const STEPPED_CHECKOUT_QUERY_PARAMS = {
  PAYMENT_METHOD: 'paymentMethod',
  NEXT_STEP: 'nextStep',
};

export const DEFAULT_STEPPED_CHECKOUT_PATH = (itemHash: string): string => PATH_TO_CHECKOUT_SHIPPING_STEP(itemHash);

export type STEPPED_CHECKOUT_MAIN_SCREEN = STEPPED_CHECKOUT_PATHS.SHIPPING | STEPPED_CHECKOUT_PATHS.PAYMENT;
