import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailThanksModalCopies } from '@shared/email-thanks-modal/interfaces';

@Component({
  selector: 'tsl-check-mailbox-modal',
  templateUrl: './check-mailbox-modal.component.html',
  styleUrls: ['./check-mailbox-modal.component.scss'],
})
export class CheckMailboxModalComponent {
  @Input() copies: EmailThanksModalCopies;

  constructor(public activeModal: NgbActiveModal) {}
}
