import { Inject, Injectable } from '@angular/core';
import { FavoriteSearchesApiService } from '@api/favorite-searches/favorite-searches-api.service';
import { FavoriteSearch } from '@api/favorite-searches/model/favorite-search.interface';
import { LOCAL_STORAGE_TOKEN } from '@core/local-storage/local-storage.token';
import { FILTER_QUERY_PARAM_KEY } from '@public/shared/components/filters/enums/filter-query-param-key.enum';
import { RecentSearch } from './interfaces/recent-search.interface';
import { isEqual } from 'lodash-es';
import { Observable, of, Subscriber } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccessTokenService } from '@core/access-token/services/access-token.service';
import { QueryStringLocationService } from '@core/search/query-string-location.service';
import { Params } from '@angular/router';

export const RECENT_SEARCHES_STORAGE_KEY = 'recentSearches';

@Injectable()
export class RecentSearchesApiService {
  private readonly STORAGE_SYSTEM: Storage;
  private readonly MAX_COUNT = 5;
  private recentSearches: RecentSearch[] = [];

  constructor(
    @Inject(LOCAL_STORAGE_TOKEN) private localStorage: Storage,
    private favoriteSearchesApiService: FavoriteSearchesApiService,
    private accessTokenService: AccessTokenService,
    private locationService: QueryStringLocationService,
  ) {
    this.STORAGE_SYSTEM = this.localStorage;
  }

  public getRecentSearches(): Observable<RecentSearch[]> {
    try {
      const recentSearches = JSON.parse(this.STORAGE_SYSTEM.getItem(RECENT_SEARCHES_STORAGE_KEY)) || [];
      return this.mergeRecentSearchesWithFavoriteSearches(recentSearches).pipe(
        tap((recentSearches: RecentSearch[]) => {
          this.updateLocalRecentSearches(recentSearches);
        }),
      );
    } catch (e) {
      return of([]);
    }
  }

  public addRecentSearch(search: RecentSearch): void {
    const formattedSearch = this.formatRecentSearch(search);

    const recentSearches = this.getStoredRecentSearches().filter((recentSearch: RecentSearch) => {
      return !this.recentSearchMatchesRecentSearch(formattedSearch, recentSearch);
    });

    recentSearches.unshift(formattedSearch);

    if (recentSearches.length > this.MAX_COUNT) {
      recentSearches.pop();
    }

    this.setRecentSearches(recentSearches);
  }

  public removeRecentSearch(search: RecentSearch): void {
    const formattedSearch = this.formatRecentSearch(search);

    const recentSearches = this.getStoredRecentSearches().filter((recentSearch: RecentSearch) => {
      return !this.recentSearchMatchesRecentSearch(formattedSearch, recentSearch);
    });

    this.setRecentSearches(recentSearches);
  }

  public deleteAllRecentSearches(): void {
    this.STORAGE_SYSTEM.removeItem(RECENT_SEARCHES_STORAGE_KEY);
  }

  public toggleFavoriteRecentSearch(search: RecentSearch): Observable<RecentSearch> {
    if (search.favoriteSearchId) {
      return this.deleteFavoriteRecentSearch(search);
    } else {
      let filters: Params = {
        [FILTER_QUERY_PARAM_KEY.keywords]: search.keyword,
        [FILTER_QUERY_PARAM_KEY.latitude]: `${this.locationService.getLocationParameters().latitude}`,
        [FILTER_QUERY_PARAM_KEY.longitude]: `${this.locationService.getLocationParameters().longitude}`,
      };

      if (search.category?.id) {
        filters = { ...filters, [FILTER_QUERY_PARAM_KEY.categoryId]: `${search.category?.id}` };
      }

      return new Observable((observer: Subscriber<RecentSearch>) => {
        this.favoriteSearchesApiService
          .create(filters, false)
          .then((favoriteSearchId: string) => {
            search.favoriteSearchId = favoriteSearchId;
            observer.next(search);
            observer.complete();
          })
          .catch((error) => {
            observer.error(error);
          });
      });
    }
  }

  private updateLocalRecentSearches(recentSearches: RecentSearch[]): void {
    if (!isEqual(this.recentSearches, recentSearches)) {
      this.recentSearches = recentSearches;
      this.setRecentSearches(recentSearches);
    }
  }

  private mergeRecentSearchesWithFavoriteSearches(recentSearches: RecentSearch[]): Observable<RecentSearch[]> {
    if (!!this.accessTokenService.accessToken) {
      return this.favoriteSearchesApiService.get().pipe(
        map((favoriteSearches: FavoriteSearch[]) => {
          return recentSearches.map((recentSearch: RecentSearch) => {
            const favoriteSearchId = favoriteSearches.find((favoriteSearch) =>
              this.recentSearchMatchesFavoriteSearch(recentSearch, favoriteSearch),
            )?.id;

            recentSearch.favoriteSearchId = favoriteSearchId || null;

            return recentSearch;
          });
        }),
      );
    } else {
      return of(recentSearches);
    }
  }

  private deleteFavoriteRecentSearch(search: RecentSearch): Observable<RecentSearch> {
    return this.favoriteSearchesApiService.delete(search.favoriteSearchId).pipe(
      map((): RecentSearch => {
        search.favoriteSearchId = null;
        return search;
      }),
    );
  }

  private getStoredRecentSearches(): RecentSearch[] {
    try {
      return JSON.parse(this.STORAGE_SYSTEM.getItem(RECENT_SEARCHES_STORAGE_KEY)) || [];
    } catch (e) {
      return [];
    }
  }

  private recentSearchMatchesFavoriteSearch(recentSearch: RecentSearch, favoriteSearch: FavoriteSearch): boolean {
    return (
      favoriteSearch.queryParams[FILTER_QUERY_PARAM_KEY.keywords] === recentSearch.keyword &&
      favoriteSearch.category === (recentSearch.category?.id ? `${recentSearch.category?.id}` : undefined)
    );
  }

  private recentSearchMatchesRecentSearch(search: RecentSearch, compare: RecentSearch): boolean {
    return search.keyword === compare.keyword && search.category?.id === compare.category?.id;
  }

  private setRecentSearches(searches: RecentSearch[]): void {
    this.STORAGE_SYSTEM.setItem(RECENT_SEARCHES_STORAGE_KEY, JSON.stringify(searches));
  }

  private getAllCategoriesName(): string {
    return $localize`:@@web_filters_category_all_categories:All categories`;
  }

  private formatRecentSearch(search: RecentSearch): RecentSearch {
    const formattedSearch = { ...search };
    if (!formattedSearch.category) {
      formattedSearch.category = { id: null, name: this.getAllCategoriesName() };
      return formattedSearch;
    }

    return formattedSearch;
  }
}
