import { getMPDeviceId } from '#analytics/index';
import { ApiOptions } from '@rudderstack/analytics-js/*';
import { APP_VERSION, APP_ID } from '@wallapop/app-version';

export const RUDDERSTACK_ROLLOUT_VERSION = 20250129;

/**
 * We want to send the device id for each event (with other common information and overwrites) but in the JavaScript SDK
 * the `deviceId` it is not a global context value but rather an specific attribute of each event or a trait in the identify call.
 * Since our backend prefers it to not be a trait (less mapping, less complexity), we are going to force these values to be sent in each event
 * Check more here: https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#identify
 */
export const getCommonRudderstackApiOptions = (): ApiOptions => {
  return {
    device: {
      id: getMPDeviceId() || null,
      type: 'web',
    },
    app: {
      name: 'web',
      namespace: '',
      installType: null,
      version: APP_VERSION,
      build: APP_ID,
      rollout: RUDDERSTACK_ROLLOUT_VERSION,
    },
  };
};
