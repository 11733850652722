import { ExperimentatorChipListDto, ExperimentatorChipDataDto } from '../dtos/experimentator-chip-list-response.dto';
import { ExperimentatorChipData, ExperimentatorResponseChipList } from '../interfaces/experimentator-chip-list-response.interface';
import { experimentatorMetaResponseMapper } from './experimentator-meta.mapper';

export const experimentatorChipListResponseMapper = (response: ExperimentatorChipListDto): ExperimentatorResponseChipList => {
  const newData: ExperimentatorChipData[] = response.data.map((data: ExperimentatorChipDataDto) => {
    return {
      title: data.title,
      action: {
        actionType: data.action.action_type,
        payload: {
          linkUrl: data.action.payload.link_url,
        },
      },
    };
  });

  return {
    data: newData,
    meta: experimentatorMetaResponseMapper(response.meta),
  };
};
