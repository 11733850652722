import { ExternalCommsNamespace } from '../typing/namespace/external-comms.namespace';
import { getBrazeSDK } from '../utils/get-braze-sdk';

export const setUserDetails = async (birthDate: Date | number, gender: ExternalCommsNamespace.Genders) => {
  const brazeInstance = await getBrazeSDK();

  const brazeUser = brazeInstance?.getUser();
  const date = new Date(birthDate);
  brazeUser?.setDateOfBirth(date.getFullYear(), date.getMonth() + 1, date.getDate());
  brazeUser?.setGender(gender);
};
