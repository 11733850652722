export const IGNORED_ERRORS: (string | RegExp)[] = [
  // Ignored due to blocked or failed network requests from external resources.
  'Blocked a restricted frame',
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
  'Non-Error promise rejection captured with value:',
  `Cannot read properties of null (reading 'firesTouchEvents')`,

  // Ignored ResizeObserver errors, often from third-party scripts.
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',

  // Ignored errors related to various ad systems that might fail intermittently.
  'richaudience',
  'amazon-adsystem',
  'google.ads.search.Ads',
  'googletag.pubads',

  //Ignored errors related with Google OAuth (GSI_LOGGER).
  '[GSI_LOGGER]: FedCM get() rejects with AbortError: signal is aborted without reason',
  '[GSI_LOGGER]: FedCM get() rejects with NetworkError: Error retrieving a token',
];
