import { FILTER_QUERY_PARAM_KEY } from '@public/shared/components/filters/enums/filter-query-param-key.enum';
import { FavoriteSearchesQueryDto } from '../dtos/favorite-searches-dto';
import { FavoriteSearchesQuery, FavoriteSearchesQueryParam } from '../model/favorite-search.interface';

export function mapQuery(query): FavoriteSearchesQueryParam[] {
  return Object.keys(query).map((param: FILTER_QUERY_PARAM_KEY) => {
    if (param === 'category_ids') {
      return {
        key: param,
        value: query[param][0],
      };
    }
    return { key: param, value: query[param] };
  });
}

export function mapQueryParams(queryParams: FavoriteSearchesQueryDto): FavoriteSearchesQuery {
  const { category_ids, ...paramsWithoutCategory } = queryParams;
  const mappedParams: FavoriteSearchesQuery = { ...paramsWithoutCategory };

  if (queryParams.category_ids) {
    mappedParams.category_ids = queryParams.category_ids[0];
  }

  return mappedParams;
}
