import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CategoryResponseDto } from './category-response.dto.interface';
import { mapCategoriesToUploadCategories } from '@api/categories/mappers/category-mapper';
import { CategoriesApiService } from '@api/categories/categories-api.service';

@Injectable()
export class CategoryService {
  private categories: CategoryResponseDto[];

  constructor(private categoriesApiService: CategoriesApiService) {}

  public getCategoryById(id: number): Observable<CategoryResponseDto> {
    return this.getCategories().pipe(map((categories) => categories.find((category) => category.category_id === id)));
  }

  public getCategories(): Observable<CategoryResponseDto[]> {
    if (this.categories) {
      return of(this.categories);
    }

    return this.categoriesApiService.getCategories().pipe(
      map((categories) => {
        return mapCategoriesToUploadCategories(categories);
      }),
      tap((categories) => (this.categories = categories)),
    );
  }

  public getCategoryIconById(categoryId: number): Observable<string> {
    const iconPath = '/assets/icons/categories/stroke/';
    const defaultIcon = `${iconPath}All.svg`;

    return this.getCategories().pipe(
      map((categories) => {
        const categoryIcon = categories?.find((category) => category.category_id === categoryId)?.icon_id;
        return categoryIcon ? `${iconPath}${categoryIcon}.svg` : defaultIcon;
      }),
      catchError(() => of(defaultIcon)),
    );
  }
}
