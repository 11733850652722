import { ConfirmationModalProperties } from '@shared/confirmation-modal/confirmation-modal.interface';

export enum CONFIRMATION_MODAL_VERIFICATIONS_TYPE {
  VERIFIED_EMAIL,
  NOT_VERIFIED_EMAIL,
}

export const CONFIRMATION_MODAL_PROPERTIES: Record<CONFIRMATION_MODAL_VERIFICATIONS_TYPE, ConfirmationModalProperties> = {
  [CONFIRMATION_MODAL_VERIFICATIONS_TYPE.VERIFIED_EMAIL]: {
    title: $localize`:@@verification_and_security_all_users_reset_password_with_verified_email_modal_title:We'll send you an email to your verified email address so you can change your password`,
    description: $localize`:@@verification_and_security_all_users_reset_password_with_verified_email_modal_body:This way we keep your account secure, ensuring that you are the person who is requesting this change.`,
    confirmMessage: $localize`:@@verification_and_security_all_users_reset_password_with_verified_email_modal_send_button:Send`,
    cancelMessage: $localize`:@@verification_and_security_all_users_reset_password_with_verified_email_modal_cancel_button:Cancel`,
    confirmButtonSubtype: 'wallagreen',
  },
  [CONFIRMATION_MODAL_VERIFICATIONS_TYPE.NOT_VERIFIED_EMAIL]: {
    title: $localize`:@@verification_and_security_all_users_reset_password_with_unverified_email_modal_title:To edit your password you must verify your email address`,
    description: $localize`:@@verification_and_security_all_users_reset_password_with_unverified_email_modal_body:Once verified, request to edit your password again and you will receive an email to do so.`,
    confirmMessage: $localize`:@@verification_and_security_all_users_reset_password_with_unverified_email_modal_verify_button:Verify`,
    cancelMessage: $localize`:@@verification_and_security_all_users_reset_password_with_unverified_email_modal_cancel_button:Cancel`,
    confirmButtonSubtype: 'wallagreen',
  },
};
