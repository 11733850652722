import { Injectable } from '@angular/core';
import { MonitoringService } from '@core/monitoring/services/monitoring.service';

interface DetailedInitError {
  error?: Error | unknown;
  detailedMessage: string;
}

const INIT_TIMEOUT = 10000;

/**
 * Service to store and flush initialization errors during the APP_INITIALIZER phase. Provided as root to act as a singleton
 */
@Injectable({ providedIn: 'root' })
export class AppStatusService {
  private errorsArray: DetailedInitError[] = [];
  private timer: NodeJS.Timeout;
  private hasFinalizedProfiling = false;

  constructor(private monitoringService: MonitoringService) {}

  /**
   * Called in the beginning of the initialization process and sets a timeout with an acceptable amount of time to complete initialization tasks
   */
  public initializeErrorListener() {
    this.timer = setTimeout(() => {
      this.flushErrorsOnCriticalError();
    }, INIT_TIMEOUT);
  }

  /**
   * Called at the end of the initialization process, cancels the timeout and sends errors to Sentry if they exist
   */
  public finalizeErrorListener() {
    clearTimeout(this.timer);
    this.sendErrorsToSentryIfPresent();
  }

  /**
   * To be called when an error is detected in the initialization of any third party that we need to load.
   * It will either be added to the error queue if initialization hasn't ended or send immediately if the process is async and initialization has finished when called.
   * @param detailedMessage string containing a detailed message containing ideally the source of the error
   * @param error conditional error to add more information like the stacktrace
   */
  public addError(detailedMessage: string, error?: Error | unknown): void {
    this.errorsArray.push({
      detailedMessage,
      error,
    });
    if (this.hasFinalizedProfiling) this.sendErrorsToSentryIfPresent();
  }

  /**
   * Getter for the error queue
   */
  public getErrorQueue() {
    return this.errorsArray;
  }

  /**
   * Called when the timeout isn't cancelled in time. This may happen if an unexpected exception is thrown and the app stays in a permanent "loading state".
   * This should alert us when a similar scenario to these Post Mortem happen:
   * https://wallapop.atlassian.net/wiki/spaces/POSTMOREM/pages/3839557636/2024-09-20+-+Safari+18+causing+multiple+issues+on+the+web
   * https://wallapop.atlassian.net/wiki/spaces/POSTMOREM/pages/3075539489/2023-04-17+-+POSTMORTEM+-+Users+with+adblock+can+not+use+webapp
   */
  private flushErrorsOnCriticalError() {
    this.addError(
      `[App Status Service]: Failed to initialize within the expected timeframe of ${INIT_TIMEOUT}`,
      new Error('Internal Timeout'),
    );
    this.sendErrorsToSentryIfPresent();
  }

  /**
   * Generic method to call Sentry if an error is detected
   */
  private sendErrorsToSentryIfPresent() {
    this.hasFinalizedProfiling = true;

    const errors = this.getErrorQueue();
    if (errors.length === 0) return;

    const errorDictionary = errors.reduce((acc, error: DetailedInitError) => {
      acc[error.detailedMessage] = error.error;
      return acc;
    }, {});

    this.monitoringService.captureMessage('[WEBAPP-INITIALIZATION]: Some errors occurred while initializing the webapp', {
      extra: errorDictionary,
    });
  }
}
