export enum CAR_SPECIFICATION_TYPE {
  SEATS = 'seats',
  COUPE = 'coupe_cabrio',
  FAMILY = 'family_car',
  MINI_VAN = 'mini_van',
  SEDAN = 'sedan',
  SMALL = 'small_car',
  VAN = 'van',
  FXF = '4X4',
  FOUR_X_FOUR = 'four_x_four',
  OTHERS = 'other',
  TWO_DOORS = 'two_doors',
  THREE_DOORS = 'three_doors',
  FOUR_DOORS = 'four_doors',
  FIVE_DOORS = 'five_doors',
  HORSEPOWER = 'horsepower',
  ELECTRIC = 'electric-hybrid',
  ELECTRIC_HYBRID = 'electric_hybrid',
  GASOIL = 'gasoil',
  GASOLINE = 'gasoline',
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}
