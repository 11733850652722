import { Injectable } from '@angular/core';

import { ApiLayerService } from '@core/api-layer/api-layer.service';
import { fetchExperimentator } from '@wallapop/api-layer/apis/experimentator/experimentator-api.service';
import { ExperimentatorResponseTypes } from '@wallapop/api-layer/apis/experimentator/interfaces/experimentator-response.interface';
import { Observable, from } from 'rxjs';

interface FetchExperimentatorProps {
  initiative: string;
  itemId?: string;
  categoryId?: string;
  keyword?: string;
}

@Injectable()
export class ExperimentatorApiService {
  constructor(private apiLayer: ApiLayerService) {}

  public getExperimentator(props: FetchExperimentatorProps): Observable<ExperimentatorResponseTypes> {
    return from(fetchExperimentator({ httpClient: this.apiLayer.httpClient, ...props }));
  }
}
