import { Injectable } from '@angular/core';
import { TermsNComms } from '@api/core/model/terms-n-comms/terms-n-comms.interface';
import { Observable } from 'rxjs';
import { TermsNCommsBodyRequest } from './dtos/request/terms-n-comms-request.interface';
import { TermsNCommsHttpService } from './http/terms-n-comms-http.service';

@Injectable()
export class TermsNCommsApiService {
  constructor(private httpService: TermsNCommsHttpService) {}

  public updateTermsAndComms(termsNComms: TermsNComms): Observable<void> {
    const body: TermsNCommsBodyRequest = {
      accepted_comms: termsNComms.comms,
      accepted_terms: termsNComms.terms,
    };

    return this.httpService.updateTermsAndComms(body);
  }
}
