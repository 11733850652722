import { Inject, Injectable } from '@angular/core';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { GEOEDGE_API_KEY } from './geoedge.constants';

@Injectable({ providedIn: 'root' })
export class GeoedgeService {
  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  public init(): void {
    this.setGeoedgeApiKey();
  }

  private setGeoedgeApiKey(): void {
    this.window['grumi'] = {
      key: GEOEDGE_API_KEY,
    };
  }
}
