export enum PUBLIC_PATHS {
  HOME = '',
  LOGIN = 'login',
  REGISTER = 'register',
  ITEM_DETAIL = 'item',
  SEARCH = 'search',
  SEARCH_UNIFIED = 'search/u',
  USER_DETAIL = 'user',
  PROCESS_COMPLETE = 'process-complete',
  NOT_FOUND = '404',
  MEMBER_GET_MEMBER = 'member-get-member',
  MULTI_FACTOR = 'multi-factor',
  THANKS = 'thanks',
  ADS_TEST_PAGE = 'ads-test',
  LEGAL_INFORMATION = 'legal-information',
  PAYMENT_FINISHED = 'payment-finished',
  WRAPPED = 'wrapped',
  DIMASIADO = 'dimasiado',
}

export const NON_PARITY_URLS = [PUBLIC_PATHS.MEMBER_GET_MEMBER, PUBLIC_PATHS.WRAPPED];

export enum PUBLIC_PATH_PARAMS {
  ID = 'id',
  WEBSLUG = 'webSlug',
  PUBLIC_FORM_TOKEN = 'publicFormToken',
}

export const PATH_TO_MULTI_FACTOR: string = `${PUBLIC_PATHS.MULTI_FACTOR}`;
