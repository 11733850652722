const RELEVANT_YIELD_CONFIG_ID = '662a2001ff51e9eeea02622c';

export const RELEVANT_YIELD_CONFIG = {
  configId: RELEVANT_YIELD_CONFIG_ID,
  manageAdserver: false,
  collapseEmptyDivs: true,
  collapseBeforeAdFetch: false,
  allowedDivIds: null,
  noSlotReload: false,
  noGpt: true,
};
