import { TRANSLATION_KEY } from '../enum/translation-keys.enum';
import { Translations } from '../interfaces/translations.interface';

export const translations: Translations = {
  [TRANSLATION_KEY.YES]: $localize`:@@web_yes:Yes`,
  [TRANSLATION_KEY.NO]: $localize`:@@web_no:No`,
  [TRANSLATION_KEY.MALE]: $localize`:@@web_male:Male`,
  [TRANSLATION_KEY.FEMALE]: $localize`:@@web_female:Female`,
  [TRANSLATION_KEY.CONSUMER_GOODS_GENERAL_CATEGORY_TITLE]: $localize`:@@web_consumer_goods_category_name:Everything else`,
  [TRANSLATION_KEY.CHAT_DESKTOP_NOTIFICATION_TITLE]: $localize`:@@web_chat_desktop_notification:New message from`,
  [TRANSLATION_KEY.CHAT_MY_PHONE_NUMBER]: $localize`:@@web_chat_my_phone_number:My phone number is`,
  [TRANSLATION_KEY.CHAT_ARCHIVE_CONVERSATION_SUCCESS]: $localize`:@@web_chat_archive_conversation_success:The conversation has been archived correctly`,
  [TRANSLATION_KEY.CHAT_UNARCHIVE_CONVERSATION_SUCCESS]: $localize`:@@web_chat_unarchive_conversation_success:The conversation has been unarchived correctly`,
  [TRANSLATION_KEY.CHAT_REPORT_USER_SUCCESS]: $localize`:@@web_chat_report_user_success:The user has been reported correctly`,
  [TRANSLATION_KEY.CHAT_REPORT_LISTING_SUCCESS]: $localize`:@@web_chat_report_listing_success:The listing has been reported correctly`,
  [TRANSLATION_KEY.CHAT_BLOCK_USER_SUCCESS]: $localize`:@@web_chat_block_user_success:The user has been blocked`,
  [TRANSLATION_KEY.CHAT_UNBLOCK_USER_SUCCESS]: $localize`:@@web_chat_unblock_user_success:The user has been unblocked`,
  [TRANSLATION_KEY.CHAT_INPUT_ENABLED_PLACEHOLDER]: $localize`:@@web_chat_input_enabled_placeholder:Write a message...`,
  [TRANSLATION_KEY.CHAT_INPUT_DISABLED_PLACEHOLDER]: $localize`:@@web_chat_input_disabled_placeholder:Conversation disabled`,
  [TRANSLATION_KEY.CHAT_TOO_MANY_CONVERSATIONS]: $localize`:@@web_chat_too_many_conversations:We can't keep up with you. Wait a moment so you can open up new conversations.`,
  [TRANSLATION_KEY.ACTIVE]: $localize`:@@web_catalog_active:Active`,
  [TRANSLATION_KEY.INACTIVE]: $localize`:@@web_catalog_inactive:Inactive`,
  [TRANSLATION_KEY.SOLD]: $localize`:@@web_catalog_sold:Sold`,
  [TRANSLATION_KEY.PUBLISHED]: $localize`:@@web_catalog_published:Selling`,
  [TRANSLATION_KEY.REVIEWS]: $localize`:@@web_catalog_reviews:Reviews`,
  [TRANSLATION_KEY.SEARCH_BY_TITLE]: $localize`:@@web_catalog_filter_input:Search by title`,
  [TRANSLATION_KEY.DATE_DESC]: $localize`:@@pro_catalog_manager_filters_dropdown_older_web_specific:Date: from recent to old`,
  [TRANSLATION_KEY.DATE_ASC]: $localize`:@@pro_catalog_manager_filters_dropdown_recent_web_specific:Date: from old to recent`,
  [TRANSLATION_KEY.PRICE_DESC]: $localize`:@@pro_catalog_manager_filters_dropdown_higher_price_web_specific:Price: from high to low`,
  [TRANSLATION_KEY.PRICE_ASC]: $localize`:@@pro_catalog_manager_filters_dropdown_lower_price_web_specific:Price: from low to high`,
  [TRANSLATION_KEY.TITLE_DESC]: $localize`:@@pro_catalog_manager_filters_dropdown_alphabetically_web_specific:Name: from A to Z`,
  [TRANSLATION_KEY.TITLE_ASC]: $localize`:@@pro_catalog_manager_filters_dropdown_alphabetically_reversed_web_specific:Name: from Z to A`,
  [TRANSLATION_KEY.STATS_GRAPH_NO_DATA]: $localize`:@@web_stats_graph_no_data:No data available`,
  [TRANSLATION_KEY.STATS_GRAPH_PHONES_SHARED]: $localize`:@@web_stats_graph_phones_shared:Shared phones`,
  [TRANSLATION_KEY.STATS_GRAPH_CITY_BUMP]: $localize`:@@web_stats_graph_city_bump:City Bump`,
  [TRANSLATION_KEY.STATS_GRAPH_COUNTRY_BUMP]: $localize`:@@web_stats_graph_:Country Bump`,
  [TRANSLATION_KEY.STATS_GRAPH_VIEWS]: $localize`:@@web_stats_graph_views:Views`,
  [TRANSLATION_KEY.STATS_GRAPH_CHATS]: $localize`:@@web_stats_graph_chats:Chats`,
  [TRANSLATION_KEY.STATS_GRAPH_LAST_THIRTY_DAYS]: $localize`:@@web_stats_graph_last_thirty_days:Last 30 days`,
  [TRANSLATION_KEY.STATS_GRAPH_LAST_FIFTEEN_DAYS]: $localize`:@@web_stats_graph_last_fifteen_days:Last 15 days`,
  [TRANSLATION_KEY.STATS_GRAPH_LAST_SEVEN_DAYS]: $localize`:@@web_stats_graph_last_seven_days:Last 7 days`,
  [TRANSLATION_KEY.TOAST_ERROR_TITLE]: $localize`:@@web_toast_error_title:Oops!`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CANCEL_SUCCESS_TITLE]: $localize`:@@web_pro_subscription_cancel_success_title:Subscription cancelled.`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CANCEL_SUCCESS_BODY]: $localize`:@@web_pro_subscription_cancel_success_body:We are sad to see you go.`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CANCEL_ERROR_TITLE]: $localize`:@@web_pro_subscription_cancel_error_title:There was an error`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CANCEL_ERROR_BODY]: $localize`:@@web_pro_subscription_cancel_error_body:Your subscription could not be cancelled.`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CONTINUE_SUCCESS_TITLE]: $localize`:@@web_pro_subscription_continue_success_title:Success`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CONTINUE_SUCCESS_BODY]: $localize`:@@web_pro_subscription_continue_success_body:Your subscription is active again`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CONTINUE_ERROR_TITLE]: $localize`:@@web_pro_subscription_continue_error_title:There was an error`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CONTINUE_ERROR_BODY]: $localize`:@@web_pro_subscription_continue_error_body:We could not proceed with your request.`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_EDIT_SUCCESS_TITLE]: $localize`:@@web_pro_subscription_edit_success_title:Great!`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_EDIT_SUCCESS_BODY]: $localize`:@@web_pro_subscription_edit_success_body:Your subscription has been edited correctly.`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_EDIT_ERROR_TITLE]: $localize`:@@web_pro_subscription_edit_error_title:There was an error`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_EDIT_ERROR_BODY]: $localize`:@@web_pro_subscription_edit_error_body:Your subscription could not be edited.`,
  [TRANSLATION_KEY.WALLAPOP_PRO]: $localize`:@@web_wallapop_pro:Wallapop PRO`,
  [TRANSLATION_KEY.BECOME_PRO]: $localize`:@@web_become_pro:Become a PRO`,
  [TRANSLATION_KEY.STATS_FAVORITES]: $localize`:@@web_stats_favorites:Favorites`,
  [TRANSLATION_KEY.STATS_VIEWS]: $localize`:@@web_stats_views:Views`,
  [TRANSLATION_KEY.STATS_CHATS]: $localize`:@@web_stats_chats:Chats`,
  [TRANSLATION_KEY.DAY]: $localize`:@@web_day:day`,
  [TRANSLATION_KEY.HOUR]: $localize`:@@web_hour:hour`,
  [TRANSLATION_KEY.MINUTE]: $localize`:@@web_minute:minute`,
  [TRANSLATION_KEY.LEFT]: $localize`:@@web_left:left`,
  [TRANSLATION_KEY.CARD_NUMBER_INVALID]: $localize`:@@web_card_number_invalid:Card number isn't valid.`,
  [TRANSLATION_KEY.CARD_DATE_INVALID]: $localize`:@@web_card_date_invalid:Card date isn't valid.`,
  [TRANSLATION_KEY.CARD_CVC_INVALID]: $localize`:@@web_card_cvc_invalid:CVC number isn't valid.`,
  [TRANSLATION_KEY.NO_RESULTS_FOUND]: $localize`:@@web_no_results_found:No results found`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CARD_SET]: $localize`:@@web_pro_subscription_card_set:Your card has been saved successfully`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CARD_DELETED]: $localize`:@@web_pro_subscription_card_deleted:Your card has been deleted`,
  [TRANSLATION_KEY.PRO_SUBSCRIPTION_CARD_DELETED_ERROR]: $localize`:@@web_pro_subscription_card_deleted_error:Your card could not be deleted`,
  [TRANSLATION_KEY.SEARCH_INPUT_PLACEHOLDER]: $localize`:@@web_search_input_placeholder:Search items`,
  [TRANSLATION_KEY.DEFAULT_ERROR_MESSAGE]: $localize`:@@web_default_error_message:Service unavailable temporarily. Try it later`,
  [TRANSLATION_KEY.TOAST_DEFAULT_SUCCESS_TITLE]: $localize`:@@web_toast_default_success_title:Yup!`,
  [TRANSLATION_KEY.TOAST_PRODUCT_CREATED]: $localize`:@@web_bump_suggestion_modal_title:Great! Now your item is on Wallapop`,
  [TRANSLATION_KEY.SUBSCRIPTION_CARD_NOT_SET_ERROR]: $localize`:@@web_subscription_card_not_set_error:Your card could not be saved`,
  [TRANSLATION_KEY.STRIPE_CARDS_RETRIEVAL_ERROR]: $localize`:@@web_stripe_cards_retrieval_error:Your cards could not be retrieved`,
  [TRANSLATION_KEY.NO_CARD_SELECTED_ERROR]: $localize`:@@web_no_card_selected_error:Please select a credit card`,
  [TRANSLATION_KEY.ADD_NEW_CARD_ERROR]: $localize`:@@web_new_card_error:This card could not be added`,
  [TRANSLATION_KEY.BUMP_ERROR]: $localize`:@@web_bump_error:Some listings have not been bumped due to an error`,
  [TRANSLATION_KEY.PRODUCT_CREATED]: $localize`:@@web_product_created:The product has been created successfully!`,
  [TRANSLATION_KEY.ALREADY_FEATURED_ERROR]: $localize`:@@web_already_featured_error:You are trying to feature an item that is already planned.`,
  [TRANSLATION_KEY.RESERVED_ITEM_CANNOT_BE_BUMPED]: $localize`:@@bump_reserved_item_pro_user_snackbar:A reserved item cannot be highlighted. Cancel the reservation to highlight it.`,
  [TRANSLATION_KEY.FORM_FIELD_ERROR_TITLE]: $localize`:@@web_form_field_error_title:Oops!`,
  [TRANSLATION_KEY.FORM_FIELD_ERROR]: $localize`:@@web_form_field_error:Check the red fields`,
  [TRANSLATION_KEY.BULK_DELETE_ERROR]: $localize`:@@web_bulk_delete_error:Some listings have not been deleted due to an error`,
  [TRANSLATION_KEY.BULK_STOCK_DELETE_ERROR]: $localize`:@@stock_management_upload_Pro_feedback_error_toast_delete_all_items_body:We were not able to delete all items. You can deactivate them, instead.`,
  [TRANSLATION_KEY.BULK_RESERVE_ERROR]: $localize`:@@web_bulk_reserve_error:Some listings have not been reserved due to an error`,
  [TRANSLATION_KEY.INVOICE_CANNOT_GENERATE_ERROR]: $localize`:@@web_invoice_cannot_generate_error:Your invoice could not be generated. Please try again`,
  [TRANSLATION_KEY.INVOICE_CANNOT_DOWNLOAD_ERROR]: $localize`:@@web_invoice_cannot_download_error:Your invoice could not be downloaded. Please try again`,
  [TRANSLATION_KEY.PAYMENT_FAILED_ERROR_TITLE]: $localize`:@@web_payment_failed_error_title:Something went wrong`,
  [TRANSLATION_KEY.PAYMENT_FAILED_ERROR]: $localize`:@@web_payment_failed_error:Check your card details.`,
  [TRANSLATION_KEY.PAYMENT_FAILED_UNKNOWN_ERROR]: $localize`:@@web_payment_failed_unknown_error:For some reason, the payment couldn't be processed. Please try again.`,
  [TRANSLATION_KEY.MISSING_IMAGE_ERROR]: $localize`:@@web_missing_image_error:You should upload at least one image`,
  [TRANSLATION_KEY.PHONE_NUMBER_ERROR]: $localize`:@@web_phone_number_error:The phone number has an invalid format`,
  [TRANSLATION_KEY.SERVER_ERROR]: $localize`:@@web_server_error:Server error:`,
  [TRANSLATION_KEY.PACK_ERROR]: $localize`:@@web_pack_error:There was a error buying this pack`,
  [TRANSLATION_KEY.LINK_ERROR]: $localize`:@@web_link_error:The web link is not valid`,
  [TRANSLATION_KEY.DELETE_ITEM_ERROR]: $localize`:@@web_delete_item_error:There was an error deleting your product`,
  [TRANSLATION_KEY.EDIT_ITEM_ERROR]: $localize`:@@web_edit_item_error:This item can not be modified because has a pending transaction.`,
  [TRANSLATION_KEY.INVALID_PASSWORD_ERROR]: $localize`:@@web_invalid_password_error:Current password is not valid`,
  [TRANSLATION_KEY.DELETE_BILLING_INFO_ERROR]: $localize`:@@web_delete_billing_info_error:There was a error deleting your billing info`,
  [TRANSLATION_KEY.PASSWORD_MIN_LENGTH_ERROR]: $localize`:@@web_password_min_length_error:Password length should be at least 8 characters`,
  [TRANSLATION_KEY.PASSWORD_DO_NOT_MATCH_ERROR]: $localize`:@@web_password_do_not_match_error:Passwords don't match`,
  [TRANSLATION_KEY.UPLOAD_EXTENSION_NOT_ALLOWED_ERROR]: $localize`:@@web_upload_extension_not_allowed_error:You tried to upload an inadequate format. Only photos in JPG or JPEG formats are accepted`,
  [TRANSLATION_KEY.UPLOAD_MAX_UPLOADS_ERROR]: $localize`:@@web_upload_max_uploads_error:This file exceeds the limit of photos:`,
  [TRANSLATION_KEY.UPLOAD_MAX_SIZE_ERROR]: $localize`:@@web_upload_max_size_error:This file exceeds the limit of size:`,
  [TRANSLATION_KEY.ITEM_UPDATED]: $localize`:@@web_item_updated:The item has been updated correctly`,
  [TRANSLATION_KEY.INVOICE_GENERATED]: $localize`:@@web_invoice_generated:Your invoice has been successfully generated`,
  [TRANSLATION_KEY.INVOICE_DOWNLOADED]: $localize`:@@web_invoice_downloaded:Your invoice has been successfully downloaded`,
  [TRANSLATION_KEY.USER_EDITED]: $localize`:@@web_user_edited:Your data has been edited correctly`,
  [TRANSLATION_KEY.IMAGE_UPLOADED]: $localize`:@@web_image_uploaded:Image uploaded!`,
  [TRANSLATION_KEY.SUGGESTED_CATEGORY]: $localize`:@@web_suggested_category:It seems that your product belongs to another category, so we’ve changed it.`,
  [TRANSLATION_KEY.DELETE_BILLING_INFO]: $localize`:@@web_delete_billing_info:Your billing info has been deleted`,
  [TRANSLATION_KEY.TO_CLEAN_DEFAULT_ERROR_MESSAGE]: $localize`:@@web_to_clean_default_error_message:Service not available at this moment. Try again later`,
  [TRANSLATION_KEY.TO_CLEAN_BULK_SOLD_ERROR]: $localize`:@@web_to_clean_bulk_sold_error:Some listings have not been set as sold due to an error`,
  [TRANSLATION_KEY.TO_CLEAN_PHONE_CALLS]: $localize`:@@web_to_clean_phone_calls:Phone calls`,
  [TRANSLATION_KEY.TO_CLEAN_PHONES]: $localize`:@@web_to_clean_phones:Phones`,
  [TRANSLATION_KEY.TO_CLEAN_MEETINGS]: $localize`:@@web_to_clean_meetings:Meetings`,
  [TRANSLATION_KEY.TO_CLEAN_MESSAGES]: $localize`:@@web_to_clean_messages:Messages`,
  [TRANSLATION_KEY.TO_CLEAN_SHARED_PHONE]: $localize`:@@web_to_clean_shared:Shared Phone`,
  [TRANSLATION_KEY.TO_CLEAN_MISSED_CALL]: $localize`:@@web_to_clean_missed_call:Missed Call`,
  [TRANSLATION_KEY.TO_CLEAN_ANSWERED_CALL]: $localize`:@@web_to_clean_answered_call:Call`,
  [TRANSLATION_KEY.TO_CLEAN_FEATURED]: $localize`:@@web_to_clean_featured:Featured`,
  [TRANSLATION_KEY.TO_CLEAN_ERROR_PURCHASING_ITEMS]: $localize`:@@web_to_clean_error_purchasing_items:It was not possible to feature these products:`,
  [TRANSLATION_KEY.TO_CLEAN_SETTINGS_EDITED]: $localize`:@@web_to_clean_settings_edited:Your setting has been updated`,
  [TRANSLATION_KEY.TO_CLEAN_TWITTER_SHARE]: $localize`:@@web_to_clean_twitter_share:Look what I found on @Wallapop:`,
  [TRANSLATION_KEY.TO_CLEAN_COUNTRY_BUMP]: $localize`:@@web_to_clean_country_bump:Country Bump`,
  [TRANSLATION_KEY.TO_CLEAN_ZONE_BUMP]: $localize`:@@web_to_clean_zone_bump:City Bump`,
  [TRANSLATION_KEY.TO_CLEAN_CITY_BUMP]: $localize`:@@web_to_clean_city_bump:City Bump`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_TUTORIAL_LISTING_LIMIT_5]: $localize`:@@web_to_clean_cars_tutorial_listing_limit_5:Upload 5 cars.`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_TUTORIAL_LISTING_LIMIT_9]: $localize`:@@web_to_clean_cars_tutorial_listing_limit_9:Upload 9 cars.`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_TUTORIAL_LISTING_LIMIT_15]: $localize`:@@web_to_clean_cars_tutorial_listing_limit_15:Upload 15 cars.`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_TUTORIAL_DESCRIPTION_2]: $localize`:@@web_to_clean_cars_tutorial_description_2:Buyers can save your shop/profile as a favorite.`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_TUTORIAL_DESCRIPTION_3]: $localize`:@@web_to_clean_cars_tutorial_description_3:You will have a different design and will stand out!`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_TUTORIAL_PHONE_ON_CAR]: $localize`:@@web_to_clean_cars_tutorial_phone_on_car:Your phone will appear in each car you have listed, so more buyers will contact you.`,
  [TRANSLATION_KEY.TO_CLEAN_PRO_TUTORIAL_EXTRA_PHONE_WEB]: $localize`:@@web_to_clean_pro_tutorial_extra_fields_phone_web:Buyers will be able to see your phone and website in your profile for easy contact.`,
  [TRANSLATION_KEY.TO_CLEAN_PRO_TUTORIAL_EXTRA_DIRECTION_DESCRIPTION]: $localize`:@@web_to_clean_pro_tutorial_extra_fields_direction_description:You will have a description of your store or service and its location.`,
  [TRANSLATION_KEY.TO_CLEAN_PRO_TUTORIAL_NO_EXPIRE]: $localize`:@@web_to_clean_pro_tutorial_no_expire:Your items won't expire.`,
  [TRANSLATION_KEY.TO_CLEAN_BRAND]: $localize`:@@web_to_clean_brand:Brand`,
  [TRANSLATION_KEY.TO_CLEAN_MODEL]: $localize`:@@web_to_clean_model:Model`,
  [TRANSLATION_KEY.TO_CLEAN_PHONES_BRAND_EXAMPLE]: $localize`:@@web_to_clean_phones_brand_example:E.g: Apple`,
  [TRANSLATION_KEY.TO_CLEAN_FASHION_BRAND_EXAMPLE]: $localize`:@@web_to_clean_fashion_brand_example:E.g: Zara`,
  [TRANSLATION_KEY.TO_CLEAN_MODEL_EXAMPLE]: $localize`:@@web_to_clean_model_example:E.g: iPhone`,
  [TRANSLATION_KEY.TO_CLEAN_REVIEWS]: $localize`:@@web_to_clean_reviews:Reviews`,
  [TRANSLATION_KEY.TO_CLEAN_ERROR_SAVING_DATA]: $localize`:@@web_to_clean_error_saving_data:Error while saving data. Please try again`,
  [TRANSLATION_KEY.DELETE_ITEMS_TITLE]: $localize`:@@web_modal_confirmation_625:Delete items`,
  [TRANSLATION_KEY.DELETE_ITEMS_DESCRIPTION]: $localize`:@@web_modal_confirmation_626:The selected items will be permanently deleted.`,
  [TRANSLATION_KEY.DELETE_BUTTON]: $localize`:@@web_modal_confirmation_689:Delete`,
  [TRANSLATION_KEY.REMOVE_FEATURED_PROFILE_FAVORITE_TITLE]: $localize`:@@web_modal_confirmation_488:Remove featured profile from favorites`,
  [TRANSLATION_KEY.REMOVE_FEATURED_PROFILE_FAVORITE_DESCRIPTION]: $localize`:@@web_modal_confirmation_489:The profile will be removed from favorites.`,
  [TRANSLATION_KEY.REMOVE_BUTTON]: $localize`:@@web_modal_confirmation_492:Remove`,
  [TRANSLATION_KEY.PROFILE_NO_LONGER_FEATURED_TITLE]: $localize`:@@web_modal_confirmation_490:This profile is no longer featured`,
  [TRANSLATION_KEY.PROFILE_NO_LONGER_FEATURED_DESCRIPTION]: $localize`:@@web_modal_confirmation_491:If you remove it you won't be able to save it again.`,
  [TRANSLATION_KEY.REMOVE_ITEM_FROM_FAVORITES_TITLE]: $localize`:@@web_modal_confirmation_484:Remove item from favorites`,
  [TRANSLATION_KEY.REMOVE_ITEM_FROM_FAVORITES_DESCRIPTION]: $localize`:@@web_modal_confirmation_485:The item will be removed from favorites.`,
  [TRANSLATION_KEY.DELETE_CARD_TITLE]: $localize`:@@web_modal_confirmation_486:Delete card`,
  [TRANSLATION_KEY.DELETE_CARD_DESCRIPTION]: $localize`:@@web_modal_confirmation_487:Are you sure you want to delete the card saved?`,
  [TRANSLATION_KEY.I_AM_SURE_CONFIRMATION]: $localize`:@@web_modal_confirmation_493:Yes, I am sure`,
  [TRANSLATION_KEY.RESERVE_ITEMS_TITLE]: $localize`:@@web_modal_confirmation_482:Reserve items`,
  [TRANSLATION_KEY.RESERVE_ITEMS_DESCRIPTION]: $localize`:@@web_modal_confirmation_483:The selected items will be reserved.`,
  [TRANSLATION_KEY.RESERVE_BUTTON]: $localize`:@@web_modal_confirmation_670:Reserve`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_ENGINE_GASOIL]: $localize`:@@web_gasoil:Gasoil`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_ENGINE_GASOLINE]: $localize`:@@web_gasoline:Gasoline`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_ENGINE_ELECTRIC_HYBRID]: $localize`:@@web_electric:Electric`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_ENGINE_OTHERS]: $localize`:@@web_others:Others`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_GEARBOX_MANUAL]: $localize`:@@web_manual:Manual`,
  [TRANSLATION_KEY.TO_CLEAN_CARS_GEARBOX_AUTOMATIC]: $localize`:@@web_automatic:Automatic`,
  [TRANSLATION_KEY.CANCEL_BUTTON]: $localize`:@@web_cancel_button:Cancel`,
  [TRANSLATION_KEY.FINDING_MISSING_WEIGHT_ERROR]: $localize`:@@upload_shipping_toggle_snackbar_select_weight_description:To enable this item to be sent, select its weight range.`,
  [TRANSLATION_KEY.FINDING_UPLOAD_TITLE_NOT_ALLOWED_LINK_ERROR]: $localize`:@@upload_item_general_info_view_snackbar_title_without_links_error_message:The title cannot contain links to websites.`,
  [TRANSLATION_KEY.FINDING_UPLOAD_DESCRIPTION_NOT_ALLOWED_LINK_ERROR]: $localize`:@@upload_item_general_info_view_snackbar_description_without_links_error_message:The description cannot contain links to websites.`,

  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_CATALOG_SECTION_TITLE]: $localize`:@@notifications_view_all_users_catalog_section_title:My products`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_CATALOG_SECTION_DESCRIPTION]: $localize`:@@notifications_view_all_users_catalog_section_description:Get notified if any of your products expire. You can republish it!`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_CATALOG_SECTION_ITEMS_EXPIRED_LABEL]: $localize`:@@notifications_view_all_users_catalog_section_items_expired_label:Expired products`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_SAVED_SEARCHES_SECTION_TITLE]: $localize`:@@notifications_view_all_users_saved_searches_section_title:My searches`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_SAVED_SEARCHES_SECTION_DESCRIPTION]: $localize`:@@notifications_view_all_users_saved_searches_section_description:Get notified when new products fit your search criteria.`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_SAVED_SEARCHES_SECTION_ALERTS_LABEL]: $localize`:@@notifications_view_all_users_saved_searches_section_alerts_label:Search alerts`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_TITLE]: $localize`:@@notifications_view_all_users_favorites_section_title:My favorites`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_DESCRIPTION]: $localize`:@@notifications_view_all_users_favorites_section_description:Get notified about any news regarding your favorite products and sellers.`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_PRICE_DROPS_LABEL]: $localize`:@@notifications_view_all_users_favorites_section_price_drops_label:Price drop`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_FAVORITES_SOLD_LABEL]: $localize`:@@notifications_view_all_users_favorites_section_favorites_sold_label:Favorites sold`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_FAVORITES_RESERVED_LABEL]: $localize`:@@notifications_view_all_users_favorites_section_favorites_reserved_label:Favorites reserved`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_FAVORITES_SECTION_NEW_ITEMS_LABEL]: $localize`:@@notifications_view_all_users_favorites_section_new_items_label:New products`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_TITLE]: $localize`:@@notifications_view_all_users_additional_options_section_title:Additional options`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_DESCRIPTION]: $localize`:@@notifications_view_all_users_additional_options_section_description:Get notified about promotions and other contents of your interest. `,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_TIPS_SUGGESTIONS_LABEL]: $localize`:@@notifications_view_all_users_additional_options_section_tips_suggestions_label:Tips & suggestions`,
  [TRANSLATION_KEY.NOTIFICATIONS_VIEW_ALL_USERS_ADDITIONAL_OPTIONS_SECTION_PROMOS_NEWS_LABEL]: $localize`:@@notifications_view_all_users_additional_options_section_promos_news_label:Promotions & new features`,
  [TRANSLATION_KEY.ACCEPT_SCREEN_REJECT_REQUEST_MODAL_TITLE]: $localize`:@@accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_title:Do you confirm that you won't ship the product?`,
  [TRANSLATION_KEY.ACCEPT_SCREEN_REJECT_REQUEST_MODAL_DESCRIPTION]: $localize`:@@accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_description:If you confirm, the sale will be cancelled and your product will be back on sale.`,
  [TRANSLATION_KEY.ACCEPT_SCREEN_REJECT_REQUEST_MODAL_CONTINUE_BUTTON]: $localize`:@@accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_continue_button:Confirm`,
  [TRANSLATION_KEY.ACCEPT_SCREEN_REJECT_REQUEST_MODAL_BACK_BUTTON]: $localize`:@@accept_view_seller_reject_sale_system_modal_buy_flow_test_variant_b_back_button:Back`,
  [TRANSLATION_KEY.ACCEPT_SCREEN_SCHEDULES_GET_ERROR]: $localize`:@@accept_view_seller_all_all_snackbar_shcedules_loading_error:We're getting trouble loading the schedules. To change the time slot, try again.`,
  [TRANSLATION_KEY.ACCEPT_SCREEN_SCHEDULES_SAVE_ERROR]: $localize`:@@accept_view_seller_all_all_snackbar_schedules_save_error:Oops! We couldn't save your selection. Try again.`,
  [TRANSLATION_KEY.DELIVERY_MAP_GENERIC_ERROR]: $localize`:@@map_view_all_users_all_all_snackbar_generic_error:Sorry, something went wrong. Try again.`,
  [TRANSLATION_KEY.DELIVERY_MAP_LOCATION_POP_UP_DESCRIPTION]: $localize`:@@map_view_all_users_all_all_no_user_location_pop_up_description:To select a point on the map, you must first add your address.`,
  [TRANSLATION_KEY.DELIVERY_MAP_LOCATION_POP_UP_ADD_BUTTON]: $localize`:@@map_view_all_users_all_all_no_user_location_pop_up_add_button:Add`,
  [TRANSLATION_KEY.DELIVERY_MAP_LOCATION_POP_UP_CANCEL_BUTTON]: $localize`:@@map_view_all_users_all_all_no_user_location_pop_up_cancel_button:Cancel`,
  [TRANSLATION_KEY.FAVORITES_VIEW_TAB_SEARCHES_ALL_CATEGORIES_LABEL_WEB_SPECIFIC]: $localize`:@@favorites_view_tab_searches_all_categories_label_web_specific:All Categories`,
  [TRANSLATION_KEY.UPLOAD_PRODUCT_MISSING_ITEM_SIZE_TITLE_ERROR]: $localize`:@@upload_item_general_info_view_snackbar_missing_item_size_title_web_specific:Choose a size`,
  [TRANSLATION_KEY.UPLOAD_PRODUCT_MISSING_ITEM_SIZE_DESCRIPTION_ERROR]: $localize`:@@upload_item_general_info_view_snackbar_missing_item_size_description_web_specific:It's a needed detail to activate the shipping option.`,
  [TRANSLATION_KEY.UPLOAD_PRODUCT_MIN_MEASURE_ERROR]: $localize`:@@bulky_items_seller_product_measuraments_minimum_size_required_error:The measurement of a bulky item can't be less than 5 cm.`,
  [TRANSLATION_KEY.UPLOAD_PRODUCT_MISSING_ALL_MEASURES]: $localize`:@@upload_item_general_info_view_snackbar_missing_all_measurements_description_web_specific:If you want to include measurements, fill in all 3 fields. If not, don't fill in any of them.`,
  [TRANSLATION_KEY.BUMP_CANT_BE_BUMPED_BECAUSE_IS_IN_MODERATION_TEXT]: $localize`:@@bump_selector_view_item_not_published_error_modal_pro_user_descripton_1_part:Your item can't be featured because it is currently not published. This may be due to moderation matters. Please try again later.`,
  [TRANSLATION_KEY.BUMP_CANT_BE_BUMPED_BECAUSE_IS_IN_MODERATION_TITLE]: $localize`:@@bump_selector_view_item_not_published_error_modal_pro_user_title:We were unable to feature your item`,
  [TRANSLATION_KEY.BUMP_CANT_BE_BUMPED_GENERIC_ERROR_TITLE]: $localize`:@@bump_selector_view_generic_error_modal_pro_user_title:Your item couldn't be highlighted`,
  [TRANSLATION_KEY.BUMP_CANT_BE_BUMPED_GENERIC_ERROR_TEXT]: $localize`:@@bump_selector_view_generic_error_modal_pro_user_descripton_1_part:Due to an error, the process has failed. Please try again.`,
};
