import { Injectable } from '@angular/core';
import { Snackbar } from '../interfaces/snackbar.interface';
import { DEFAULT_SNACKBAR } from '../constants/default-snackbar';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SNACKBAR_TYPES } from '../constants/snackbar-types';
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackbarsSubject: BehaviorSubject<Snackbar[]> = new BehaviorSubject([]);

  public get snackbars$(): Observable<Snackbar[]> {
    return this.snackbarsSubject.asObservable();
  }

  public get snackbars(): Snackbar[] {
    return this.snackbarsSubject.value;
  }

  public show(snackbar: Snackbar): void {
    const newSnackbars: Snackbar[] = this.snackbars;
    newSnackbars.push({ ...DEFAULT_SNACKBAR, ...snackbar });

    this.snackbarsSubject.next(newSnackbars);
  }

  public showError(res: HttpErrorResponse): void {
    const error = res.error;

    if (error) {
      let content: string;
      if (error[0] && error[0].message) {
        content = error[0].message;
      } else {
        content = error.message || $localize`:@@web_default_error_message:Service unavailable temporarily. Try it later`;
      }
      this.show({ content, type: SNACKBAR_TYPES.NEGATIVE });
    }
  }

  public remove(snackbar: Snackbar): void {
    const newSnackbars: Snackbar[] = this.snackbars.filter((t) => t !== snackbar);

    this.snackbarsSubject.next(newSnackbars);
  }
}
