// The remote FF strategy code is temporal in order to help us validate Rudderstack in production
// No removal ticket since we don't know yet next steps until test is complete

import { createHttpClient } from '@wallapop/api-layer/core/http/http-client';
import { HttpClient } from '@wallapop/api-layer/core/http/http-client.interface';
import { FeatureFlagService } from '@wallapop/feature-flag';

class AnalyticsFeatureFlagServiceClass extends FeatureFlagService<HttpClient> {
  private FF_NAME_ENABLE_MPARTICLE = 'web_enable_mparticle';
  private FF_NAME_ENABLE_RUDDERSTACK = 'web_enable_rudderstack';
  private FF_NAME_DISABLE_MPARTICLE_LOGOUT = 'web_disable_mparticle_logout';

  private refreshIntervalMs = 5 * 60_000; // Every 5 minutes ensure flags are up to date from server
  private interval: NodeJS.Timer | undefined;

  static globalSingleton: Nullable<AnalyticsFeatureFlagServiceClass> = null;

  constructor(httpClient: HttpClient) {
    super(httpClient);
    if (AnalyticsFeatureFlagServiceClass.globalSingleton) {
      return AnalyticsFeatureFlagServiceClass.globalSingleton;
    }
    AnalyticsFeatureFlagServiceClass.globalSingleton = this;
    this.cleanFlagsInterval();
  }

  public async isMparticleEnabled(): Promise<boolean> {
    try {
      const flag = await this.getFlags([this.FF_NAME_ENABLE_MPARTICLE]);
      return flag[0]?.active;
    } catch {
      return true;
    }
  }

  public async isRudderstackEnabled(): Promise<boolean> {
    try {
      const flag = await this.getFlags([this.FF_NAME_ENABLE_RUDDERSTACK]);
      return flag[0]?.active;
    } catch {
      return true;
    }
  }

  /**
   * TODO: Remove after a week [WPA-77775]
   */
  public async isMparticleLogoutDisabled(): Promise<boolean> {
    try {
      const flag = await this.getFlags([this.FF_NAME_DISABLE_MPARTICLE_LOGOUT]);
      return flag[0]?.active;
    } catch {
      return false;
    }
  }

  private cleanFlagsInterval(): void {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.clearFlagsFromMemCache();
      }, this.refreshIntervalMs);
    }
  }
}

export const AnalyticsFeatureFlagService = new AnalyticsFeatureFlagServiceClass(createHttpClient());
