import { getBrazeFromWindow } from './get-braze-from-window';

// Important note: Please see that we are delegating the load of the Braze SDK by the one that will consume this library
// That's why in the future, we might desire a dynamic import and will return a Promise for this method
export const getBrazeSDK = () => {
  return getBrazeFromWindow();

  // TODO: Review the mParticle kits in the projects and see if we should pass the library as ref instead once everyone is consuming this library
  //return import('@braze/web-sdk')
};
