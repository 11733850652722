import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { DeviceService } from '@core/device/device.service';
import { HttpClient } from '@wallapop/api-layer/core/http/http-client.interface';
import { createHttpClient } from '@wallapop/api-layer/core/http/http-client';
import { APP_LOCALE } from '@configs/subdomains.config';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { environment } from '@environments/environment';
import { createAuthorizationInterceptor } from '@wallapop/api-layer/core/http/interceptors/authorization-interceptor';
import { createDeviceInterceptor } from '@wallapop/api-layer/core/http/interceptors/device-interceptor';
import { createLanguageInterceptor } from '@wallapop/api-layer/core/http/interceptors/language-interceptor';
import { createMParticleInterceptor } from '@wallapop/api-layer/core/http/interceptors/mparticle-interceptor';
import { createUnauthorizedInterceptor } from '@wallapop/api-layer/core/http/interceptors/unauthorized-interceptor';
import { applyInterceptor } from '@wallapop/api-layer/core/http/interceptors/apply-interceptor';
import { createAppVersionInterceptor } from '@wallapop/api-layer/core/http/interceptors/app-version-interceptor';
import { APP_VERSION } from '@wallapop/app-version';
import { AuthService } from '@core/auth/auth.service';
import { LogoutService } from '@core/logout/logout.service';
import { REFRESH_TOKEN_CLIENT_REPOSITORY } from '@core/refresh-token/repository/refresh-token-client-repository.token';
import { RefreshTokenRepository } from '@wallapop/api-layer/core/authorization/refresh-token/refresh-token-repository.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiLayerService {
  private _httpClient: HttpClient;

  constructor(
    @Inject(LOCALE_ID) private locale: APP_LOCALE,
    @Inject(WINDOW_TOKEN) private window: Window,
    @Inject(REFRESH_TOKEN_CLIENT_REPOSITORY) private refreshTokenClientRepository: RefreshTokenRepository,
    private cookieService: CookieService,
    private deviceService: DeviceService,
    private authService: AuthService,
    private logoutService: LogoutService,
  ) {}

  public get httpClient(): HttpClient {
    if (this._httpClient) {
      return this._httpClient;
    }

    const instance = createHttpClient({ locale: this.locale });

    const refresh = () => this.authService.refreshToken().toPromise();
    const logout = () => this.logoutService.logout().toPromise();

    const unauthorizedInterceptor = createUnauthorizedInterceptor({
      baseUrl: environment.baseUrl,
      httpClient: instance,
      logout,
      refresh,
      refreshTokenRepository: this.refreshTokenClientRepository,
    });
    applyInterceptor(instance, unauthorizedInterceptor);

    // Adds language interceptor
    const languageInterceptor = createLanguageInterceptor({
      locale: this.locale,
      languages: this.window.navigator.languages || [],
      baseUrl: environment.baseUrl,
    });
    applyInterceptor(instance, languageInterceptor);

    // Adds authorization interceptor
    const authorizationInterceptor = createAuthorizationInterceptor({
      baseUrl: environment.baseUrl,
      getCurrentCookies: () => this.cookieService.getAll(),
    });
    applyInterceptor(instance, authorizationInterceptor);

    // // Adds device interceptor
    const deviceIdInterceptor = createDeviceInterceptor({
      getDeviceId: () => this.deviceService.getDeviceId(),
    });
    applyInterceptor(instance, deviceIdInterceptor);

    // // Adds MPID interceptor
    const mParticleIdInterceptor = createMParticleInterceptor({
      baseUrl: environment.baseUrl,
    });
    applyInterceptor(instance, mParticleIdInterceptor);

    // // Adds AppVersion
    const appVersionInterceptor = createAppVersionInterceptor({ appVersion: APP_VERSION, baseUrl: environment.baseUrl });
    applyInterceptor(instance, appVersionInterceptor);

    this._httpClient = instance;

    return instance;
  }
}
