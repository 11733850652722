import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SITE_URL } from '@configs/site-url.config';
import { EventService } from '@core/event/event.service';
import { ReleaseVersionService } from '@core/release-version/release-version.service';
import { LOGOUT_ENDPOINT } from '@core/user/user.service';
import { APP_VERSION } from '@wallapop/app-version';
import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AccessTokenService } from '@core/access-token/services/access-token.service';
import { LOCAL_STORAGE_TOKEN } from '@core/local-storage/local-storage.token';
import { RECENT_SEARCHES_STORAGE_KEY } from '@api/search/recent-searches/recent-searches-api.service';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { IS_FIRST_LOGIN_STORE_KEY } from '@shared/required-user-kind-modal/required-user-kind-modal.constants';

export const COOKIE_PUBLISHER_ID = 'publisherId';
export const COOKIE_CREDIT_NAME = 'creditName';
export const COOKIE_CREDIT_QUANTITY = 'creditQuantity';

@Injectable()
export class LogoutService {
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private releaseVersionService: ReleaseVersionService,
    private permissionService: NgxPermissionsService,
    private accessTokenService: AccessTokenService,
    private event: EventService,
    private analyticsService: AnalyticsService,
    @Inject(SITE_URL) private siteUrl: string,
    @Inject(LOCAL_STORAGE_TOKEN) private localStorage: Storage,
  ) {}

  public logout(redirect?: string): Observable<void> {
    const headers: HttpHeaders = new HttpHeaders({
      DeviceAccessToken: this.accessTokenService.deviceAccessToken,
      AppBuild: this.releaseVersionService.getReleaseVersion(APP_VERSION),
      DeviceOS: '0',
    });

    try {
      return this.httpClient.post<void>(`${environment.baseUrl}${LOGOUT_ENDPOINT}`, null, { headers }).pipe(
        take(1),
        finalize(() => {
          this.finalizeUserSession(redirect);
        }),
      );
    } catch (error) {
      return of();
    }
  }

  public finalizeUserSession(redirect?: string): void {
    const redirectUrl = redirect ? redirect : this.siteUrl;

    this.clearUserStorage();
    this.analyticsService.logoutUser(() => {
      this.event.emit(EventService.USER_LOGOUT, redirectUrl);
    });
  }

  private clearUserStorage(): void {
    this.removeCookies();
    this.accessTokenService.deleteAccessToken();
    this.permissionService.flushPermissions();
    this.localStorage.removeItem(RECENT_SEARCHES_STORAGE_KEY);
    this.localStorage.removeItem(IS_FIRST_LOGIN_STORE_KEY);
  }

  private removeCookies(): void {
    const cookieOptions = environment.name === 'local' ? { domain: 'localhost' } : { domain: '.wallapop.com' };
    this.cookieService.remove(COOKIE_PUBLISHER_ID, cookieOptions);
    this.cookieService.remove(COOKIE_CREDIT_NAME, cookieOptions);
    this.cookieService.remove(COOKIE_CREDIT_QUANTITY, cookieOptions);
  }
}
