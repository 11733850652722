import { AnalyticsEventAttributes, AnalyticsPageView, trackMParticlePageView } from '#analytics/index';
import { getProvidersForAnalytics } from './event-routing';
import { trackRudderstackPageView } from './providers/rudderstack/track-rudderstack-page';

export const trackAnalyticsPageView = async <T extends AnalyticsEventAttributes>(event: AnalyticsPageView<T>): Promise<void> => {
  const providers = await getProvidersForAnalytics();

  if (providers.includes('mparticle')) {
    trackMParticlePageView(event);
  }

  if (providers.includes('rudderstack')) {
    trackRudderstackPageView(event);
  }
};
