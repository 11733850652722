import { FavoriteSearch, FavoriteSearchesQuery } from '@api/favorite-searches/model/favorite-search.interface';
import { FavoriteSearchesDto } from '../dtos/favorite-searches-dto';
import { mapQuery, mapQueryParams } from './query-mapper';

export function mapFavoriteSearches(favoriteSearchesDto: FavoriteSearchesDto[]): FavoriteSearch[] {
  return favoriteSearchesDto.map((favoriteSearch) => {
    return {
      id: favoriteSearch.id,
      title: favoriteSearch.title,
      subtitle: favoriteSearch.description,
      category: favoriteSearch.query.category_ids && favoriteSearch.query.category_ids[0],
      hasKeywords: !!favoriteSearch.query.keywords,
      isActive: favoriteSearch.alert.enabled,
      gotNewResults: favoriteSearch.alert.hits > 0,
      query: mapQuery(favoriteSearch.query),
      queryParams: mapQueryParams(favoriteSearch.query),
    };
  });
}

export function mapFiltersFromFavoriteSearchId(favoriteSearchesDto: FavoriteSearchesDto): FavoriteSearchesQuery {
  return mapQueryParams(favoriteSearchesDto.query);
}
