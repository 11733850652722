import { Injectable } from '@angular/core';
import { UserVerifications } from '@api/core/model/verifications';
import { UserVerificationsService } from '@api/user-verifications/user-verifications.service';
import { UserSettingsService } from '@core/user-settings/user-settings.service';
import { LOCAL_STORAGE_LEAKED_CREDENTIALS_MODAL_SHOWN, UserService } from '@core/user/user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@sentry/angular';
import { take } from 'rxjs/operators';
import { LEAKED_CREDNETIALS_MODAL_ACTIONS } from '../constants/leaked-credentilas-modal-actions.constants';
import { LeakedCredentialsModalComponent } from '../leaked-credentials-modal/leaked-credentials-modal.component';
import { LeakedCredentialsTrackingEventsService } from './leaked-credentials-tracking-events.service';

@Injectable()
export class LeakedCredentialsService {
  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private userVerificationsService: UserVerificationsService,
    private trackingService: LeakedCredentialsTrackingEventsService,
    private userSettingsService: UserSettingsService,
  ) {}

  public init(): void {
    if (this.shouldShowLeakedCredentialsModal()) {
      this.userVerificationsService
        .getVerifications()
        .pipe(take(1))
        .subscribe((userVerifications: UserVerifications) => {
          this.showLeakedCredentialsModal(userVerifications.email);
        });
    }
  }

  private shouldShowLeakedCredentialsModal(): boolean {
    const user: User = this.userService.user;

    if (!user?.hasCredentialsLeaked) {
      return false;
    }

    return !this.userService.getLocalStore(LOCAL_STORAGE_LEAKED_CREDENTIALS_MODAL_SHOWN);
  }

  private showLeakedCredentialsModal(emailVerified: boolean): void {
    const modalRef: NgbModalRef = this.modalService.open(LeakedCredentialsModalComponent, { animation: false });

    this.userService.saveLocalStore(LOCAL_STORAGE_LEAKED_CREDENTIALS_MODAL_SHOWN, 'true');
    this.trackingService.trackLeakedCredentialsView();

    modalRef.result.then(
      (reason: LEAKED_CREDNETIALS_MODAL_ACTIONS) => {
        if (reason === LEAKED_CREDNETIALS_MODAL_ACTIONS.CHANGE_PASSWORD)
          this.userSettingsService.handleChangePassword(emailVerified, this.userService.user.email, true);
      },
      () => {},
    );
  }
}
