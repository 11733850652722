import { Injectable } from '@angular/core';
import { translations } from './translations/constants/translations';
import { TRANSLATION_KEY } from './translations/enum/translation-keys.enum';

/**
 * @deprecated This service is considered deprecated. Please refer the following documentation
 * https://wallapop.atlassian.net/wiki/spaces/FE/pages/2624389173/Use+translations+files+for+each+module
 *
 */
@Injectable({
  providedIn: 'root',
})
export class I18nService {
  /**
   * @deprecated Make use of `$localize` within a separated file instead.
   * Check https://wallapop.atlassian.net/wiki/spaces/FE/pages/2624389173/Use+translations+files+for+each+module
   */
  public translate(translationKey: TRANSLATION_KEY): string {
    return translations[translationKey] || '';
  }
}
