import { Inject, Injectable } from '@angular/core';
import { NavigationCategoriesApiService } from '@api/navigation-categories/navigation-categories-api.service';
import { SITE_URL } from '@configs/site-url.config';
import { categoryExperimentatorToCategoryNavigationListMapper } from '@core/experimentator/mappers/category-experimentator-to-category-navigation-item.mapper';
import { addViewAllOptionToCategory } from '@shared/view-all-category/add-view-all-category.mapper';
import { getViewAllCategoryOption } from '@shared/view-all-category/get-view-all-category';
import { CategoryNavigationItem } from '@wallapop/web-components-react';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CategoryNavigation } from './navigation-categories.interfaces';

@Injectable({
  providedIn: 'root',
})
export class NavigationCategoriesService {
  private categoriesSubject = new BehaviorSubject<CategoryNavigation>(null);

  constructor(
    private navigationCategoriesApiService: NavigationCategoriesApiService,
    @Inject(SITE_URL) private siteUrl: string,
  ) {}

  public getNavigationCategories(): Observable<CategoryNavigation> {
    if (this.categoriesSubject.getValue()?.categories.length) {
      return this.categoriesSubject.asObservable();
    }

    return this.navigationCategoriesApiService.getNavigationCategories().pipe(
      map(({ data, meta }) => {
        const dataWithViewAllOption = data.map((category) =>
          addViewAllOptionToCategory(category, this.siteUrl, false, category.categoryId),
        );

        const mappedData: CategoryNavigationItem[] = categoryExperimentatorToCategoryNavigationListMapper(dataWithViewAllOption);

        return {
          categoriesTitle: meta.title,
          categories: [
            getViewAllCategoryOption({ categoryId: null, url: this.siteUrl, isTopCategory: false, rootCategoryId: null }),
            ...mappedData,
          ],
        };
      }),
      tap((categories) => this.categoriesSubject.next(categories)),
    );
  }
}
