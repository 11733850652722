import { AxiosRequestConfig } from 'axios';

export interface HttpRequestHeaderRules {
  headerName: string;
  domains: string[];
  conditions?: {
    type: 'path' | 'pathStartsWith';
    values: string[];
  }[];
}

export const getMatchingHeadersByRules = (
  headerRules: HttpRequestHeaderRules[],
  request: AxiosRequestConfig,
  headerValues: () => Record<string, string>,
): Record<string, string> => {
  const matchingHeaders: Record<string, string> = {};

  if (!request.url) {
    return matchingHeaders;
  }

  const requestURL = new URL(request.url);

  for (const headerRule of headerRules) {
    const { headerName, conditions, domains } = headerRule;
    const domain = requestURL.hostname;

    if (!domains.includes(domain)) {
      continue;
    }

    if (!conditions) {
      matchingHeaders[headerName] = headerValues()[headerName];
      continue;
    }

    conditionLoop: for (const condition of conditions) {
      const { type, values } = condition;

      for (const value of values) {
        if (type === 'path' && requestURL.pathname.includes(`/${value}`)) {
          matchingHeaders[headerName] = headerValues()[headerName];
          break conditionLoop;
        }

        if (type === 'pathStartsWith' && requestURL.pathname.startsWith(`/${value}`)) {
          matchingHeaders[headerName] = headerValues()[headerName];
          break conditionLoop;
        }
      }
    }
  }

  return matchingHeaders;
};
