<div class="TermsNCommsModalComponent p-4 p-md-5 text-center">
  <div class="TermsNCommsModalComponent__body align-self-center">
    <h3 class="TermsNCommsModalComponent__title my-2" i18n="@@register_marketing_promotions_consent_user_already_registered_main_title">
      Updated Terms of use and Privacy policy
    </h3>
    <p
      class="TermsNCommsModalComponent__description mb-1"
      i18n="@@register_marketing_promotions_consent_user_already_registered_description"
    >
      To continue using Wallapop, you must read and accept our Terms of Use and Privacy Policy.
    </p>
    <form class="TermsNCommsModalComponent__form mt-4 text-left d-inline-block">
      <div class="align-items-center d-flex mb-2">
        <tsl-checkbox-form [(ngModel)]="termsNComms.comms" name="comms" id="checkboxComms"></tsl-checkbox-form>
        <label class="ml-2 mb-0" i18n="@@register_marketing_promotions_consent_all_users_comms_checkbox_description"
          >I want to receive communications about wallapop news and promotions.</label
        >
      </div>

      <div class="align-items-center d-flex mb-2">
        <tsl-checkbox-form [(ngModel)]="termsNComms.terms" name="terms" id="checkboxTerms"></tsl-checkbox-form>
        <label class="ml-2 mb-0">
          <span i18n="@@register_marketing_promotions_consent_all_users_terms_use_checkbox_part_1_text_web_specific"
            >I have read and accept the</span
          >
          <a
            id="linkTerms"
            [href]="TERMS_AND_CONDITIONS_URL"
            target="_blank"
            class="ml-1"
            i18n="@@register_marketing_promotions_consent_all_users_terms_use_checkbox_part_2_text_web_specific"
            >Terms of use</a
          >
          <span i18n="@@register_marketing_promotions_consent_all_users_terms_use_checkbox_part_3_text_web_specific" class="ml-1">and</span>
          <a
            id="linkPrivacy"
            [href]="PRIVACY_POLICY_URL"
            target="_blank"
            class="ml-1"
            i18n="@@register_marketing_promotions_consent_all_users_terms_use_checkbox_part_4_text_web_specific"
            >Privacy policy</a
          >
          <span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_terms_use_checkbox_part_5_text_web_specific"
            >of Wallapop.</span
          ></label
        >
      </div>
    </form>
    <p class="TermsNCommsModalComponent__smallText">
      <span i18n="@@register_marketing_promotions_consent_all_users_more_info_part_1_text_web_specific">View</span
      ><a
        class="TermsNCommsModalComponent__moreInfo ml-1"
        (click)="onClickMoreInfo()"
        i18n="@@register_marketing_promotions_consent_all_users_more_info_part_2_text_web_specific"
        >+info</a
      >
      <span class="ml-1" i18n="@@register_marketing_promotions_consent_all_users_more_info_part_3_text_web_specific"
        >about data protection.</span
      >
    </p>
  </div>

  <div class="TermsNCommsModalComponent__footer mt-4">
    <tsl-button
      className="btn-link btn-link--bold w-100"
      class="TermsNCommsModalComponent__reject w-50"
      (click)="onRejectTerms()"
      i18n="@@register_marketing_promotions_consent_all_users_reject_button"
      >Reject</tsl-button
    >
    <tsl-button
      className="btn-primary btn-primary--bold w-100"
      class="TermsNCommsModalComponent__accept w-50"
      (click)="onConfirmTerms()"
      [disabled]="!termsNComms.terms"
      i18n="@@register_marketing_promotions_consent_user_already_registered_accept_button"
      >Accept</tsl-button
    >
  </div>
</div>
