import { NewOldFavoriteSearch } from '@api/favorite-searches/dtos/favorite-search-new-old-dto.interface';
import { CATEGORY_IDS } from '@core/category/category-ids';
import { ItemCard } from '@public/core/interfaces/item-card.interface';
import { SearchResponseDto } from '../dtos';
import { SearchItemCarResponseMapper } from './cars/search-cars-response.mapper';
import { SearchItemConsumerGoodsResponseMapper } from './consumer-goods/search-consumer-goods-response.mapper';
import { SearchItemRealEstateResponseMapper } from './real-estate/search-real-estate-response.mapper';

export type ItemCardMapper<T> = (item: SearchResponseDto<T> | NewOldFavoriteSearch) => ItemCard[];

const ItemCategoryIdMapper: Record<number, ItemCardMapper<unknown>> = {
  [CATEGORY_IDS.CAR]: SearchItemCarResponseMapper,
  [CATEGORY_IDS.REAL_ESTATE]: SearchItemRealEstateResponseMapper,
};

export function SearchApiItemMapper<T>(categoryId: string): ItemCardMapper<T> {
  return ItemCategoryIdMapper[+categoryId] || SearchItemConsumerGoodsResponseMapper;
}
