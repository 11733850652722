import { HttpClient } from '../../core/http/http-client.interface';
import { ExperimentatorCategoryDto } from './dtos/experimentator-category-response.dto';
import { ExperimentatorChipListDto } from './dtos/experimentator-chip-list-response.dto';
import { ExperimentatorCollectionDto } from './dtos/experimentator-collection-response.dto';
import { ExperimentatorItemDto } from './dtos/experimentator-item-response.dto';
import { EXPERIMENTATOR_VALID_LAYOUTS, ExperimentatorComponentLayoutDto } from './dtos/experimentator-response.dto';
import { ExperimentatorUserDto } from './dtos/experimentator-user-response.dto';
import { ExperimentatorVideoDto } from './dtos/experimentator-video-response.dto';

import { getExperimentator } from './http/experimentator-http.service';
import { experimentatorCategoriesResponseMapper } from './mappers/experimentator-categories-response.mapper';
import { experimentatorChipListResponseMapper } from './mappers/experimentator-chip-list-response.mapper';
import { experimentatorCollectionsResponseMapper } from './mappers/experimentator-collections-response.mapper';
import { experimentatorItemsResponseMapper } from './mappers/experimentator-items-response.mapper';
import { experimentatorMetaResponseMapper } from './mappers/experimentator-meta.mapper';
import { experimentatorUserResponseMapper } from './mappers/experimentator-user-response.mapper';
import { experimentatorVideoResponseMapper } from './mappers/experimentator-videos-response.mapper';

interface FetchExperimentatorProps {
  httpClient: HttpClient;
  initiative: string;
  itemId?: string;
  categoryId?: string;
  keyword?: string;
}

const mappers = {
  collections: experimentatorCollectionsResponseMapper,
  items: experimentatorItemsResponseMapper,
  chip_list: experimentatorChipListResponseMapper,
  users: experimentatorUserResponseMapper,
  categories: experimentatorCategoriesResponseMapper,
  videos: experimentatorVideoResponseMapper,
};

export const fetchExperimentator = async ({ httpClient, initiative, itemId, categoryId, keyword }: FetchExperimentatorProps) => {
  const { data } = await getExperimentator(httpClient, initiative, itemId, categoryId, keyword);
  const { data: wallItems, meta } = data;

  const isLayoutValid = (layout: ExperimentatorComponentLayoutDto) => {
    return EXPERIMENTATOR_VALID_LAYOUTS.includes(layout);
  };

  if (wallItems?.length && isLayoutValid(meta.layout)) {
    const experimentatorType = data.meta.type;
    if (experimentatorType === 'collections') {
      return mappers.collections(data as ExperimentatorCollectionDto);
    }
    if (experimentatorType === 'items') {
      return mappers.items(data as ExperimentatorItemDto);
    }
    if (experimentatorType === 'chips_list') {
      return mappers.chip_list(data as ExperimentatorChipListDto);
    }
    if (experimentatorType === 'users') {
      return mappers.users(data as ExperimentatorUserDto);
    }
    if (experimentatorType === 'categories') {
      return mappers.categories(data as ExperimentatorCategoryDto);
    }
    if (experimentatorType === 'video_items') {
      return mappers.videos(data as ExperimentatorVideoDto);
    }
  }

  return { data: [], meta: experimentatorMetaResponseMapper(meta) };
};
