import { SEARCH_UNIFIED_QUERY_PARAM } from '@wallapop/api-layer/apis/search/unified/constants/search-unified-query-param-constant';

export enum FILTER_QUERY_PARAM_KEY {
  categoryId = 'category_ids',
  objectType = 'object_type_id',
  objectTypes = 'object_type_ids',
  maxPrice = 'max_sale_price',
  minPrice = 'min_sale_price',
  postedAgo = 'time_filter',
  condition = 'condition',
  longitude = 'longitude',
  latitude = 'latitude',
  distance = 'distance',
  shipping = 'shipping',
  countryCode = 'country_code',
  size = 'size',
  brand = 'brand',
  model = 'model',
  bodyType = 'body_type',
  engine = 'engine',
  gearbox = 'gearbox',
  warranty = 'warranty',
  professional = 'professional',
  maxHorsePower = 'max_horse_power',
  minHorsePower = 'min_horse_power',
  minYear = 'min_year',
  maxYear = 'max_year',
  minKm = 'min_km',
  maxKm = 'max_km',
  minDoors = 'min_num_doors',
  maxDoors = 'max_num_doors',
  minSeats = 'min_seats',
  maxSeats = 'max_seats',
  operation = 'operation',
  garage = 'garage',
  terrace = 'terrace',
  elevator = 'elevator',
  garden = 'garden',
  pool = 'pool',
  minSurface = 'min_surface',
  maxSurface = 'max_surface',
  type = 'type',
  rooms = 'rooms',
  bathrooms = 'bathrooms',
  brandModel = 'brand_model',
  keywords = 'keywords',
  orderBy = 'order_by',
  favoriteSearchId = 'favorite_search_id',
  filtersSource = 'filters_source',
  maxDate = 'maxDate',
  minDate = 'minDate',
  status = 'status',
  maxFinancedPrice = 'max_financed_price',
  minFinancedPrice = 'min_financed_price',
  subscriptionType = 'subscription_type',
  isRefurbished = 'is_refurbished',
  color = 'color',
  storageCapacity = 'storage_capacity',
  searchId = 'search_id',
  filtersActive = 'filters_active',
  sortByActive = 'sort_by_active',
}

type SearchUnifiedQueryParamValues = (typeof SEARCH_UNIFIED_QUERY_PARAM)[keyof typeof SEARCH_UNIFIED_QUERY_PARAM];

export const FILTER_QUERY_PARAM_KEY_V2: {
  [key in keyof typeof FILTER_QUERY_PARAM_KEY]?: SearchUnifiedQueryParamValues;
} = {
  categoryId: SEARCH_UNIFIED_QUERY_PARAM.CATEGORY_ID,
  shipping: SEARCH_UNIFIED_QUERY_PARAM.IS_SHIPPABLE,
  distance: SEARCH_UNIFIED_QUERY_PARAM.DISTANCE_IN_KM,
  objectTypes: SEARCH_UNIFIED_QUERY_PARAM.SUBCATEGORY_IDS,
  size: SEARCH_UNIFIED_QUERY_PARAM.FASHION_SIZE,
  filtersSource: SEARCH_UNIFIED_QUERY_PARAM.SOURCE,
} as const;
