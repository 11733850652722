import { getBrazeSDK } from '../utils/get-braze-sdk';
import { environment } from '@wallapop/environments';
import { ExternalCommsInAppMessage } from '../typing';

export const subscribeToInAppMessageUpdates = async () => {
  const brazeInstance = await getBrazeSDK();

  if (!environment.production) {
    brazeInstance?.toggleLogging();
  }
  brazeInstance?.subscribeToInAppMessage((inAppMessage) => {
    let shouldDisplay = true;
    const msgId = (inAppMessage as ExternalCommsInAppMessage)?.extras?.['msg-id'];

    if (msgId === 'push-primer') {
      if (!brazeInstance.isPushSupported() || brazeInstance.isPushPermissionGranted() || brazeInstance.isPushBlocked()) {
        shouldDisplay = false;
      }
    }

    if (shouldDisplay) {
      brazeInstance.showInAppMessage(inAppMessage);
    }
  });
};
