import { MULTI_FACTOR_OPERATION } from '@api/core/model/multi-factor/multi-factor-operation.enum';
import { EmailThanksModalCopies } from '@shared/email-thanks-modal/interfaces';

const CHANGE_EMAIL_COPIES = (email: string): EmailThanksModalCopies => {
  return {
    title: $localize`:@@email_change_old_mailbox_verification_all_users_system_modal_title:Check the mailbox`,
    description: $localize`:@@email_change_old_mailbox_verification_all_users_system_modal_description:We have sent a verification email to ${email}:INTERPOLATION:. Open it and follow the instructions to change the email.`,
    button: $localize`:@@email_change_old_mailbox_verification_all_users_system_modal_understood_button:Understood`,
  };
};

const KYC_EMAIL_TRANSLATIONS = (email: string): EmailThanksModalCopies => {
  return {
    title: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_title:Check the mailbox`,
    description: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_description:We have sent a verification email to ${email}:INTERPOLATION:. Open it and follow the instructions to change the email.`,
    button: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_understood_button:Understood`,
  };
};

const ADD_BANK_ACCOUNT_EMAIL_TRANSLATIONS = (email: string): EmailThanksModalCopies => {
  return {
    title: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_title:Check the mailbox`,
    description: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_description:We have sent a verification email to ${email}:INTERPOLATION:. Open it and follow the instructions to change the email.`,
    button: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_understood_button:Understood`,
  };
};

const EDIT_BANK_ACCOUNT_EMAIL_TRANSLATIONS = (email: string): EmailThanksModalCopies => {
  return {
    title: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_title:Check the mailbox`,
    description: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_description:We have sent a verification email to ${email}:INTERPOLATION:. Open it and follow the instructions to change the email.`,
    button: $localize`:@@user_verification_bank_details_all_users_verification_in_progress_pop_up_understood_button:Understood`,
  };
};

export const GENERIC_MODAL_COPIES = (email: string): Record<string, EmailThanksModalCopies> => {
  return {
    [MULTI_FACTOR_OPERATION.CHANGE_EMAIL]: CHANGE_EMAIL_COPIES(email),
    [MULTI_FACTOR_OPERATION.KYC_BANNER_BANK_DETAILS]: KYC_EMAIL_TRANSLATIONS(email),
    [MULTI_FACTOR_OPERATION.ADD_BANK_DETAILS]: ADD_BANK_ACCOUNT_EMAIL_TRANSLATIONS(email),
    [MULTI_FACTOR_OPERATION.EDIT_BANK_DETAILS]: EDIT_BANK_ACCOUNT_EMAIL_TRANSLATIONS(email),
  };
};
