import { mapImageHttpToHttps } from '../../../core/mappers/image-mapper';
import { ExperimentatorUserDataDto, ExperimentatorUserDto } from '../dtos/experimentator-user-response.dto';
import { ExperimentatorResponseUser, ExperimentatorUserData } from '../interfaces/experimentator-user-response.interface';
import { experimentatorMetaResponseMapper } from './experimentator-meta.mapper';

export const experimentatorUserResponseMapper = (response: ExperimentatorUserDto): ExperimentatorResponseUser => {
  const newData: ExperimentatorUserData[] = response.data.map((data: ExperimentatorUserDataDto) => {
    return {
      userId: data.user_id,
      avatarImage: data.avatar_image ? mapImageHttpToHttps(data.avatar_image) : undefined,
      isFavorited: data.favorited?.flag,
      isFavoriteable: data.favoriteable?.flag,
      userScoring: data.user_scoring,
      images: data.images.map((image) => mapImageHttpToHttps(image)),
      title: data.title,
      action: {
        actionType: data.action.action_type,
        payload: {
          linkUrl: data.action.payload.link_url,
        },
      },
    };
  });

  return {
    data: newData,
    meta: experimentatorMetaResponseMapper(response.meta),
  };
};
