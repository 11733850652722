import { Inject, Injectable } from '@angular/core';
import { LoadExternalLibsService } from '@core/load-external-libs/load-external-libs.service';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { BehaviorSubject, catchError, Observable, of, ReplaySubject } from 'rxjs';
import { OneTrustApi } from './one-trust.interface';
import { environment } from '@environments/environment';
import { TcfService } from '../tcf/tcf.service';
import { TCF_API_COMMAND, TCF_API_VERSION, TcVendorConsents } from '../tcf/tcf.interface';
import {
  IABVendors,
  ONE_TRUST_SOURCE,
  OT_CONSENT_COOKIE_NAME,
  SESSION_TIMEOUT,
  SET_USER_CONSENT_ENDPOINT_COOKIE,
  SET_USER_CONSENT_MAX_RETRIES,
} from './one-trust.constants';
import { CookieService } from 'ngx-cookie';
import { ConsentApiService } from '@api/consent/consent-api.service';
import { AppStatusService } from '@core/app-status/app-status.service';

@Injectable({
  providedIn: 'root',
})
export class OneTrustService {
  private static NAME_LIB = 'OneTrust';
  private static ACTIVE_GROUPS_KEY = 'OptanonActiveGroups';
  public readonly ONE_TRUST_GROUP_CODES = {
    STRICTLY_NECESSARY_COOKIES: 'C0001',
    PERFORMANCE_COOKIES: 'C0002',
    FUNCTIONAL_COOKIES: 'C0003',
    TARGETING_COOKIES: 'C0004',
    NATIVE_SCAN_DEVICE_CHARACTERISTICS: 'NDev',
    NATIVE_USE_PRECISE_GEOLOCATION: 'NGeo',
  };
  private readonly oneTrustReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly allowedVendorsSubject$: BehaviorSubject<IABVendors[]> = new BehaviorSubject<IABVendors[]>([]);
  private readonly consentGroupsSubject$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private readonly otConsentHashSubject: ReplaySubject<string> = new ReplaySubject(1);
  private userConsentRetries = 0;
  private readonly consentIsDefinedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get isReady$(): Observable<boolean> {
    return this.oneTrustReady$.asObservable();
  }

  public get oneTrustApi(): OneTrustApi {
    return this.window[OneTrustService.NAME_LIB];
  }

  /**
   * Subject for the OptanonActiveGroups window property
   */
  public get consentGroups$(): Observable<string[]> {
    return this.consentGroupsSubject$.asObservable();
  }

  public get allowedVendors$(): Observable<IABVendors[]> {
    return this.allowedVendorsSubject$.asObservable();
  }

  public get otConsentHash$(): Observable<string> {
    return this.otConsentHashSubject.asObservable();
  }

  public get isConsentDefined$(): Observable<boolean> {
    return this.consentIsDefinedSubject$.asObservable();
  }

  constructor(
    @Inject(WINDOW_TOKEN) private window: Window,
    private loadExternalLibsService: LoadExternalLibsService,
    private consentApiService: ConsentApiService,
    private tcfService: TcfService,
    private cookieService: CookieService,
    private appStatusService: AppStatusService,
  ) {
    this.addOneTrustCallback();
    this.addSetConsentChangeListener();
  }

  public init(): Promise<void> {
    const attributes: HTMLAttributes = {
      dataset: [
        {
          key: 'domainScript',
          value: environment.oneTrustId,
        },
        {
          key: 'documentLanguage',
          value: 'true',
        },
      ],
    };

    return this.loadExternalLibsService
      .loadScriptBySourceWithAttributes(ONE_TRUST_SOURCE, attributes)
      .pipe(
        catchError((error) => {
          this.appStatusService.addError('[One Trust Service]: There was an issue when loading the OneTrust script', error);
          return of(error);
        }),
      )
      .toPromise();
  }

  /**
   * Takes in a string in the following format: ',V2STACK42, C0001,NDev,NGeo, V5,V7,'
   * @returns a string array with empty values filtered and spaces trimmed, eg: ['V2STACK42', 'C0001', 'NDev', 'NGeo', 'V5', 'V7']
   */
  private mapOneTrustActiveGroups = (activeGroups: string | undefined | null): string[] => {
    return activeGroups
      ? activeGroups
          .split(',')
          .filter((group) => group !== '')
          .map((group) => group.trim())
      : [];
  };

  private addOneTrustCallback(): void {
    this.window['OptanonWrapper'] = (): void => {
      if (!this.oneTrustReady$.getValue()) {
        this.setConsentBasedOnCookie();
        this.setAllowedVendors();
        this.oneTrustApi.OnConsentChanged(this.onConsentChangedCallback.bind(this));
        this.oneTrustReady$.next(true);
        this.setConsentGroups();
      }
    };
  }

  private getCClassConsentGroups(consentGroups: string[]): string[] {
    return consentGroups.filter((consentGroup: string) => consentGroup.startsWith('C'));
  }

  private callSetUserConsentEndpoint(groups: string[]): void {
    const cClassConsentGroups = this.getCClassConsentGroups(groups);
    const sentUserConsentCookie = this.cookieService.get(SET_USER_CONSENT_ENDPOINT_COOKIE);

    if (sentUserConsentCookie || cClassConsentGroups?.length === 0) return;
    if (this.userConsentRetries >= SET_USER_CONSENT_MAX_RETRIES) {
      console.error("Couldn't forward user consent.");
      this.userConsentRetries = 0;
      return;
    }
    const consentHash = this.cookieService.get(OT_CONSENT_COOKIE_NAME);

    this.consentApiService.setUserConsent({ consents: consentHash, consentGroups: cClassConsentGroups }).subscribe({
      next: () => {
        this.cookieService.put(SET_USER_CONSENT_ENDPOINT_COOKIE, 'true', {
          expires: new Date(new Date().getTime() + SESSION_TIMEOUT),
        });
      },
      error: () => {
        this.userConsentRetries = this.userConsentRetries + 1;
        setTimeout(() => {
          this.callSetUserConsentEndpoint(groups);
        }, 30000);
      },
    });
  }

  private addSetConsentChangeListener(): void {
    this.oneTrustReady$.subscribe((isReady: boolean) => {
      if (isReady) {
        const consentHash = this.cookieService.get(OT_CONSENT_COOKIE_NAME);

        this.consentGroupsSubject$.subscribe((groups) => {
          if (groups.length === 0 || !consentHash) return;
          this.callSetUserConsentEndpoint(groups);
        });
      }
    });
  }

  private setOtConsentHash(): void {
    const consentHash = this.cookieService.get(OT_CONSENT_COOKIE_NAME);

    this.otConsentHashSubject.next(consentHash);
  }

  private setConsentBasedOnCookie(): void {
    const consentCookieHash = this.cookieService.get(OT_CONSENT_COOKIE_NAME);

    this.consentIsDefinedSubject$.next(!!consentCookieHash);
  }

  private setConsentGroups(): void {
    const currentActiveGroups = this.window[OneTrustService.ACTIVE_GROUPS_KEY];
    const parsedActiveGroups = this.mapOneTrustActiveGroups(currentActiveGroups);

    this.consentGroupsSubject$.next(parsedActiveGroups);
  }

  private deleteSentConsentCookie(): void {
    this.cookieService.remove(SET_USER_CONSENT_ENDPOINT_COOKIE);
  }

  private setAllowedVendors(): void {
    this.tcfService.tcfApi(
      TCF_API_COMMAND.GET_TC_DATA,
      TCF_API_VERSION.V2,
      (tcData) => {
        const { consents } = tcData.vendor;
        this.allowedVendorsSubject$.next(this.getAllowedVendors(consents));

        if (consents[IABVendors.GOOGLE]) {
          this.setOtConsentHash();
        }
      },
      [IABVendors.GOOGLE, IABVendors.CRITEO],
    );
  }

  private onConsentChangedCallback(): void {
    this.setConsentBasedOnCookie();
    this.setAllowedVendors();

    this.deleteSentConsentCookie();
    this.setConsentGroups();
  }

  private getAllowedVendors(consents: TcVendorConsents): IABVendors[] {
    const allowedVendors: IABVendors[] = [];

    for (const vendorId in consents) {
      const vendor = Number.parseInt(vendorId);
      if (consents[vendor]) {
        allowedVendors.push(vendor);
      }
    }

    return allowedVendors;
  }
}
