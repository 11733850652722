import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

const isMockServer = environment.isMsw;

if (process.env.NODE_ENV === 'development' && isMockServer) {
  import('@configs/mocks/browser').then(({ worker }) => {
    worker.start();
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
