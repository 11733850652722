import { SCREEN_IDS } from '@wallapop/analytics/analytics.constants';

export const CATEGORY_NAVIGATION_SOURCES: { [key: string]: CategorySource } = {
  categoryNavigation: 'category_navigation',
  topCategoryNavigation: 'top_category_navigation',
};

export type CategorySource = 'category_navigation' | 'subcategory_navigation' | 'top_category_navigation';

export interface TrackClickCollectionCategoriesProps {
  collection: string;
  categoryId?: number;
  screenId: SCREEN_IDS;
  source?: CategorySource;
  position?: number;
}

export interface TrackClickSeeMoreCategoriesProps {
  screenId: SCREEN_IDS;
  source?: CategorySource;
  categoryId?: number;
  subcategoryId?: number;
  categoryLevel?: number;
}

export interface TrackClickSeeMoreCategories {
  ({ source, screenId, categoryId, subcategoryId, categoryLevel }: TrackClickSeeMoreCategoriesProps): void;
}

export interface TrackClickCollectionCategories {
  (data: TrackClickCollectionCategoriesProps): void;
}

export interface TrackScrollCategories {
  (): void;
}

export interface TrackClickDrawerNavigation {
  (screenId: SCREEN_IDS): void;
}

export interface TrackCategories {
  trackClickSeeMoreCategories: TrackClickSeeMoreCategories;
  trackClickCollectionCategories: TrackClickCollectionCategories;
  trackScrollCategories: TrackScrollCategories;
  trackClickDrawerNavigation: TrackClickDrawerNavigation;
}
