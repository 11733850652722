import { Batch } from '@mparticle/event-models';
import { MPConfiguration } from '@mparticle/web-sdk';

interface onCreateBatch {
  (batch: Batch): Batch | null;
}
export interface CustomMPConfiguration extends Omit<MPConfiguration, 'onCreateBatch'> {
  onCreateBatch?: onCreateBatch | undefined;
}

export interface MParticleKitSDK {
  default: {
    register: (config: CustomMPConfiguration) => void;
  };
}

export enum MPARTICLE_KITS {
  BRAZE = 'Braze',
  GOOGLE_ANALYTICS4 = 'Google_Analytics4',
}
