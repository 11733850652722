import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FilterParameter } from '@public/shared/components/filters/interfaces/filter-parameter.interface';
import { SearchQueryStringService } from '@core/search/search-query-string.service';
import { FILTER_QUERY_PARAM_KEY } from '@public/shared/components/filters/enums/filter-query-param-key.enum';
import { CATEGORY_IDS } from '@core/category/category-ids';
import { REAL_ESTATE_SPECIFICATION_TYPE } from '@public/core/constants/item-specifications/realestate-constants';
import { FILTER_PARAMETERS_SEARCH } from '@public/features/search/core/services/constants/filter-parameters';
import { SEARCH_SOURCE } from '@public/core/services/search-tracking-events/enums/search-source-enum';
import { PUBLIC_PATHS } from '@public/public-routing-constants';

@Injectable({
  providedIn: 'root',
})
export class SearchNavigatorService {
  private notAutomaticallyCleanableParams: FILTER_QUERY_PARAM_KEY[] = [
    FILTER_QUERY_PARAM_KEY.keywords,
    FILTER_QUERY_PARAM_KEY.latitude,
    FILTER_QUERY_PARAM_KEY.longitude,
    FILTER_QUERY_PARAM_KEY.distance,
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private queryStringService: SearchQueryStringService,
  ) {}

  public navigate(filterParams: FilterParameter[], filtersSource: SEARCH_SOURCE, isMultipleParams?: boolean): void {
    const newQueryParams = this.getQueryParamsAfterFiltersChange(filtersSource, filterParams, isMultipleParams);

    this.router.navigate([`/${PUBLIC_PATHS.SEARCH}`], {
      queryParams: {
        ...newQueryParams,
        [FILTER_PARAMETERS_SEARCH.SEARCH_SOURCE]: filtersSource,
      },
    });
  }

  private shouldDeleteFavoriteSearchId(filterParams: Params, filtersSource: SEARCH_SOURCE) {
    return filtersSource !== SEARCH_SOURCE.FAVORITE_SEARCHES && filterParams.hasOwnProperty(FILTER_QUERY_PARAM_KEY.favoriteSearchId);
  }

  private getQueryParamsAfterFiltersChange(
    filtersSource: SEARCH_SOURCE,
    filterParams: FilterParameter[],
    isMultipleParams?: boolean,
  ): Params {
    const queryParams = this.queryStringService.mapFilterToQueryParams(filterParams);

    if (filtersSource === SEARCH_SOURCE.RECENT_SEARCHES || filtersSource === SEARCH_SOURCE.SUGGESTER) {
      const categoryId = filterParams.find((filterParam) => filterParam.key === FILTER_QUERY_PARAM_KEY.categoryId);

      if (!categoryId) {
        filterParams.push({ key: FILTER_QUERY_PARAM_KEY.categoryId, value: undefined });
        return this.cleanUndefined(queryParams);
      }
    }

    if (filtersSource === SEARCH_SOURCE.DEFAULT_FILTERS || filtersSource === SEARCH_SOURCE.FAVORITE_SEARCHES) {
      return this.cleanUndefined(queryParams);
    }

    const currentParams = this.route.snapshot.queryParams;
    let newParams = { ...currentParams, ...queryParams };

    if (this.hasCategoryChange(currentParams, newParams)) {
      return this.getParamsAfterCategoryChange(newParams, isMultipleParams);
    }

    if (this.shouldDeleteFavoriteSearchId(newParams, filtersSource)) {
      const { [FILTER_QUERY_PARAM_KEY.favoriteSearchId]: _, ...filteredValues } = newParams;

      newParams = { ...filteredValues };
    }

    if (this.hasRealEstateChange(currentParams, newParams)) {
      return this.getParamsAfterRealEstateChange(currentParams, newParams);
    }

    return this.cleanUndefined(newParams);
  }

  private getParamsAfterCategoryChange(newParams: Params, isMultipleParams?: boolean): Params {
    const params = { [FILTER_QUERY_PARAM_KEY.categoryId]: newParams[FILTER_QUERY_PARAM_KEY.categoryId] };

    if (isMultipleParams) {
      const multipleParams = {
        [FILTER_QUERY_PARAM_KEY.categoryId]: newParams[FILTER_QUERY_PARAM_KEY.categoryId],
        [FILTER_QUERY_PARAM_KEY.objectTypes]: newParams[FILTER_QUERY_PARAM_KEY.objectTypes],
      };
      return this.triggerParams(newParams, multipleParams);
    }

    return this.triggerParams(newParams, params);
  }

  private triggerParams(newParams: Params, params: Params): Params {
    this.notAutomaticallyCleanableParams.forEach((key) => (params[key] = newParams[key]));

    return this.cleanUndefined(params);
  }

  private getParamsAfterRealEstateChange(currentParams: Params, newParams: Params): Params {
    const params = this.cleanRealEstate(currentParams, newParams);

    return this.cleanUndefined(params);
  }

  private hasCategoryChange(currentParams: Params, newParams: Params): boolean {
    const currentCategory = currentParams[FILTER_QUERY_PARAM_KEY.categoryId];
    const newCategory = newParams[FILTER_QUERY_PARAM_KEY.categoryId];

    return currentCategory !== newCategory;
  }

  private hasRealEstateChange(currentParams: Params, newParams: Params): boolean {
    if (newParams[FILTER_QUERY_PARAM_KEY.categoryId] !== CATEGORY_IDS.REAL_ESTATE.toString()) {
      return false;
    }

    const currentOperation = currentParams[FILTER_QUERY_PARAM_KEY.operation];
    const currentType = currentParams[FILTER_QUERY_PARAM_KEY.type];
    const newOperation = newParams[FILTER_QUERY_PARAM_KEY.operation];
    const newType = newParams[FILTER_QUERY_PARAM_KEY.type];

    return currentOperation !== newOperation || currentType !== newType;
  }

  // TODO: Review and refactor this
  private cleanRealEstate(currentParams: Params, newParams: Params): Params {
    const realEstateParams: Params = { ...currentParams, ...newParams };

    if (
      (newParams[FILTER_QUERY_PARAM_KEY.operation] || currentParams[FILTER_QUERY_PARAM_KEY.operation]) &&
      (newParams[FILTER_QUERY_PARAM_KEY.type] || currentParams[FILTER_QUERY_PARAM_KEY.type]) &&
      (currentParams[FILTER_QUERY_PARAM_KEY.type] !== newParams[FILTER_QUERY_PARAM_KEY.type] ||
        currentParams[FILTER_QUERY_PARAM_KEY.operation] !== newParams[FILTER_QUERY_PARAM_KEY.operation])
    ) {
      realEstateParams[FILTER_QUERY_PARAM_KEY.minPrice] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.maxPrice] = undefined;
    }

    if (currentParams[FILTER_QUERY_PARAM_KEY.operation] !== newParams[FILTER_QUERY_PARAM_KEY.operation]) {
      if (currentParams[FILTER_QUERY_PARAM_KEY.type] === REAL_ESTATE_SPECIFICATION_TYPE.ROOM) {
        newParams[FILTER_QUERY_PARAM_KEY.type] = undefined;
      }
      realEstateParams[FILTER_QUERY_PARAM_KEY.rooms] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.bathrooms] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.elevator] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.garage] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.terrace] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.pool] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.garden] = undefined;
    }

    if (currentParams[FILTER_QUERY_PARAM_KEY.type] !== newParams[FILTER_QUERY_PARAM_KEY.type]) {
      realEstateParams[FILTER_QUERY_PARAM_KEY.minSurface] = undefined;
      realEstateParams[FILTER_QUERY_PARAM_KEY.maxSurface] = undefined;
      if (
        realEstateParams[FILTER_QUERY_PARAM_KEY.type] &&
        realEstateParams[FILTER_QUERY_PARAM_KEY.type] !== REAL_ESTATE_SPECIFICATION_TYPE.HOUSE &&
        realEstateParams[FILTER_QUERY_PARAM_KEY.type] !== REAL_ESTATE_SPECIFICATION_TYPE.FLAT
      ) {
        realEstateParams[FILTER_QUERY_PARAM_KEY.rooms] = undefined;
        realEstateParams[FILTER_QUERY_PARAM_KEY.bathrooms] = undefined;
        realEstateParams[FILTER_QUERY_PARAM_KEY.elevator] = undefined;
        realEstateParams[FILTER_QUERY_PARAM_KEY.garage] = undefined;
        realEstateParams[FILTER_QUERY_PARAM_KEY.terrace] = undefined;
        realEstateParams[FILTER_QUERY_PARAM_KEY.pool] = undefined;
        realEstateParams[FILTER_QUERY_PARAM_KEY.garden] = undefined;
      }
    }

    return realEstateParams;
  }

  private cleanUndefined(params: Params): Params {
    const cleanedParams = {};
    const keys = Object.keys(params).filter((key) => params[key]);

    keys.forEach((key) => (cleanedParams[key] = params[key]));

    return cleanedParams;
  }
}
