import { Inject, Injectable } from '@angular/core';
import { ExperimentatorApiService } from '@api/experimentator/experimentator-api.service';
import { SITE_URL } from '@configs/site-url.config';
import { UserService } from '@core/user/user.service';
import { CATEGORY_NAVIGATION_SOURCES } from '@private/shared/category-navigation/category-navigation-tracking/category-navigation-tracking.interface';
import { ExperimentatorResponseCategory } from '@wallapop/api-layer/apis/experimentator/interfaces/experimentator-category-response.interface';
import { ExperimentatorResponseTypes } from '@wallapop/api-layer/apis/experimentator/interfaces/experimentator-response.interface';
import { CategoryNavigationItem } from '@wallapop/web-components-react';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { addViewAllOptionToCategory } from '../../shared/view-all-category/add-view-all-category.mapper';
import { TopCategoryNavigation } from './experimentator.interface';
import { categoryExperimentatorToCategoryNavigationListMapper } from './mappers/category-experimentator-to-category-navigation-item.mapper';

@Injectable({ providedIn: 'root' })
export class ExperimentatorService {
  private topCategoriesSubject = new BehaviorSubject<TopCategoryNavigation>(null);

  constructor(
    private experimentatorApiService: ExperimentatorApiService,
    private userService: UserService,
    @Inject(SITE_URL) private siteUrl: string,
  ) {}

  public getExperimentator(initiative: string): Observable<ExperimentatorResponseTypes> {
    return this.experimentatorApiService.getExperimentator({ initiative });
  }

  public getTopCategories(): Observable<TopCategoryNavigation> {
    if (this.topCategoriesSubject.getValue()?.topCategories.length) {
      return this.topCategoriesSubject.asObservable();
    }

    const isLogged = this.userService.isLogged;
    const topCategoriesTitle = isLogged
      ? $localize`:@@your_top_categories_all_users_browse_title:Your Top Categories`
      : $localize`:@@top_categories_all_users_browse_title:Top Categories`;

    return this.getExperimentator(CATEGORY_NAVIGATION_SOURCES.categoryNavigation).pipe(
      map(({ data }: ExperimentatorResponseCategory) => {
        const dataWithViewAllOption = data.map((category) => addViewAllOptionToCategory(category, this.siteUrl, true, category.categoryId));

        const mappedData: CategoryNavigationItem[] = categoryExperimentatorToCategoryNavigationListMapper(dataWithViewAllOption);

        return {
          topCategoriesTitle,
          topCategories: [...mappedData],
        };
      }),
      tap((topCategories) => this.topCategoriesSubject.next(topCategories)),
      catchError(() => of({ topCategories: [], topCategoriesTitle })),
    );
  }
}
