import { InjectionToken } from '@angular/core';
import { RefreshTokenRepository } from '@wallapop/api-layer/core/authorization/refresh-token/refresh-token-repository.interface';
import { refreshTokenClientRepository } from './refresh-token-client-repository';

export const REFRESH_TOKEN_CLIENT_REPOSITORY: InjectionToken<RefreshTokenRepository> = new InjectionToken<RefreshTokenRepository>(
  'REFRESH_TOKEN_CLIENT_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => refreshTokenClientRepository(),
  },
);
