import { AnalyticsEvent, AnalyticsEventAttributes } from '#analytics/index';
import { initRudderstackAnalytics, isRudderstackAnalyticsDisabled } from './init-rudderstack-analytics';
import { getCommonRudderstackApiOptions } from './utils/get-common-rudderstack-api-options';

export const trackRudderstackEvent = async <T extends AnalyticsEventAttributes>(event: AnalyticsEvent<T>): Promise<void> => {
  if (isRudderstackAnalyticsDisabled()) {
    return;
  }

  const lib = await initRudderstackAnalytics();

  const { name, attributes } = event;
  const apiOptions = getCommonRudderstackApiOptions();
  lib?.track(name, attributes, apiOptions);
};
