import { RefreshToken } from '../../apis/auth/refresh/interfaces/refresh-response.interface';
import { Observable, Observer } from './observable.types';

export const createObservable = (): Observable<RefreshToken | Error> => {
  const observers: Observer<RefreshToken | Error>[] = [];

  const subscribe = (observer: Observer<RefreshToken | Error>) => {
    observers.push(observer);
  };

  const unsubscribe = (observer: Observer<RefreshToken | Error>) => {
    const index = observers.indexOf(observer);
    if (index !== -1) {
      observers.splice(index, 1);
    }
  };

  const notify = (data: RefreshToken | Error) => {
    observers.forEach((observer) => observer.callback(data));
  };

  const clear = () => {
    observers.length = 0;
  };

  return {
    subscribe,
    unsubscribe,
    notify,
    clear,
  };
};
