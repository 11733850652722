import { CategoryFieldsDto, CategoryResponseDto } from '@core/category/category-response.dto.interface';
import { ObjectType } from '@private/features/upload/core/models/brand-model.interface';
import { CATEGORY_FIELD_EXCLUDED_NAME, CategoryAttributesDto, CategoryDto, SuggestedCategoryDto, SuggestedSubcategoryDto } from '../dtos';
import { Category } from '@core/category/category.interface';
import { CATEGORY_IDS } from '@core/category/category-ids';
import { SelectFormOption } from '@shared/form/components/select-form-v2/interfaces/select-form-option.interface';

export function mapCategoryDtosToCategories(categories: CategoryDto[]): Category[] {
  return categories.map((category) => {
    return { ...mapCategoryDtoToCategory(category), subcategories: mapCategoryDtosToCategories(category.subcategories) };
  });
}

function mapCategoryDtoToCategory(category: CategoryDto): Category {
  return {
    id: +category.id,
    icon: category.icon,
    name: category.name,
    ...(category.path && { path: category.path }),
    subcategories: category.subcategories,
    attributes: mapTypeAttributes(category.attributes),
    ...(category.parent_id && { parentId: +category.parent_id }),
    ...(category.vertical_id && { verticalId: category.vertical_id }),
    ...(category.category_leaf_selection_mandatory && { categoryLeafSelectionMandatory: category.category_leaf_selection_mandatory }),
    excludedAttributes: getExcluded(category.attributes),
  };
}

export const HERO_CATEGORIES = [CATEGORY_IDS.CAR, CATEGORY_IDS.SERVICES, CATEGORY_IDS.REAL_ESTATE, CATEGORY_IDS.JOBS];

export function mapCategoriesToUploadCategories(categories: CategoryDto[], suggested = false): CategoryResponseDto[] {
  if (suggested) {
    return categories
      .filter((category) => !HERO_CATEGORIES.includes(category.id))
      .map((category) => mapCategoryToUploadCategoryResponse(category, suggested));
  }
  return categories.map((category) => mapCategoryToUploadCategoryResponse(category, suggested));
}

function mapCategoryToUploadCategoryResponse(category: CategoryDto, suggested: boolean): CategoryResponseDto {
  const { id, name, icon, vertical_id, attributes } = category;

  return {
    category_id: id,
    name,
    icon_id: icon,
    vertical_id,
    fields: mapTypeAttributes(attributes),
    suggested,
    excludedAttributes: getExcluded(attributes),
    categoryLeafSelectionMandatory: category.category_leaf_selection_mandatory || false,
  };
}

function mapTypeAttributes(attributes: CategoryAttributesDto): CategoryFieldsDto {
  return {
    ...(attributes.subcategory && { type_of_object: attributes.subcategory }),
    ...(attributes.subcategory_lv2 && { type_of_object_level_2: attributes.subcategory_lv2 }),
    ...(attributes.brand && { brand: attributes.brand }),
    ...(attributes.model && { model: attributes.model }),
    ...(attributes.size && { size: attributes.size }),
    ...(attributes.gender && { gender: attributes.gender }),
    ...(attributes.isbn && { isbn: attributes.isbn }),
    ...(attributes.height_cm && { height_cm: attributes.height_cm }),
    ...(attributes.length_cm && { length_cm: attributes.length_cm }),
    ...(attributes.width_cm && { width_cm: attributes.width_cm }),
  };
}

function getExcluded(attributes: CategoryAttributesDto): (keyof CategoryFieldsDto)[] | null {
  const excludedValue = attributes[CATEGORY_FIELD_EXCLUDED_NAME];

  if (excludedValue?.length) {
    const validKeys: (keyof CategoryFieldsDto)[] = excludedValue.map((value) => value as keyof CategoryFieldsDto);
    return validKeys;
  }

  return null;
}

export function mapCategoriesToObjectTypes(categories: Category[]): ObjectType[] {
  return categories.map((category) => mapCategoryToObjectType(category));
}

function mapCategoryToObjectType(category: Category): ObjectType {
  const objectType: ObjectType = {
    id: `${category.id}`,
    name: category.name,
    hierarchy: [],
    has_children: !!category.subcategories?.length,
    children: category.subcategories?.map((subcategory) => mapCategoryToObjectType(subcategory)),
    ...(category.parentId && { parentId: `${category.parentId}` }),
    icon: category?.icon ? `/assets/icons/filters/categories/${category.icon}.svg` : null,
    ...(category.excludedAttributes && { excludedAttributes: category.excludedAttributes }),
  };

  return objectType;
}

export function mapSuggestedCategoriesToUploadCategories(suggestedCategories: SuggestedCategoryDto[]): CategoryResponseDto[] {
  return mapCategoriesToUploadCategories(
    suggestedCategories.map((suggested) => fromSuggestedToCategoryDto(suggested)),
    true,
  );
}

function fromSuggestedToCategoryDto(suggested: SuggestedCategoryDto): CategoryDto {
  const { id, name, icon, subcategories } = suggested;
  return {
    id,
    name,
    icon,
    vertical_id: 'consumer_goods',
    attributes: {},
    subcategories,
  };
}

export function mapSuggestedCategoriesToUploadCategory(categories: Category[], suggestedCategories: SuggestedCategoryDto[]): Category[] {
  const validSuggestedCategories = getValidSuggestedCategories(categories, suggestedCategories);

  return validSuggestedCategories.map((suggested) => {
    if (!suggested.parent && suggested.has_children) {
      const matchingCategory = getMatchingCategory(categories, suggested);

      if (matchingCategory) {
        return mapSuggestedCategoriesToCategory(matchingCategory, suggested);
      }
    }

    const matchingCategory = flattenDeep(categories).find((category) => {
      return category.id === suggested.id;
    });

    return mapSuggestedCategoriesToCategory(matchingCategory, suggested);
  });
}

function getMatchingCategory(categories: Category[], suggested: SuggestedCategoryDto): Category {
  return categories.find((category) => {
    return category.id === suggested.id;
  });
}

function getValidSuggestedCategories(categories: Category[], suggestedCategories: SuggestedCategoryDto[]): SuggestedCategoryDto[] {
  const validCategories = categories.filter((category) => !HERO_CATEGORIES.includes(category.id));
  const flattenedValidCategories = validCategories.reduce(
    (acc, val) => (val.subcategories?.length ? acc.concat(flattenDeep(val.subcategories), val) : acc.concat(val)),
    [],
  );
  const validSuggestedCategories = suggestedCategories.filter((suggestedOption) =>
    flattenedValidCategories.some((category) => category.id === suggestedOption.id),
  );

  return validSuggestedCategories;
}

function mapSuggestedCategoriesToCategory(suggestedCategories: Category, suggested?: SuggestedCategoryDto): Category {
  const { id, name, parentId, path, icon, subcategories } = suggestedCategories;
  return {
    id,
    name,
    parentId: parentId,
    icon: icon ?? suggested.icon,
    path,
    verticalId: 'consumer_goods',
    attributes: {},
    subcategories: subcategories ? subcategories : [],
    suggested: true,
    hasChildren: !!(subcategories.length || suggested.has_children),
    rootParentId: getRootParentId(suggested),
  };
}

function getRootParentId(category: SuggestedCategoryDto): number {
  if (!category?.parent) {
    return category.id;
  }
  return getRootParentId(category?.parent);
}

export function mapSuggestedSubcategoriesToUploadSubcategories(suggestedSubcategories: SuggestedSubcategoryDto[]): SelectFormOption[] {
  return suggestedSubcategories.map((subcategory) => {
    const mappedSubcategory: SelectFormOption = {
      value: subcategory.id.toString(),
      label: subcategory.name,
      sublabel: subcategory.path,
      ...(subcategory.has_children !== undefined && { hasChildren: subcategory.has_children }),

      suggested: true,
    };
    return mappedSubcategory;
  });
}

export function mapCategoriesToFashionGenderAttribute(categories: CategoryDto[]): boolean {
  const fashionCategory = categories.find((category) => category.id === CATEGORY_IDS.FASHION_ACCESSORIES);

  if (!fashionCategory) return false;

  return !!fashionCategory.attributes['gender'];
}

export function mapCategoriesToOptions(subcategories: Category[]): SelectFormOption[] {
  return subcategories.map((subcategory) => {
    const mappedSubcategory: SelectFormOption = {
      value: `${subcategory.id}`,
      label: subcategory.name,
      sublabel: subcategory.path && subcategory.suggested ? subcategory.path : buildLabel(subcategory),
      children: mapCategoriesToOptions(subcategory.subcategories),
      ...(subcategory.parentId && { parentValue: `${subcategory.parentId}` }),
      icon: subcategory.icon,
      suggested: !!subcategory.suggested,
      ...(subcategory.hasChildren !== undefined && { hasChildren: subcategory.hasChildren }),
      rootParentId: subcategory?.rootParentId,
      categoryLeafSelectionMandatory: subcategory?.categoryLeafSelectionMandatory ?? null,
      path: subcategory.path,
    };
    return mappedSubcategory;
  });
}

function buildLabel(subcategory: Category): string {
  const subcategoriesList = subcategory.subcategories;
  let label: string[] = [];

  if (!!subcategoriesList.length && subcategory.parentId) {
    subcategoriesList.forEach((childCategory) => {
      label.push(childCategory.name);
    });

    return label.join(', ').toString();
  }
  return '';
}

function flattenDeep(arr1: Category[]): Category[] {
  return arr1.reduce((acc, val) => (val.subcategories?.length ? acc.concat(flattenDeep(val.subcategories), val) : acc.concat(val)), []);
}

export function isConsumerGoodsCategory(categoryId: number): boolean {
  return areCategoryIdsEqual(categoryId, CATEGORY_IDS.CONSUMER_GOODS);
}

export function isAHeroCategory(categoryId: number): boolean {
  return HERO_CATEGORIES.includes(categoryId);
}

export function isJobsCategory(categoryId: number): boolean {
  return areCategoryIdsEqual(categoryId, CATEGORY_IDS.JOBS);
}

export function isServicesCategory(categoryId: number): boolean {
  return areCategoryIdsEqual(categoryId, CATEGORY_IDS.SERVICES);
}

export function isFashionCategory(categoryId: number): boolean {
  return areCategoryIdsEqual(categoryId, CATEGORY_IDS.FASHION_ACCESSORIES);
}

function areCategoryIdsEqual(categoryId: number, categoryIdToCompare: number): boolean {
  return categoryId === categoryIdToCompare;
}
