import { mapImageHttpToHttps } from '../../../core/mappers/image-mapper';
import { ExperimentatorCollectionDataDto, ExperimentatorCollectionDto } from '../dtos/experimentator-collection-response.dto';
import {
  ExperimentatorResponseCollections,
  ExperimentatorCollectionData,
} from '../interfaces/experimentator-collection-response.interface';
import { experimentatorMetaResponseMapper } from './experimentator-meta.mapper';

export const experimentatorCollectionsResponseMapper = (response: ExperimentatorCollectionDto): ExperimentatorResponseCollections => {
  const newData: ExperimentatorCollectionData[] = response.data.map((data: ExperimentatorCollectionDataDto) => {
    return {
      images: data.images.map((image) => mapImageHttpToHttps(image)),
      itemCount: data.item_count,
      title: data.title,
      action: {
        actionType: data.action.action_type,
        payload: {
          linkUrl: data.action.payload.link_url,
        },
      },
    };
  });

  return {
    data: newData,
    meta: experimentatorMetaResponseMapper(response.meta),
  };
};
