import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, map, take, tap, switchMap } from 'rxjs/operators';
import { AccessTokenService } from '@core/access-token/services/access-token.service';
import { LogoutService } from '@core/logout/logout.service';
import { RefreshToken } from '@api/core/model/auth/refresh-token/refresh-token.interface';
import { AuthApiService } from '@api/auth/auth-api.service';

@Injectable()
export class AuthService {
  public isRefreshing = new BehaviorSubject<boolean>(false);
  public refreshToken$: Observable<RefreshToken>;
  private refreshTokenSubject$: BehaviorSubject<RefreshToken | null>;

  constructor(
    private authApiService: AuthApiService,
    private logoutService: LogoutService,
    private accessTokenService: AccessTokenService,
  ) {
    this.refreshTokenSubject$ = new BehaviorSubject<RefreshToken | null>(undefined);
    this.refreshToken$ = this.refreshTokenSubject$.pipe(
      filter((refreshToken) => refreshToken !== null),
      take(1),
    );
  }

  public refreshToken(): Observable<RefreshToken> {
    const refreshService = this.authApiService.refresh().pipe(
      tap(({ token }) => {
        this.accessTokenService.storeAccessToken(token);
      }),
      map((refreshToken) => refreshToken),
    );
    if (this.refreshTokenSubject$.value === null) {
      return this.refreshToken$;
    }
    this.refreshTokenSubject$.next(null);

    return refreshService.pipe(
      tap((refreshToken) => {
        this.refreshTokenSubject$.next(refreshToken);
      }),
      catchError((error) => {
        return this.logout().pipe(
          switchMap(() => {
            throw error;
          }),
        );
      }),
    );
  }

  public logout(): Observable<void> {
    return new Observable((observer) => {
      this.logoutService.finalizeUserSession();
      observer.next();
      observer.complete();
    });
  }
}
