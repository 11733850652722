import { HTTP_CLIENT_HEADERS } from '#api-layer/core/http/http-client.constants';
import { HttpClientInterceptor, HttpClientResponseOnRejectedInterceptor } from '../http-client.types';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { HTTP_RESPONSE_CODE } from '../http-client.codes';
import { LOGOUT_ENDPOINT } from '../../../apis/logout/endpoints';
import { RefreshToken } from '../../../apis/auth/refresh/interfaces/refresh-response.interface';
import { HttpClient } from '../http-client.interface';
import { Observer } from '../../observable/observable.types';
import { AUTH_REFRESH_ENDPOINT } from '../../../apis/auth/refresh/endpoints';
import { RefreshTokenRepository } from '../../authorization/refresh-token/refresh-token-repository.interface';
import { LOGIN_ENDPOINT } from '../../../apis/auth/access/login/endpoints';

interface UnauthorizedInterceptorOptions {
  baseUrl: string;
  httpClient: HttpClient;
  refresh: () => Promise<RefreshToken>;
  logout: (options: unknown) => Promise<void>;
  refreshTokenRepository: RefreshTokenRepository;
}

interface HandleAuthorizationError {
  error: AxiosError<AuthorizationError, unknown>;
  httpClient: HttpClient;
  refresh: () => Promise<RefreshToken>;
  logout: (options: unknown) => Promise<void>;
  refreshTokenRepository: RefreshTokenRepository;
}

export const AUTHORIZATION_ERROR_CODES = {
  ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
  REFRESH_TOKEN_EXPIRED: 'REFRESH_TOKEN_EXPIRED',
} as const;

export type AuthorizationErrorCode = (typeof AUTHORIZATION_ERROR_CODES)[keyof typeof AUTHORIZATION_ERROR_CODES];

export interface AuthorizationError {
  code: AuthorizationErrorCode;
}

type CreateUnauthorizedInterceptor = (options: UnauthorizedInterceptorOptions) => HttpClientInterceptor;

interface HttpClientRequestConfig extends AxiosRequestConfig<unknown> {
  retry?: boolean;
}

const handleAuthorizationError = async ({ error, httpClient, refresh, logout, refreshTokenRepository }: HandleAuthorizationError) => {
  if (!error.config) {
    return Promise.reject(error);
  }

  const originalConfig: HttpClientRequestConfig = error.config;
  const isRefreshing = refreshTokenRepository.isRefreshing();
  if (!isRefreshing) {
    refreshTokenRepository.startIsRefreshing();
    if (originalConfig.retry) {
      refreshTokenRepository.refreshToken$.clear();
      await logout({});
      return Promise.reject(error);
    }
    originalConfig.retry = true;
    let refreshedToken: RefreshToken;
    try {
      refreshedToken = await refresh();
      finishRefreshTokenProcess(refreshTokenRepository, refreshedToken);
    } catch (error) {
      finishRefreshTokenProcess(refreshTokenRepository, new Error('Refresh token error'));
      await logout({});
      return Promise.reject(error);
    }

    if (!originalConfig.headers) {
      return Promise.reject(error);
    }

    originalConfig.headers[HTTP_CLIENT_HEADERS.AUTHORIZATION] = `Bearer ${refreshedToken.token}`;
    const response = await httpClient(originalConfig);

    return response;
  }
  return new Promise((resolve, reject) => {
    const observer: Observer<RefreshToken | Error> = {
      callback: async (data) => {
        if (data instanceof Error) {
          return reject(data);
        }
        if (!originalConfig.headers) {
          return reject(error);
        }
        originalConfig.headers[HTTP_CLIENT_HEADERS.AUTHORIZATION] = `Bearer ${data.token}`;
        try {
          const response = await httpClient(originalConfig);
          resolve(response);
        } catch (retryError) {
          reject(retryError);
        }
      },
    };
    refreshTokenRepository.refreshToken$.subscribe(observer);
  });
};

const finishRefreshTokenProcess = (refreshTokenRepository: RefreshTokenRepository, notification: Error | RefreshToken) => {
  refreshTokenRepository.clearIsRefreshing();
  refreshTokenRepository.refreshToken$.notify(notification);
  refreshTokenRepository.refreshToken$.clear();
};

export const createUnauthorizedInterceptor: CreateUnauthorizedInterceptor = (options) => {
  const { baseUrl, httpClient, logout, refresh, refreshTokenRepository } = options;

  const responseOnRejectedInterceptor: HttpClientResponseOnRejectedInterceptor = async (error) => {
    if (axios.isAxiosError<AuthorizationError>(error)) {
      const { config, response } = error;
      if (!config?.url || !response) {
        return Promise.reject(error);
      }

      const isWallapopRequest = config.url.startsWith(baseUrl);
      const isNotLoginEndpoint = !config.url.includes(LOGIN_ENDPOINT);
      const isNotLogoutEndpoint = !config.url.includes(LOGOUT_ENDPOINT);
      const isNotRefreshEndpoint = !config.url.includes(AUTH_REFRESH_ENDPOINT);
      const isNotLocalhostDomain = !config.url.startsWith('http://localhost');
      const unauthorizedReason = response.headers[HTTP_CLIENT_HEADERS.CUSTOM_WALLAPOP_UNAUTHORIZED];
      const isRefreshTokenExpired = unauthorizedReason === AUTHORIZATION_ERROR_CODES.REFRESH_TOKEN_EXPIRED;
      const isAccessTokenExpired = unauthorizedReason === AUTHORIZATION_ERROR_CODES.ACCESS_TOKEN_EXPIRED;
      const canPerformUnauthorizedRequests =
        isWallapopRequest && isNotLocalhostDomain && isNotLoginEndpoint && isNotLogoutEndpoint && isNotRefreshEndpoint;
      const isPlainUnauthorizedError =
        response.status === HTTP_RESPONSE_CODE.UNAUTHORIZED_ERROR && !response.headers[HTTP_CLIENT_HEADERS.CUSTOM_WALLAPOP_UNAUTHORIZED];

      if (!canPerformUnauthorizedRequests) return Promise.reject(error);

      if (isPlainUnauthorizedError || isRefreshTokenExpired) {
        await logout({
          isPlainUnauthorizedError: `${isPlainUnauthorizedError}`,
          isRefreshTokenExpired: `${isRefreshTokenExpired}`,
        });
      }

      if (isAccessTokenExpired) {
        return handleAuthorizationError({ error, httpClient, refresh, logout, refreshTokenRepository });
      }

      return Promise.reject(error);
    }
  };

  return {
    response: {
      onRejected: responseOnRejectedInterceptor,
    },
  };
};
