import { Environment } from './interfaces';

export const environment: Environment = {
  production: false,
  name: 'beta',
  appDomain: `.beta.wallapop.com/`,
  protocol: 'https://',
  baseUrl: 'https://api.beta.wallapop.com/',
  webBackend: 'http://web-backend.wallapop.beta/',
  cdnUrl: 'https://web-static.beta.wallapop.com/',
  cookieSuffix: 'Beta',
  mParticleKey: 'us1-04eb03d316b54f4f99265340abe886e6',
  analytics: {
    url: 'https://tracking.beta.wallapop.com',
    writeKey: '2oQYiYlJlPD9bYds1bGUmajcyjz',
    enabled: true,
  },
  threatMetrixOrgId: '6ldgf22r',
  threatMetrixProfilingDomain: 'clear.wallapop.com',
  amplitudeWebDeploymentKey: 'client-9XxS03kKfG3MMsAfafVJz8h9aJmu0qZy',
  amplitudeAnalyticsKey: 'bdd4a9086e65883af24a46901a645e8a',
  googleClientId: '405640933858-64hjsj5970aqdcqp9ibnr6frfkpq4mcv.apps.googleusercontent.com',
  facebookAppId: '498622033610361',
  oneTrustId: '89536a6a-67a8-4b93-b6a2-06e1cbcfc48f-test',
  brazeKey: '516bd193-25b2-48b9-b79d-f8516f104d2f',
  brazeBaseUrl: 'sdk.iad-01.braze.com',
  featureFlagEndpoint: 'https://feature-flag.beta.wallapop.com/',
  sentry: {
    allowUrls: [/^https?:\/\/(.*)\.beta\.wallapop\.com/],
    denyUrls: [/^https?:\/\/(.*)\.beta\.wallapop\.com\/assets/],
  },
  tmxOrgId: '6ldgf22r',
  isLocal: false,
  awsLocationService: {
    mapConfig: {
      apiKey:
        'v1.public.eyJqdGkiOiIwYzY0YjBiOS03MjcwLTRlMTYtOTlmZi1kYThmZmIzM2YwMjkifUb1OmHXDJWppjEU9vPvPv8GoZxhnp2GLfoVOcWzG_xdirGqII4-yuSmK9h14hC0VhuEryInCf4pvSev3lPMmW2XW1tEWXR1HinFrT6AD_ihNqrEV0KRvQrgSSaYKYc4TSDHZHaiyhJqjq3xPUPHMWBq64baBhtaGP-mwrcF5PQfoi7l7f9XlI8dL_Sv97lE7G4qsiOVO9BFSj58ISKl3JiFdrYpu_F7Fgj0Q17Dc_q92EoWcKHEIthxARsE8YypDymbF-NZ-bsKQbsHguMKk2rE-ifrQgfDmq2VAVC8m9uBMij8wVX1SCwqwfaued3gUYdYtWo1JUiZwVtiJmoOrjg.ZGQzZDY2OGQtMWQxMy00ZTEwLWIyZGUtOGVjYzUzMjU3OGE4',
      region: 'eu-west-1',
      mapName: 'map',
    },
    geoConfig: {
      apiKey:
        'v1.public.eyJqdGkiOiIwMDllNDQyNy0wMWM3LTQwYzQtYTgzMy1mOTNhZGEyYmMyNzcifXcBXPw590AOsjkx7IkHZClCl5DFV1GVGFYK965LUnpKEPZI0tNsFNZmTDrW1iSNQGaPXOTITVJfwYc_55eitohO4Jk3oVCXeoKIbYEjKyk_KgpPkOigOGxiANJn2IWvVVANfK5WfzOJXVSP-9sThzBjcHyZ6i4FXgYOZ9SjwlO3O5PhUtXfNCFAeeD5KdYeDJCSN9uIaVycTSVl1tRZNjE0a7BXFmW406Ekd5SJSiUxfudeeXUEbX7kD9RV5dtJAPinDNtZSkT1gZ2xcHaZ1uXs4AnzWRk2zFKQoKo4F1vtCBM7Na71VsHH6JXmY50EOKk997qR7A9wY0JfGWXKZgk.ZGQzZDY2OGQtMWQxMy00ZTEwLWIyZGUtOGVjYzUzMjU3OGE4',
      region: 'eu-west-1',
      indexName: 'places',
    },
  },
  tiktokKey: 'BUG0854D6QNLQU18NMK0',
};
