import { ExperimentatorCategoryData } from '@wallapop/api-layer/apis/experimentator/interfaces/experimentator-category-response.interface';
import { getViewAllCategoryOption } from './get-view-all-category';

interface SeeMoreThisCategoryOptionParams {
  categories: ExperimentatorCategoryData[];
  rootCategoryId: number;
  isTopCategory: boolean;
  siteUrl: string;
}

export function addViewAllOptionToCategory(
  category: ExperimentatorCategoryData,
  siteUrl: string,
  isTopCategory: boolean,
  rootCategoryId: number,
): ExperimentatorCategoryData {
  return addViewAllOption({
    categories: [category],
    rootCategoryId,
    isTopCategory,
    siteUrl,
  })[0];
}

export function addViewAllOption({
  categories,
  rootCategoryId,
  isTopCategory,
  siteUrl,
}: SeeMoreThisCategoryOptionParams): ExperimentatorCategoryData[] {
  return categories.map((category: ExperimentatorCategoryData) => {
    if (!hasSubcategories(category)) return category;

    const viewAllOption = getViewAllCategoryOption({
      url: siteUrl,
      categoryId: category.categoryId,
      rootCategoryId,
      isTopCategory,
    });

    return {
      ...category,
      subcategories: [
        viewAllOption,
        ...addViewAllOption({ categories: category.subcategories, rootCategoryId, isTopCategory: false, siteUrl }),
      ],
    };
  });
}

function hasSubcategories(category: ExperimentatorCategoryData): boolean {
  return category.subcategories && category.subcategories.length > 0;
}
