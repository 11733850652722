import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { FavoriteSearch, FavoriteSearchesQuery } from '@api/favorite-searches/model/favorite-search.interface';
import { FavoriteSearchesHttpService } from './http/favorite-searches-http.service';
import { AnalyticsService } from '@core/analytics/analytics.service';
import {
  AnalyticsEvent,
  AnalyticsPageView,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_SCREEN_EVENT_NAMES,
  ANALYTICS_EVENT_TYPES,
  ClickSnackBar,
  FavoriteSearch as FavoriteSearchEvent,
  ImpressionFavoriteSearchesTooltip,
  SCREEN_IDS,
  ViewFavoriteSearches,
} from '@wallapop/analytics/constants';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { mapFiltersFromFavoriteSearchId, mapFavoriteSearches } from './mappers/favorite-searches-mapper';
import { SORT_BY } from '@api/core/model';
import { SearchPagination } from '@public/features/search/interfaces/search-pagination.interface';
import { NewOldFavoriteSearchResponse } from './dtos/favorite-search-new-old-dto.interface';
import { CurrentSearchInfoService } from '@core/search/current-search-info/current-search-info.service';
import { FavoriteSearchesTotalHits } from './dtos/favorite-searches-total-hits';
import { BulletsService } from '@core/bullets/bullets.service';
import { mapFavoriteSearchesHitsToBullet } from './mappers/favorite-searches-hits-mapper';
import { Bullet, BulletType } from '@core/bullets/models/bullets.interface';
import { AccessTokenService } from '@core/access-token/services/access-token.service';
import { SEARCH_ITEMS_MINIMAL_LENGTH } from '@public/features/search/core/services/constants/search-item-max';
import { CachedItems } from './model/favorite-search-cached-item.interface';
import { HEADER_NAMES } from '@core/constants/header-constants';
import { ItemCardMapper, SearchApiItemMapper } from '@api/search/search/mappers/search-api-item-mapper';
import { UserService } from '@core/user/user.service';
import { Params } from '@angular/router';

@Injectable()
export class FavoriteSearchesApiService {
  private _nextPage: string | null = null;
  private order: SORT_BY | null = null;
  private bubble: string | null = null;
  private searchId: string;
  private readonly _newFavoriteSearchCreated$: ReplaySubject<string> = new ReplaySubject();
  private isLoggedUser: Subject<Boolean> = new Subject();

  public get newFavoriteSearchCreated$(): Observable<string> {
    return this._newFavoriteSearchCreated$.asObservable();
  }

  constructor(
    private favoriteSearchesHttpService: FavoriteSearchesHttpService,
    private analyticsService: AnalyticsService,
    private currentSearchInfoService: CurrentSearchInfoService,
    private bulletsService: BulletsService,
    private accessTokenService: AccessTokenService,
    private userService: UserService,
  ) {
    this.currentSearchInfoService.searchId$.subscribe((searchId) => {
      if (searchId) this.searchId = searchId;
    });

    if (!!this.accessTokenService.accessToken) {
      this.updateTotalHits().subscribe();
    } else {
      this.bulletsService.bullets = { show: false, type: BulletType.FAVORITE_SEARCHES_HITS };
    }

    this.isLoggedUser.next(this.userService.isLogged);
  }

  public get(): Observable<FavoriteSearch[]> {
    return this.favoriteSearchesHttpService.get().pipe(
      map((response) => {
        return mapFavoriteSearches(response);
      }),
    );
  }

  public getQueryParamsFromFavoriteSearchId(favoriteSearchId: string): Observable<FavoriteSearchesQuery> {
    return this.favoriteSearchesHttpService.getFavoriteSearchById(favoriteSearchId).pipe(
      map((response) => {
        return mapFiltersFromFavoriteSearchId(response.body);
      }),
    );
  }

  public enable(favoriteSearchId: string): Observable<void> {
    return this.favoriteSearchesHttpService.enable(favoriteSearchId);
  }

  public async create(filters: Params, emit = true): Promise<string> {
    const searchParams = new URLSearchParams(filters).toString();

    const response = await this.favoriteSearchesHttpService.create(searchParams).toPromise();
    const lastFavoriteSearchId = this.getFavoriteSearchIdFromHeaders(response);
    if (emit) {
      this._newFavoriteSearchCreated$.next(lastFavoriteSearchId);
    }
    return lastFavoriteSearchId;
  }

  public delete(favoriteSearchId: string): Observable<void> {
    return this.favoriteSearchesHttpService.delete(favoriteSearchId);
  }

  public exist(filters: Params): Observable<HttpResponse<HttpHeaderResponse>> {
    const mappedFilters = new URLSearchParams(filters).toString();
    const areFiltersValid = mappedFilters.split('&').some((f) => f.includes('category_ids') || f.includes('keywords'));

    return this.userService.isLogged && areFiltersValid ? this.favoriteSearchesHttpService.exist(mappedFilters) : of();
  }

  public updateTotalHits(): Observable<void | Bullet> {
    return this.favoriteSearchesHttpService.getTotalHits().pipe(
      map((hits: FavoriteSearchesTotalHits) => {
        const hitsToBullets = mapFavoriteSearchesHitsToBullet(hits);
        this.bulletsService.bullets = hitsToBullets;
      }),
      catchError((err) => {
        return of((this.bulletsService.bullets = { show: false, type: BulletType.FAVORITE_SEARCHES_HITS }));
      }),
    );
  }

  public clear(): void {
    this.nextPage = null;
  }

  public getFavoriteSearchIdFromHeaders(resp: HttpResponse<HttpHeaderResponse>): string {
    const { headers } = resp;
    const locationResponse = headers.get('Location');
    const splittedLocationhttpResponse = locationResponse.split('/');
    return splittedLocationhttpResponse[splittedLocationhttpResponse.length - 1];
  }

  public trackFavoriteSearch(
    favoriteSearchId: string,
    screenId: 111 | 323,
    source?: 'search_box' | 'recent_searches' | 'floating_banner' | null,
  ): void {
    const event: AnalyticsEvent<FavoriteSearchEvent> = {
      name: ANALYTICS_EVENT_NAMES.FavoriteSearch,
      eventType: ANALYTICS_EVENT_TYPES.UserPreference,
      attributes: {
        screenId: screenId,
        savedSearchId: favoriteSearchId,
        source: source,
      },
    };
    this.analyticsService.trackEvent(event);
  }

  public trackUnfavoriteSearch(favoriteSearchId: string, screenId: 111 | 323): void {
    const event: AnalyticsEvent<FavoriteSearchEvent> = {
      name: ANALYTICS_EVENT_NAMES.UnfavoriteSearch,
      eventType: ANALYTICS_EVENT_TYPES.UserPreference,
      attributes: {
        screenId: screenId,
        savedSearchId: favoriteSearchId,
      },
    };
    this.analyticsService.trackEvent(event);
  }

  public trackReactivateFavoriteSearch(favoriteSearchId: string, screenId: 323): void {
    const event: AnalyticsEvent<FavoriteSearchEvent> = {
      name: ANALYTICS_EVENT_NAMES.ReactivateFavoriteSearch,
      eventType: ANALYTICS_EVENT_TYPES.UserPreference,
      attributes: {
        screenId: screenId,
        savedSearchId: favoriteSearchId,
      },
    };
    this.analyticsService.trackEvent(event);
  }

  public trackViewFavoriteSearches(screenId: 323, numberOfSearches: number): void {
    const pageViewEvent: AnalyticsPageView<ViewFavoriteSearches> = {
      name: ANALYTICS_SCREEN_EVENT_NAMES.ViewFavoriteSearches,
      attributes: {
        screenId: screenId,
        numberOfFavoriteSearches: numberOfSearches,
      },
    };
    this.analyticsService.trackPageView(pageViewEvent);
  }

  public trackClickFavoriteSearches(favoriteSearchId: string, screenId: 323): void {
    const event: AnalyticsEvent<FavoriteSearchEvent> = {
      name: ANALYTICS_EVENT_NAMES.ClickFavoriteSearch,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        screenId: screenId,
        savedSearchId: favoriteSearchId,
      },
    };
    this.analyticsService.trackEvent(event);
  }

  public trackImpressionFavoriteSearchTooltip(source: 'repeat_search' | 'first_search' | 'floating_banner', screenId: 111) {
    const event: AnalyticsEvent<ImpressionFavoriteSearchesTooltip> = {
      name: ANALYTICS_EVENT_NAMES.ImpressionFavoriteSearchesTooltip,
      attributes: {
        searchId: this.searchId,
        screenId,
        source,
      },
      eventType: ANALYTICS_EVENT_TYPES.Other,
    };
    this.analyticsService.trackEvent(event);
  }

  public trackClickFavoriteSearchFeedback(): void {
    const event: AnalyticsEvent<ClickSnackBar> = {
      name: ANALYTICS_EVENT_NAMES.ClickSnackBar,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        screenId: SCREEN_IDS.Search,
        source: 'FavoriteSearchFeedback',
        cta: 'SeeFavorites',
      },
    };
    this.analyticsService.trackEvent(event);
  }

  public getFavoriteSearch(favoriteSearchId?: string, categoryId?: string, cachedItems?: CachedItems): Observable<SearchPagination> {
    const handleGetFavoriteSearch = this.handleGetFavoriteSearch(categoryId);

    if (this.nextPage) {
      return handleGetFavoriteSearch(this.favoriteSearchesHttpService.getNextFavoriteSearch(this.nextPage)).pipe(
        switchMap(this.checkLoadMoreItems(cachedItems, favoriteSearchId, categoryId)),
      );
    } else {
      return handleGetFavoriteSearch(this.favoriteSearchesHttpService.getFavoriteSearch(favoriteSearchId)).pipe(
        switchMap(this.checkLoadMoreItems(cachedItems, favoriteSearchId, categoryId)),
      );
    }
  }

  private get nextPage(): string {
    return this._nextPage;
  }

  private set nextPage(nextPage: string) {
    this._nextPage = nextPage;
  }

  private handleGetFavoriteSearch(categoryId: string) {
    return (observable: Observable<HttpResponse<NewOldFavoriteSearchResponse>>) => {
      return observable.pipe(
        tap(({ headers, body }: HttpResponse<NewOldFavoriteSearchResponse>) => {
          const nextPage: string = headers.get(HEADER_NAMES.NEXT_PAGE);
          this.order = body.old.order;
          this.bubble = body.old.bubble;
          this.nextPage = nextPage;
        }),
        map(({ body }: HttpResponse<NewOldFavoriteSearchResponse>) => {
          const mapper: ItemCardMapper<NewOldFavoriteSearchResponse> = SearchApiItemMapper(categoryId);
          return {
            old: mapper(body.old),
            new: mapper(body.new),
          };
        }),
        map((newAndOldItems) => ({
          items: newAndOldItems.old,
          hasMore: !!this.nextPage,
          searchId: null,
          sortBy: SORT_BY.NEWEST,
          bubble: this.bubble,
          newItems: newAndOldItems.new,
        })),
      );
    };
  }

  private checkLoadMoreItems(
    cachedItems: CachedItems,
    favoriteSearchId: string,
    categoryId: string,
  ): (searchPagination: SearchPagination) => Observable<SearchPagination> {
    return (searchPagination: SearchPagination): Observable<SearchPagination> => {
      const { items = [], newItems = [] } = cachedItems || {};

      const parsedSearchPagination: SearchPagination = {
        ...searchPagination,
        items: [...items, ...searchPagination.items],
        newItems: [...newItems, ...searchPagination.newItems],
      };

      const loadMoreItems =
        parsedSearchPagination.items.length + parsedSearchPagination.newItems.length < SEARCH_ITEMS_MINIMAL_LENGTH &&
        parsedSearchPagination.hasMore;

      if (loadMoreItems) {
        return this.getFavoriteSearch(favoriteSearchId, categoryId, {
          items: parsedSearchPagination.items,
          newItems: parsedSearchPagination.newItems,
        });
      }

      return of(parsedSearchPagination);
    };
  }
}
