import type { CurrencyCode } from '#api-layer/core/interfaces/money.interface';
import { mapImageHttpToHttps } from '../../../core/mappers/image-mapper';
import { ExperimentatorItemDto } from '../dtos/experimentator-item-response.dto';
import { ExperimentatorItemData, ExperimentatorResponseItems } from '../interfaces/experimentator-item-response.interface';
import { experimentatorMetaResponseMapper } from './experimentator-meta.mapper';

export const experimentatorItemsResponseMapper = (response: ExperimentatorItemDto): ExperimentatorResponseItems => {
  const newData: ExperimentatorItemData[] = response.data.map((data) => {
    return {
      bump: data.bump?.type || null,
      categoryId: data.category_id,
      description: data.description,
      id: data.id,
      images: data.images.map((image) => mapImageHttpToHttps(image)),
      isFavorited: data.favorited?.flag,
      isFavoriteable: data.favoriteable?.flag,
      isRefurbished: data.refurbished?.flag || false,
      isPro: data.pro?.flag || false,
      isReserved: data.reserved?.flag,
      bumpIsHidden: data.bump?.hidden || false,
      price: { amount: data.price?.amount, currency: data.price?.currency as CurrencyCode },
      title: data.title,
      slug: data.web_slug,
      shippable: data.shipping && {
        isItemShippable: data.shipping.item_is_shippable,
        userAllowsShipping: data.shipping.user_allows_shipping,
        costConfigurationId: data.shipping.cost_configuration_id || null,
      },
      isTopProfile: data.is_top_profile?.flag,
    };
  });

  return {
    data: newData,
    meta: experimentatorMetaResponseMapper(response.meta),
  } as ExperimentatorResponseItems;
};
