import { ItemCard, ItemCardShipping } from '@public/core/interfaces/item-card.interface';
import { SearchItemConsumerGoodsResponseDto, SearchItemConsumerGoodsShippingDto, SearchResponseDto } from '../../dtos';
import { SearchItemImageMapper } from '../models/search-image-response.mapper';
import { DELIVERY_INFO_ITEM_COSTS } from '@core/item/item.service';

export function SearchItemConsumerGoodsResponseMapper({
  search_objects,
}: SearchResponseDto<SearchItemConsumerGoodsResponseDto>): ItemCard[] {
  return search_objects.map((item: SearchItemConsumerGoodsResponseDto) => ({
    id: item.id,
    title: item.title,
    description: item.description,
    salePrice: item.price,
    currencyCode: item.currency,
    distance: item.distance,
    ownerId: item.user.id,
    webSlug: item.web_slug,
    images: item.images.map(SearchItemImageMapper),
    flags: {
      pending: item.flags.pending,
      sold: item.flags.sold,
      favorite: item.favorited,
      reserved: item.flags.reserved,
      banned: item.flags.banned,
      expired: item.flags.expired,
      onhold: item.flags.onhold,
    },
    bumpFlags: {
      bumped: item.visibility_flags.bumped,
      highlighted: item.visibility_flags.highlighted,
      country_bumped: item.visibility_flags.country_bumped,
      boosted: item.visibility_flags.boosted,
    },
    categoryId: item.category_id,
    saleConditions: {
      fix_price: false,
      exchange_allowed: false,
      cost_configuration_id: item.shipping?.cost_configuration_id || null,
    },
    isRefurbished: item.is_refurbished,
    userAllowsShipping: buildUserAllowsShipping(item),
    specs: ItemConsumerGoodsSpecsMapper(item),
    taxonomy: item?.taxonomy,
    ...(item.discount && {
      discount: {
        percentage: item.discount.percentage,
        previousPrice: item.discount.previous_price.amount,
      },
    }),
    ...(!!item.shipping && {
      shipping: buildShipping(item.shipping),
    }),
  }));
}

export function ItemConsumerGoodsSpecsMapper(content: SearchItemConsumerGoodsResponseDto): string[] {
  let specs: string[] = [];

  if (content.type_attributes) {
    const { brand, model, gender, size, condition } = content.type_attributes;

    if (brand) {
      specs.push(brand.text);
    }
    if (model) {
      specs.push(model.text);
    }
    if (gender) {
      specs.push(gender.text);
    }
    if (size) {
      specs.push(size.text);
    }
    if (condition) {
      specs.push(condition.text);
    }
  }
  return specs;
}

function buildUserAllowsShipping(item: SearchItemConsumerGoodsResponseDto): boolean {
  if (item.shipping) {
    return item.shipping.item_is_shippable && item.shipping.user_allows_shipping;
  }

  return item.shipping_allowed;
}

function buildShipping(shipping: SearchItemConsumerGoodsShippingDto): ItemCardShipping {
  return {
    onlyFaceToFace: !shipping.user_allows_shipping || !shipping.item_is_shippable,
    shippingAvailable: shipping.item_is_shippable && shipping.user_allows_shipping,
    hasFreeShipping:
      shipping.item_is_shippable &&
      shipping.user_allows_shipping &&
      shipping.cost_configuration_id === DELIVERY_INFO_ITEM_COSTS.SELLER_PAYS,
  };
}
