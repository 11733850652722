export const SEARCH_UNIFIED_QUERY_PARAM = {
  KEYWORDS: 'keywords',
  CATEGORY_ID: 'category_id',
  SUBCATEGORY_IDS: 'subcategory_ids',
  MAX_PRICE: 'max_sale_price',
  MIN_PRICE: 'min_sale_price',
  CONDITION: 'condition',
  DISTANCE_IN_KM: 'distance_in_km',
  FASHION_SIZE: 'fashion_size',
  BRAND: 'brand',
  MODEL: 'model',
  COLOR: 'color',
  IS_SHIPPABLE: 'is_shippable',
  IS_REFURBISHED: 'is_refurbished',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  ORDER_BY: 'order_by',
  TIME_FILTER: 'time_filter',
  SOURCE: 'source',
  SEARCH_ID: 'search_id',
  SEARCH_FILTERS_CONTEXT: 'search_filters_context',
} as const;

export const SEARCH_UNIFIED_NEXT_PAGE_PARAM = 'next_page';
