import { ExperimentatorCategoryDataDto, ExperimentatorCategoryDto } from '../dtos/experimentator-category-response.dto';
import { ExperimentatorCategoryData, ExperimentatorResponseCategory } from '../interfaces/experimentator-category-response.interface';
import { experimentatorMetaResponseMapper } from './experimentator-meta.mapper';

export const experimentatorCategoriesResponseMapper = (response: ExperimentatorCategoryDto): ExperimentatorResponseCategory => {
  const mappedData: ExperimentatorCategoryData[] = response.data.map((data: ExperimentatorCategoryDataDto) => {
    return experimentatorCategoriesDataResponseMapper(data);
  });

  return {
    data: mappedData,
    meta: experimentatorMetaResponseMapper(response.meta),
  };
};

const experimentatorCategoriesDataResponseMapper = (category: ExperimentatorCategoryDataDto): ExperimentatorCategoryData => {
  return {
    categoryId: Number(category.id),
    title: category.name,
    icon: category.icon || null,
    url: category.action.payload.link_url,
    subcategories: category.subcategories?.map((subcategory) => experimentatorCategoriesDataResponseMapper(subcategory)) || [],
  };
};
