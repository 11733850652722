import { Inject, Injectable } from '@angular/core';
import { ADS_SOURCES, BLOCKTHROUGH_URL, CRITEO_URL } from '@core/ads/constants';
import { CriteoService, GooglePublisherTagService } from '@core/ads/vendors';
import { GEOEDGE_URL } from '@core/ads/vendors/geoedge/geoedge.constants';
import { GeoedgeService } from '@core/ads/vendors/geoedge/geoedge.service';
import { IABVendors } from '@core/ads/vendors/oneTrust/one-trust.constants';
import { OneTrustService } from '@core/ads/vendors/oneTrust/one-trust.service';
import { TcData, TCF_API_COMMAND, TCF_API_VERSION } from '@core/ads/vendors/tcf/tcf.interface';
import { TcfService } from '@core/ads/vendors/tcf/tcf.service';
import { LoadExternalLibsService } from '@core/load-external-libs/load-external-libs.service';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { combineLatest, forkJoin, interval, Observable, zip } from 'rxjs';
import { concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { RelevantYieldService } from '@core/ads/vendors/relevantYield/relevant-yield.service';

@Injectable({
  providedIn: 'root',
})
export class LoadAdsService {
  constructor(
    @Inject(WINDOW_TOKEN) private window: Window,
    private loadExternalLibsService: LoadExternalLibsService,
    private googlePublisherTagService: GooglePublisherTagService,
    private criteoService: CriteoService,
    private oneTrustService: OneTrustService,
    private tcfService: TcfService,
    private geoedgeService: GeoedgeService,
    private relevantYieldService: RelevantYieldService,
  ) {
    this.geoedgeService.init();
  }

  public loadAds(): Observable<void> {
    return combineLatest([this.oneTrustService.isReady$, this.oneTrustService.isConsentDefined$]).pipe(
      filter(([isReady, isConsentDefined]: [boolean, boolean]) => isReady && isConsentDefined),
      switchMap(() => this.loadAdsScripts()),
    );
  }

  public initRelevantYield(): void {
    if (this.relevantYieldService.isLibraryRefDefined()) {
      this.relevantYieldService.init();
    }
  }

  private loadAdsScripts(): Observable<void> {
    return this.loadScriptsByConsent().pipe(
      filter((libs: boolean) => libs),
      tap(() => {
        this.tcfService.tcfApi(TCF_API_COMMAND.ADD_EVENT_LISTENER, TCF_API_VERSION.V2, this.initApstag.bind(this));
      }),
      switchMap(() =>
        forkJoin([
          this.loadExternalLibsService.loadScriptBySource(BLOCKTHROUGH_URL),
          this.loadExternalLibsService.loadScriptBySource(GEOEDGE_URL),
        ]),
      ),
      map(() => null),
    );
  }

  private loadScriptsByConsent(): Observable<boolean> {
    return this.oneTrustService.allowedVendors$.pipe(
      switchMap((allowedVendors: IABVendors[]) => {
        const allowedCriteoVendors: boolean = allowedVendors.includes(IABVendors.CRITEO);
        const adsSources = ADS_SOURCES.filter((source) => source !== CRITEO_URL || allowedCriteoVendors);
        return this.loadScriptsBySource(adsSources);
      }),
    );
  }

  private loadScriptsBySource(source: string[]): Observable<boolean> {
    return this.loadExternalLibsService.loadScriptBySource(source).pipe(
      concatMap(() => this.checkAllLibsBySource()),
      filter((libs: boolean) => libs),
    );
  }

  private checkAllLibsBySource(): Observable<boolean> {
    const libChecks: Observable<boolean>[] = [
      this.checkLibIsDefined(() => this.googlePublisherTagService.isLibraryRefDefined()),
      this.checkLibIsDefined(() => this.criteoService.isLibraryRefDefined()),
      this.checkLibIsDefined(() => this.relevantYieldService.isLibraryRefDefined()),
    ];

    return zip(...libChecks).pipe(map((libs: boolean[]) => libs.every((lib: boolean) => lib)));
  }

  private checkLibIsDefined(fn: () => boolean): Observable<boolean> {
    return interval(100).pipe(
      map(() => fn()),
      filter((defined: boolean) => defined),
      take(1),
    );
  }

  private initApstag(tcData: TcData, success: boolean): void {
    if (success) {
      if (tcData.tcString) {
        this.tcfService.tcfApi(TCF_API_COMMAND.REMOVE_EVENT_LISTENER, TCF_API_VERSION.V2, this.initApstag.bind(this));
      }
    }
  }
}
