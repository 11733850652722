import { Inject, Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { environment } from '@environments/environment';
import { WINDOW_TOKEN } from '@core/window/window.token';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenService {
  private _accessToken: string;

  constructor(
    @Inject(WINDOW_TOKEN) private windowRef: Window,
    private cookieService: CookieService,
  ) {}

  public storeAccessToken(accessToken: string): void {
    const cookieName = this.getCookieName();
    const cookieOptions: CookieOptions =
      environment.name === 'local' ? { domain: this.windowRef.location.hostname } : { domain: '.wallapop.com' };
    cookieOptions.expires = this.expirationDate;
    this.cookieService.put(cookieName, accessToken, cookieOptions);
    this._accessToken = accessToken;
    this.storeAccessTokenLocalhost(accessToken);
  }

  public deleteAccessToken() {
    const cookieName = this.getCookieName();
    const cookieOptions = environment.name === 'local' ? { domain: this.windowRef.location.hostname } : { domain: '.wallapop.com' };
    this.cookieService.remove(cookieName, cookieOptions);
    this.cookieService.remove('device' + cookieName.charAt(0).toUpperCase() + cookieName.slice(1), cookieOptions);
    this._accessToken = null;
    this.deleteAccessTokenLocalhost();
  }

  get deviceAccessToken(): string {
    return this.cookieService.get('deviceAccessToken' + environment.cookieSuffix)
      ? this.cookieService.get('deviceAccessToken' + environment.cookieSuffix)
      : '';
  }

  get accessToken(): string {
    const cookieName = this.getCookieName();
    const accessTokenFromCookies = this.cookieService.get(cookieName);

    if (!accessTokenFromCookies) {
      return this._accessToken;
    }

    if (accessTokenFromCookies !== this._accessToken) {
      this._accessToken = accessTokenFromCookies;
    }

    return this._accessToken;
  }

  get accessTokenFromCookies(): string {
    return this.cookieService.get(this.getCookieName());
  }

  get expirationDate(): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }

  private getCookieName() {
    const cookieName = 'accessToken';
    return environment.production ? cookieName : cookieName + environment.cookieSuffix;
  }

  private storeAccessTokenLocalhost(accessToken: string): void {
    if (!this.isLocalhostServer()) {
      return;
    }

    const cookieName = this.getCookieName();
    const cookieOptions: CookieOptions = { domain: 'localhost' };
    cookieOptions.expires = this.expirationDate;
    this.cookieService.put(cookieName, accessToken, cookieOptions);
  }

  private deleteAccessTokenLocalhost(): void {
    if (!this.isLocalhostServer()) {
      return;
    }

    const cookieName = this.getCookieName();
    const cookieOptions = { domain: 'localhost' };
    this.cookieService.remove(cookieName, cookieOptions);
    this.cookieService.remove('device' + cookieName.charAt(0).toUpperCase() + cookieName.slice(1), cookieOptions);
  }

  private isLocalhostServer(): boolean {
    return document.location.href.startsWith('http://localhost:4200');
  }
}
