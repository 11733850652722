import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@core/user/user.service';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { AnalyticsEvent, ANALYTICS_EVENT_NAMES, ANALYTICS_EVENT_TYPES, SaveChangeEmail, SCREEN_IDS } from '@wallapop/analytics/constants';
import { SnackbarService } from '@layout/snackbar/core/services/snackbar.service';
import { CheckMailboxModalComponent } from '@shared/check-mailbox-modal/check-mailbox-modal.component';
import { EmailThanksModalCopies } from '@shared/email-thanks-modal/interfaces';

@Component({
  selector: 'tsl-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss'],
})
export class EmailModalComponent {
  public emailForm: UntypedFormGroup;
  public currentEmail: string;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    public activeModal: NgbActiveModal,
    private snackbarService: SnackbarService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
  ) {
    this.emailForm = this.fb.group({
      email_address: ['', [Validators.required, this.email]],
    });
  }

  public onSubmit() {
    if (this.emailForm.valid) {
      this.trackChangeEmailEvent();
      this.userService.updateEmail(this.emailInput).subscribe(
        () => {
          this.activeModal.close(this.emailInput);
          this.openThanksModal();
        },
        () => {
          this.snackbarService.show({
            type: 'negative',
            title: $localize`:@@web_toast_error_title:Oops!`,
            content: $localize`:@@web_server_error:Server error:`,
          });
        },
      );
    } else {
      for (const control in this.emailForm.controls) {
        if (this.emailForm.controls.hasOwnProperty(control) && !this.emailForm.controls[control].valid) {
          this.emailForm.controls[control].markAsDirty();
        }
      }
      this.snackbarService.show({
        type: 'negative',
        title: $localize`:@@web_toast_error_title:Oops!`,
        content: $localize`:@@web_form_field_error:Check the red fields`,
      });
    }
  }

  get emailInput(): string {
    return this.emailForm.get('email_address').value;
  }

  private email(control: AbstractControl): { [key: string]: boolean } {
    if (Validators.required(control)) {
      return null;
    }
    const pattern: RegExp =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return pattern.test(control.value) ? null : { email: true };
  }

  private openThanksModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(CheckMailboxModalComponent, {
      windowClass: 'modal-standard',
      animation: false,
    });
    modalRef.componentInstance.copies = this.getCheckMailBoxModalCopies(this.emailInput);
  }

  private getCheckMailBoxModalCopies(email: string): EmailThanksModalCopies {
    let copies: EmailThanksModalCopies = {
      title: $localize`:@@email_change_new_mailbox_verification_all_users_system_modal_title:Check the mailbox`,
      description: $localize`:@@email_change_new_mailbox_verification_all_users_system_modal_description:Last step: access ${email}:INTERPOLATION:, open the email we've sent you and verify the change. That's all.`,
      button: $localize`:@@email_change_new_mailbox_verification_all_users_system_modal_understood_button:Understood`,
    };
    return copies;
  }

  private trackChangeEmailEvent(): void {
    const event: AnalyticsEvent<SaveChangeEmail> = {
      name: ANALYTICS_EVENT_NAMES.SaveChangeEmail,
      eventType: ANALYTICS_EVENT_TYPES.UserPreference,
      attributes: {
        screenId: SCREEN_IDS.ChangeEmail,
      },
    };
    this.analyticsService.trackEvent(event);
  }
}
