import { ImageDto } from '../../../core/dtos/image-dto';
import { ExperimentatorCategoryDataDto } from './experimentator-category-response.dto';
import { ExperimentatorChipDataDto } from './experimentator-chip-list-response.dto';
import { ExperimentatorCollectionDataDto } from './experimentator-collection-response.dto';
import { ExperimentatorItemDataDto } from './experimentator-item-response.dto';
import { ExperimentatorUserDataDto } from './experimentator-user-response.dto';
import { ExperimentatorVideoDataDto } from './experimentator-video-response.dto';

export const EXPERIMENTATOR_VALID_LAYOUTS = [
  'slider',
  'grid',
  'colorful_slider',
  'tags',
  'vertical_card_slider',
  'tabs',
  'composition',
  'video_slider',
] as const;

export interface ExperimentatorDataFlag {
  flag: boolean;
}

export interface ActionDto {
  action_type: 'link';
  payload: PayloadDto;
}

export enum EXPERIMENTATOR_META_ORDER_TYPE {
  NEWEST = 'newest',
  DISTANCE = 'distance',
}

interface PayloadDto {
  link_url: string;
}

type ExperimentatorComponentTypeDto = 'items' | 'collections' | 'chips_list' | 'users' | 'categories' | 'video_items';
export type ExperimentatorComponentLayoutDto = (typeof EXPERIMENTATOR_VALID_LAYOUTS)[number];

interface ExperimentatorDurationDto {
  in: number;
  out: number;
}

interface ExperimentatorAnimationDto {
  transition: 'fade';
  sequence: 'alternating';
  interval: number;
  duration: ExperimentatorDurationDto;
}

export type ExperimentatorSeeMoreTypeDto = 'card' | 'header';

export interface ExperimentatorSeeMoreDto {
  text: string;
  type: ExperimentatorSeeMoreTypeDto;
  action: ActionDto;
}

export interface ExperimentatorMetaDto {
  feedback: boolean;
  layout: ExperimentatorComponentLayoutDto;
  type: ExperimentatorComponentTypeDto;
  title: string;
  images?: ImageDto[];
  badges?: string[];
  animation?: ExperimentatorAnimationDto;
  next?: string;
  subtitle?: string;
  see_more?: ExperimentatorSeeMoreDto;
  impression_id?: string;
  // smoke test
  tab1_title?: string;
  tab2_title?: string;
}

export type ExperimentatorDataDto =
  | ExperimentatorItemDataDto
  | ExperimentatorCollectionDataDto
  | ExperimentatorChipDataDto
  | ExperimentatorUserDataDto
  | ExperimentatorCategoryDataDto
  | ExperimentatorVideoDataDto;

export interface ExperimentatorResponseDto {
  data: ExperimentatorDataDto[];
  meta: ExperimentatorMetaDto;
}
