import { PERMISSIONS } from './user-constants';

interface PermissionConfig {
  permission: PERMISSIONS;
  statusMapper: (isFlagActive: boolean) => boolean;
}

const defaultStatusMapper = (isFlagActive: boolean) => isFlagActive;
const invertedStatusMapper = (isFlagActive: boolean) => !isFlagActive;

export const FEATURE_FLAGS_ENUM = {
  BUMPS: 'EnableVisibility',
  SUBSCRIPTIONS: 'EnablePros',
  HIDE_ADS: 'NoAds',
} as const;

export type PredefinedFeatureFlags = ObjectValues<typeof FEATURE_FLAGS_ENUM>;

export const INIT_FEATURE_FLAGS = [FEATURE_FLAGS_ENUM.BUMPS, FEATURE_FLAGS_ENUM.SUBSCRIPTIONS, FEATURE_FLAGS_ENUM.HIDE_ADS];

export const featurePermissionConfig: Partial<Record<PredefinedFeatureFlags, PermissionConfig>> = {
  [FEATURE_FLAGS_ENUM.BUMPS]: {
    permission: PERMISSIONS.bumps,
    statusMapper: defaultStatusMapper,
  },
  [FEATURE_FLAGS_ENUM.SUBSCRIPTIONS]: {
    permission: PERMISSIONS.subscriptions,
    statusMapper: defaultStatusMapper,
  },
  [FEATURE_FLAGS_ENUM.HIDE_ADS]: {
    permission: PERMISSIONS.showAds,
    statusMapper: invertedStatusMapper,
  },
};
