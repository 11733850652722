import { init as initializeAmplitudeAnalytics } from '@amplitude/analytics-browser';
import { Experiment, type ExperimentClient } from '@amplitude/experiment-js-client';
import { environment } from '@wallapop/environments';
import {
  getThrottledVariant,
  isFlagOffOrControl,
  validateIdentityChange,
  waitForMParticleIdResolution,
} from './amplitude-integration.utils';
import { IdentityArgs, type ExpectedVariants } from './amplitude-types';
import { INTERNAL_SDK_VARIANTS } from './amplitude-integration.constants';

let experimentClient: ExperimentClient | null;
let analyticsIdentity: IdentityArgs | null;
let isLibraryReady = false;

const checkExperimentClientStatus = async () => {
  const experimentClientCandidate = await validateIdentityChange({
    experimentClient,
    analyticsIdentity,
  });
  if (experimentClientCandidate) experimentClient = experimentClientCandidate;
};

export const initializeExperimentation = async ({ userGetter, deviceGetter, identityReadyCallback }: IdentityArgs) => {
  await waitForMParticleIdResolution(identityReadyCallback);
  if (experimentClient) return;

  const trackingId = userGetter();
  const deviceId = deviceGetter();
  if (!trackingId) return;

  analyticsIdentity = { userGetter, deviceGetter, identityReadyCallback };

  initializeAmplitudeAnalytics(environment.amplitudeAnalyticsKey, {
    autocapture: false,
    userId: trackingId,
    deviceId,
  });

  experimentClient = Experiment.initializeWithAmplitudeAnalytics(environment.amplitudeWebDeploymentKey, {
    fetchOnStart: true,
    pollOnStart: true,
    retryFetchOnFailure: true,
    /**
     * We're currently using the tracking provider provided by Amplitude (initializeAmplitudeAnalytics + initializeWithAmplitudeAnalytics methods)
     * TODO: RudderStack should be used for the tracking provider once it's implementation is completed [WPA-72358]
     */
  });

  // TODO: Implement error handling through sentry in the respective wrappers [WPA-71363]
  try {
    await experimentClient.start({ user_id: trackingId, device_id: deviceId, user_properties: { Platform: 'web' } });
    isLibraryReady = true;
  } catch (error: unknown) {
    isLibraryReady = true;
    throw error;
  }
};

export const isFlagEnabled = (flagKey: string): boolean => {
  checkExperimentClientStatus();

  const { key } = getThrottledVariant({ flagKey, experimentClient });
  return !isFlagOffOrControl(key);
};

export const getVariationFromFlag = (flagKey: string): ExpectedVariants => {
  checkExperimentClientStatus();

  const { key } = getThrottledVariant({ flagKey, experimentClient });
  return isFlagOffOrControl(key) ? INTERNAL_SDK_VARIANTS.OFF : (key as ExpectedVariants);
};

export const getVariablesFromFlag = (flagKey: string): AnyObject => {
  checkExperimentClientStatus();

  const { payload } = getThrottledVariant({ flagKey, experimentClient });
  return payload;
};

export const isExperimentationLibReady = () => isLibraryReady;

/**
 * Do not use this if you don't know what you're doing
 */
export const resetLibCache = () => {
  experimentClient = null;
  analyticsIdentity = null;
  isLibraryReady = false;
};
