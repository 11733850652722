import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACCEPT_HEADERS, HEADER_NAMES } from '@core/constants/header-constants';
import { getUUID } from '@core/uuid/uuid.utils';
import { environment } from '@environments/environment';
import { mapUploadFormToUploadItem } from '@private/features/upload/core/config/mappers/upload-item-properties.mapper';
import { UploadItem } from '@private/features/upload/core/models/upload-item.interface';
import { UploadForms } from '@shared/uploader/upload.interface';
import { find, findIndex, map as lodashMap, without } from 'lodash-es';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { EventService } from '../event/event.service';
import { Car } from './car';
import { FAKE_ITEM_IMAGE_BASE_PATH, Item, ITEM_TYPES } from './item';
import {
  AllowedActionResponse,
  AvailableProductsResponse,
  CarContent,
  CarInfo,
  ConversationUser,
  Duration,
  ItemBulkResponse,
  ItemContent,
  ItemCounters,
  ItemDataResponse,
  ItemResponse,
  ItemsData,
  ItemsWithAvailableProductsResponse,
  ItemWithProducts,
  LatestItemResponse,
  Order,
  OrderPro,
  Product,
  ProductDurations,
  Purchase,
  RealestateContent,
  RealEstateResponse,
  SelectedItemsAction,
} from './item-response.interface';
import { Realestate } from './realestate';
import { UserActionPermission, UserActionPermissionCause } from './interfaces/user-action-permission.interface';

export const PUBLISHED_ID = 0;
export const ONHOLD_ID = 90;
export const SOLD_OUTSIDE = 30;

export const PAYMENT_PROVIDER = 'STRIPE';
export const ACTIVATE_ENDPOINT = 'activate';
export enum ITEM_STATUS {
  SOLD = 'sold',
  ACTIVE = 'active',
  PENDING = 'pending',
  PUBLISHED = 'published',
}

export const ITEMS_API_URL = 'api/v3/items';
export const WEB_ITEMS_API_URL = 'api/v3/web/items';
export const USERS_API_URL = 'api/v3/users';
export const PROTOOL_API_URL = 'api/v3/protool';
export const V1_API_URL = 'shnm-portlet/api/v1';

export const LATEST_CAR_ENDPOINT = (userId: string) => `${environment.baseUrl}${USERS_API_URL}/${userId}/latest-car`;

// TODO remove this enum, when we have the endpoint with this values.
export enum DELIVERY_INFO_ITEM_COSTS {
  BUYER_PAYS = '814429d6-7844-471d-97af-196cd4020f26',
  SELLER_PAYS = '04cf65ea-42f5-11ed-b878-0242ac120002',
}

// FIXME: In order to follow the project architecture, this service should be provided in root
@Injectable()
export class ItemService {
  public selectedAction: string;
  public selectedItems$: ReplaySubject<SelectedItemsAction> = new ReplaySubject(1);
  public selectedItems: string[] = [];

  private bumpTypes = ['countrybump', 'citybump', 'zonebump'];

  constructor(
    private http: HttpClient,
    private eventService: EventService,
  ) {}

  public getFakeItem(id: string): Item {
    const fakeItem: Item = new Item(id, 1, '1', 'No disponible');
    fakeItem.setFakeImage(FAKE_ITEM_IMAGE_BASE_PATH);
    return fakeItem;
  }

  public getCounters(id: string): Observable<ItemCounters> {
    return this.http
      .get<ItemCounters>(`${environment.baseUrl}${ITEMS_API_URL}/${id}/counters`)
      .pipe(catchError(() => of({ views: 0, favorites: 0, conversations: 0 })));
  }

  public getPurchases(): Observable<Purchase[]> {
    return this.http.get<Purchase[]>(`${environment.baseUrl}${WEB_ITEMS_API_URL}/mine/purchases`);
  }

  public bulkDelete(): Observable<ItemBulkResponse> {
    const itemsToDelete = this.selectedItems.slice(0, 40);
    return this.http
      .delete<ItemBulkResponse>(`${environment.baseUrl}${ITEMS_API_URL}`, {
        body: { ids: itemsToDelete },
      })
      .pipe(
        map((response) => {
          const responseFailedIds = response?.failedIds;
          return {
            updatedIds: responseFailedIds ? itemsToDelete.filter((itemId) => !responseFailedIds.includes(itemId)) : this.selectedItems,
            failedIds: responseFailedIds ? responseFailedIds : [],
          };
        }),
        tap((response) => {
          this.eventService.emit('itemChangeStatus', response.updatedIds);
          itemsToDelete.forEach((item) => {
            if (!response.failedIds.includes(item)) {
              this.deselectItem(item);
            }
          });
        }),
      );
  }

  public selectItem(id: string) {
    this.selectedItems.push(id);
    this.selectedItems$.next({
      id: id,
      action: 'selected',
    });
  }

  public deselectItems() {
    this.selectedItems = [];
    this.selectedItems$.next(undefined);
    this.selectedAction = null;
  }

  public deselectItem(id: string) {
    this.selectedItems = without(this.selectedItems, id);
    this.selectedItems$.next({
      id: id,
      action: 'deselected',
    });

    if (this.selectedItems.length === 0) {
      this.selectedAction = null;
    }
  }

  public getPaginationItems(url: string, next?): Observable<ItemsData> {
    return this.http
      .get<HttpResponse<ItemResponse[]>>(`${environment.baseUrl}${url}`, {
        params: {
          since: next,
        },
        observe: 'response' as 'body',
      })
      .pipe(
        map((r) => {
          const res: ItemResponse[] = r.body;
          const nextPage: string = r.headers.get('x-nextpage');
          const params = new URLSearchParams(nextPage);

          let data: Item[] = [];
          if (res.length > 0) {
            data = res.map((i: ItemResponse) => {
              const item: Item = this.mapRecordData(i);
              item.views = i.content.views;
              item.favorites = i.content.favorites;
              item.conversations = i.content.conversations;
              return item;
            });
          }
          return {
            data: data,
            since: params.get('since'),
          };
        }),
        mergeMap((itemsData: ItemsData) => {
          return this.getPurchases().pipe(
            map((purchases: Purchase[]) => {
              this.mapBumpInfoToItemData(purchases, itemsData.data);
              return itemsData;
            }),
          );
        }),
        map((itemsData: ItemsData) => {
          this.mapSelectedInfoToItemData(itemsData.data);
          return itemsData;
        }),
      );
  }

  public mapBumpInfoToItemData(purchases: Purchase[], itemsData: Item[]) {
    purchases.forEach((purchase: Purchase) => {
      const index: number = findIndex(itemsData, {
        id: purchase.item_id,
      });
      if (index !== -1) {
        if (this.bumpTypes.includes(purchase.purchase_name)) {
          itemsData[index].bumpExpiringDate = purchase.expiration_date;
          itemsData[index].flags.bump_type = purchase.purchase_name;
        }
        if (purchase.visibility_flags) {
          itemsData[index].flags.bumped = purchase.visibility_flags.bumped;
          itemsData[index].flags.highlighted = purchase.visibility_flags.highlighted;
        }
      }
    });
  }

  public mapSelectedInfoToItemData(itemsData: Item[]) {
    this.selectedItems.forEach((selectedItemId: string) => {
      const index: number = findIndex(itemsData, {
        id: selectedItemId,
      });
      if (index !== -1) {
        itemsData[index].selected = true;
      }
    });
  }

  public mine(next: string, status?: string): Observable<ItemsData> {
    return this.getPaginationItems(ITEMS_API_URL + '/mine/' + status, next);
  }

  public deleteItem(id: string): Observable<Object> {
    return this.http.delete(`${environment.baseUrl}${ITEMS_API_URL}/${id}`);
  }

  public reserveItem(id: string, reserved: boolean): Observable<Object> {
    return this.http.put(`${environment.baseUrl}${ITEMS_API_URL}/${id}/reserve`, {
      reserved,
    });
  }

  public reactivateItem(id: string): Observable<Object> {
    return this.http.put(`${environment.baseUrl}${ITEMS_API_URL}/${id}/reactivate`, {});
  }

  public favoriteItem(id: string, favorited: boolean): Observable<Object> {
    return this.http.put(`${environment.baseUrl}${ITEMS_API_URL}/${id}/favorite`, {
      favorited,
    });
  }

  public bulkReserve(): Observable<ItemBulkResponse> {
    return this.http.put<ItemBulkResponse>(`${environment.baseUrl}${ITEMS_API_URL}/reserve`, {
      ids: this.selectedItems,
    });
  }

  public soldOutside(id: string): Observable<Object> {
    return this.http.put(`${environment.baseUrl}${ITEMS_API_URL}/${id}/sold`, {});
  }

  public getConversationUsers(id: string): Observable<ConversationUser[]> {
    return this.http.get<ConversationUser[]>(`${environment.baseUrl}${ITEMS_API_URL}/${id}/conversation-users`);
  }

  public getAvailableReactivationProducts(id: string): Observable<Product> {
    return this.http
      .get(`${environment.baseUrl}${WEB_ITEMS_API_URL}/${id}/available-reactivation-products`)
      .pipe(map((response: AvailableProductsResponse) => response.products[0]));
  }

  public purchaseProducts(orderParams: Order[], orderId: string): Observable<string[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'X-PaymentProvider': PAYMENT_PROVIDER,
    });

    return this.http.post<string[]>(`${environment.baseUrl}${WEB_ITEMS_API_URL}/purchase/products/${orderId}`, orderParams, {
      headers,
    });
  }

  public update(itemProperties: UploadForms): Observable<ItemResponse | CarContent | RealEstateResponse> {
    let url: string = ITEMS_API_URL + '/';
    const headers: HttpHeaders = new HttpHeaders({ 'X-DeviceOS': '0', [HEADER_NAMES.ACCEPT]: ACCEPT_HEADERS.UPLOAD_V2 });
    const uploadItem: UploadItem = mapUploadFormToUploadItem(itemProperties);

    return this.http
      .put<ItemResponse | CarContent | RealEstateResponse>(`${environment.baseUrl}${url}${itemProperties.id}`, uploadItem, { headers })
      .pipe(tap(() => this.eventService.emit(EventService.ITEM_UPDATED, uploadItem)));
  }

  public deletePicture(itemId: string, pictureId: string): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}${ITEMS_API_URL}/${itemId}/picture/${pictureId}`);
  }

  public get(id: string): Observable<Item> {
    return this.http.get<ItemResponse>(`${environment.baseUrl}${ITEMS_API_URL}/${id}/vertical`).pipe(
      map((r) => this.mapRecordData(r)),
      catchError(() => of(this.getFakeItem(id))),
    );
  }

  public updatePicturesOrder(itemId: string, pictures_order: { [fileId: string]: number }): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}${ITEMS_API_URL}/${itemId}/change-picture-order`, {
      pictures_order,
    });
  }

  public getItemsWithAvailableProducts(ids: string[]): Observable<ItemWithProducts[]> {
    return this.http
      .get(`${environment.baseUrl}${WEB_ITEMS_API_URL}/available-visibility-products`, {
        params: {
          itemsIds: ids.join(','),
        },
      })
      .pipe(
        map((res: ItemsWithAvailableProductsResponse[]) => {
          return res.map((i: ItemsWithAvailableProductsResponse) => {
            return {
              item: this.mapRecordData(i),
              products: this.getProductDurations(i.productList),
            };
          });
        }),
      );
  }

  public canDoAction(action: string, id: string): Observable<UserActionPermission> {
    return this.getActionsAllowed(id).pipe(
      map((actions: AllowedActionResponse[]) => {
        const canDo: AllowedActionResponse = find(actions, { type: action });
        const cause: Undefinable<UserActionPermissionCause> = canDo?.cause;
        return {
          isAllowed: !!canDo?.allowed,
          ...(cause && { cause }),
        };
      }),
    );
  }

  public bulkSetActivate(): Observable<Object> {
    return this.http
      .put(`${environment.baseUrl}${ITEMS_API_URL}/${ACTIVATE_ENDPOINT}`, {
        ids: this.selectedItems,
      })
      .pipe(
        tap(() => {
          this.eventService.emit('itemChangeStatus', this.selectedItems);
          this.deselectItems();
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          return of(errorResponse);
        }),
      );
  }

  public activateSingleItem(id: string): Observable<void> {
    return this.http
      .put<void>(`${environment.baseUrl}${ITEMS_API_URL}/${id}/${ACTIVATE_ENDPOINT}`, {})
      .pipe(tap(() => this.eventService.emit('itemChangeStatus', [id])));
  }

  public deactivateSingleItem(id: string): Observable<void> {
    return this.http
      .put<void>(`${environment.baseUrl}${ITEMS_API_URL}/${id}/inactivate`, {})
      .pipe(tap(() => this.eventService.emit('itemChangeStatus', [id])));
  }

  public bulkSetDeactivate(): Observable<Object> {
    return this.http
      .put(`${environment.baseUrl}${ITEMS_API_URL}/inactivate`, {
        ids: this.selectedItems,
      })
      .pipe(
        tap(() => {
          this.eventService.emit('itemChangeStatus', this.selectedItems);
          this.deselectItems();
        }),
      );
  }

  public cancelAutorenew(itemId: string): Observable<Object> {
    return this.http.put(`${environment.baseUrl}${PROTOOL_API_URL}/autorenew/update`, [
      {
        item_id: itemId,
        autorenew: false,
      },
    ]);
  }

  public getLatest(userId: string): Observable<ItemDataResponse> {
    return this.http.get(LATEST_CAR_ENDPOINT(userId)).pipe(
      map((resp: LatestItemResponse) => {
        return {
          count: resp.count - 1,
          data: resp.items[0] ? this.mapRecordData(resp.items[0]) : null,
        };
      }),
    );
  }

  public bumpProItems(orderParams: OrderPro[]): Observable<string[]> {
    return this.http.post<string[]>(`${environment.baseUrl}${PROTOOL_API_URL}/purchaseItems`, orderParams);
  }

  public getCarInfo(brand: string, model: string, version: string): Observable<CarInfo> {
    return this.http.get<CarInfo>(`${environment.baseUrl}${ITEMS_API_URL}/cars/info`, {
      params: {
        brand,
        model,
        version,
      },
    });
  }
  protected mapRecordData(response: ItemResponse): Item {
    const data: ItemResponse = <ItemResponse>response;
    const content: ItemContent = data.content;
    if (data.type === 'cars') {
      return this.mapCar(content);
    } else if (data.type === 'real_estate') {
      return this.mapRealEstate(content);
    }
    return this.mapItem(content);
  }

  private getActionsAllowed(id: string): Observable<AllowedActionResponse[]> {
    return this.http.get<AllowedActionResponse[]>(`${environment.baseUrl}${ITEMS_API_URL}/${id}/actions-allowed`);
  }

  private getProductDurations(productList: Product[]): ProductDurations {
    const durations: number[] = lodashMap(productList[0].durations, 'duration');
    const types: string[] = lodashMap(productList, 'name');
    const productDurations = {};
    durations.forEach((duration: number) => {
      productDurations[duration] = {};
      types.forEach((type: string) => {
        productDurations[duration][type] = this.findDuration(productList, duration, type);
      });
    });
    return productDurations;
  }

  private findDuration(productList: Product[], duration: number, type: string): Duration {
    const product: Product = find(productList, { name: type });
    return find(product.durations, { duration: duration });
  }

  private mapCar(content: CarContent): Car {
    return new Car(
      content.id,
      content.seller_id,
      content.title,
      content.storytelling,
      content.sale_price === undefined ? content.price : content.sale_price,
      content.currency_code || content.currency,
      content.modified_date,
      content.url,
      content.flags,
      content.sale_conditions,
      content.images,
      content.web_slug,
      content.brand,
      content.model,
      content.year,
      content.gearbox,
      content.engine,
      content.color,
      content.horsepower,
      content.body_type,
      content.num_doors,
      content.extras,
      content.warranty,
      content.num_seats,
      content.condition,
      content.version,
      content.financed_price,
      content.publish_date,
      content.image,
      content.km,
    );
  }

  private mapRealEstate(content: RealestateContent): Realestate {
    return new Realestate(
      content.id,
      content.seller_id,
      content.title,
      content.storytelling,
      content.location,
      content.sale_price,
      content.currency_code,
      content.modified_date,
      content.url,
      content.flags,
      content.images,
      content.web_slug,
      content.operation,
      content.type,
      content.condition,
      content.surface,
      content.bathrooms,
      content.rooms,
      content.garage,
      content.terrace,
      content.elevator,
      content.pool,
      content.garden,
      content.image,
      content.publish_date,
    );
  }

  private mapItem(content: ItemContent): Item {
    return new Item(
      content.id,
      null,
      content.seller_id,
      content.title,
      content.description,
      content.category_id,
      null,
      content.sale_price === undefined ? content.price : content.sale_price,
      content.currency_code || content.currency,
      content.modified_date,
      content.url,
      content.flags,
      null,
      content.sale_conditions,
      content.images
        ? content.images[0]
        : {
            id: getUUID(),
            original_width: content.image ? content.image.original_width : null,
            original_height: content.image ? content.image.original_height : null,
            average_hex_color: '',
            urls_by_size: content.image,
          },
      content.images,
      content.web_slug,
      content.publish_date,
      content.delivery_info,
      ITEM_TYPES.CONSUMER_GOODS,
      content.extra_info
        ? {
            object_type: {
              id: content.extra_info.object_type && content.extra_info.object_type.id ? content.extra_info.object_type.id.toString() : null,
              name: content.extra_info.object_type && content.extra_info.object_type.name ? content.extra_info.object_type.name : null,
            },
            brand: content.extra_info.brand,
            model: content.extra_info.model,
            gender: content.extra_info.gender,
            size: {
              id: content.extra_info.size && content.extra_info.size.id ? content.extra_info.size.id.toString() : null,
            },
            condition: content.extra_info.condition || null,
          }
        : undefined,
      null,
      null,
      null,
      content.hashtags,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      content.stock?.units,
    );
  }
}
