import { ErrorHandler, NgModule } from '@angular/core';

import { AdsService } from './services';
import { LoadAdsService } from './services/load-ads/load-ads.service';
import { AdsTargetingsService } from './services/ads-targetings/ads-targetings.service';
import { AmazonPublisherService, CriteoService, GooglePublisherTagService } from './vendors';
import { environment } from '@environments/environment';

class AdsErrorHandler implements ErrorHandler {
  handleError(error) {
    // catching Ads errors in production to prevent them to reach Sentry
    if (!environment.production) {
      throw error;
    }
  }
}

@NgModule({
  providers: [
    AdsService,
    LoadAdsService,
    AmazonPublisherService,
    CriteoService,
    GooglePublisherTagService,
    AdsTargetingsService,
    [{ provide: ErrorHandler, useClass: AdsErrorHandler }],
  ],
})
export class AdsModule {}
