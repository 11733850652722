/* eslint-disable no-restricted-properties */
import { Injectable } from '@angular/core';
import { User } from '@core/user/user';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular';
import { SENTRY_INIT_CONFIGURATION } from '../constants/monitoring-configuration';
import { CaptureContext, TransactionContext } from '@sentry/types';
import { MONITORING_EVENT_TYPES } from '../constants/event-types';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  public initialize(user: User): void {
    if (environment.production || environment.name === 'beta') {
      Sentry.init(SENTRY_INIT_CONFIGURATION);
      this.setAuthenticatedUser(user);
    }
  }

  public captureMessage(message: string, context?: CaptureContext) {
    return Sentry.captureMessage(message, { ...context, tags: { eventType: MONITORING_EVENT_TYPES.MANUAL } });
  }

  public captureException(exception: unknown, context?: CaptureContext) {
    return Sentry.captureException(exception, { ...context, tags: { eventType: MONITORING_EVENT_TYPES.MANUAL } });
  }

  public startTransaction(transaction: TransactionContext): Sentry.Transaction {
    return Sentry.startTransaction(transaction);
  }

  private setAuthenticatedUser(user: User): void {
    if (!user) return;

    Sentry.setUser({
      id: user.id,
      country_code: user.location?.country_code,
    });
  }
}
