import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable, of } from 'rxjs';

import { NgxPermissionsService } from 'ngx-permissions';
import { featurePermissionConfig } from './featureflag-constants';
import { PERMISSIONS } from './user-constants';
import {
  type FeatureFlagName,
  type FeatureFlag,
  FeatureFlagService as FeatureFlagSharedService,
  type FeatureFlagDict,
} from '@wallapop/feature-flag';
import { ApiLayerService } from '@core/api-layer/api-layer.service';
import { HttpClient } from '@wallapop/api-layer/core/http/http-client.interface';
import { AppStatusService } from '@core/app-status/app-status.service';
import { FeatureFlagKeys, GENERIC_FEATURE_FLAGS } from './featureflag.keys';

export const FEATURE_FLAG_ENDPOINT = 'api/v3/featureflag';
export const FEATURE_FLAG_ACCEPT_HEADER = 'application/vnd.featureflag-v2+json';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlagsSharedService: FeatureFlagSharedService<HttpClient>;
  private fetchedFeatureFlags: FeatureFlagDict = {};

  constructor(
    private permissionService: NgxPermissionsService,
    private apiLayerService: ApiLayerService,
    private appStatusService: AppStatusService,
  ) {
    this.featureFlagsSharedService = new FeatureFlagSharedService(this.apiLayerService.httpClient);
  }

  /**
   * Retrieve activated status for a single flag
   * @param {FeatureFlagKeys} flagName must be set in the featureflag.keys.ts file under the GENERIC_FEATURE_FLAGS constant
   * @returns boolean indicating if the flag is active
   */
  public getFlag(name: FeatureFlagKeys): boolean {
    return this.fetchedFeatureFlags[name];
  }

  /**
   * Retrieve activated status for an array of flags
   * @param {FeatureFlagKeys[]} flagNames flag names, must be set in the featureflag.keys.ts file under the GENERIC_FEATURE_FLAGS constant
   * @returns {FeatureFlag[]} object array containing the `active` and `name` attributes for each retrieved flag
   */
  public getFlags(names: FeatureFlagKeys[]): FeatureFlag[] {
    return names.map((ffName) => ({ name: ffName, active: this.fetchedFeatureFlags[ffName] }));
  }

  public isExperimentalFeaturesEnabled(): boolean {
    return this.featureFlagsSharedService.isExperimentalFeaturesEnabled();
  }

  public getWebappInitializationFlags(): Promise<FeatureFlag[]> {
    return firstValueFrom(
      this.fetchFlags(Object.values(GENERIC_FEATURE_FLAGS)).pipe(
        tap((featureFlags) => {
          this.fetchedFeatureFlags = featureFlags.reduce((acc, currVal) => {
            acc[currVal.name] = currVal.active;
            return acc;
          }, this.fetchedFeatureFlags);
        }),
        catchError((error) => {
          this.appStatusService.addError('[Feature Flag Service]: Error while loading initial feature flags', error);
          return of(error);
        }),
      ),
    );
  }

  private fetchFlags(names: FeatureFlagName[], cache = true): Observable<FeatureFlag[]> {
    return from(this.featureFlagsSharedService.getFlags(names, cache)).pipe(
      catchError(() => {
        const fallBackFlags = names.map((ffName) => {
          const locallyStoredFallback = this.featureFlagsSharedService.getFlagFromCache({ flagName: ffName });
          return locallyStoredFallback || { name: ffName, active: false };
        });

        return of(fallBackFlags);
      }),
      tap((featureFlags) => {
        featureFlags.forEach((featureFlag) => {
          this.checkPermission(featureFlag.name, featureFlag.active);
        });
      }),
    );
  }

  private checkPermission(featureFlagName: FeatureFlagName, isFlagActive: boolean): void {
    const permissionConfig = featurePermissionConfig[featureFlagName];
    if (permissionConfig) {
      const { permission, statusMapper } = permissionConfig;
      statusMapper(isFlagActive) ? this.addPermissions(permission) : this.removePermissions(permission);
    }
  }

  private addPermissions(permission: PERMISSIONS): void {
    this.permissionService.addPermission(permission);
  }

  private removePermissions(permission: PERMISSIONS): void {
    this.permissionService.removePermission(permission);
  }
}
