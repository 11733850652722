import { Injectable } from '@angular/core';

import { ApiLayerService } from '@core/api-layer/api-layer.service';
import { captureException } from '@sentry/angular';
import { NavigationCategories } from '@wallapop/api-layer/apis/navigation/categories/interfaces/navigation-categories.interface';
import { fetchNavigationCategories } from '@wallapop/api-layer/apis/navigation/categories/navigation-categories-api.service';
import { Observable, from, map } from 'rxjs';

@Injectable()
export class NavigationCategoriesApiService {
  constructor(private apiLayer: ApiLayerService) {}

  public getNavigationCategories(): Observable<NavigationCategories> {
    return from(fetchNavigationCategories(this.apiLayer.httpClient, this.captureError));
  }

  private captureError(error: unknown): void {
    captureException(error);
  }
}
