import { Inject, Injectable } from '@angular/core';
import {
  CUSTOM_EVENTS,
  getUser,
  logCustomEvent,
  openSession,
  setExternalCommsUser,
  subscribeToInAppMessageUpdates,
} from '@wallapop/external-comms';
import { UserService } from '@core/user/user.service';
import { LOCAL_STORAGE_TOKEN } from '@core/local-storage/local-storage.token';
import { FeatureFlagService } from '@core/user/featureflag.service';
import { GENERIC_FEATURE_FLAGS } from '@core/user/featureflag.keys';
import { WebviewManagerService } from '@core/webview-manager/webview-manager.service';

const USER_JUST_LOGGED_IN_KEY = 'wallapop_user_just_logged_in';
export const WEBVIEW_QUERY_PARAM = 'webview';

@Injectable({
  providedIn: 'root',
})
export class ExternalCommsService {
  constructor(
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
    private webviewManagerService: WebviewManagerService,
    @Inject(LOCAL_STORAGE_TOKEN) private localStorage: Storage,
  ) {}

  public initializeExternalComms = async () => {
    const isBrazeLoginEnabled = this.featureFlagService.getFlag(GENERIC_FEATURE_FLAGS.WEBX_BRAZE_LOGIN_WEBAPP);

    if (this.webviewManagerService.getIsBrazeDisabled() && this.webviewManagerService.getIsWebview()) return;

    if (isBrazeLoginEnabled) {
      const brazeUser = await getUser();
      const brazeUserId = await new Promise((resolve) => {
        // Using deprecated method since the recommended one just doesn't work
        brazeUser.getUserId((_userId) => {
          resolve(_userId);
        });
      });

      const user = this.userService.user;
      if (user && user.id !== brazeUserId && this.userService.isLogged) {
        setExternalCommsUser(user);
        const userJustLoggedIn = this.localStorage.getItem(USER_JUST_LOGGED_IN_KEY);

        if (userJustLoggedIn) {
          this.sendPushPrimingEvent();
        }
      }
    }

    await subscribeToInAppMessageUpdates();
    openSession();
  };

  private sendPushPrimingEvent = async () => {
    await logCustomEvent(CUSTOM_EVENTS.PUSH_PRIMING);
    this.localStorage.setItem(USER_JUST_LOGGED_IN_KEY, 'false');
  };
}
