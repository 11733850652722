import { AnalyticsEvent, AnalyticsEventAttributes, trackMParticleEvent } from '#analytics/index';
import { getProvidersForAnalytics } from './event-routing';
import { trackRudderstackEvent } from './providers/rudderstack/track-rudderstack-event';

export const trackAnalyticsEvent = async <T extends AnalyticsEventAttributes>(event: AnalyticsEvent<T>): Promise<void> => {
  const providers = await getProvidersForAnalytics();

  if (providers.includes('mparticle')) {
    trackMParticleEvent(event);
  }

  if (providers.includes('rudderstack')) {
    trackRudderstackEvent(event);
  }
};
