import { Injectable } from '@angular/core';
import { HTTP_RESPONSE_CODE } from '@api/core/model/multi-factor/http-response-code';
import { MultiFactorRequestError } from '@api/core/model/multi-factor/multi-factor-error.interface';
import { MULTI_FACTOR_OPERATION } from '@api/core/model/multi-factor/multi-factor-operation.enum';
import { UserService } from '@core/user/user.service';
import { SnackbarService } from '@layout/snackbar/core/services/snackbar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CheckMailboxModalComponent } from '@shared/check-mailbox-modal/check-mailbox-modal.component';
import { EmailThanksModalCopies } from '@shared/email-thanks-modal/interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GENERIC_MODAL_COPIES } from './constants/generic-modal-copies-constants';
import { DEFAULT_ERROR_SNACKBAR } from '@layout/snackbar/core/constants/default-snackbar';

export const FIRST_GENERIC_STEP: boolean = true;

@Injectable()
export class MultiFactorManagerService {
  constructor(
    private modalService: NgbModal,
    private snackbarService: SnackbarService,
    private userService: UserService,
  ) {}

  public start(
    mfaRequest: Observable<void | MultiFactorRequestError>,
    isFirstGenericStep: boolean = false,
  ): Observable<void | MultiFactorRequestError> {
    return mfaRequest.pipe(
      catchError((error: MultiFactorRequestError) => {
        if (isFirstGenericStep) this.handleError(error);
        return throwError(error);
      }),
    );
  }

  private handleError(error: MultiFactorRequestError): void {
    const { code, operation } = error;

    if (code === HTTP_RESPONSE_CODE.FORBIDDEN) {
      const modalRef = this.openCheckMailboxModal();
      modalRef.componentInstance.copies = this.getModalCopies(operation);
      return;
    }

    if (code >= HTTP_RESPONSE_CODE.INTERNAL_SERVER_ERROR) {
      this.snackbarService.show(DEFAULT_ERROR_SNACKBAR);
      return;
    }
  }

  private openCheckMailboxModal(): NgbModalRef {
    return this.modalService.open(CheckMailboxModalComponent, {
      windowClass: 'modal-standard',
      animation: false,
    });
  }

  private getModalCopies(operation: MULTI_FACTOR_OPERATION): EmailThanksModalCopies {
    return GENERIC_MODAL_COPIES(this.userService.user.email)[operation];
  }
}
