export const CRITEO_URL = 'https://static.criteo.net/js/ld/publishertag.js';

export const GOOGLE_AD_SENSE_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5927681371993674';

export const GOOGLE_ADS_SENSE_SEARCH = 'https://www.google.com/adsense/search/ads.js';
export const GOOGLE_ADS_SENSE_SEARCH_URL = '/assets/js/google-ads-sense-for-search.js';

export const SOVRN_TAG = 'https://get.s-onetag.com/d85235a8-435f-4801-9601-21095be3f841/tag.min.js';
export const PUBSTACK_URL = 'https://boot.pbstck.com/v1/tag/2cf8aa82-8568-40e6-adff-339a4c958fe6';

export const BLOCKTHROUGH_URL = 'https://btloader.com/tag?o=4887594739957760&upapi=true';
export const RELEVANT_YIELD_URL = 'https://wallapop-cdn.relevant-digital.com/static/tags/636a277774ca71aa43450b62.js';
export const GOOGLE_PUBLISHER_TAG_URL = '//securepubads.g.doubleclick.net/tag/js/gpt.js';

export const ADS_SOURCES: string[] = [
  CRITEO_URL,
  GOOGLE_AD_SENSE_URL,
  GOOGLE_ADS_SENSE_SEARCH,
  GOOGLE_ADS_SENSE_SEARCH_URL,
  PUBSTACK_URL,
  GOOGLE_PUBLISHER_TAG_URL,
  SOVRN_TAG,
  RELEVANT_YIELD_URL,
];
