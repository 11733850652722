import { Component, Inject, OnInit } from '@angular/core';
import { ANALYTICS_EVENT_NAMES, ANALYTICS_EVENT_TYPES, OpenWallapop } from '@wallapop/analytics/constants';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { DeviceService } from '@core/device/device.service';
import { SessionService } from '@core/session/session.service';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { APP_ID, APP_VERSION } from '@wallapop/app-version';

export const APP_VERSION_DATA_ATTRIBUTE = 'data-app-version';
export const APP_ID_DATA_ATTRIBUTE = 'data-app-id';

@Component({
  selector: 'tsl-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private deviceService: DeviceService,
    private sessionService: SessionService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.onNewSessionStart().subscribe(() => this.trackOpenWallapop());
    this.appendAppVersionToDOM();
    this.appendAppIdToDOM();
  }

  private onNewSessionStart(): Observable<void> {
    return this.analyticsService.mParticleReady$.pipe(
      concatMap(() => this.sessionService.newSession$),
      take(1),
    );
  }

  private trackOpenWallapop(): void {
    this.analyticsService.trackEvent<OpenWallapop>({
      name: ANALYTICS_EVENT_NAMES.OpenWallapop,
      eventType: ANALYTICS_EVENT_TYPES.Other,
      attributes: {
        currentUrl: window.location.href,
        refererUrl: document.referrer,
        webPlatformType: this.deviceService.getDeviceType(),
        webDeviceId: this.deviceService.getDeviceId(),
      },
    });
  }

  private appendAppVersionToDOM(): void {
    this.document.documentElement.setAttribute(APP_VERSION_DATA_ATTRIBUTE, APP_VERSION);
  }

  private appendAppIdToDOM(): void {
    this.document.documentElement.setAttribute(APP_ID_DATA_ATTRIBUTE, APP_ID);
  }
}
