export const getCookie = (name: string): string => window.document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const setCookie = (name: string, value: string, days: number = 30): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  window.document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
};
export const deleteCookie = (name: string): void => {
  window.document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
};
