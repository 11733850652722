import { Injectable } from '@angular/core';
import { AdsService } from '@core/ads/services';
import { encryptText } from '@core/helpers/sha256-hash-generator/sha256-hash-generator';
import { setUpLiveramp } from '@wallapop/ads';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { OneTrustService } from '../oneTrust/one-trust.service';
import { AppStatusService } from '@core/app-status/app-status.service';

@Injectable({ providedIn: 'root' })
export class LiveRampService {
  constructor(
    private oneTrustService: OneTrustService,
    private adsService: AdsService,
    private appStatusService: AppStatusService,
  ) {}

  public init(userEmail: string): void {
    const oneTrustConsent$ = this.oneTrustService.otConsentHash$.pipe(filter((value) => !!value));
    const adsLoaded$ = this.adsService.adsReady$.pipe(filter((value) => !!value));

    combineLatest([oneTrustConsent$, adsLoaded$])
      .pipe(
        switchMap(([otConsentHash, _]) =>
          encryptText(userEmail).pipe(
            map((userHashedEmail: string) => {
              return {
                otConsentHash,
                userHashedEmail,
              };
            }),
          ),
        ),
        catchError((error) => {
          this.appStatusService.addError('[LiveRamp Service]: Error while initializing Live Ramp', error);
          return of(error);
        }),
      )
      .subscribe(({ otConsentHash, userHashedEmail }) => setUpLiveramp(otConsentHash, userHashedEmail));
  }
}
