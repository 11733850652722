import { Injectable } from '@angular/core';
import { mapNotificationsFromBraze } from './mappers/notification-mapper';
import { Notification } from '@api/core/model/notification/notification.interface';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { UserService } from '@core/user/user.service';
import { NotificationDto } from '@api/notification/dtos/response/notifcation-dto';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { NOTIFICATION_TYPES } from './model/notification-types-enum';
import {
  getCachedContentCards,
  logCardClick,
  logCardImpressions,
  logContentCardsDisplayed,
  requestContentCardsRefresh,
  requestImmediateDataFlush,
  subscribeToContentCardsUpdates,
} from '@wallapop/external-comms';
import { ExternalCommsClassicCard, ExternalCommsContentCards } from '@wallapop/external-comms/src/typing';

// TODO: Typing for the notification needs to be reviewed now that is using the external comms lib
@Injectable()
export class NotificationApiService {
  private _unreadNotificationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _notificationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _notifications$: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
  private _isLoadingNotifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private handlers: Function[] = [];

  get notificationsCount$(): Observable<number> {
    return this._notificationsCount$.asObservable();
  }

  get unreadNotificationsCount$(): Observable<number> {
    return this._unreadNotificationsCount$.asObservable();
  }

  get notifications$(): Observable<Notification[]> {
    return this._notifications$.asObservable();
  }

  get isLoadingNotifications$(): Observable<boolean> {
    return this._isLoadingNotifications$.asObservable();
  }

  get notificationsCount(): number {
    return this._notificationsCount$.value;
  }

  get unreadNotificationsCount(): number {
    return this._unreadNotificationsCount$.value;
  }

  get notifications(): Notification[] {
    return this._notifications$.value;
  }

  constructor(
    private analyticsService: AnalyticsService,
    private userService: UserService,
  ) {
    if (this.userService.isLogged) {
      this.analyticsService.mParticleReady$.subscribe(() => {
        this.subscribeToPromise(subscribeToContentCardsUpdates(this.handleContentCardUpdates.bind(this)));
        this.refreshNotifications();
      });
    }
  }

  public refreshNotifications(handler?: Function): void {
    if (handler) {
      this.handlers.push(handler);
    }
    this.subscribeToPromise(requestContentCardsRefresh());
  }

  public logContentCardsDisplayed(): void {
    this.subscribeToPromise(logContentCardsDisplayed());
  }

  public logContentCard(id: string): void {
    from(getCachedContentCards()).subscribe((response) => {
      const notificationCenterCards = this.filterNotificationCenterContentCards(response.cards as unknown as NotificationDto[]);
      const cardFoundById = notificationCenterCards.find((card) => card.id === id) as unknown as NotificationDto;
      this.subscribeToPromise(logCardImpressions([cardFoundById as unknown as ExternalCommsContentCards], true));
    });
  }

  public logCardClick(id: string): void {
    from(getCachedContentCards()).subscribe((response) => {
      const card = response.cards.find((card) => id === card.id);
      this.subscribeToPromise(logCardClick(card as ExternalCommsClassicCard));
      this.subscribeToPromise(requestImmediateDataFlush());
    });
  }

  private handleContentCardUpdates(contentCards: ExternalCommsContentCards): void {
    const notificationCenterCards = this.filterNotificationCenterContentCards(contentCards.cards as unknown as NotificationDto[]);
    const notifications = mapNotificationsFromBraze(notificationCenterCards);
    this._notifications$.next(notifications);
    this._notificationsCount$.next(notifications.length);
    this._unreadNotificationsCount$.next(notifications.filter((notification) => !notification.isRead).length);
    this._isLoadingNotifications$.next(false);
    for (let handler of this.handlers) {
      handler();
    }
    this.handlers = [];
  }

  private filterNotificationCenterContentCards(cards: NotificationDto[]): NotificationDto[] {
    return cards.filter((card) => card.extras?.feed_type === NOTIFICATION_TYPES.NOTIFICATION_CENTER);
  }

  // Shortcut to subscribe external comms methods as an Observable
  private subscribeToPromise<T = void>(promise: Promise<T>): void {
    from(promise).subscribe();
  }
}
