import { Inject, Injectable } from '@angular/core';
import { WINDOW_TOKEN } from '@core/window/window.token';

@Injectable()
export class SmoothScrollBehaviourService {
  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  public enable(): void {
    this.window.document.documentElement.style.scrollBehavior = 'smooth';
  }

  public disable(): void {
    this.window.document.documentElement.style.scrollBehavior = 'auto';
  }
}
