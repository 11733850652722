import { HttpClientInterceptor, HttpClientRequestOnFulfilledInterceptor } from '../http-client.types';
import { AUTH_REFRESH_ENDPOINT } from '../../../apis/auth/refresh/endpoints';
import { AUTH_ACCESS_GOOGLE_ENDPOINT } from '../../../apis/auth/access/social/google/endpoints';
import { AUTH_ACCESS_FACEBOOK_ENDPOINT } from '../../../apis/auth/access/social/facebook/endpoints';
import { ACCESS_AUTHORIZE_ENDPOINT } from '../../../apis/auth/access/authorize/endpoints';
import { AUTH_REGISTER_DEVICE_ENDPOINT } from '../../../apis/auth/register-device/endpoints';
import { LOGIN_ENDPOINT } from '../../../apis/auth/access/login/endpoints';
import { USER_CONSENT_ENDPOINT } from '#api-layer/apis/consent/user-consent/endpoints';
import { environment } from '@wallapop/environments';
import { getMatchingHeadersByRules, type HttpRequestHeaderRules } from './rule-matcher/rule-matcher';

interface DeviceInterceptorOptions {
  getDeviceId: () => string;
}

type CreateDeviceInterceptor = (options: DeviceInterceptorOptions) => HttpClientInterceptor;

export const DEVICE_HEADER_NAME_API_V1 = 'DeviceOS'.toLowerCase();
export const DEVICE_HEADER_NAME_API_V3 = 'X-DeviceOS'.toLowerCase();
export const DEVICE_ID_HEADER_NAME = 'X-DeviceID'.toLowerCase();
export const DEVICE_TOKEN_HEADER_NAME = 'X-DeviceToken'.toLowerCase();
export const API_V3 = 'api/v3/';
export const BFF = 'bff';

export const DEVICE_OS = {
  WEB: 0,
  ANDROID: 1,
  IOS: 2,
} as const;

const ALLOWED_DOMAINS = [new URL(environment.baseUrl).hostname, new URL(environment.featureFlagEndpoint).hostname];

const addAPIV1OnAllRequests: HttpRequestHeaderRules = {
  headerName: DEVICE_HEADER_NAME_API_V1,
  domains: ALLOWED_DOMAINS,
};

const addAPIV3IfNewAPIPath: HttpRequestHeaderRules = {
  headerName: DEVICE_HEADER_NAME_API_V3,
  domains: ALLOWED_DOMAINS,
  conditions: [
    {
      type: 'pathStartsWith',
      values: [API_V3, BFF],
    },
  ],
};

const addDeviceIDOnAuth: HttpRequestHeaderRules = {
  headerName: DEVICE_ID_HEADER_NAME,
  domains: ALLOWED_DOMAINS,
  conditions: [
    {
      type: 'pathStartsWith',
      values: [API_V3, BFF],
    },
    {
      type: 'path',
      values: [AUTH_REGISTER_DEVICE_ENDPOINT, USER_CONSENT_ENDPOINT],
    },
  ],
};

const addDeviceTokenOnAuth: HttpRequestHeaderRules = {
  headerName: DEVICE_TOKEN_HEADER_NAME,
  domains: ALLOWED_DOMAINS,
  conditions: [
    {
      type: 'path',
      values: [
        ACCESS_AUTHORIZE_ENDPOINT,
        AUTH_REFRESH_ENDPOINT,
        AUTH_ACCESS_FACEBOOK_ENDPOINT,
        AUTH_ACCESS_GOOGLE_ENDPOINT,
        LOGIN_ENDPOINT,
      ],
    },
  ],
};

const RULES: HttpRequestHeaderRules[] = [addAPIV1OnAllRequests, addAPIV3IfNewAPIPath, addDeviceIDOnAuth, addDeviceTokenOnAuth];

export const createDeviceInterceptor: CreateDeviceInterceptor = (options) => {
  const { getDeviceId } = options;

  const onFulfilledInterceptor: HttpClientRequestOnFulfilledInterceptor = (request) => {
    request.headers ??= {};

    const deviceOsWeb = String(DEVICE_OS.WEB);
    const deviceId = getDeviceId();

    const matchingHeaders = getMatchingHeadersByRules(RULES, request, () => ({
      [DEVICE_HEADER_NAME_API_V1]: deviceOsWeb,
      [DEVICE_HEADER_NAME_API_V3]: deviceOsWeb,
      [DEVICE_ID_HEADER_NAME]: deviceId,
      [DEVICE_TOKEN_HEADER_NAME]: deviceId,
    }));

    Object.assign(request.headers, matchingHeaders);

    return request;
  };

  return {
    request: {
      onFulfilled: onFulfilledInterceptor,
    },
  };
};
