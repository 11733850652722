import { Snackbar } from '../interfaces/snackbar.interface';
import { SNACKBAR_TYPES } from './snackbar-types';

export const DEFAULT_SNACKBAR: Snackbar = {
  autoClose: 8000,
  closeOnClick: true,
  content: '',
  id: '',
  type: SNACKBAR_TYPES.NEGATIVE,
};

export const DEFAULT_ERROR_SNACKBAR: Snackbar = {
  type: SNACKBAR_TYPES.NEGATIVE,
  content: $localize`:@@web_default_error_message:Service unavailable temporarily. Try it later`,
};
