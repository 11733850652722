import { Inject, Injectable } from '@angular/core';
import { AdForSearchGroupSlotData, AdForSearchPageOptions, AdSlotForSearchBaseConfiguration } from '@core/ads/models';
import { AdSlotConfiguration } from '@core/ads/models/ad-slot-configuration';
import { AdTargetings } from '@core/ads/models/ad-targetings';
import { OneTrustService } from '@core/ads/vendors/oneTrust/one-trust.service';
import { DeviceService } from '@core/device/device.service';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { GooglePublisherTagService } from '../../vendors';
import { LoadAdsService } from '../load-ads/load-ads.service';
import { IABVendors } from '@core/ads/vendors/oneTrust/one-trust.constants';

@Injectable({
  providedIn: 'root',
})
export class AdsService {
  private readonly setSlotsSubject: BehaviorSubject<AdSlotConfiguration[]> = new BehaviorSubject<AdSlotConfiguration[]>([]);
  private readonly setNativeSlotsSubject: BehaviorSubject<AdSlotConfiguration[]> = new BehaviorSubject<AdSlotConfiguration[]>([]);
  private readonly _adsReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly refreshSlotsSubject: Subject<void> = new Subject();

  private readonly setAdForSearchSlotSubject: BehaviorSubject<AdForSearchGroupSlotData> = new BehaviorSubject<AdForSearchGroupSlotData>(
    null,
  );

  constructor(
    private loadAdsService: LoadAdsService,
    private googlePublisherTagService: GooglePublisherTagService,
    private deviceService: DeviceService,
    private oneTrustService: OneTrustService,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {
    this.listenerToSetSlots();
    this.listenerToSetNativeSlots();
    this.listenerToAdForSearchSetSlots();
    this.listenerToDisplaySlots();
    this.listenerToRefreshSlots();
    this.listenerToDisplayNativeSlots();
  }

  public get adsReady$(): Observable<boolean> {
    return this._adsReady$.asObservable();
  }

  public init(): void {
    if (!this._adsReady$.getValue()) {
      this.loadAdsService
        .loadAds()
        .pipe(
          take(1),
          // TODO: Decide what we should do when Ads scripts are blocked (usually by Adblockers)
          catchError(() => of({})),
        )
        .subscribe(() => {
          this._adsReady$.next(true);
        });
    }
  }

  public setAdForSearchSlots(adForSearchGroupSlotData: AdForSearchGroupSlotData): void {
    this.setAdForSearchSlotSubject.next(adForSearchGroupSlotData);
  }

  public setSlots(adSlots: AdSlotConfiguration[]): void {
    this.setSlotsSubject.next(adSlots);
  }

  public setNativeSlots(adSlots: AdSlotConfiguration[]): void {
    this.setNativeSlotsSubject.next(adSlots);
  }

  public destroySlots(adSlots: AdSlotConfiguration[]): void {
    this.googlePublisherTagService.destroySlots(adSlots);
  }

  public destroyNativeSlots(adSlots: AdSlotConfiguration[] = this.setNativeSlotsSubject.getValue()) {
    this.googlePublisherTagService.destroyNativeSlots(adSlots);
  }

  public refreshSlots(adSlots: AdSlotConfiguration[]): void {
    this.googlePublisherTagService.refreshSlots(adSlots);
  }

  public refreshAllSlots(): void {
    this.refreshSlotsSubject.next();
  }

  public clearSlots(adSlots: AdSlotConfiguration[]): void {
    this.googlePublisherTagService.clearSlots(adSlots);
  }

  public setAdKeywords(adTargetings: AdTargetings): void {
    this.googlePublisherTagService.setAdTargeting(adTargetings);
  }

  public setTargetingByAdsKeywords(): void {
    this.googlePublisherTagService.setTargetingByAdsKeywords();
  }

  public adSlotLoaded$(adSlot: AdSlotConfiguration): Observable<boolean> {
    return this.googlePublisherTagService.isAdSlotLoaded$(adSlot);
  }

  public displayAdForSearch(adForSearchPageOptions: AdForSearchPageOptions, adSlotForSearch: AdSlotForSearchBaseConfiguration[]): void {
    this.adsReady$
      .pipe(
        filter((adsReady: boolean) => adsReady),
        tap(() => this.googlePublisherTagService.displayForSearch(adForSearchPageOptions, adSlotForSearch)),
        take(1),
      )
      .subscribe();
  }

  private get adSlotsDefined$(): Observable<boolean> {
    return this.googlePublisherTagService.isAdSlotsDefined$;
  }

  private get adNativeSlotsDefined$(): Observable<boolean> {
    return this.googlePublisherTagService.isNativeAdSlotsDefined$;
  }

  private get allowedVendors$(): Observable<IABVendors[]> {
    return this.oneTrustService.allowedVendors$;
  }

  private get fetchHeaderBids(): Function {
    return this.window['fetchHeaderBids'];
  }

  private get refreshHeaderBids(): Function {
    return this.window['refreshHeaderBids'];
  }

  private listenerToSetSlots(): void {
    combineLatest([this.adsReady$, this.setSlotsSubject.asObservable()])
      .pipe(
        filter(([adsReady, adSlots]: [boolean, AdSlotConfiguration[]]) => adsReady && adSlots.length > 0),
        map(([_, adSlots]: [boolean, AdSlotConfiguration[]]) => adSlots),
      )
      .subscribe((adSlots: AdSlotConfiguration[]) => {
        this.googlePublisherTagService.setTargetingByAdsKeywords();
        this.googlePublisherTagService.setSlots(adSlots);
        this.loadAdsService.initRelevantYield();
      });
  }

  private listenerToSetNativeSlots(): void {
    combineLatest([this.adsReady$, this.setNativeSlotsSubject.asObservable()])
      .pipe(
        filter(([adsReady, adSlots]: [boolean, AdSlotConfiguration[]]) => adsReady && adSlots.length > 0),
        map(([_, adSlots]: [boolean, AdSlotConfiguration[]]) => adSlots),
      )
      .subscribe((adSlots: AdSlotConfiguration[]) => {
        this.googlePublisherTagService.setTargetingByAdsKeywords();
        this.googlePublisherTagService.setNativeSlots(adSlots);
      });
  }

  private listenerToAdForSearchSetSlots(): void {
    combineLatest([this.adsReady$, this.setAdForSearchSlotSubject.asObservable()])
      .pipe(
        filter(([adsReady, adForSearchGroupSlotData]: [boolean, AdForSearchGroupSlotData]) => {
          const slotConfig = adForSearchGroupSlotData?.slotConfig;

          return adsReady && slotConfig && slotConfig.length > 0;
        }),
        map(([_, adForSearchGroupSlotData]: [boolean, AdForSearchGroupSlotData]) => [adForSearchGroupSlotData]),
      )
      .subscribe((adForSearchGroupSlotData: [AdForSearchGroupSlotData]) => {
        const slotData = adForSearchGroupSlotData[0];
        const { slotConfig, pageOptions } = slotData;

        this.googlePublisherTagService.setTargetingByAdsKeywords();
        this.googlePublisherTagService.displayForSearch(pageOptions, slotConfig);
      });
  }

  private listenerToDisplaySlots(): void {
    combineLatest([this.adsReady$, this.adSlotsDefined$, this.allowedVendors$])
      .pipe(
        filter(([adsReady, adSlotsDefined]: [boolean, boolean, IABVendors[]]) => adsReady && adSlotsDefined),
        map(([adsReady, adSlotsDefined, allowedVendors]: [boolean, boolean, IABVendors[]]) => allowedVendors),
      )
      .subscribe((allowedVendors: IABVendors[]) => {
        const allowedGoogleVendors: boolean = allowedVendors.includes(IABVendors.GOOGLE);
        this.callFetchHeaderBids(allowedGoogleVendors);
      });
  }

  private listenerToDisplayNativeSlots(): void {
    combineLatest([this.adsReady$, this.adNativeSlotsDefined$])
      .pipe(filter(([adsReady, adSlotsDefined]: [boolean, boolean]) => adsReady && adSlotsDefined))
      .subscribe(() => {
        this.googlePublisherTagService.displayNativeAdSlots(this.setNativeSlotsSubject.getValue());
      });
  }

  private listenerToRefreshSlots(): void {
    combineLatest([this.allowedVendors$, this.refreshSlotsSubject.asObservable()])
      .pipe(map(([allowedVendors, refreshSlots]: [IABVendors[], void]) => allowedVendors))
      .subscribe((allowedVendors: IABVendors[]) => {
        const allowedGoogleVendors: boolean = allowedVendors.includes(IABVendors.GOOGLE);
        this.googlePublisherTagService.setTargetingByAdsKeywords();
        if (this.fetchHeaderBids) {
          this.callRefreshHeaderBids(allowedGoogleVendors);
        }
        this.refreshAdForSearch();
      });
  }

  private callFetchHeaderBids(allowSegmentation: boolean): void {
    const slots = this.setSlotsSubject.getValue();

    const definedSlots = this.googlePublisherTagService.getDefinedSlots();
    const deviceType = this.deviceService.getDeviceType();

    if (this.fetchHeaderBids) {
      // This is needed for RichAudience initialization
      this.window['deviceType'] = deviceType;

      // RichAudience magic function
      this.fetchHeaderBids(allowSegmentation, slots, definedSlots);
    }

    this.googlePublisherTagService.setPubAdsConfig();
  }

  private callRefreshHeaderBids(allowSegmentation: boolean): void {
    const slots = this.setSlotsSubject.getValue();
    const definedSlots = this.googlePublisherTagService.getDefinedSlots();

    this.refreshHeaderBids(allowSegmentation, slots, definedSlots);
  }

  private refreshAdForSearch(): void {
    const slotData = this.setAdForSearchSlotSubject.getValue();

    if (slotData) {
      const { slotConfig, pageOptions } = slotData;

      this.googlePublisherTagService.displayForSearch(pageOptions, slotConfig);
    }
  }
}
