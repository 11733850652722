export enum REAL_ESTATE_SPECIFICATION_TYPE {
  GARAGE = 'garage',
  ELEVATOR = 'elevator',
  GARDEN = 'garden',
  POOL = 'pool',
  TERRACE = 'terrace',
  BATHROOMS = 'bathrooms',
  ROOMS = 'rooms',
  SURFACE = 'surface',
  ROOM = 'room',
  FLAT = 'apartment',
  HOUSE = 'house',
  OFFICE = 'office',
  LAND = 'land',
  BOX_ROOM = 'box_room',
  NEW_BUILD = 'new_construction',
  GOOD_CONDITION = 'mint',
  TO_REFORM = 'to_reform',
  BUY = 'buy',
  RENT = 'rent',
}
