import { Injectable } from '@angular/core';
import { TermsNComms } from '@api/core/model/terms-n-comms/terms-n-comms.interface';
import { TermsNCommsApiService } from '@api/terms-n-comms/terms-n-comms-api.service';
import {
  AnalyticsEvent,
  AnalyticsPageView,
  ANALYTICS_EVENT_NAMES,
  ANALYTICS_EVENT_TYPES,
  ClickAcceptTermsAndCommunications,
  ClickRefuseTermsAndCommunications,
  SCREEN_IDS,
  ViewTermsAndCommunicationsUpdate,
  ANALYTICS_SCREEN_EVENT_NAMES,
} from '@wallapop/analytics/constants';
import { AnalyticsService } from '@core/analytics/analytics.service';
import { LogoutService } from '@core/logout/logout.service';
import { UserService } from '@core/user/user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, take } from 'rxjs/operators';
import { TermsNCommsModalComponent } from '../components/terms-n-comms-modal/terms-n-comms-modal.component';

@Injectable()
export class TermsNCommsService {
  constructor(
    private modalService: NgbModal,
    private termsNCommsApiService: TermsNCommsApiService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private logoutService: LogoutService,
  ) {}

  public showTermsNCommsModal(): void {
    const user = this.userService.user;
    if (user && !user.hasAcceptedTerms) {
      const modalRef: NgbModalRef = this.modalService.open(TermsNCommsModalComponent, {
        backdrop: 'static',
        keyboard: false,
        animation: false,
      });

      this.trackViewGdprPopUp();

      modalRef.result.then((termsNComms: TermsNComms) => {
        termsNComms ? this.acceptedTermsAndComms(termsNComms) : this.rejectedTermsAndComms();
      });
    }
  }

  private acceptedTermsAndComms(termsNComms: TermsNComms): void {
    this.trackClickAcceptTermsAndComunications(termsNComms.comms);

    this.termsNCommsApiService
      .updateTermsAndComms(termsNComms)
      .pipe(
        switchMap(() => this.userService.getAndUpdateLoggedUser()),
        take(1),
      )
      .subscribe();
  }

  private rejectedTermsAndComms(): void {
    this.trackClickRefuseTermsAndComunications();
    this.logoutService.logout().subscribe();
  }

  private trackViewGdprPopUp(): void {
    const event: AnalyticsPageView<ViewTermsAndCommunicationsUpdate> = {
      name: ANALYTICS_SCREEN_EVENT_NAMES.ViewTermsAndCommunicationsUpdate,
      attributes: {
        screenId: SCREEN_IDS.TermsCommunicationsConsentUpdate,
      },
    };

    this.analyticsService.trackPageView(event);
  }

  private trackClickAcceptTermsAndComunications(communicationsConsent: boolean): void {
    const event: AnalyticsEvent<ClickAcceptTermsAndCommunications> = {
      name: ANALYTICS_EVENT_NAMES.ClickAcceptTermsAndCommunications,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        screenId: SCREEN_IDS.TermsCommunicationsConsentUpdate,
        communicationsConsent,
      },
    };

    this.analyticsService.trackEvent(event);
  }

  private trackClickRefuseTermsAndComunications(): void {
    const event: AnalyticsEvent<ClickRefuseTermsAndCommunications> = {
      name: ANALYTICS_EVENT_NAMES.ClickRefuseTermsAndCommunications,
      eventType: ANALYTICS_EVENT_TYPES.Navigation,
      attributes: {
        screenId: SCREEN_IDS.TermsCommunicationsConsentUpdate,
      },
    };

    this.analyticsService.trackEvent(event);
  }
}
