export type HttpResponseCodeType = (typeof HTTP_RESPONSE_CODE)[keyof typeof HTTP_RESPONSE_CODE];

export const HTTP_RESPONSE_CODE = {
  GONE: 410,
  UNAUTHORIZED_ERROR: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_CONTENT: 422,
  INTERNAL_SERVER_ERROR: 500,
} as const;
