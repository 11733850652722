import { LIVERAMP_ATS_REFRESH_ENVELOPES_URL, LIVERAMP_ATS_URL } from '../ads-sources';
import {
  CONSENT_TYPES,
  ENVELOPE_DEFAULT_IDENTIFIER_TYPE,
  IDENTIFIER_TYPES,
  IdentifierTypes,
  LIVERAMP_ENVELOPE_COOKIE_NAME,
  LIVERAMP_ENVELOPE_EXPIRATION_TIME,
  LIVERAMP_IDENTIFIER_HASH_COOKIE_NAME,
  WALLAPOP_PLACEMENT_ID,
} from '../constants';
import { LiverampConfig, LiverampEnvelope, LiverampEnvelopeCookie, LiverampEnvelopeResponse } from '../interfaces/liveramp.interfaces';
import { deleteCookie, getCookie, setCookie } from '../utils/cookie.utils';

export const init = (consentHash: string, identifier: string, identifierType: IdentifierTypes = ENVELOPE_DEFAULT_IDENTIFIER_TYPE) => {
  const cookieIdentifier = getCookie(LIVERAMP_IDENTIFIER_HASH_COOKIE_NAME);
  const cookieEnvelope = _decodeEnvelope(getCookie(LIVERAMP_ENVELOPE_COOKIE_NAME));
  const shouldRegisterEnvelope = !cookieIdentifier || !cookieEnvelope || cookieIdentifier !== identifier;
  const liverampConfig: LiverampConfig = {
    pid: WALLAPOP_PLACEMENT_ID,
    it: shouldRegisterEnvelope ? identifierType : IDENTIFIER_TYPES.ENVELOPE,
    iv: shouldRegisterEnvelope ? identifier : cookieEnvelope,
    ct: CONSENT_TYPES.UE,
    cv: consentHash,
  };

  shouldRegisterEnvelope ? _registerEnvelope(liverampConfig) : _refreshEnvelope(liverampConfig);
  (<any>window)['ats'] = {};
  (<any>window)['ats'].retrieveEnvelope = retrieveEnvelope;
};

const _registerEnvelope = (liverampConfig: LiverampConfig): void => {
  const body = _getLiverampApiParams(liverampConfig);

  fetch(`${LIVERAMP_ATS_URL}?${body}`, { method: 'GET' })
    .then((res) => res.json())
    .then((envelopeResponse: LiverampEnvelopeResponse) => {
      const envelope: LiverampEnvelope = envelopeResponse.envelopes?.[0];
      const encodedEnvelope = _encodeEnvelope(envelope);

      if (encodedEnvelope) {
        setCookie(LIVERAMP_ENVELOPE_COOKIE_NAME, encodedEnvelope);
        setCookie(LIVERAMP_IDENTIFIER_HASH_COOKIE_NAME, liverampConfig.iv);
        setCookie(LIVERAMP_ENVELOPE_EXPIRATION_TIME, _calculateEnvelopeTTL().toString());
      }
    })
    .catch((error) => console.error('Cannot register liveramp envelope', error));
};

const _refreshEnvelope = (liverampConfig: LiverampConfig): void => {
  const body = _getLiverampApiParams(liverampConfig);
  const envelopeExpirationTime = +getCookie(LIVERAMP_ENVELOPE_EXPIRATION_TIME);
  const now = +new Date();

  if (now < envelopeExpirationTime) {
    return;
  }

  fetch(`${LIVERAMP_ATS_REFRESH_ENVELOPES_URL}?${body}`, { method: 'GET' })
    .then(() => {
      setCookie(LIVERAMP_ENVELOPE_EXPIRATION_TIME, _calculateEnvelopeTTL().toString());
    })
    .catch((error) => {
      deleteCookie(LIVERAMP_ENVELOPE_COOKIE_NAME);
      deleteCookie(LIVERAMP_IDENTIFIER_HASH_COOKIE_NAME);
      deleteCookie(LIVERAMP_ENVELOPE_EXPIRATION_TIME);

      console.error('Cannot refresh liveramp envelope', error);
    });
};

const _getLiverampApiParams = (liverampConfig: LiverampConfig): URLSearchParams => {
  const { pid, it, iv, ct, cv } = liverampConfig;
  return new URLSearchParams({
    pid,
    it: it.toString(),
    iv,
    ct: ct.toString(),
    cv,
  });
};

const _encodeEnvelope = (envelope: LiverampEnvelope): string => {
  const envelopeToEncode: LiverampEnvelopeCookie = { envelope: [envelope.value] };

  return encodeURIComponent(window.btoa(JSON.stringify(envelopeToEncode)));
};

const _decodeEnvelope = (envelope: string): string | null => {
  try {
    if (!envelope) {
      return null;
    }
    const decodedEnvelope = decodeURIComponent(envelope);

    return JSON.parse(window.atob(decodedEnvelope))?.envelope[0];
  } catch (e) {
    console.log('Cannot decode envelope', e);
    deleteCookie(LIVERAMP_ENVELOPE_COOKIE_NAME);
    deleteCookie(LIVERAMP_IDENTIFIER_HASH_COOKIE_NAME);
    deleteCookie(LIVERAMP_ENVELOPE_EXPIRATION_TIME);
    return null;
  }
};

const _calculateEnvelopeTTL = (countryCode?: string, regionCode?: string): number => {
  const expirationTime = 1296000000 + +new Date();

  return expirationTime;
};

// This a mocked function needed from prebid
const retrieveEnvelope = (callback: any) => {
  let env = undefined;
  try {
    env = decodeURIComponent(document.cookie.match('(^|;) *_lr_env=([^;]*)')?.[2] || '');
    env = {
      envelope: JSON.parse(window.atob(env)).envelope,
    };
    env = JSON.stringify(env);
  } catch (e) {
    console.log(e);
  } finally {
    if (callback) {
      callback(env);
    } else {
      return env;
    }
  }
};
